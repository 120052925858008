import { FC, ReactElement } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import styles from './advertise.module.scss'

const Advertise: FC = (): ReactElement => {
  const [t]: UseTranslationResponse<'login'> = useTranslation('login')

  return (
    <div className={styles.advertise} data-test="advertise">
      <p className={styles.text}>{t('login.advertise')}</p>
    </div>
  )
}

export default Advertise
