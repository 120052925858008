import i18next from 'i18next'
import sortBy from 'lodash/sortBy'
import { TFunction } from 'react-i18next'
import { Dispatch } from 'redux'

import { InputType, RelationalOperator } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { FieldType } from '../../../../../dictionaries'
import { IFormFieldConfig } from '../../../../../interfaces/common'
import { closeSideView } from '../../../../Layout/store/actionCreators'
import { forms } from '../../../constants'
import {
  Country,
  IExtension,
  ILocalizedString,
  IReferralPartner,
  IReferralPartnerProduct,
  IReferralPartnerStatus,
  UsedBy
} from '../../../interfaces'
import { setSideView } from '../../../store/actions/referralPartner'

import styles from '../partnerActivation.module.scss'

export const generateProducts = (
  products: IReferralPartnerProduct[],
  country = 'DE',
  hasKnownStatus: boolean
): IFormFieldConfig[] => {
  const currentLocale: keyof ILocalizedString = (i18next.language || 'de') as never

  const productData: IFormFieldConfig[] = sortBy(
    products,
    ({ displayName }) => displayName.alteos[currentLocale]
  ).reduce((productAcc, currProduct) => {
    const isPartnerCountry = currProduct.country?.includes(country as Country) === true
    const isInternalAllowed = currProduct.isUsedBy?.includes(UsedBy.Alteos) === true

    if (isPartnerCountry && isInternalAllowed) {
      return [
        ...productAcc,
        {
          editType: InputType.checkbox,
          id: `products.${currProduct.id}`,
          label: currProduct.displayName.alteos[currentLocale],
          disabled: !hasKnownStatus
        },
        ...((currProduct.extensions || []) as IExtension[]).reduce((extensionAcc, currExtension) => {
          const isAllowedExtension = currExtension.isUsedBy?.includes(UsedBy.Alteos) === true
          if (isAllowedExtension) {
            return [
              ...extensionAcc,
              {
                id: `productConfigExtension["${currProduct.id}"].values.${currExtension.name}`,
                editType: InputType.select,
                required: currExtension.required,
                availableIf: [
                  {
                    field: `products.${currProduct.id}`,
                    value: true,
                    operator: RelationalOperator.eq
                  }
                ],
                label: currExtension.label[currentLocale],
                options: (currExtension.values || []).map((option) => ({
                  key: typeof option.key === 'boolean' ? option.key : `${option.key}`,
                  value: option.label[currentLocale]
                })),
                placeholder: currExtension.label[currentLocale],
                disabled: !hasKnownStatus
              }
            ] as unknown as IExtension[]
          }
          return extensionAcc
        }, [] as IExtension[])
      ] as IFormFieldConfig[]
    }
    return productAcc
  }, [] as IFormFieldConfig[])

  return productData
}

export const generateUpdatePartnerCards = (
  t: TFunction,
  partner: IReferralPartner,
  products: IReferralPartnerProduct[],
  disabledButtonSaveUpdatePartnerForm: boolean,
  dispatch: Dispatch
): IFormConfig => {
  const hasKnownStatus = Object.values(IReferralPartnerStatus).includes(partner.status)
  const productCheckboxes = generateProducts(products, partner.country as string, hasKnownStatus)

  const formConfig: IFormConfig = {
    form: forms.updateReferralPartner,
    url: '',
    initialValues: { ...partner, updateNotes: '' },
    successMessage: `${t('referralPartner.edit.successMessageUpdate')}`,
    formSections: [
      {
        title: t('referralPartner.edit.websiteDataSectionTitle'),
        icon: 'details',
        fields: [
          {
            id: 'companyName',
            editType: InputType.string,
            required: true,
            size: 'large',
            maximum: 100,
            label: t('referralPartner.edit.companyName'),
            disabled: !hasKnownStatus
          },
          {
            id: 'legalEntity',
            editType: InputType.select,
            options: [
              { key: 'AG', value: 'AG' },
              { key: 'e.K.', value: 'e.K.' },
              { key: 'e.V.', value: 'e.V.' },
              { key: 'Einzelkauffrau/Einzelkaufmann', value: 'Einzelkauffrau/Einzelkaufmann' },
              { key: 'Einzelunternehmen', value: 'Einzelunternehmen' },
              { key: 'GbR', value: 'GbR' },
              { key: 'gGmbH', value: 'gGmbH' },
              { key: 'GmbH', value: 'GmbH' },
              { key: 'GmbH & Co. KG', value: 'GmbH & Co. KG' },
              { key: 'KG', value: 'KG' },
              { key: 'KGaA', value: 'KGaA' },
              { key: 'oHG', value: 'oHG' },
              { key: 'UG (haftungsbeschränkt)', value: 'UG (haftungsbeschränkt)' }
            ],
            required: true,
            size: 'xsmall',
            maximum: 100,
            label: t('referralPartner.edit.legalEntity'),
            disabled: !hasKnownStatus
          },
          {
            id: 'subdomain',
            editType: InputType.string,
            required: true,
            size: 'full',
            regex: '^[a-z0-9](?:-?[a-z0-9]+)*$',
            maximum: 40,
            label: t('referralPartner.edit.subdomain'),
            disabled: !hasKnownStatus
          },
          {
            id: 'companyDisplayName',
            editType: InputType.string,
            required: true,
            size: 'full',
            maximum: 100,
            label: t('referralPartner.edit.companyDisplayName'),
            placeholder: t('referralPartner.edit.companyDisplayNamePlaceHolder'),
            disabled: !hasKnownStatus
          },
          {
            type: FieldType.Divider
          },
          {
            type: FieldType.Title,
            value: t('referralPartner.edit.products'),
            disabled: !hasKnownStatus
          },
          ...productCheckboxes
        ]
      },
      {
        title: t('referralPartner.edit.contactDataSectionTitle'),
        icon: 'details',
        fields: [
          {
            id: 'companyAddressStreet',
            editType: InputType.string,
            required: true,
            size: 'large',
            label: t('referralPartner.edit.companyAddressStreet'),
            disabled: !hasKnownStatus
          },
          {
            id: 'companyAddressHouseNumber',
            editType: InputType.string,
            required: true,
            size: 'xsmall',
            maximum: 100,
            label: t('referralPartner.edit.companyAddressHouseNumber'),
            disabled: !hasKnownStatus
          },
          {
            id: 'companyAddressPlz',
            editType: InputType.string,
            required: true,
            size: 'xsmall',
            label: t('referralPartner.edit.companyAddressPlz'),
            disabled: !hasKnownStatus
          },
          {
            id: 'companyAddressCity',
            editType: InputType.string,
            required: true,
            size: 'large',
            maximum: 100,
            label: t('referralPartner.edit.companyAddressCity'),
            disabled: !hasKnownStatus
          },
          {
            type: FieldType.Divider
          },
          {
            id: 'companyPhone',
            editType: InputType.string,
            required: false,
            size: 'half',
            label: t('referralPartner.edit.companyPhone'),
            disabled: !hasKnownStatus
          },
          {
            id: 'companyEmail',
            editType: InputType.email,
            maximum: 100,
            required: true,
            size: 'half',
            label: t('referralPartner.edit.companyEmail'),
            disabled: !hasKnownStatus
          }
        ]
      },
      {
        title: t('referralPartner.edit.additionnalDataSectionTitle'),
        icon: 'details',
        fields: [
          {
            id: 'legalRepFirstName',
            editType: InputType.string,
            required: true,
            maximum: 100,
            size: 'half',
            label: t('referralPartner.edit.legalRepFirstName'),
            disabled: !hasKnownStatus
          },
          {
            id: 'legalRepLastName',
            editType: InputType.string,
            required: true,
            maximum: 100,
            size: 'half',
            label: t('referralPartner.edit.legalRepLastName'),
            disabled: !hasKnownStatus
          },
          {
            id: 'salesTaxIdNumber',
            editType: InputType.string,
            required: true,
            size: 'half',
            label: t('referralPartner.edit.salesTaxIdNumber'),
            disabled: !hasKnownStatus
          },
          {
            id: 'issuingAuthority',
            editType: InputType.string,
            required: false,
            maximum: 100,
            size: 'half',
            label: t('referralPartner.edit.issuingAuthority'),
            disabled: !hasKnownStatus
          },
          {
            id: 'companyRegistrationNumber',
            editType: InputType.string,
            required: false,
            maximum: 100,
            size: 'half',
            label: t('referralPartner.edit.companyRegistrationNumber'),
            disabled: !hasKnownStatus
          },
          {
            type: FieldType.Divider
          },
          {
            id: 'billingEmail',
            editType: InputType.email,
            maximum: 100,
            required: true,
            size: 'half',
            label: t('referralPartner.edit.billingEmail'),
            disabled: !hasKnownStatus
          },
          {
            id: 'IBAN',
            editType: InputType.string,
            required: false,
            size: 'half',
            label: t('referralPartner.edit.iban'),
            disabled: !hasKnownStatus
          }
        ]
      },
      {
        title: t('referralPartner.edit.partnerPortalSectionTitle'),
        icon: 'users',
        fields: [
          {
            editType: InputType.checkbox,
            id: 'isPartnerPortalAllowed',
            label: t('referralPartner.edit.isPartnerPortalAllowed'),
            disabled: !hasKnownStatus
          }
        ]
      },
      {
        title: t('referralPartner.edit.savingDataSectionTitle'),
        icon: 'edit',
        fields: [
          {
            id: 'updateNotes',
            editType: InputType.text,
            label: t('referralPartner.edit.notes'),
            placeholder: t('referralPartner.edit.notesPlaceholder'),
            maximum: 500,
            disabled: !hasKnownStatus || disabledButtonSaveUpdatePartnerForm
          },
          {
            editType: InputType.button,
            label: t('referralPartner.edit.cancelChanges'),
            dataTest: 'cancel-changes-SP-button',
            buttonType: 'reset',
            handleClick: () => {
              dispatch(setSideView(null))
              dispatch(closeSideView())
            },
            color: 'tertiary',
            noMinWidth: true,
            style: styles.formButton
          },
          {
            editType: InputType.button,
            label: t('referralPartner.edit.saveChanges'),
            dataTest: 'save-changes-SP-button',
            disabled: !hasKnownStatus || disabledButtonSaveUpdatePartnerForm,
            noMinWidth: true
          }
        ]
      }
    ]
  }

  return formConfig
}

export default generateUpdatePartnerCards
