import { IRequestPagination } from '@alteos/ui'

import { IFilter } from '../../../../components/Filters/interfaces'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { DEFAULT_PAGINATION } from '../../constants'
import { IGetPaymentLinkStatusesResponsePayload, ISetSideViewPayload, IUpcomingPayments } from '../../interfaces'
import {
  ADD_FILTER_UPCOMING_PAYMENTS,
  CREATE_BULK_PAYMENT_LINKS_REQUEST,
  CREATE_BULK_PAYMENT_LINKS_SUCCESS,
  CREATE_PAYMENT_LINK_REQUEST,
  CREATE_PAYMENT_LINK_SUCCESS,
  GET_PAYMENT_LINK_REQUEST,
  GET_PAYMENT_LINK_SUCCESS,
  LOAD_UPCOMING_PAYMENTS_FAILURE,
  LOAD_UPCOMING_PAYMENTS_REQUEST,
  LOAD_UPCOMING_PAYMENTS_SUCCESS,
  ON_CHANGE_FILTERS_UPCOMING_PAYMENTS,
  REMOVE_FILTER_UPCOMING_PAYMENTS,
  RESET_FILTERS_UPCOMING_PAYMENTS,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SET_FILTER_UPCOMING_PAYMENTS,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS
} from '../constants'

export function loadUpcomingPaymentsStart(
  payload: IRequestPagination = DEFAULT_PAGINATION
): IAction<IRequestPagination> {
  return {
    type: LOAD_UPCOMING_PAYMENTS_REQUEST,
    payload
  }
}
export function loadUpcomingPaymentsSuccess(payload: IUpcomingPayments[]): IAction<any> {
  return {
    type: LOAD_UPCOMING_PAYMENTS_SUCCESS,
    payload
  }
}

export function loadUpcomingPaymentsFailure(): IBasicAction {
  return {
    type: LOAD_UPCOMING_PAYMENTS_FAILURE
  }
}

export function setSideView(payload: ISetSideViewPayload | null): IAction<ISetSideViewPayload | null> {
  return {
    type: SET_ACTIVE_SIDE_VIEW_TYPE,
    payload
  }
}

export function addActiveFilterUpcomingPayments(payload: string): IAction<string> {
  return {
    type: ADD_FILTER_UPCOMING_PAYMENTS,
    payload
  }
}

export function setFilterUpcomingPayments(payload: IFilter): IAction<IFilter> {
  return {
    type: SET_FILTER_UPCOMING_PAYMENTS,
    payload
  }
}

export function removeFilterUpcomingPayments(payload: string): IAction<string> {
  return {
    type: REMOVE_FILTER_UPCOMING_PAYMENTS,
    payload
  }
}

export function resetFiltersUpcomingPayments(): IBasicAction {
  return {
    type: RESET_FILTERS_UPCOMING_PAYMENTS
  }
}

export function onChangeFiltersUpcomingPayments(payload?: Partial<IRequestPagination>): IAction<any> {
  return {
    type: ON_CHANGE_FILTERS_UPCOMING_PAYMENTS,
    payload
  }
}

export function updatePaymentMethodStart(
  payload: IFormConfig,
  paymentScheduleId: string
): IAction<IFormConfig & { paymentScheduleId: string }> {
  return {
    type: UPDATE_PAYMENT_METHOD_REQUEST,
    payload: {
      ...payload,
      paymentScheduleId
    }
  }
}

export function updatePaymentMethodSuccess(): IBasicAction {
  return {
    type: UPDATE_PAYMENT_METHOD_SUCCESS
  }
}

export function createPaymentLinkStart(paymentScheduleId: string): IAction<{ paymentScheduleId: string }> {
  return {
    type: CREATE_PAYMENT_LINK_REQUEST,
    payload: {
      paymentScheduleId
    }
  }
}

export function createPaymentLinkSuccess(): IBasicAction {
  return {
    type: CREATE_PAYMENT_LINK_SUCCESS
  }
}

export function createBulkPaymentLinksSuccess(): IBasicAction {
  return {
    type: CREATE_BULK_PAYMENT_LINKS_SUCCESS
  }
}

export function createBulkPaymentLinksStart(paymentScheduleIds: string[]): IAction<{ paymentScheduleIds: string[] }> {
  return {
    type: CREATE_BULK_PAYMENT_LINKS_REQUEST,
    payload: {
      paymentScheduleIds
    }
  }
}

export function getPaymentLinkStart(paymentScheduleIds: string[]): IAction<{ paymentScheduleIds: string[] }> {
  return {
    type: GET_PAYMENT_LINK_REQUEST,
    payload: {
      paymentScheduleIds
    }
  }
}

export function getPaymentLinkSuccess(payload: IGetPaymentLinkStatusesResponsePayload): IAction<any> {
  return {
    type: GET_PAYMENT_LINK_SUCCESS,
    payload
  }
}

export function saveErrorMessage(
  message: string,
  type: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type,
    payload: {
      message,
      originalError
    }
  }
}
