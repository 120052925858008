import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { IGroup, IPartnerResponse } from '../../interfaces'
import { LOAD_PARTNER_FAILURE, LOAD_PARTNER_REQUEST, LOAD_PARTNER_SUCCESS } from '../constants'

export function loadPartnerStart(
  partnerId: string,
  groups: IGroup[]
): IAction<{ partnerId: string; groups: IGroup[] }> {
  return {
    type: LOAD_PARTNER_REQUEST,
    payload: { partnerId, groups }
  }
}
export function loadPartnerSuccess(payload: IPartnerResponse): IAction<any> {
  return {
    type: LOAD_PARTNER_SUCCESS,
    payload
  }
}

export function loadPartnerFailure(): IBasicAction {
  return {
    type: LOAD_PARTNER_FAILURE
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: LOAD_PARTNER_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}
