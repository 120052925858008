import { IProductConfigExtension, IReferralPartnerUpdateRequest } from '../../interfaces'
import { mapCommissionRateValueToNumber } from './mapCommissionValueToNumber'

export function prepareUpdateReferralPartnerBody(values: { [fieldName: string]: any }): IReferralPartnerUpdateRequest {
  const referralPartnerUpdateRequestPayload = mapValuesToUpdateRequestPayload(values)

  return referralPartnerUpdateRequestPayload
}

function mapValuesToUpdateRequestPayload(values: { [fieldName: string]: any }) {
  const {
    products: productSelection,
    productConfigExtension: selectedProductConfigExtension,
    subdomain,
    companyName,
    companyDisplayName,
    issuingAuthority,
    legalEntity,
    legalRepFirstName,
    legalRepLastName,
    companyEmail,
    billingEmail,
    IBAN,
    salesTaxIdNumber,
    companyPhone,
    companyRegistrationNumber,
    companyAddressHouseNumber,
    companyAddressCity,
    companyAddressStreet,
    companyAddressPlz,
    updateNotes,
    isPartnerPortalAllowed
  } = values

  const selectedProductIds: string[] = mapToProductIds(productSelection as { [key: string]: boolean })

  const productConfigExtension: IProductConfigExtension = mapToProductConfigExtension(
    selectedProductConfigExtension,
    selectedProductIds
  )

  return {
    subdomain,
    companyName,
    companyEmail,
    billingEmail,
    companyDisplayName,
    issuingAuthority,
    legalEntity,
    legalRepFirstName,
    legalRepLastName,
    companyAddressPlz,
    companyAddressHouseNumber,
    companyAddressCity,
    companyAddressStreet,
    iban: IBAN,
    salesTaxIdNumber,
    companyPhone,
    companyRegistrationNumber,
    productIds: selectedProductIds,
    productConfigExtension,
    notes: updateNotes,
    isPartnerPortalAllowed
  }
}

function mapToProductIds(productSelection: { [key: string]: boolean }): string[] {
  return Object.entries(productSelection)
    .map((value: [string, boolean]) => (value[1] === true ? value[0] : null))
    .filter((value: string | null): value is string => value !== null && value !== '')
}

function mapToProductConfigExtension(
  selectedProductConfigExtension: IProductConfigExtension,
  selectedProductIds: string[]
): IProductConfigExtension {
  if (typeof selectedProductConfigExtension === 'undefined' || selectedProductConfigExtension === null) {
    return {}
  }
  const filteredProductConfigExtension: IProductConfigExtension = Object.fromEntries(
    Object.entries(selectedProductConfigExtension as IProductConfigExtension).filter((value) =>
      selectedProductIds.includes(value[0])
    )
  )
  return mapCommissionRateValueToNumber(filteredProductConfigExtension)
}
