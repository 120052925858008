import i18next from 'i18next'
import { all, put, spawn } from 'typed-redux-saga'

import { sagas as auth } from '../modules/Auth'
import { logout } from '../modules/Auth/store/actionCreators'
import { sagas as i18n } from '../modules/i18n'
import { sagas as invoices } from '../modules/Invoices'
import { sagas as partner } from '../modules/Partner'
import { sagas as partners } from '../modules/PartnersManagement'
import { sagas as payments } from '../modules/Payments'
import { sagas as policies } from '../modules/Policies'
import { sagas as policy } from '../modules/Policy'
import { ERROR_ROUTE } from '../modules/Static'
import { isUnauthorizedException } from '../services/api/exceptions'
import { getRouter } from './utils/getRouter'
import { triggerErrorPopup } from './utils/triggerErrorPopup'

export default function* rootSaga(): any {
  const sagas: Generator[] = [
    ...auth(),
    ...policies(),
    ...policy(),
    ...partners(),
    ...partner(),
    ...payments(),
    ...invoices(),
    ...i18n()
  ]
  yield* all(
    sagas.map((saga: Generator) =>
      spawn(function* () {
        while (true) {
          try {
            yield saga
          } catch (error: unknown) {
            const router = yield* getRouter()
            if (isUnauthorizedException(error)) {
              yield* put(logout())
              yield* triggerErrorPopup(i18next.t('login:login.tokenExpiredError'))
            } else {
              // eslint-disable-next-line no-console
              console.error('error in saga occured', error)
              void router.navigate(ERROR_ROUTE, { state: { error } })
            }
          }
        }
      })
    )
  )
}
