export function FileUploaderFolderIcon(): React.ReactElement {
  return (
    <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.513 9.8H69.4v53.826H12.513V9.801Z" fill="#DFDFDF" />
      <path
        d="M69.4 32.522v6.789c-1.389 4.922-4.63 10.711-6.543 13.26l-6-18.571c2.988-1.81 10.26-5.084 12.543-1.478ZM69.4 21.718v1.99c-8.888.105-13.034 2.419-19.971 6.578l-7.143-1.143c2.663-1.04 5.025-2.085 7.218-3.056 6.942-3.074 12.185-5.396 19.896-4.369ZM69.4 13.826v3.237c-12.386-.46-18.009 1.701-25.415 4.547-4.279 1.645-9.153 3.518-16.27 5.247l-2-8.857c2.774-.416 5.482-.126 7.926.136 2.59.278 4.883.524 6.645-.136 3.113-1.166 15.01-5.365 29.114-4.174ZM47.752 9.8H69.4v1.228c-8.462.79-13.865.622-21.648-1.227ZM16.231 9.8l-3.718 2.146V9.801h3.718Z"
        fill="#000"
        fillOpacity=".1"
      />
      <path d="M33.26 27.475h31.518v35.182H6.57V15.764H32.26v11.711h1Z" fill="#fff" stroke="#A5A8AD" strokeWidth="2" />
      <circle cx="63.971" cy="60.201" fill="#2F3237" r="10.4" />
      <path d="M63.97 55.398v9.6M68.77 60.199h-9.6" stroke="#fff" strokeWidth="2" />
    </svg>
  )
}
