import { ProcessingType } from '../../../dictionaries'
import { IRootState } from '../../../store/interfaces'
import { ModalId } from '../../Modal/store/interfaces'
import { IMandate, IPolicyModuleState, IPurchase } from '../interfaces'

export function getModalId(state: IRootState): ModalId | null {
  return state.modal.modalId
}

export function getPurchaseData(state: IRootState): IPurchase | null {
  return state.policy.purchaseData
}

export function getPaymentProcessingType(state: IRootState): ProcessingType | undefined {
  return state.policy.purchaseData?.processingData.type
}
export function getMandate(state: IRootState): IMandate | null {
  return state.policy.mandate
}

export function getTerminationData(state: IRootState): IPolicyModuleState['terminationData'] {
  return state.policy.terminationData
}
