import { requireNotNull } from '../../../utils'
import { AUTH_CLEAR_ERROR, LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS, LOGOUT, REFRESH_TOKEN } from '../constants'
import { IAuthAction, IAuthState } from '../interfaces'

const initialState: IAuthState = {
  currentUser: null,
  errorMessage: null,
  isFetching: false
}

export function authReducer(state: IAuthState = initialState, action: IAuthAction): IAuthState {
  switch (action.type) {
    case LOGIN_START: {
      return { ...state, isFetching: true }
    }
    case LOGIN_SUCCESS: {
      const { loginResponse } = action.payload
      return {
        ...state,
        currentUser: {
          name: loginResponse.name,
          permissions: loginResponse.permissions,
          refreshToken: loginResponse.refreshToken,
          token: loginResponse.token,
          userId: loginResponse.userId
        },
        isFetching: false,
        errorMessage: null
      }
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        currentUser: null,
        errorMessage: action.payload.errorMessage,
        isFetching: false
      }
    }
    case LOGOUT: {
      return {
        ...state,
        currentUser: null,
        errorMessage: null,
        isFetching: false
      }
    }
    case REFRESH_TOKEN: {
      const currentUser = requireNotNull(state.currentUser, 'cannot refresh token if user is null')
      const { refreshToken, token } = action.payload
      return {
        ...state,
        currentUser: {
          ...currentUser,
          token,
          refreshToken
        }
      }
    }
    case AUTH_CLEAR_ERROR: {
      return {
        ...state,
        errorMessage: null
      }
    }
    default: {
      return state
    }
  }
}
