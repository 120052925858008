/* eslint-disable @typescript-eslint/typedef */
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

const DEFAULT_DELAY = 500

function useDebounce<T>(value: T, delay: number = DEFAULT_DELAY): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebounced = useCallback(
    debounce((prop: T) => {
      setDebouncedValue(prop)
    }, delay),
    []
  )

  useEffect(() => {
    setDebounced(value)
  }, [setDebounced, value])

  return debouncedValue
}

export default useDebounce
