import { CombinedState, combineReducers, Reducer } from 'redux'

import { IAction } from '../../../interfaces/store'
import PartnerTab from '../dictionaries/PartnerTab'
import { IPartnerTabState, ITabs } from '../interfaces'
import { SET_ACTIVE_PARTNER_TAB } from './constants'
import groupsReducer from './reducers/groups'
import partnerReducer from './reducers/partner'
import partnerPortalReducer from './reducers/partnerPortal'
import permissionsReducer from './reducers/permissions'
import refferalPartnerReducer from './reducers/referralPartner'
import { defaultReferralPartner } from './utils/defaultReferralPartner'

export const initialState: IPartnerTabState = {
  tabs: { activeTab: PartnerTab.Groups },
  partnerState: {
    isFetchingPartner: false,
    partner: { id: '', name: '' },
    errorMessage: null
  },
  referralPartnerState: {
    isFetchingPartner: false,
    isActivatingReferralPartner: false,
    isRejectingReferralPartner: false,
    isTerminatingReferralPartner: false,
    isUpdatingReferralPartner: false,
    activeSideViewType: null,
    referralPartner: defaultReferralPartner,
    errorMessage: null,
    products: [],
    referralPartnerLog: []
  },
  groupsState: {
    isFetchingGroups: false,
    groups: [],
    errorMessage: null
  },
  permissionsState: {
    isFetchingPermissions: false,
    permissions: {},
    errorMessage: null
  },
  partnerPortalState: {
    roles: [],
    isImportingPartnerPortalUsers: false,
    errorMessage: null
  }
}

function partnerTabReducer(state: ITabs = { activeTab: PartnerTab.Groups }, action: IAction<any>): ITabs {
  switch (action.type) {
    case SET_ACTIVE_PARTNER_TAB:
      return { activeTab: action.payload as PartnerTab }

    default:
      return state
  }
}

const reducer: Reducer<CombinedState<IPartnerTabState>, IAction<any>> = combineReducers({
  tabs: partnerTabReducer,
  referralPartnerState: refferalPartnerReducer,
  partnerState: partnerReducer,
  permissionsState: permissionsReducer,
  groupsState: groupsReducer,
  partnerPortalState: partnerPortalReducer
})

export default reducer
