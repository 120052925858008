import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { Container, EditModeLabel } from '../../../../components/sideViewComponents'
import { ProcessingType } from '../../../../dictionaries'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { getPaymentProcessingType } from '../../store/selectors'
import { generateReduxFormConfig } from '../../utils'
import { SEPA_ENDORSEMENT_MODAL_ID, NON_SEPA_ENDORSEMENT_MODAL_ID } from './EndorsementModal/constants'
import { NonSepaEndorsementModal } from './EndorsementModal/NonSepaEndorsementModal'
import { SepaEndorsementModal } from './EndorsementModal/SepaEndorsementModal'
import generatePolicyObjectFormCards from './utils/generatePolicyObjectFormCards'

import styles from './styles.module.scss'

const PolicyObjectForm: FC = () => {
  const dispatch = useDispatch()
  const policy = useSelector((state: IStore) => state[MODULE_NAME].policy)
  const config = useSelector((state: IStore) => state[MODULE_NAME].config)
  const isSubmitting = useSelector((state: IStore) => state[MODULE_NAME].isSubmitting)
  const processingType = useSelector(getPaymentProcessingType)
  const { t } = useTranslation('policy')

  if (policy === null || config === null) {
    return null
  }

  const formConfig = generatePolicyObjectFormCards(config, policy, t)
  const props = generateReduxFormConfig(formConfig, dispatch, {
    helpers: policy,
    isSubmitting,
    formType: FormType.EDIT
  })

  return (
    <>
      <Container className={styles.title} title={t('policyObjectForm.title')} icon="pencil" label={<EditModeLabel />} />
      <Form {...props} />
      {processingType === ProcessingType.sepa ? (
        <SepaEndorsementModal modalId={SEPA_ENDORSEMENT_MODAL_ID} />
      ) : (
        <NonSepaEndorsementModal modalId={NON_SEPA_ENDORSEMENT_MODAL_ID} />
      )}
    </>
  )
}

export default PolicyObjectForm
