import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IStore } from '../../../../../interfaces/store'
import { IRootState } from '../../../../../store/interfaces'
import { forms, MODULE_NAME } from '../../../constants'
import { IClaim } from '../../../interfaces'
import { generateReduxFormConfig } from '../../../utils'
import generateUpdateClaimStatusFormCard from './utils/generateUpdateClaimStatusFormCard'

type ClaimReserveProps = { claim: IClaim }

const ClaimUpdateStatusForm: FC<ClaimReserveProps> = ({ claim }: ClaimReserveProps): ReactElement => {
  const dispatch = useDispatch()

  const formValues = useSelector((state: IStore) => state.form[forms.editTransactionUpdateStatusForm]?.values)

  const claimStatus = formValues?.status

  const isSubmitting = useSelector((state: IRootState) => state[MODULE_NAME].isSubmitting)
  const { t } = useTranslation(['policy'])

  const reserveFormConfig = generateUpdateClaimStatusFormCard(t, claim, claimStatus)

  const updateFormProps: IFormProps = generateReduxFormConfig(reserveFormConfig, dispatch, {
    hasFormButtons: true,
    form: forms.editTransactionUpdateStatusForm,
    dataTest: forms.editTransactionUpdateStatusForm,
    formType: FormType.CREATE,
    isSubmitting
  })

  return <Form {...updateFormProps} />
}

export default ClaimUpdateStatusForm
