import { IAction } from '../../../../interfaces/store'
import { PaymentTab } from '../../interfaces'

export function setActivePaymentsTab(action: IAction<PaymentTab>): void {
  const tab = action.payload
  try {
    if (typeof window !== 'undefined') {
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.set('tab', tab)
      window.history.pushState({}, '', newUrl)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error setting active tab', e)
  }
}
