import { AxiosResponse } from 'axios'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { IPartnerQuery, IPartnerQueryRequest, IReferralPartnersResponse } from '../interfaces/referralPartners'
import * as Endpoint from './endpointConstants'

export class ReferralPartnersApi {
  constructor(private api: Api) {}

  getReferralPartners = async ({
    offset,
    limit,
    query = {} as IPartnerQuery
  }: IPartnerQueryRequest): Promise<IReferralPartnersResponse> => {
    const params = new URLSearchParams({ offset: `${offset}`, limit: `${limit}`, ...query })

    const response: AxiosResponse<IReferralPartnersResponse> = await this.api.get(
      `${Endpoint.REFERRAL_PARTNERS}?${params.toString()}`
    )
    return response.data
  }
}

export const referralPartnersApi: ReferralPartnersApi = new ReferralPartnersApi(defaultApiInstance)
