import { IFormConfig } from '../../../../components/Form/interfaces'
import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IProduct } from '../../../../interfaces/common'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { IReferralPartner, IReferralPartnerLog, ISetSideViewPayload } from '../../interfaces'
import {
  LOAD_REFERRAL_PARTNER_FAILURE,
  LOAD_REFERRAL_PARTNER_SUCCESS,
  LOAD_REFERRAL_PARTNER_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_FAILURE,
  APPROVE_REFERRAL_PARTNER_REQUEST,
  APPROVE_REFERRAL_PARTNER_SUCCESS,
  APPROVE_REFERRAL_PARTNER_FAILURE,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  REJECT_REFERRAL_PARTNER_REQUEST,
  REJECT_REFERRAL_PARTNER_SUCCESS,
  REJECT_REFERRAL_PARTNER_FAILURE,
  TERMINATE_REFERRAL_PARTNER_REQUEST,
  TERMINATE_REFERRAL_PARTNER_SUCCESS,
  TERMINATE_REFERRAL_PARTNER_FAILURE,
  UPDATE_REFERRAL_PARTNER_REQUEST,
  UPDATE_REFERRAL_PARTNER_SUCCESS,
  UPDATE_REFERRAL_PARTNER_FAILURE,
  GET_REFERRAL_PARTNER_LOG_REQUEST,
  GET_REFERRAL_PARTNER_LOG_SUCCESS,
  GET_REFERRAL_PARTNER_LOG_FAILURE
} from '../constants'

export function loadReferralPartnerStart(partnerId: string): IAction<{ partnerId: string }> {
  return {
    type: LOAD_REFERRAL_PARTNER_REQUEST,
    payload: { partnerId }
  }
}

export function loadProductsReferralPartnerStart(): IBasicAction {
  return {
    type: LOAD_PRODUCTS_REQUEST
  }
}

export function loadProductsReferralPartnerSuccess(payload: IProduct[]): IAction<IProduct[]> {
  return {
    type: LOAD_PRODUCTS_SUCCESS,
    payload
  }
}

export function loadProductsReferralPartnerFailure(): IBasicAction {
  return {
    type: LOAD_PRODUCTS_FAILURE
  }
}

export function loadReferralPartnerSuccess(payload: IReferralPartner): IAction<any> {
  return {
    type: LOAD_REFERRAL_PARTNER_SUCCESS,
    payload
  }
}

export function loadReferralPartnerFailure(): IBasicAction {
  return {
    type: LOAD_REFERRAL_PARTNER_FAILURE
  }
}

export function approveReferralPartnerStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: APPROVE_REFERRAL_PARTNER_REQUEST,
    payload
  }
}

export function approveReferralPartnerSuccess(): IBasicAction {
  return {
    type: APPROVE_REFERRAL_PARTNER_SUCCESS
  }
}

export function approveReferralPartnerFailure(): IBasicAction {
  return {
    type: APPROVE_REFERRAL_PARTNER_FAILURE
  }
}

export function setSideView(payload: ISetSideViewPayload | null): IAction<ISetSideViewPayload | null> {
  return {
    type: SET_ACTIVE_SIDE_VIEW_TYPE,
    payload
  }
}

export function rejectReferralPartnerStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: REJECT_REFERRAL_PARTNER_REQUEST,
    payload
  }
}

export function rejectReferralPartnerSuccess(): IBasicAction {
  return {
    type: REJECT_REFERRAL_PARTNER_SUCCESS
  }
}

export function rejectReferralPartnerFailure(): IBasicAction {
  return {
    type: REJECT_REFERRAL_PARTNER_FAILURE
  }
}

export function terminateReferralPartnerStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: TERMINATE_REFERRAL_PARTNER_REQUEST,
    payload
  }
}

export function terminateReferralPartnerSuccess(): IBasicAction {
  return {
    type: TERMINATE_REFERRAL_PARTNER_SUCCESS
  }
}

export function terminateReferralPartnerFailure(): IBasicAction {
  return {
    type: TERMINATE_REFERRAL_PARTNER_FAILURE
  }
}

export function updateReferralPartnerStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: UPDATE_REFERRAL_PARTNER_REQUEST,
    payload
  }
}

export function updateReferralPartnerSuccess(): IBasicAction {
  return {
    type: UPDATE_REFERRAL_PARTNER_SUCCESS
  }
}

export function updateReferralPartnerFailure(): IBasicAction {
  return {
    type: UPDATE_REFERRAL_PARTNER_FAILURE
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: LOAD_REFERRAL_PARTNER_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function getReferralPartnerLogStart(partnerId: string): IAction<{ partnerId: string }> {
  return {
    type: GET_REFERRAL_PARTNER_LOG_REQUEST,
    payload: { partnerId }
  }
}

export function getReferralPartnerLogSuccess(payload: IReferralPartnerLog[]): IAction<IReferralPartnerLog[]> {
  return {
    type: GET_REFERRAL_PARTNER_LOG_SUCCESS,
    payload
  }
}

export function getReferralPartnerLogFailure(): IBasicAction {
  return {
    type: GET_REFERRAL_PARTNER_LOG_FAILURE
  }
}
