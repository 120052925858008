import debounce from 'lodash/debounce'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { IFilter } from '../../../../components/Filters/interfaces'
import SearchTags from '../../../../components/SearchTags'
import { IBasicDispatchProps, IStore } from '../../../../interfaces/store'
import { MODULE_NAME, QUERY } from '../../constants'
import { IModuleActions } from '../../interfaces'
import actionsToBind from '../../store/actionCreators'

const DEBOUNCE_TIME = 500

type IStoreProps = Pick<IStore[typeof MODULE_NAME], 'filtersParams' | 'isFetchingPolicies'>
type IDispatchProps = {
  actions: IModuleActions
}

type Props = IStoreProps & IDispatchProps

const Search: FC<Props> | null = ({ actions, filtersParams, isFetchingPolicies }: Props) => {
  const { t } = useTranslation('common')
  const filter: IFilter | undefined = filtersParams.find((item: IFilter) => item.key === QUERY)

  if (typeof filter === 'undefined') {
    return null
  }

  function handleChange(value: string) {
    if (typeof filter !== 'undefined') {
      if (value === '') {
        actions.removeFilter('')
        actions.getPolicies()
      } else {
        actions.setFilter({ ...filter, selectedFilter: value })
        actions.getPolicies()
      }
    }
  }

  function handleReset() {
    if (typeof filter !== 'undefined') {
      actions.setFilter({ ...filter, selectedFilter: '' })
      actions.getPolicies()
    }
    actions.removeFilter(QUERY)
    actions.getPolicies()
  }

  function handleTagDelete() {
    actions.removeFilter(QUERY)
    actions.getPolicies()
  }

  const tags: string[] = filter.selectedFilter !== '' ? [filter.selectedFilter as string] : []
  const errors: { length: string; characters: string } = {
    length: t('search.error.length'),
    characters: t('search.error.characters')
  }

  return (
    <SearchTags
      maxTagsAmount={1}
      placeholder={t('search.placeholder')}
      buttonLabel={t('search.buttonLabel')}
      appliedTags={tags}
      isProcessing={isFetchingPolicies}
      dataTest="policies-search"
      handleReset={handleReset}
      handleTagsChange={debounce(handleChange, DEBOUNCE_TIME, { trailing: true, leading: false })}
      handleTagDelete={handleTagDelete}
      defaultValue={filter?.selectedFilter as string}
      errors={errors}
    />
  )
}

function mapStateToProps(state: IStore): IStoreProps {
  const { filtersParams, isFetchingPolicies }: IStoreProps = state.policies

  return {
    filtersParams,
    isFetchingPolicies
  }
}

function mapDispatchToProps(dispatch: reduxDispatch): IBasicDispatchProps {
  return {
    actions: bindActionCreators(actionsToBind, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search) as FC<any>
