import { takeLatest } from 'typed-redux-saga'

import { LOAD_REFERRAL_PARTNERS_REQUEST, LOAD_PARTNERS_REQUEST, ON_CHANGE_FILTERS_REFERRAL_PARTNER } from './constants'
import { loadPartners } from './sagas/partners'
import { loadReferralPartners, watchFilterChangesReferralPartner } from './sagas/referralPartners'

function* watchLoadReferralPartnersStart(): Generator {
  yield* takeLatest<any>(LOAD_REFERRAL_PARTNERS_REQUEST, loadReferralPartners)
}

function* watchLoadPartnersStart(): Generator {
  yield* takeLatest<any>(LOAD_PARTNERS_REQUEST, loadPartners)
}

function* watchFilterChangesReferralPartnerStart(): Generator {
  yield* takeLatest<any>(ON_CHANGE_FILTERS_REFERRAL_PARTNER, watchFilterChangesReferralPartner)
}

// 3. Root saga
export default (): Generator[] => [
  watchLoadReferralPartnersStart(),
  watchFilterChangesReferralPartnerStart(),
  watchLoadPartnersStart()
]
