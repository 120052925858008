import i18next from 'i18next'

import { DateConstant, InputType } from '@alteos/ui'

import { ICardSection } from '../../../components/Cards'
import { PolicyUpdateReason } from '../../../dictionaries'
import { ISelectOption } from '../../../interfaces/common'
import { assertUnreachable } from '../../../utils'
import { IPolicy } from '../interfaces'

enum Purpose {
  sideView,
  policy,
  policyHolder
}

const getUpdateReasonOptions = (purpose: Purpose): ISelectOption[] => {
  // NOTE: older, view only options to keep backward compatibility (https://versicorp.atlassian.net/browse/DAVE-5319)
  const viewOptions: ISelectOption[] = [
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.changeOfRenter'),
      key: PolicyUpdateReason.ChangeOfRenter
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.customerRequest'),
      key: PolicyUpdateReason.CustomerRequest
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.damagedLicensePlate'),
      key: PolicyUpdateReason.DamagedLicensePlate
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.guarantee'),
      key: PolicyUpdateReason.GuaranteeWarranty
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.initialDataDiscrepancy'),
      key: PolicyUpdateReason.InitialDataDiscrepancy
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.relocation'),
      key: PolicyUpdateReason.Relocation
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.licensePlateTheft'),
      key: PolicyUpdateReason.LicensePlateTheft
    }
  ]

  const policyHolderOptions: ISelectOption[] = [
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.changeOfPersonalData'),
      key: PolicyUpdateReason.ChangeOfPersonalData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.changeOfPolicyHolder'),
      key: PolicyUpdateReason.ChangeOfPolicyHolder
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.correctionOfPersonalData'),
      key: PolicyUpdateReason.CorrectionOfPersonalData
    }
  ]

  const policyObjectOptions: ISelectOption[] = [
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.addendumOfObjectData'),
      key: PolicyUpdateReason.AddendumOfObjectData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.changeOfObjectData'),
      key: PolicyUpdateReason.ChangeOfObjectData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.correctionOfObjectData'),
      key: PolicyUpdateReason.CorrectionOfObjectData
    }
  ]

  const policyOptions: ISelectOption[] = [
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.addendumOfOtherData'),
      key: PolicyUpdateReason.AddendumOfOtherData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.addendumOfPersonalData'),
      key: PolicyUpdateReason.AddendumOfPersonalData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.changeOfOtherData'),
      key: PolicyUpdateReason.ChangeOfOtherData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.changeOfPersonalData'),
      key: PolicyUpdateReason.ChangeOfPersonalData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.correctionOfOtherData'),
      key: PolicyUpdateReason.CorrectionOfOtherData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.correctionOfPersonalData'),
      key: PolicyUpdateReason.CorrectionOfPersonalData
    },
    {
      value: i18next.t('policy:forms.updateInformationSection.updateReasonOptions.shiftingOfStartDate'),
      key: PolicyUpdateReason.ShiftingOfStartDate
    }
  ]

  switch (purpose) {
    case Purpose.sideView: {
      const all: ISelectOption[] = [...viewOptions, ...policyOptions, ...policyHolderOptions, ...policyObjectOptions]
      return all
    }
    case Purpose.policy:
      return policyOptions
    case Purpose.policyHolder:
      return policyHolderOptions
    default:
      return assertUnreachable(purpose)
  }
}

const generateEditHeaderCard = (policy: IPolicy, purpose: Purpose): ICardSection => {
  const updateReasonOptions: ISelectOption[] = getUpdateReasonOptions(purpose)

  return {
    title: i18next.t('policy:forms.updateInformationSection.title'),
    fields: [
      {
        label: i18next.t('policy:forms.updateInformationSection.effectiveRequestedAt'),
        editType: InputType.date,
        minimum: policy.createdAt,
        maximum: DateConstant.now,
        size: 'half',
        id: 'effectiveRequestedAt',
        required: true
      },
      {
        label: i18next.t('policy:forms.updateInformationSection.effectiveAt'),
        editType: InputType.date,
        minimum: policy.createdAt,
        maximum: DateConstant.now,
        size: 'half',
        id: 'effectiveAt',
        required: true
      },
      {
        id: 'updateReason',
        editType: InputType.select,
        label: i18next.t('policy:forms.updateInformationSection.updateReason'),
        required: true,
        options: updateReasonOptions
      },
      {
        id: 'helpers.note',
        editType: InputType.text,
        label: i18next.t('policy:forms.updateInformationSection.note'),
        placeholder: i18next.t('policy:forms.updateInformationSection.notePlaceholder'),
        maximum: 500
      }
    ]
  }
}

export const generateEditHeaderCardForPolicyHolder = (policy: IPolicy): ICardSection => {
  return generateEditHeaderCard(policy, Purpose.policyHolder)
}

export const generateEditHeaderCardForPolicyValues = (policy: IPolicy): ICardSection => {
  return generateEditHeaderCard(policy, Purpose.policy)
}
