import cx from 'classnames'
import { FC } from 'react'

import { IElementViewFieldConfig } from '../../../../../interfaces/common'
import { useDynamicTranslation } from '../../../../../modules/i18n/hooks'

import styles from '../../card.module.scss'

interface IProps {
  isInline: boolean
  field: IElementViewFieldConfig
  value: any
  source?: any
  formValues?: any
}

const ElementItem: FC<IProps> = ({ field, isInline, value, source, formValues }: IProps) => {
  const isFullWidth: boolean = field.size === 'full'
  const isHalfWidth: boolean = field.size === 'half'
  const label: string = useDynamicTranslation(field.label)
  const hasLabel: boolean = label.trim() !== ''
  const computedValue: any = typeof value === 'function' ? value(source, formValues) : value

  return (
    <div
      className={cx(styles.item, styles.text, {
        [styles.fullWidth]: isFullWidth,
        [styles.halfWidth]: isHalfWidth,
        [styles.grid]: !isInline
      })}
    >
      {hasLabel ? <span className={styles.label}>{label}:</span> : null}
      <span className={styles.itemContent}>{computedValue}</span>
    </div>
  )
}

export default ElementItem
