/* eslint-disable */
import React, { ReactElement } from 'react'

import { IIconFactory } from '../interfaces'
import { DocumentExtension } from '../dictionaries'

export const pdfIcon: ReactElement = (
  <svg width="28" height="51" viewBox="0 0 28 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 8C1 6.08611 1.00212 4.75129 1.13753 3.74416C1.26907 2.76579 1.50966 2.2477 1.87868 1.87868C2.2477 1.50966 2.76579 1.26907 3.74416 1.13753C4.75129 1.00212 6.08611 1 8 1H17.0082C19.0703 1 19.877 1.01184 20.6086 1.25475C20.8135 1.32279 21.0138 1.40403 21.2082 1.49796C21.9023 1.83331 22.4893 2.38682 23.9687 3.82328C25.5095 5.31933 26.1033 5.91371 26.464 6.62861C26.565 6.82875 26.6524 7.03545 26.7256 7.24732C26.9872 8.00412 27 8.8442 27 10.9918V21C27 22.9139 26.9979 24.2487 26.8625 25.2558C26.7309 26.2342 26.4903 26.7523 26.1213 27.1213C25.7523 27.4903 25.2342 27.7309 24.2558 27.8625C23.2487 27.9979 21.9139 28 20 28H8C6.08611 28 4.75129 27.9979 3.74416 27.8625C2.76579 27.7309 2.2477 27.4903 1.87868 27.1213L1.18882 27.8112L1.87868 27.1213C1.50966 26.7523 1.26907 26.2342 1.13753 25.2558C1.00212 24.2487 1 22.9139 1 21V8Z"
      fill="white"
      stroke="#EE4A4A"
      strokeWidth="2"
    />
    <path
      d="M7.09946 21.5651C5.5702 18.8521 22.2017 14.348 20.9306 17.8894C20.4722 19.1666 16.9741 17.8971 14.8085 15.1323C12.688 12.4251 11.8775 8.23696 13.448 8.00939C15.1186 7.76732 14.5023 12.251 12.7678 16.0505C10.9759 19.9759 8.02106 23.2001 7.09946 21.5651Z"
      stroke="#EE4A4A"
      strokeWidth="2"
    />
    <rect y="33" width="28" height="18" rx="4" fill="#EE4A4A" />
    <path
      d="M6.14986 45V38.718H9.11986C9.41386 38.718 9.67786 38.769 9.91186 38.871C10.1459 38.967 10.3439 39.102 10.5059 39.276C10.6739 39.45 10.8029 39.663 10.8929 39.915C10.9829 40.161 11.0279 40.431 11.0279 40.725C11.0279 41.025 10.9829 41.298 10.8929 41.544C10.8029 41.79 10.6739 42 10.5059 42.174C10.3439 42.348 10.1459 42.486 9.91186 42.588C9.67786 42.684 9.41386 42.732 9.11986 42.732H7.51786V45H6.14986ZM7.51786 41.544H8.95786C9.16186 41.544 9.32086 41.493 9.43486 41.391C9.55486 41.283 9.61486 41.127 9.61486 40.923V40.527C9.61486 40.323 9.55486 40.17 9.43486 40.068C9.32086 39.96 9.16186 39.906 8.95786 39.906H7.51786V41.544ZM11.8761 38.718H14.2431C14.6571 38.718 15.0351 38.784 15.3771 38.916C15.7191 39.048 16.0101 39.246 16.2501 39.51C16.4901 39.768 16.6761 40.095 16.8081 40.491C16.9401 40.881 17.0061 41.337 17.0061 41.859C17.0061 42.381 16.9401 42.84 16.8081 43.236C16.6761 43.626 16.4901 43.953 16.2501 44.217C16.0101 44.475 15.7191 44.67 15.3771 44.802C15.0351 44.934 14.6571 45 14.2431 45H11.8761V38.718ZM14.2431 43.785C14.6511 43.785 14.9721 43.671 15.2061 43.443C15.4401 43.215 15.5571 42.849 15.5571 42.345V41.373C15.5571 40.869 15.4401 40.503 15.2061 40.275C14.9721 40.047 14.6511 39.933 14.2431 39.933H13.2441V43.785H14.2431ZM17.9715 45V38.718H22.1385V39.933H19.3395V41.22H21.7245V42.426H19.3395V45H17.9715Z"
      fill="white"
    />
  </svg>
)

const icons: IIconFactory = {
  [DocumentExtension.Pdf]: pdfIcon,
  [DocumentExtension.Unknown]: pdfIcon
}

export default icons
