export enum ClaimStatus {
  Created = 'created',
  InProgress = 'inProgress',
  Closed = 'closed',
  Reopened = 'reopened',
  Reclosed = 'reclosed',
  InRecourse = 'inRecourse',
  Approved = 'approved',
  Rejected = 'rejected',
  PartiallyApproved = 'partiallyApproved'
}
