import isNil from 'lodash/isNil'

import { IGroup, IPartnerResponse } from '../../../interfaces'
import { IPermission } from '../../../interfaces/permissions'

const attachAppKey = (partner: IPartnerResponse, groups: IGroup[]): IPartnerResponse => {
  groups.forEach((group: IGroup) =>
    (group.permissions as IPermission[]).forEach((p: IPermission) => {
      if (!isNil(p.appKey)) {
        partner.appKey = p.appKey
        return
      }
    })
  )

  return partner
}

export default attachAppKey
