import { TFunction } from 'react-i18next'

import { InputType, formatEuroCurrency } from '@alteos/ui'

import { IFormConfig } from '../../../../../../components/Form/interfaces'
import { forms } from '../../../../constants'
import { IClaim } from '../../../../interfaces'

const generateClaimReserveFormCard = (t: TFunction, claim: IClaim, styles: Record<string, any>): IFormConfig => {
  const reserveFormConfig: IFormConfig = {
    form: forms.createTransactionReserveForm,
    url: `v1/claims/${claim.id}/reserves`,
    initialValues: {
      amount: {
        value: ''
      },
      type: ''
    },
    successMessage: `${t('policyClaimsTransactionsForm.reserveForm.successMessage')}`,
    formSections: [
      {
        title: t('policyClaimsTransactionsForm.reserveForm.title'),
        fields: [
          {
            id: 'type',
            editType: InputType.select,
            required: true,
            size: 'small',
            placeholder: t('policyClaimsTransactionsForm.reserveForm.reserveTypePlaceholder'),
            label: t('policyClaimsTransactionsForm.reserveForm.reserveTypeLabel'),
            options: [
              {
                key: 'increase',
                value: t('policyClaimsTransactionsForm.reserveForm.reserveType.increase')
              },
              {
                key: 'decrease',
                value: t('policyClaimsTransactionsForm.reserveForm.reserveType.decrease')
              }
            ]
          },
          {
            id: 'amount.value',
            editType: InputType.number,
            required: true,
            size: 'small',
            label: t('policyClaimsTransactionsForm.reserveForm.amount'),
            placeholder: '0,00',
            minimum: 0.01,
            regex: '^(?!0(\\.0+)?$)(\\d+|\\d*\\.\\d{1,2})$'
          },
          {
            id: 'helpers.reserveAmount',
            editType: InputType.contentBox,
            size: 'small',
            content: `<div className=${styles.currentReserveAmount}><span>${t(
              'policyClaimsTransactionsForm.reserveForm.currentAmount'
            )}:</span><h2>${formatEuroCurrency(claim.currentReserveAmount)}</h2></div>`
          },
          {
            editType: InputType.button,
            label: t('policyClaimsTransactionsForm.reserveForm.button')
          }
        ]
      }
    ]
  }

  return reserveFormConfig
}

export default generateClaimReserveFormCard
