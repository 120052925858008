import { IRequestPagination } from '@alteos/ui'

import { IFilter } from '../../../components/Filters/interfaces'
import { IBasicStore } from '../../../interfaces/store'

export enum PaymentLinkRequestEnum {
  InProgress = 'in-progress',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled'
}

export interface IUpcomingPayments {
  paymentScheduleId: string
  effectiveFrom: string
  premium: number
  tax: number
  gross: number
  policyId: string
  paymentMethod: string
  policyPrettyId: string
  customerEmail: string
  productName: string
  paymentLink: string | null
  paymentLinkRequest: PaymentLinkRequestEnum | null
  updatedAt?: string
  createdAt?: string
}

export interface IUpcomingPaymentsState extends IBasicStore {
  isFetchingUpcomingPayments: boolean
  isFetchingPaymentLink: boolean
  isCreatingPaymentLink: boolean
  isUpdatingPaymentMethod: boolean
  upcomingPayments: IUpcomingPayments[]
  pagination: IRequestPagination
  hasMore: boolean
  activeSideViewType: UpcomingPaymentsSideViewType | null
  selectedPaymentId: string | null
  selectedPaymentIds: string[] | null
  filters?: IFilter[]
  search?: string
}

export enum UpcomingPaymentsSideViewType {
  PaymentMethodSideView = 'PaymentMethodSideView'
}

export interface ISetSideViewPayload {
  type: UpcomingPaymentsSideViewType
  params: {
    paymentScheduleId: string
  }
}
