import { IRequestPagination } from '@alteos/ui'

import { IFilter } from '../../../components/Filters/interfaces'
import { IFormConfig } from '../../../components/Form/interfaces'
import { ISaveErrorMessagePayload } from '../../../interfaces/action'
import { IAction, IBasicAction } from '../../../interfaces/store'
import { IExtendedPolicy, IPaginationActionPayload, ISetSideViewPayload } from '../interfaces'
import {
  ADD_FILTER,
  GET_FILTERS_PARAMS_REQUEST,
  GET_FILTERS_PARAMS_SUCCESS,
  GET_POLICIES_FAILURE,
  GET_POLICIES_REQUEST,
  GET_POLICIES_SUCCESS,
  REMOVE_FILTER,
  RESET_FILTERS,
  SET_FILTER,
  SET_PAGINATION,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  IMPORT_POLICIES_START,
  IMPORT_POLICIES_SUCCESS,
  IMPORT_POLICIES_FAILURE,
  TERMINATE_POLICIES_START,
  TERMINATE_POLICIES_SUCCESS,
  TERMINATE_POLICIES_FAILURE
} from './constants'

const DEFAULT_LIMIT = 20

const defaultPagination: IRequestPagination = { offset: 0, limit: DEFAULT_LIMIT }

export function getPolicies(payload: IRequestPagination = defaultPagination): IAction<IRequestPagination> {
  return {
    type: GET_POLICIES_REQUEST,
    payload
  }
}

export function getFiltersParams(): IBasicAction {
  return {
    type: GET_FILTERS_PARAMS_REQUEST
  }
}

export function savePolicies(payload: IExtendedPolicy[]): IAction<IExtendedPolicy[]> {
  return {
    type: GET_POLICIES_SUCCESS,
    payload
  }
}

export function saveFiltersParams(payload: IFilter[]): IAction<IFilter[]> {
  return {
    type: GET_FILTERS_PARAMS_SUCCESS,
    payload
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: GET_POLICIES_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function addActiveFilter(payload: string): IAction<string> {
  return {
    type: ADD_FILTER,
    payload
  }
}

export function setFilter(payload: IFilter): IAction<IFilter> {
  return {
    type: SET_FILTER,
    payload
  }
}

export function removeFilter(payload: string): IAction<string> {
  return {
    type: REMOVE_FILTER,
    payload
  }
}

export function resetFilters(): IBasicAction {
  return {
    type: RESET_FILTERS
  }
}

export function setPagination(payload: IPaginationActionPayload): IAction<IPaginationActionPayload> {
  return {
    type: SET_PAGINATION,
    payload
  }
}

export function setSideView(payload: ISetSideViewPayload | null): IAction<ISetSideViewPayload | null> {
  return {
    type: SET_ACTIVE_SIDE_VIEW_TYPE,
    payload
  }
}

export function importPoliciesStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: IMPORT_POLICIES_START,
    payload
  }
}

export function importPoliciesSuccess(): IBasicAction {
  return {
    type: IMPORT_POLICIES_SUCCESS
  }
}

export function importPoliciesFailure(): IBasicAction {
  return {
    type: IMPORT_POLICIES_FAILURE
  }
}

export function terminatePoliciesStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: TERMINATE_POLICIES_START,
    payload
  }
}

export function terminatePoliciesSuccess(): IBasicAction {
  return {
    type: TERMINATE_POLICIES_SUCCESS
  }
}

export function terminatePoliciesFailure(): IBasicAction {
  return {
    type: TERMINATE_POLICIES_FAILURE
  }
}

export default {
  addActiveFilter,
  resetFilters,
  setFilter,
  getFiltersParams,
  saveFiltersParams,
  getPolicies,
  savePolicies,
  saveErrorMessage,
  removeFilter,
  setPagination,
  setSideView,
  importPoliciesStart,
  terminatePoliciesStart
}
