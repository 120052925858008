import { IUpcomingPayments, PaymentLinkRequestEnum } from '../../interfaces'

export function updatePaymentLinkStatus(
  upcomingPayments: IUpcomingPayments[],
  selectedPaymentIds: string[],
  paymentLinkStatuses: Record<string, { status: PaymentLinkRequestEnum; value?: string | undefined }>
): IUpcomingPayments[] {
  const selectedPaymentIdsSet = new Set(selectedPaymentIds)

  return upcomingPayments.map((upcomingPayment) => {
    if (selectedPaymentIdsSet.has(upcomingPayment.paymentScheduleId)) {
      return {
        ...upcomingPayment,
        paymentLinkRequest:
          paymentLinkStatuses[upcomingPayment.paymentScheduleId]?.status ?? PaymentLinkRequestEnum.InProgress,
        paymentLink: paymentLinkStatuses[upcomingPayment.paymentScheduleId]?.value ?? null
      }
    }
    return upcomingPayment
  })
}
