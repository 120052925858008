import get from 'lodash/get'
import isNil from 'lodash/isNil'
import pick from 'lodash/pick'
import { TFunction } from 'react-i18next'

import { DateConstant, IFormValues, InputType, RelationalOperator } from '@alteos/ui'

import { ICardSection } from '../../../../../components/Cards'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { PERIODIC_ENDORSEMENT_ENABLED } from '../../../../../config'
import { FieldType, PolicyUpdateReason } from '../../../../../dictionaries'
import { SectionIds } from '../../../../../dictionaries/SectionIds'
import { IFieldConfig, ISelectOption } from '../../../../../interfaces/common'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { forms } from '../../../constants'
import { EndorsementReason } from '../../../dictionaries'
import { ICustomer, IPolicy, IPolicyObject, IPolicyRisk } from '../../../interfaces'
import { PremiumRelatedSection } from '../PremiumRelatedSection/PremiumRelatedSection'

const generateHeaderCard = (
  policy: IPolicy,
  withEndorsement: boolean,
  t: TFunction,
  initialValues: Record<string, any>,
  fieldsToReset: string[] = []
): ICardSection => {
  const defaultOptions: ISelectOption[] = [
    {
      value: t('policy:forms.updateInformationSection.updateReasonOptions.addendumOfObjectData'),
      key: PolicyUpdateReason.AddendumOfObjectData
    },
    {
      value: t('policy:forms.updateInformationSection.updateReasonOptions.changeOfObjectData'),
      key: PolicyUpdateReason.ChangeOfObjectData
    },
    {
      value: t('policy:forms.updateInformationSection.updateReasonOptions.correctionOfObjectData'),
      key: PolicyUpdateReason.CorrectionOfObjectData
    }
  ]

  const endorsementOption = {
    value: t('policy:forms.updateInformationSection.updateReasonOptions.endorsement'),
    key: EndorsementReason.Endorsement
  }

  const periodicEndorsementOption = {
    value: t('policy:forms.updateInformationSection.updateReasonOptions.periodicEndorsement'),
    key: EndorsementReason.PeriodicEndorsement
  }

  const endorsementOptions = PERIODIC_ENDORSEMENT_ENABLED
    ? [endorsementOption, periodicEndorsementOption]
    : [endorsementOption]

  return {
    title: t('policy:forms.updateInformationSection.title'),
    fields: [
      {
        label: t('policy:forms.updateInformationSection.effectiveRequestedAt'),
        editType: InputType.date,
        minimum: policy.createdAt,
        maximum: DateConstant.now,
        size: 'half',
        id: 'effectiveRequestedAt',
        required: true
      },
      {
        label: t('policy:forms.updateInformationSection.effectiveAt'),
        editType: InputType.date,
        minimum: policy.createdAt,
        maximum: DateConstant.now,
        size: 'half',
        id: 'effectiveAt',
        required: true
      },
      {
        id: 'updateReason',
        editType: InputType.select,
        label: t('policy:forms.updateInformationSection.updateReason'),
        required: true,
        options: withEndorsement ? [...defaultOptions, ...endorsementOptions] : defaultOptions,
        handleFieldChangeOptions: {
          fieldsToUpdate: fieldsToReset.map((field: string) => {
            return {
              name: field,
              newValue: get(initialValues, field),
              conditions: [
                {
                  operator: RelationalOperator.notEq,
                  value: EndorsementReason.Endorsement
                }
              ]
            }
          })
        }
      },
      {
        id: 'helpers.endorsementReason',
        editType: InputType.string,
        label: t('policy:policyObjectForm.endorsementReason'),
        required: true,
        minimum: 3,
        maximum: 80,
        availableIf: [
          {
            field: 'updateReason',
            operator: RelationalOperator.eq,
            value: EndorsementReason.Endorsement
          }
        ]
      },
      {
        id: 'helpers.note',
        editType: InputType.text,
        label: t('policy:forms.updateInformationSection.note'),
        placeholder: t('policy:forms.updateInformationSection.notePlaceholder'),
        maximum: 500
      }
    ]
  }
}

const disableConditions = [
  {
    field: 'updateReason',
    operator: RelationalOperator.notEq,
    value: EndorsementReason.Endorsement
  },
  { field: 'updateReason', operator: RelationalOperator.notEq, value: EndorsementReason.PeriodicEndorsement }
]

const generatePolicyObjectFormCards = (config: IUiConfig, policy: IPolicy, t: TFunction): IFormConfig => {
  const { type, ...customer }: ICustomer = policy.customer

  const addons: IPolicy['addons'] = { ...policy.addons }

  const initialValues: Record<string, any> = {
    customer,
    package: policy.package?.name,
    effectiveAt: '',
    effectiveRequestedAt: '',
    metadata: policy.metadata,
    objects: policy.objects.map(({ risks, values: objectValues, ...rest }: IPolicyObject) => {
      return {
        ...rest,
        values: { ...objectValues },
        risks: risks.map(({ name, values }: IPolicyRisk) => {
          return { name, values }
        })
      }
    }),
    updateReason: '',
    values: policy.values,
    addons
  }

  if (!isNil(policy.device)) {
    initialValues['device'] = policy.device
  }

  let withEndorsement = false
  let premiumSectionFields

  const sections: ICardSection[] = config.detailsCards.objects.map((section: ICardSection) => {
    if (section.id === SectionIds.ObjectsFormPremiumRelated) {
      withEndorsement = true

      const sectionFields: string[] = section.fields
        .flatMap((field: IFieldConfig) => (!isNil(field.id) ? [field.id] : []))
        .concat(['package', 'addons'])

      premiumSectionFields = sectionFields

      return {
        ...section,
        disabledIf: disableConditions,
        fields: [
          ...section.fields,
          {
            type: FieldType.Element,
            editOnly: true,
            value: (_src: any, formValues: IFormValues) => {
              return <PremiumRelatedSection formValues={pick(formValues, sectionFields)} policy={policy} />
            },
            size: 'full'
          }
        ]
      }
    }
    return section
  })

  return {
    form: forms.editPolicyObjectForm,
    url: '',
    initialValues,
    successMessage: t('policyObjectForm.successMessage'),
    formSections: [generateHeaderCard(policy, withEndorsement, t, initialValues, premiumSectionFields), ...sections]
  }
}

export default generatePolicyObjectFormCards
