import get from 'lodash/get'
import set from 'lodash/set'
import { TFunction } from 'react-i18next'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'

import { ICardSection } from '../../../../../components/Cards'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { ClaimStatus, FieldType } from '../../../../../dictionaries'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { forms, getClaimSubStatuses } from '../../../constants'
import { IClaim, IPolicy } from '../../../interfaces'
import ClaimDocumentsTable from '../View/ClaimDocumentsTable'
import ClaimHistoryTable from '../View/ClaimHistoryTable'
import { claimStatusFormFields } from './claimStatusFormFields'

interface IClaimFormInitialValues {
  values: {
    [key: string]: string
  }
  claimType: string
  customerId: string
  productId: string
  productVersion: string
  claimedAt: string
  status: string
  subStatus: string | null
}

const generateEditClaimFormCards = (
  config: IUiConfig,
  claim: IClaim,
  policy: IPolicy,
  t: TFunction,
  language: ISupportedLanguage,
  selectedStatus: string
  // eslint-disable-next-line max-params
): IFormConfig => {
  const initialValues: IClaimFormInitialValues = {
    values: {},
    claimType: getTranslation(language, config.claimsCards.typeName ?? ''),
    customerId: policy.customerId,
    productId: policy.product.id as string,
    claimedAt: new Date().toISOString().split('T')[0],
    productVersion: policy.product.version as string,
    status: claim.status,
    subStatus: claim.subStatus
  }

  const subStatuses = getClaimSubStatuses(selectedStatus as ClaimStatus)

  const updateInformationCard: ICardSection = {
    title: t('policy:editClaimForm.updateInformationCard.title'),
    fields: [...claimStatusFormFields(t, subStatuses)]
  }

  const claimDocumentsCard: ICardSection = {
    title: t('editClaimForm.documentsCard.title'),
    fields: [
      {
        type: FieldType.Element,
        value: <ClaimDocumentsTable claim={claim} t={t} />,
        size: 'full'
      }
    ]
  }

  const claimHistoryCard: ICardSection = {
    title: t('createClaimForm.historyTable.title'),
    fields: [
      {
        type: FieldType.Element,
        value: <ClaimHistoryTable claim={claim} t={t} />,
        size: 'full'
      }
    ]
  }

  config.claimsCards.claimDetailsCards.forEach((group) => {
    group.fields.forEach((field) => {
      if (typeof field.id !== 'undefined') {
        set(initialValues, field.id, get(claim, field.id, ''))
      }
    })
  })

  return {
    form: forms.editClaimForm,
    url: `v1/policies/${policy.id}/claims/${claim.id}/update`,
    initialValues,
    successMessage: t('editClaimForm.successMessage'),
    formSections: [claimHistoryCard, ...config.claimsCards.claimDetailsCards, claimDocumentsCard, updateInformationCard]
  }
}

export default generateEditClaimFormCards
