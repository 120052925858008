import { put } from 'typed-redux-saga'

import actions from './actionCreators'

export function* openSideView(): Generator {
  yield* put(actions.openSideView())
}

export function* closeSideView(): Generator {
  yield* put(actions.closeSideView())
}
