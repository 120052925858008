import { TFunction } from 'react-i18next'

import { InputType, RelationalOperator } from '@alteos/ui'

import { IFormConfig } from '../../../../../../components/Form/interfaces'
import { CLAIM_SUB_STATUS_MAP, forms } from '../../../../constants'
import { IClaim } from '../../../../interfaces'

const generateClaimObligationFormCard = (t: TFunction, claim: IClaim): IFormConfig => {
  const subStatuses = CLAIM_SUB_STATUS_MAP.closed
  const obligationFormConfig: IFormConfig = {
    form: forms.createTransactionObligationForm,
    url: `v1/claims/${claim.id}/obligations`,
    initialValues: {
      amount: {
        value: ''
      },
      source: 'claim',
      type: 'reverseObligation',
      description: ''
    },
    successMessage: t('policyClaimsTransactionsForm.obligationForm.successMessage'),
    formSections: [
      {
        title: t('policyClaimsTransactionsForm.obligationForm.title'),
        fields: [
          {
            id: 'amount.value',
            editType: InputType.number,
            required: true,
            size: 'small',
            minimum: 0.01,
            label: t('policyClaimsTransactionsForm.obligationForm.amount'),
            placeholder: '0,00',
            regex: '^(?!0(\\.0+)?$)(\\d+|\\d*\\.\\d{1,2})$'
          },
          {
            editType: InputType.text,
            id: 'description',
            required: true,
            label: t('policyClaimsTransactionsForm.obligationForm.description'),
            placeholder: t('policyClaimsTransactionsForm.obligationForm.descriptionPlaceholder')
          },
          {
            id: '',
            editType: InputType.alert,
            content: t('policyClaimsTransactionsForm.obligationForm.descriptionNote'),
            alertType: 'info'
          },
          {
            id: 'isClosingClaim',
            editType: InputType.checkbox,
            title: '',
            label: t('policyClaimsTransactionsForm.obligationForm.withPayout'),
            handleFieldChangeOptions: {
              fieldsToUpdate: [
                {
                  name: 'subStatus',
                  newValue: null
                }
              ]
            }
          },
          {
            id: 'closingClaimSubStatus',
            editType: InputType.select,
            required: true,
            availableIf: [{ field: 'isClosingClaim', operator: RelationalOperator.eq, value: true }],
            isClearable: true,
            placeholder: t('policy:claims.cards.selectPlaceholder'),
            label: t('policy:claims.cards.subStatus'),
            options: (subStatuses ?? []).map((subStatus) => {
              return {
                value: t(`policy:claims.claimSubStatus.${subStatus}`),
                key: subStatus
              }
            })
          },
          {
            editType: InputType.button,
            label: t('policyClaimsTransactionsForm.obligationForm.button')
          }
        ]
      }
    ]
  }

  return obligationFormConfig
}

export default generateClaimObligationFormCard
