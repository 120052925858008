import { IUpcomingPayments } from '../../interfaces'

export function updatePolicyPaymentMethod(
  upcomingPayments: IUpcomingPayments[],
  selectedPaymentId: string,
  updatedValues: Partial<IUpcomingPayments>
): IUpcomingPayments[] {
  const payment = upcomingPayments.find((payment) => payment.paymentScheduleId === selectedPaymentId)!

  return upcomingPayments.map((upcomingPayment) => {
    if (upcomingPayment.policyPrettyId === payment.policyPrettyId) {
      return {
        ...upcomingPayment,
        ...updatedValues
      }
    }
    return upcomingPayment
  })
}
