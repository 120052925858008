import { IRequestPagination } from '@alteos/ui'

import { addNewFilter, removeActiveFilter, resetFilters, setActiveFilter } from '../../../../components/Filters/utils'
import { IAction } from '../../../../interfaces/store'
import { LOGOUT } from '../../../Auth/constants'
import { DEFAULT_FILTERS, DEFAULT_PAGINATION } from '../../constants'
import {
  IGetPaymentLinkStatusesResponsePayload,
  ISetSideViewPayload,
  IUpcomingPayments,
  IUpcomingPaymentsState,
  PaymentLinkRequestEnum
} from '../../interfaces'
import {
  ADD_FILTER_UPCOMING_PAYMENTS,
  CREATE_BULK_PAYMENT_LINKS_FAILURE,
  CREATE_BULK_PAYMENT_LINKS_REQUEST,
  CREATE_BULK_PAYMENT_LINKS_SUCCESS,
  CREATE_PAYMENT_LINK_FAILURE,
  CREATE_PAYMENT_LINK_REQUEST,
  CREATE_PAYMENT_LINK_SUCCESS,
  GET_PAYMENT_LINK_FAILURE,
  GET_PAYMENT_LINK_REQUEST,
  GET_PAYMENT_LINK_SUCCESS,
  LOAD_UPCOMING_PAYMENTS_FAILURE,
  LOAD_UPCOMING_PAYMENTS_REQUEST,
  LOAD_UPCOMING_PAYMENTS_SUCCESS,
  ON_CHANGE_FILTERS_UPCOMING_PAYMENTS,
  REMOVE_FILTER_UPCOMING_PAYMENTS,
  RESET_FILTERS_UPCOMING_PAYMENTS,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SET_FILTER_UPCOMING_PAYMENTS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS
} from '../constants'
import { updatePaymentLinkStatus } from '../utils/updatePaymentLinkStatus'
import { updatePolicyPaymentMethod } from '../utils/updatePolicyPaymentMethod'
import { updateUpcomingPayments } from '../utils/updateUpcomingPayment'

export const initialState: IUpcomingPaymentsState = {
  isFetchingUpcomingPayments: false,
  isFetchingPaymentLink: false,
  isCreatingPaymentLink: false,
  upcomingPayments: [],
  pagination: DEFAULT_PAGINATION,
  hasMore: true,
  activeSideViewType: null,
  selectedPaymentId: null,
  selectedPaymentIds: null,
  errorMessage: null,
  isUpdatingPaymentMethod: false,
  filters: DEFAULT_FILTERS
}

function reducer(state: IUpcomingPaymentsState = initialState, action: IAction<any>): IUpcomingPaymentsState {
  switch (action.type) {
    case LOAD_UPCOMING_PAYMENTS_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }
      return {
        ...state,
        isFetchingUpcomingPayments: true,
        pagination: {
          ...state.pagination,
          limit: payload.limit,
          offset: payload.offset
        },
        search: payload.query
      }
    }

    case LOAD_UPCOMING_PAYMENTS_SUCCESS: {
      const { payload }: IAction<IUpcomingPayments[]> = action

      return {
        ...state,
        isFetchingUpcomingPayments: false,
        hasMore: payload.length >= state.pagination.limit,
        upcomingPayments: state.pagination.offset === 0 ? payload : [...state.upcomingPayments, ...payload],
        pagination: {
          ...state.pagination
        }
      }
    }

    case LOAD_UPCOMING_PAYMENTS_FAILURE: {
      return {
        ...state,
        isFetchingUpcomingPayments: false,
        pagination: DEFAULT_PAGINATION
      }
    }

    case SET_ACTIVE_SIDE_VIEW_TYPE: {
      const { payload }: IAction<ISetSideViewPayload | null> = action
      if (payload === null) {
        return {
          ...state,
          activeSideViewType: null,
          selectedPaymentId: null
        }
      }

      return {
        ...state,
        activeSideViewType: payload.type,
        selectedPaymentId: payload.params.paymentScheduleId
      }
    }

    case ON_CHANGE_FILTERS_UPCOMING_PAYMENTS:
      return {
        ...state,
        pagination: DEFAULT_PAGINATION
      }

    case ADD_FILTER_UPCOMING_PAYMENTS:
      return {
        ...state,
        filters: addNewFilter(state.filters!, action.payload)
      }

    case SET_FILTER_UPCOMING_PAYMENTS:
      return {
        ...state,
        filters: setActiveFilter(state.filters!, action.payload)
      }

    case REMOVE_FILTER_UPCOMING_PAYMENTS:
      return {
        ...state,
        filters: removeActiveFilter(state.filters!, action.payload)
      }

    case RESET_FILTERS_UPCOMING_PAYMENTS:
      return {
        ...state,
        filters: resetFilters(state.filters!)
      }

    case UPDATE_PAYMENT_METHOD_REQUEST: {
      return {
        ...state,
        isUpdatingPaymentMethod: true
      }
    }

    case UPDATE_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        isUpdatingPaymentMethod: false,
        upcomingPayments: updatePolicyPaymentMethod(state.upcomingPayments, state.selectedPaymentId!, {
          paymentLink: null,
          paymentMethod: 'sepa'
        })
      }
    }

    case UPDATE_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
        isUpdatingPaymentMethod: false
      }
    }

    case CREATE_PAYMENT_LINK_REQUEST: {
      const { payload }: IAction<{ paymentScheduleId: string }> = action
      return {
        ...state,
        isCreatingPaymentLink: true,
        selectedPaymentIds: [payload.paymentScheduleId],
        upcomingPayments: updateUpcomingPayments(state.upcomingPayments, [payload.paymentScheduleId], {
          paymentLinkRequest: PaymentLinkRequestEnum.InProgress
        })
      }
    }

    case CREATE_BULK_PAYMENT_LINKS_REQUEST: {
      const { payload }: IAction<{ paymentScheduleIds: string[] }> = action
      return {
        ...state,
        isCreatingPaymentLink: true,
        selectedPaymentIds: payload.paymentScheduleIds,
        upcomingPayments: updateUpcomingPayments(state.upcomingPayments, payload.paymentScheduleIds, {
          paymentLinkRequest: PaymentLinkRequestEnum.InProgress
        })
      }
    }

    case CREATE_PAYMENT_LINK_SUCCESS:
    case CREATE_BULK_PAYMENT_LINKS_SUCCESS: {
      return {
        ...state,
        isCreatingPaymentLink: false
      }
    }

    case CREATE_PAYMENT_LINK_FAILURE:
    case CREATE_BULK_PAYMENT_LINKS_FAILURE: {
      return {
        ...state,
        isCreatingPaymentLink: false,
        upcomingPayments: updateUpcomingPayments(state.upcomingPayments, state.selectedPaymentIds!, {
          paymentLinkRequest: null
        })
      }
    }

    case GET_PAYMENT_LINK_REQUEST: {
      return {
        ...state,
        isFetchingPaymentLink: true
      }
    }

    case GET_PAYMENT_LINK_SUCCESS: {
      const { payload }: IAction<IGetPaymentLinkStatusesResponsePayload> = action

      return {
        ...state,
        isFetchingPaymentLink: false,
        upcomingPayments: updatePaymentLinkStatus(
          state.upcomingPayments,
          state.selectedPaymentIds!,
          payload.paymentLinks
        )
      }
    }

    case GET_PAYMENT_LINK_FAILURE: {
      return {
        ...state,
        isFetchingPaymentLink: false
      }
    }

    case LOGOUT: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
