import defaultApiInstance, { Api } from '../../../../services/api/api'
import { ICreateScheduledTerminationRequestPayload, IScheduledAction, IScheduledActionsApi } from '../../interfaces'

class ScheduledActionsApi implements IScheduledActionsApi {
  constructor(private api: Api) {}

  getScheduledActions = async (policyId: string): Promise<IScheduledAction[]> => {
    const response = await this.api.get(`v2/scheduled-actions/${policyId}`)
    return response.data
  }

  createScheduledAction = async (
    policyId: string,
    payload: ICreateScheduledTerminationRequestPayload
  ): Promise<{ id: string }> => {
    const response = await this.api.post(`v2/scheduled-actions/${policyId}`, payload)
    return response.data
  }

  cancelScheduledAction = async (actionId: string): Promise<{ id: string }> => {
    const response = await this.api.post(`v2/scheduled-actions/${actionId}/cancel`, {})
    return response.data
  }
}

export const scheduledActionsApi = new ScheduledActionsApi(defaultApiInstance)
