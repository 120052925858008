import { LabelType } from '../../../../../components/Label/dictionaries'
import { ClaimSubStatus } from '../../../../../dictionaries/ClaimSubStatus'

function generateClaimSubStatusLabelType(value: ClaimSubStatus | null): LabelType {
  switch (value) {
    case ClaimSubStatus.NotYetInProgress:
      return LabelType.neutral
    case ClaimSubStatus.ApprovedAndPaid:
    case ClaimSubStatus.Paid:
      return LabelType.success
    case ClaimSubStatus.PaymentPending:
    case ClaimSubStatus.PartiallyPaid:
    case ClaimSubStatus.PartiallyApprovedAndPaid:
      return LabelType.info
    case ClaimSubStatus.InformationIsBeingChecked:
    case ClaimSubStatus.FurtherInformationOrDocumentsRequested:
    case ClaimSubStatus.ReviewingOfFurtherFindingsOrDocuments:
      return LabelType.warn
    case ClaimSubStatus.RejectedWithoutPayment:
    case ClaimSubStatus.WithoutPayment:
      return LabelType.error
    default:
      return LabelType.neutral
  }
}

export default generateClaimSubStatusLabelType
