// NOTE: reserved elastic special characters: https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters

const isValueWrong = (value: string, regExp?: RegExp): boolean => {
  let notAllowedCharactersRegexp = new RegExp(/(?![0-9])[=&|<>!(){}[\]^"~*?:/\\]/, 'gi')
  if (regExp instanceof RegExp) {
    notAllowedCharactersRegexp = new RegExp(regExp, 'gi')
  }
  return notAllowedCharactersRegexp.test(value)
}

export default isValueWrong
