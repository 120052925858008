import cx from 'classnames'
import { FC, ReactElement, Suspense } from 'react'

import { Loader } from '../../../../components/Loader'
import Sidebar from './Sidebar'

import styles from './layout.module.scss'

interface IProps {
  children: ReactElement
  sideViewContent: ReactElement | null
  searchContent: ReactElement | null
  noPadding?: Boolean
}

const Layout: FC<IProps> = ({ children, sideViewContent, searchContent, noPadding }: IProps) => {
  return (
    <div className={styles.container}>
      <Sidebar />
      {searchContent !== null ? <div className={styles.searchContainer}>{searchContent}</div> : null}
      <div className={cx(styles.contentContainer, { [styles.noPadding]: noPadding })}>
        <Suspense fallback={<Loader name="route-loader" />}>{children}</Suspense>
      </div>
      {sideViewContent}
    </div>
  )
}

export default Layout
