import { AxiosResponse } from 'axios'

import defaultApiInstance, { Api } from '../../../../services/api/api'
import { ICreateNoteResponse, INote, INoteRequestPayload, INotesApi, INotesResponse } from '../../interfaces'
import { GET_NOTES_BY_ID_QUERY } from '../../queries/getNotesByIdQuery'

class NotesApi implements INotesApi {
  constructor(private api: Api) {}

  createNoteInPolicyUpdate = async (policyId: string, text: string): Promise<AxiosResponse<ICreateNoteResponse>> => {
    const createNoteUrl = `v1/policies/${policyId}/notes`
    const createNotePayload: INoteRequestPayload = {
      text
    }
    const response: AxiosResponse<ICreateNoteResponse> = await this.api.post(createNoteUrl, createNotePayload)
    return response
  }

  updateNote = async (policyId: string, noteId: string, text: string): Promise<AxiosResponse> => {
    const updateNoteUrl = `v1/policies/${policyId}/notes/${noteId}`
    const updateNotePayload: INoteRequestPayload = {
      text
    }
    const response = await this.api.post(updateNoteUrl, updateNotePayload)
    return response
  }

  loadNotes = async (policyId: string, currentNotes: INote[], payload: number): Promise<INotesResponse> => {
    const NOTES_LIMIT = 15
    let offset = 0
    if (currentNotes.length > 0) {
      offset = payload
    }

    const response: INotesResponse = await this.api.query(GET_NOTES_BY_ID_QUERY, {
      policyId,
      offset,
      limit: NOTES_LIMIT
    })
    return response
  }
}

export const notesApi: INotesApi = new NotesApi(defaultApiInstance)
