import { TFunction } from 'react-i18next'
import { Dispatch } from 'redux'

import { formatDate } from '@alteos/ui'

import { IButton, ICardSection } from '../../../../../../../components/Cards'
import { FieldType } from '../../../../../../../dictionaries'
import { PolicySideViewType } from '../../../../../dictionaries'
import { IPolicyDocument } from '../../../../../interfaces'
import { setModal } from '../../../../../store/actionCreators'

function generatePolicyDocumentCards(document: IPolicyDocument, t: TFunction): ICardSection {
  return {
    fields: [
      {
        type: FieldType.FileName,
        value: document.name,
        id: `title-${document.name}`
      },
      {
        type: FieldType.Text,
        label: t('document.updatedAt'),
        value: formatDate(document.createdAt),
        id: `created-at-${document.name}`,
        icon: 'calendar'
      },
      {
        type: FieldType.Text,
        label: t('document.version'),
        value: document.version,
        id: `version-${document.name}`
      },
      {
        type: FieldType.Text,
        label: t('document.category'),
        value: t(`document.categories.${document.purpose}`),
        id: `category-${document.name}`
      }
    ]
  }
}

function generatePolicyDocumentButtons(
  document: IPolicyDocument,
  dispatch: Dispatch,
  t: TFunction,
  index: number = 0
): IButton[] {
  return [
    {
      children: t('document.fileDetails'),
      color: 'outline',
      small: true,
      icon: 'details',
      dataTest: `document-details-${document.name}`,
      handleClick: () =>
        dispatch(
          setModal({
            type: PolicySideViewType.DocumentDetails,
            // NOTE: Should be replaced with real id.
            params: {
              id: index.toString()
            }
          })
        )
    },
    {
      children: t('document.download'),
      color: 'outline',
      small: true,
      dataTest: `document-download-${document.name}`,
      icon: 'download',
      to: document.url,
      targetBlank: true
    }
  ]
}

export function generatePolicyDocumentsParts(
  document: IPolicyDocument,
  dispatch: Dispatch,
  t: TFunction,
  index: number = 0
): { documentCards: ICardSection; documentButtons: IButton[] } {
  const documentCards = generatePolicyDocumentCards(document, t)
  const documentButtons = generatePolicyDocumentButtons(document, dispatch, t, index)

  return {
    documentCards,
    documentButtons
  }
}
