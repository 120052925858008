export const API_URL: string = import.meta.env.REACT_APP_API_BASE_URL as string
export const APP_KEY: string = import.meta.env.REACT_APP_APP_KEY as string
export const CLIENT_ID: string = import.meta.env.REACT_APP_CLIENT_ID as string
export const APP_ENV: string = import.meta.env.REACT_APP_ENVIRONMENT as string
export const HOTJAR_KEY: string = import.meta.env.REACT_APP_HOTJAR_KEY as string
export const ZENDESK_URL: string = import.meta.env.REACT_APP_ZENDESK_URL as string
export const UNLEASH_TOKEN: string = import.meta.env.REACT_APP_UNLEASH_TOKEN as string
export const UNLEASH_URL: string = import.meta.env.REACT_APP_UNLEASH_URL as string
export const UNLEASH_APP: string = import.meta.env.REACT_APP_UNLEASH_APP as string
export const DATADOG_SITE: string = import.meta.env.REACT_APP_DATADOG_SITE as string
export const DATADOG_APPLICATION_ID: string = import.meta.env.REACT_APP_DATADOG_APPLICATION_ID as string
export const DATADOG_CLIENT_TOKEN: string = import.meta.env.REACT_APP_DATADOG_CLIENT_TOKEN as string
export const DATADOG_SAMPLING_RATE: string = import.meta.env.REACT_APP_DATADOG_SAMPLING_RATE as string

export const PERIODIC_ENDORSEMENT_ENABLED: boolean =
  typeof import.meta.env.REACT_APP_PERIODIC_ENDORSEMENT_ENABLED !== 'undefined'
    ? (import.meta.env.REACT_APP_PERIODIC_ENDORSEMENT_ENABLED as string).toLowerCase() === 'true'
    : false
