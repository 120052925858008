import { FormState } from 'redux-form'
import { call, put, select } from 'typed-redux-saga'

import { IFormConfig } from '../../../../components/Form/interfaces'
import ToastType from '../../../../dictionaries/ToastType'
import { IAction, IStore } from '../../../../interfaces/store'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils'
import { actions as layoutActions } from '../../../Layout'
import { partnerApi } from '../../api/partnerApi'
import { IGroup, IPartnerResponse, IPartnerState } from '../../interfaces'
import { IUpdatePermissionRequest, IUpdatePermissionResponse } from '../../interfaces/permissions'
import deletePartnerNameFromGroupName from '../../screens/PartnerLegacy/utils/deletePartnerNameFromGroupName'
import {
  loadGroupsFailure,
  loadGroupsSuccess,
  saveErrorMessage,
  updateGroupFailure,
  updateGroupSuccess
} from '../actions/groups'

interface IPermissionDetails {
  version: string
  action: string
  conditions: { [key: string]: string }
  internalResourceFields: { [key: string]: string }[]
}
export function* loadGroups({ payload: partnerId }: IAction<string>): Generator {
  try {
    const response: IPartnerResponse = yield* call(partnerApi.getPartnerGroupsByPartnerId, partnerId)

    yield* put(loadGroupsSuccess(deletePartnerNameFromGroupName(response)))
  } catch (error: unknown) {
    yield* put(saveErrorMessage("Failed to fetch partner's groups", error))
    yield* put(loadGroupsFailure())
  }
}

export function* updateGroup({ payload }: IAction<IFormConfig & IGroup & IPartnerState>): Generator {
  const { successMessage }: IFormConfig = payload

  const formName: string = payload.form

  const formValues: FormState = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const values: Record<string, unknown> = formValues.values ?? {}

  const body: IUpdatePermissionRequest = {
    name: `${payload.partner.name}  ${payload.name}`,
    permissions: Object.keys(values).map((key: string) => {
      const permission: IPermissionDetails = {
        version: '1',
        action: key,
        conditions: {
          'session:appKey': payload.partner.appKey ?? '',
          'session:scopePartnerId': payload.partner.id
        },
        internalResourceFields: []
      }
      return JSON.stringify(permission)
    })
  }

  try {
    const response: IUpdatePermissionResponse = yield* call(partnerApi.updateGroupById, payload.id, body)
    yield* put(updateGroupSuccess(response))
    yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
  } catch (error) {
    yield* put(updateGroupFailure())
    yield* triggerErrorPopup(error)
  }
}
