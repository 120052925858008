import { isAxiosError } from 'axios'
import { call, put, select } from 'typed-redux-saga'

import { IFormConfig } from '../../../../components/Form/interfaces'
import { ToastType } from '../../../../dictionaries'
import { IAction, IStore } from '../../../../interfaces/store'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils/requireNotNull'
import { actions as layoutActions } from '../../../Layout'
import { disputedPaymentsApi } from '../../api'
import { MODULE_NAME } from '../../constants'
import { IDisputedPayments, IPaymentDetails, ISelectedPayment } from '../../interfaces'
import {
  changeIBANSuccess,
  loadDisputedPaymentsSuccess,
  resolveDisputePaymentConfirmed,
  resolveDisputePaymentSuccess,
  setDisputedSideView
} from '../actions/disputed'
import { saveErrorMessage } from '../actions/upcoming'
import { CHANGE_IBAN_FAILURE, LOAD_DISPUTED_PAYMENTS_FAILURE, RESOLVE_DISPUTED_PAYMENT_FAILURE } from '../constants'
import { getFilterPayload } from '../utils/getFilterPayload'
import { isClientError } from './upcoming'

export function* loadDisputedPayments(): Generator {
  try {
    const disputedPaymentState = yield* select((state: IStore) => state[MODULE_NAME].disputedPayments)

    let filterPayload = getFilterPayload(disputedPaymentState.filters)

    const response: IDisputedPayments[] = yield* call(
      disputedPaymentsApi.loadDisputedPayments,
      disputedPaymentState.pagination,
      filterPayload,
      disputedPaymentState.search
    )

    yield* put(loadDisputedPaymentsSuccess(response))
  } catch (error) {
    yield* put(saveErrorMessage('Failed to fetch disputed payments', LOAD_DISPUTED_PAYMENTS_FAILURE, error))
    throw error
  }
}

export function* resolveDisputedPayment({
  payload
}: IAction<{ policyId: string; policyPrettyId: string; transactionId: string }>): Generator {
  try {
    yield* call(disputedPaymentsApi.resolveDisputePayment, payload.policyId, payload.transactionId)
    yield* put(resolveDisputePaymentSuccess(payload.policyId))
    yield* put(
      layoutActions.displayToast({
        message: `The disputed payment for the policy: ${payload.policyPrettyId} is resolved.`,
        type: ToastType.Success
      })
    )
  } catch (error) {
    yield* put(
      saveErrorMessage(
        `Failed to resolve the disputed payment of the policy ${payload.policyId}`,
        RESOLVE_DISPUTED_PAYMENT_FAILURE,
        error
      )
    )

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

export function* resolveMultipleDisputedPayments({
  payload
}: IAction<{ policyId: string; policyPrettyId: string; transactionIds: string[] }>): Generator {
  try {
    yield* call(disputedPaymentsApi.resolveMultipleDisputePayments, payload)
    yield* put(resolveDisputePaymentSuccess(payload.policyId))
    yield* put(
      layoutActions.displayToast({
        message: `The disputed payments for policy: ${payload.policyPrettyId} are resolved.`,
        type: ToastType.Success
      })
    )
  } catch (error) {
    yield* put(
      saveErrorMessage(
        `Failed to resolve the disputed payments of the policy ${payload.policyId}`,
        RESOLVE_DISPUTED_PAYMENT_FAILURE,
        error
      )
    )

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

export function* changeIBAN({ payload }: IAction<IFormConfig & ISelectedPayment>): Generator {
  const formName = payload.form

  const formValues = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const { values }: { [fieldName: string]: any } | undefined = formValues

  const body: IPaymentDetails = {
    firstName: values.firstName,
    lastName: values.lastName,
    iban: values.iban
  }
  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(disputedPaymentsApi.changeIBAN, payload.policyId, body, payload.policyPrettyId, payload.transactionId)
    yield* put(changeIBANSuccess())
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* put(setDisputedSideView(null))
    yield* put(layoutActions.closeSideView())
    yield* put(
      resolveDisputePaymentConfirmed({
        policyId: payload.policyId,
        policyPrettyId: payload.policyPrettyId,
        transactionId: payload.transactionId
      })
    )
  } catch (error) {
    yield* put(saveErrorMessage('Failed to change IBAN', CHANGE_IBAN_FAILURE, error))
    yield* put(layoutActions.setSideViewIsProcessingData(false))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}
