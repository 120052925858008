import { z } from 'zod'

import { localStorageService } from '../../../utils/localStorageService'

const LOCAL_STORAGE_KEY = 'currentUser' as const

const SavedUsedStateSchema = z.object({
  name: z.string(),
  permissions: z.array(z.any()), // TODO: replace any[] because Action[] is not accurate from IAuthState
  userId: z.string().uuid()
})

export type SavedUsedState = z.infer<typeof SavedUsedStateSchema>

class AuthCache {
  getCurrent(): SavedUsedState | null {
    return localStorageService.getItem(LOCAL_STORAGE_KEY, SavedUsedStateSchema)
  }
  save(currentUser: SavedUsedState): void {
    localStorageService.setItem(LOCAL_STORAGE_KEY, SavedUsedStateSchema.parse(currentUser))
  }
  clear(): void {
    localStorageService.clearItem(LOCAL_STORAGE_KEY)
  }
}

export const authCache = new AuthCache()
