import { FC, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container } from '../../../../components/sideViewComponents'
import { ISelectOption } from '../../../../interfaces/common'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { IModuleActions } from '../../interfaces'
import actionsToBind from '../../store/actionCreators'
import { partnersSelector } from '../../store/selectors'
import generateBatchImportFormCards from './utils/generateBatchImportFormCards'

import styles from './batchImport.module.scss'

const BatchImportForm: FC = (): ReactElement => {
  const { t } = useTranslation('policies')
  const dispatch: reduxDispatch = useDispatch()
  const actions: IModuleActions = useMemo(() => bindActionCreators(actionsToBind, dispatch), [dispatch])
  const partners: ISelectOption[] = useSelector(partnersSelector)
  const isSubmitting: boolean = useSelector((store: IStore) => store[MODULE_NAME].isImportingPolicies)

  const formConfig: IFormConfig = generateBatchImportFormCards(t, partners, isSubmitting)

  const batchImportFormProps: IFormProps = {
    ...formConfig,
    isSubmitting,
    formType: FormType.CREATE,
    onClose: () => actions.setSideView(null),
    onSubmit: () => actions.importPoliciesStart(formConfig)
  }

  return (
    <>
      <Container className={styles.title} title={t('policies.batchImport.title')} icon="batch-actions" />
      <Form {...batchImportFormProps} />
    </>
  )
}

export default BatchImportForm
