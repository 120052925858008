/* eslint-disable @typescript-eslint/typedef */
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import merge from 'lodash/merge'

import { requireString } from '../../../utils/requireString'
import { IPolicy, IQuotePolicyRequestPayload } from '../interfaces'

function getQuotationPayload(formState: Record<string, any>, policy: IPolicy): IQuotePolicyRequestPayload {
  const { product } = policy
  const { addons: addonsFromForm, objects: objectsFromForm, package: selectedPackage } = formState
  const objectsMerged = merge(cloneDeep(policy.objects), objectsFromForm)
  const valuesMerged = merge(formState.values ?? {})
  const addons: string[] = []
  if (!isNil(addonsFromForm)) {
    addons.push(
      ...Object.entries(addonsFromForm)
        .filter(([_key, value]): any => value)
        .map(([key]): any => key)
    )
  }

  const objectValuesMerged: Record<string, unknown> = Array.isArray(objectsMerged)
    ? objectsMerged.reduce((curr, next) => {
        return { ...curr, ...next.values }
      }, {})
    : {}

  const { packageName, ...restValues } = valuesMerged

  return {
    addons,
    package: requireString(selectedPackage),
    product: {
      productId: product.id,
      productVersion: product.latestVersion
    },
    values: { ...restValues, ...objectValuesMerged },
    expirationDateRequired: false
  }
}

export default getQuotationPayload
