import set from 'lodash/set'
import { TFunction } from 'react-i18next'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'

import { ICardSection } from '../../../../../components/Cards'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { ClaimSubStatus } from '../../../../../dictionaries/ClaimSubStatus'
import { IFieldConfig } from '../../../../../interfaces/common'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { forms } from '../../../constants'
import { IPolicy } from '../../../interfaces'
import { claimStatusFormFields } from '../../ClaimDetails/utils/claimStatusFormFields'

interface IClaimFormInitialValues {
  values: {
    [key: string]: string
  }
  claimedAt: string
  claimType: string
}

const generateCreateClaimFormCards = (
  config: IUiConfig,
  policy: IPolicy,
  t: TFunction,
  language: ISupportedLanguage,
  claimSubStatus: ClaimSubStatus[]
): IFormConfig => {
  const initialValues: IClaimFormInitialValues = {
    values: {},
    claimedAt: new Date().toISOString().split('T')[0],
    claimType: getTranslation(language, config.claimsCards.typeName ?? '')
  }

  const claimStatusCard: ICardSection = {
    title: t('policy:createClaimForm.claimStatusCard.title'),
    fields: [...claimStatusFormFields(t, claimSubStatus, true)]
  }

  config.claimsCards.claimDetailsCards.forEach((group: ICardSection) => {
    group.fields.forEach((field: IFieldConfig) => {
      if (typeof field.id !== 'undefined') {
        set(initialValues, field.id, '')
      }
    })
  })

  return {
    form: forms.createClaimForm,
    url: `v1/policies/${policy.id}/claim`,
    initialValues,
    successMessage: t('createClaimForm.successMessage'),
    formSections: [...config.claimsCards.claimDetailsCards, claimStatusCard]
  }
}

export default generateCreateClaimFormCards
