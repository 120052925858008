import cx from 'classnames'
import { FC } from 'react'

import { Icon, MarkdownContentContainer } from '@alteos/ui'

import { ITextViewFieldConfig } from '../../../../../interfaces/common'
import { useDynamicTranslation } from '../../../../../modules/i18n/hooks'
import { FieldValue } from '../../../interfaces'

import styles from '../../card.module.scss'

interface IProps {
  isInline: boolean
  field: ITextViewFieldConfig
  value: FieldValue
  dataTest?: string
}

const TextItem: FC<IProps> = ({ field, isInline, value, dataTest: externalDataTest }: IProps) => {
  const label: string = useDynamicTranslation(field.label)
  const isFullWidth: boolean = field.size === 'full'
  const isHalfWidth: boolean = field.size === 'half'
  const dataTest: string = typeof externalDataTest !== 'undefined' ? externalDataTest : field.id ?? label
  return (
    <div
      className={cx(styles.item, styles.text, {
        [styles.fullWidth]: isFullWidth,
        [styles.halfWidth]: isHalfWidth,
        [styles.grid]: !isInline,
        [styles.withLineBreak]: field.withLineBreak === true
      })}
    >
      {typeof field.label !== 'undefined' && (
        <span className={styles.label} data-test={`${dataTest}-label`}>
          {label}
          {!isInline && ':'}
        </span>
      )}
      <div className={styles.itemContent}>
        <div className={styles.markdownContainer}>
          {typeof field.icon === 'string' ? (
            <Icon name={field.icon} tint={{ color: 'neutral', shade: '76' }} className={styles.icon} />
          ) : null}
          <MarkdownContentContainer
            content={(value ?? '-').toString()}
            dataTest={dataTest}
            withTwoColumnsList={false}
          />
        </div>
      </div>
    </div>
  )
}

export default TextItem
