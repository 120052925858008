interface IWithCreatedAt {
  createdAt: string
}

// TODO: provide currying for different sorting functions by field
function sortByCreatedAt(itemA: IWithCreatedAt, itemB: IWithCreatedAt): number {
  return itemB.createdAt.localeCompare(itemA.createdAt)
}

export default sortByCreatedAt
