import i18next from 'i18next'
import { PureComponent, ReactNode } from 'react'

import StatusContent from '../../components/StatusContent'

interface IState {
  hasError: boolean
}

interface IProps {
  children: ReactNode
}

class ErrorBoundary extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  resetErrorState: () => void = () => {
    this.setState({ hasError: false })
    // TODO, yo!
    // history.__legacy_push(POLICIES_ROUTE)
  }

  render(): ReactNode {
    const { hasError }: IState = this.state
    const { children }: IProps = this.props

    if (hasError) {
      return (
        <StatusContent
          title={i18next.t('static:error.title')}
          content={i18next.t('static:error.content')}
          subContent={i18next.t('static:error.subContent')}
          button={{
            handleClick: this.resetErrorState,
            label: i18next.t('static:error.button'),
            icon: 'arrow-left'
          }}
          handleLogoClick={this.resetErrorState}
          dataTest="error-boundary"
        />
      )
    }

    return children
  }
}

export default ErrorBoundary
