import { isNil } from 'lodash'

import { IFieldConfig } from '../../../interfaces/common'
import {
  isAlertFormFieldConfig,
  isStringFormFieldConfig,
  isTextViewFieldConfig,
  isViewField
} from '../../../interfaces/guards'

export const getFieldIdValue = (field: IFieldConfig): string | undefined => {
  const isNonIdFormField: boolean = isStringFormFieldConfig(field) || isAlertFormFieldConfig(field)
  if (isNil(field.id) && (isViewField(field) || isTextViewFieldConfig(field) || isNonIdFormField)) {
    if (!isNil(field.value)) {
      field.disabled = true
    }
    return ''
  }
  return field.id
}
