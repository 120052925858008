import cx from 'classnames'
import { FC, ReactElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'
import { ITranslatable } from '@alteos/product-configuration/dist/translation/ITranslatable'
import { Icon } from '@alteos/ui'

import { getCurrentLanguage } from '../../../modules/i18n/selectors'

import styles from './container.module.scss'

interface IProps {
  children?: ReactNode
  label?: ReactNode
  className?: string
  title?: ITranslatable
  subtitle?: ITranslatable
  dataTest?: string
  icon?: string
}

const Container: FC<IProps> = ({
  children = '',
  label,
  className = '',
  title,
  subtitle,
  icon,
  dataTest = ''
}: IProps): ReactElement | null => {
  const language: ISupportedLanguage = useSelector(getCurrentLanguage)
  const withIcon: boolean = typeof icon !== 'undefined'
  const cl: string = cx(styles.container, className, { [styles.withIcon]: withIcon })

  return (
    <div className={cl} data-test={dataTest}>
      {withIcon === true ? (
        <aside>
          <div className={styles.iconContainer}>
            <Icon name={icon as string} />
          </div>
        </aside>
      ) : null}
      <div>
        {typeof title !== 'undefined' ? <h2 className={styles.title}>{getTranslation(language, title)}</h2> : null}
        {typeof subtitle !== 'undefined' ? (
          <h3 className={styles.subtitle}>{getTranslation(language, subtitle)}</h3>
        ) : null}
        {typeof label !== 'undefined' ? <div className={styles.editModeLabelContainer}>{label}</div> : null}
      </div>
      {children}
    </div>
  )
}

export default Container
