import { IRequestPagination } from '@alteos/ui'

import { IFilter } from '../../../components/Filters/interfaces'
import { IBasicStore } from '../../../interfaces/store'

export interface IDisputedPayments {
  id: string
  policyPrettyId: string
  transactionId: string
  policyId: string
  startsAt: string
  gross: number
  fee: number
  productName: string
  createdAt: string
}

export interface ISelectedPayment {
  policyId: string
  policyPrettyId: string
  transactionId: string
}

export interface ISelectedMultiplePayment {
  policyId: string
  policyPrettyId: string
  transactionIds: string[]
}

export interface IDisputedPaymentsState extends IBasicStore {
  isFetchingDisputedPayments: boolean
  isResolvingDisputedPayment: boolean
  isChangingIBAN: boolean
  resolvingDisputedPaymentInitiated: boolean
  resolvingMultipleDisputedPaymentInitiated: boolean
  disputedPayments: IDisputedPayments[]
  pagination: IRequestPagination
  hasMore: boolean
  selectedPayment: ISelectedPayment | null
  activeSideViewType: UpdateIBANSideViewType | null
  filters?: IFilter[]
  search?: string
}

export enum UpdateIBANSideViewType {
  UpdateIBANSideView = 'UpdateIBANSideView'
}

export interface IDisputedSetSideViewPayload {
  type: UpdateIBANSideViewType
  params: {
    policyId: string
    policyPrettyId: string
    transactionId: string
  }
}
