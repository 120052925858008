export function tryParseNumber(dynamicValue: unknown): number {
  if (typeof dynamicValue === 'string') {
    const parsedNumber: number = parseFloat(dynamicValue)
    if (!Number.isNaN(parsedNumber)) {
      return parsedNumber
    } else {
      throw new Error(`Cannot parse string: '${dynamicValue}' to a number`)
    }
  } else if (typeof dynamicValue === 'number') {
    return dynamicValue
  } else {
    throw new Error(`Cannot parse dynamicValue '${dynamicValue}' of type: '${typeof dynamicValue}' to a number`)
  }
}
