import { IFilter } from '../interfaces'

const resetFilters: (coll: IFilter[]) => IFilter[] = (coll: IFilter[]): IFilter[] => {
  return coll.map((filter: IFilter) => {
    if (filter.isCountable) {
      return {
        ...filter,
        active: false,
        selectedFilter: ''
      }
    }
    return filter
  })
}

export default resetFilters
