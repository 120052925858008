import { TFunction } from 'react-i18next'

import { InputType, MaskType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { forms } from '../../../constants'
import { IMandate, IPolicy } from '../../../interfaces'
import { mapPaymentData } from '../../../utils/mapPaymentData'

const generateEditIbanFormCards: (t: TFunction, policy: IPolicy, mandate: IMandate | null) => IFormConfig = (
  t: TFunction,
  policy: IPolicy,
  mandate: IMandate | null
) => {
  const paymentData = mapPaymentData(mandate, policy.purchase?.processingData)

  return {
    form: forms.editIbanForm,
    url: '',
    initialValues: {
      firstName: paymentData.firstName,
      lastName: paymentData.lastName
    },
    successMessage: t('policy:editIbanForm.successMessage'),
    formSections: [
      {
        fields: [
          {
            id: 'firstName',
            label: t('policyHolderDetails.personalInfo.firstName'),
            editType: InputType.string,
            size: 'small',
            required: true
          },
          {
            id: 'lastName',
            label: t('policyHolderDetails.personalInfo.lastName'),
            editType: InputType.string,
            size: 'small',
            required: true
          },
          {
            id: 'iban',
            label: t('policy:editIbanForm.newIban'),
            placeholder: 'XX 00 0000 0000 0000 0000 00',
            editType: InputType.masked,
            mask: MaskType.iban,
            required: true
          }
        ]
      }
    ]
  }
}

export default generateEditIbanFormCards
