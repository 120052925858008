import classNames from 'classnames'
import { FC, ReactElement } from 'react'

import { Loader } from '../Loader'

import styles from './style.module.scss'

interface IProps {
  label: string
  value: string | null
  variant?: 'normal' | 'bold'
  dataTest: string
}

export const KeyValuePairItem: FC<IProps> = ({ label, value, variant = 'normal', dataTest }: IProps) => {
  const loader: ReactElement = <Loader name="loader" dataTest={`${dataTest}-loader`} />

  return (
    <div
      className={classNames(styles.keyValuePairWrapper, {
        [styles.variantBold]: variant === 'bold'
      })}
    >
      <div className={styles.keyValuePairKey} data-test={`${dataTest}-key`}>
        {label}
      </div>
      <div className={styles.keyValuePairSeparator} />
      <div className={styles.keyValuePairValue} data-test={`${dataTest}-value`}>
        {value === null ? loader : value}
      </div>
    </div>
  )
}
