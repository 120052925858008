import { ReactElement } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useSelector } from 'react-redux'

import Container from '../../../../../components/sideViewComponents/Container'
import { IStore } from '../../../../../interfaces/store'
import { MODULE_NAME } from '../../../constants'
import { IReferralPartner } from '../../../interfaces/referralPartner'

import styles from '../partnerPortal.module.scss'

interface IProps {
  children: ReactElement
}

export const FormContainer = ({ children }: IProps): ReactElement | null => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')

  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )

  if (typeof referralPartner === 'undefined') {
    return null
  }

  return (
    <>
      <Container title={t('partnerPortal.partnerPortalManagement')} icon="users" className={styles.title} />
      {children}
    </>
  )
}
