import defaultApiInstance, { Api } from '../../../../services/api/api'
import { tryParseNumber } from '../../../../utils/tryParseNumber'
import {
  ClaimReserveType,
  IClaimResponse,
  IClaimApi,
  ISyncDocsResponse,
  IClaimCreateNoteResponse,
  IClaimNote
} from '../../interfaces'
import { GET_CLAIM_BY_ID_QUERY } from '../../queries/getClaimByIdQuery'

export class ClaimApi implements IClaimApi {
  constructor(private api: Api) {}

  getClaim = async (claimId: string): Promise<IClaimResponse> => {
    const response: IClaimResponse = await this.api.query(GET_CLAIM_BY_ID_QUERY, {
      claimId
    })

    return response
  }

  createInitialClaimReserve = async (claimId: string, initialLossReserve: unknown): Promise<void> => {
    await this.api.post(`v1/claims/${claimId}/reserves`, {
      amount: {
        value: tryParseNumber(initialLossReserve)
      },
      type: ClaimReserveType.Increase
    })
  }

  syncClaimDocuments = async (claimId: string): Promise<ISyncDocsResponse> => {
    const response = await this.api.post(`/v1/claims/${claimId}/synchronize`)
    return response.data
  }

  createClaimNote = async (claimId: string, text: string): Promise<IClaimCreateNoteResponse> => {
    const response = await this.api.post(`/v1/claims/${claimId}/notes`, { text })
    return response.data
  }

  getClaimNotes = async (claimId: string): Promise<IClaimNote[]> => {
    const response = await this.api.get(`/v1/claims/${claimId}/notes`)
    const { notes } = response.data
    return notes
  }
}

export const claimApi: IClaimApi = new ClaimApi(defaultApiInstance)
