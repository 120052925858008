import { IRequestPagination } from '@alteos/ui'

import { ISaveErrorMessagePayload } from '../../../interfaces/action'
import { IAction, IBasicAction } from '../../../interfaces/store'
import { DEFAULT_PAGINATION } from '../constants'
import { IInvoicePreviewResponse, IInvoicesResponse, InvoiceTypeEnum } from '../interfaces'
import {
  LOAD_INVOICES_FAILURE,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICE_PREVIEW_FAILURE,
  LOAD_INVOICE_PREVIEW_REQUEST,
  LOAD_INVOICE_PREVIEW_SUCCESS,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SET_INVOICE_SIDE_VIEW,
  SET_SHOW_ACTIONS
} from './constants'

interface ISetActiveTabAction {
  module: string
  payload: string
}

export function loadInvoicesSuccess(payload: IInvoicesResponse): IAction<IInvoicesResponse> {
  return {
    type: LOAD_INVOICES_SUCCESS,
    payload
  }
}

export function loadInvoicesStart(
  payload: IRequestPagination = DEFAULT_PAGINATION,
  invoiceTypes?: InvoiceTypeEnum[]
): IAction<IRequestPagination & { invoiceTypes?: InvoiceTypeEnum[] }> {
  return {
    type: LOAD_INVOICES_REQUEST,
    payload: {
      ...payload,
      invoiceTypes
    }
  }
}

export function sendEmailFailure(): IBasicAction {
  return {
    type: SEND_EMAIL_FAILURE
  }
}

export function sendEmailSuccess(): IBasicAction {
  return {
    type: SEND_EMAIL_SUCCESS
  }
}

export function sendEmailStart(payload: { invoiceId: string }): IAction<{ invoiceId: string }> {
  return {
    type: SEND_EMAIL_REQUEST,
    payload
  }
}

export function loadInvoicesFailure(): IBasicAction {
  return {
    type: LOAD_INVOICES_FAILURE
  }
}

export function loadInvoicePreviewSuccess(payload: IInvoicePreviewResponse): IAction<IInvoicePreviewResponse> {
  return {
    type: LOAD_INVOICE_PREVIEW_SUCCESS,
    payload
  }
}

export function loadInvoicePreviewStart(invoiceId: string): IAction<{ invoiceId: string }> {
  return {
    type: LOAD_INVOICE_PREVIEW_REQUEST,
    payload: {
      invoiceId
    }
  }
}

export function loadInvoicePreviewFailure(): IBasicAction {
  return {
    type: LOAD_INVOICE_PREVIEW_FAILURE
  }
}

export function openActionsList(payload: string | null): IAction<string | null> {
  return { type: SET_SHOW_ACTIONS, payload: payload || null }
}

export function saveErrorMessage(
  message: string,
  type: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type,
    payload: {
      message,
      originalError
    }
  }
}

export const setActiveTab = ({ module, payload }: ISetActiveTabAction): IAction<string> => {
  return {
    type: `SET_ACTIVE_${module}_TAB`,
    payload
  }
}

export function setInvoiceSideView(payload: string | null): IAction<string | null> {
  return {
    type: SET_INVOICE_SIDE_VIEW,
    payload
  }
}
