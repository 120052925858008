import cx from 'classnames'
import { FC, ReactElement } from 'react'

import { LabelMode, LabelType } from './dictionaries'

import styles from './label.module.scss'

interface ILabelProps {
  content: string
  tooltip?: string
  mode: keyof typeof LabelMode
  type?: keyof typeof LabelType
  className?: string
  dataTest: string
}

const Label: FC<ILabelProps> = ({
  content = '',
  mode = 'outline',
  type = LabelType.neutral,
  className = '',
  dataTest
}: ILabelProps): ReactElement | null => {
  if (content === '') {
    return null
  }

  return (
    <div className={cx(styles.container, className, styles[type], styles[mode])}>
      <p className={cx(styles.content)} data-test={dataTest}>
        {content}
      </p>
    </div>
  )
}

export default Label
