import { call, put } from 'typed-redux-saga'

import { IAction } from '../../../../interfaces/store'
import { partnerApi } from '../../api/partnerApi'
import { IPermissionsResponse } from '../../interfaces/permissions'
import { saveErrorMessage } from '../actions/partner'
import { loadPermissionsFailure, loadPermissionsSuccess } from '../actions/permissions'

export function* loadPermissions({ payload: query }: IAction<string>): Generator {
  try {
    const response: IPermissionsResponse = yield* call(partnerApi.listPermissions, query)

    yield* put(loadPermissionsSuccess(response.permissions))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch Permissions ', error))
    yield* put(loadPermissionsFailure())
  }
}
