import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ClaimStatus } from '@alteos/claim-client/dist/dictionaries/ClaimStatus'

import Label from '../../../../components/Label'
import { LabelType } from '../../../../components/Label/dictionaries'
import generateClaimLabelType from '../../SideViewContent/ClaimDetails/utils/generateClaimLabelType'

interface IProps {
  status: ClaimStatus | null
}

export const ClaimStatusLabel: FC<IProps> = ({ status }) => {
  const { t } = useTranslation('policy')
  const formattedValue: string = t(`claims.claimStatus.${status}`)
  const labelType: LabelType = generateClaimLabelType(status)
  return <Label type={labelType} content={formattedValue} dataTest="claim-status" mode="light" />
}
