import isNil from 'lodash/isNil'
import { TFunction } from 'react-i18next'

import { formatFactory } from '@alteos/ui'

import { ICardSection } from '../../../../../components/Cards'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { FieldType } from '../../../../../dictionaries'
import { IFieldConfig } from '../../../../../interfaces/common'
import { isViewField } from '../../../../../interfaces/guards'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { forms } from '../../../constants'
import { ICustomer, IPolicy, IPolicyObject, IPolicyRisk } from '../../../interfaces'
import { generateEditHeaderCardForPolicyValues, stringifyObject } from '../../../utils'

const generatePolicyValuesFormCards = (t: TFunction, policy: IPolicy, config: IUiConfig): IFormConfig => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...customer }: ICustomer = policy.customer
  const { formatCapitalize } = formatFactory

  const initialValues: Record<string, any> = {
    customer,
    effectiveAt: '',
    effectiveRequestedAt: '',
    metadata: policy.metadata,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    objects: policy.objects.map(({ coverageStartsAt, coverageEndsAt, risks, ...rest }: IPolicyObject) => {
      return {
        ...rest,
        risks: risks.map(({ name, values }: IPolicyRisk) => {
          return { name, values }
        })
      }
    }),
    updateReason: '',
    values: { ...policy.values }
  }

  if (!isNil(policy.device)) {
    initialValues['device'] = policy.device
  }

  initialValues.values = stringifyObject(initialValues.values)

  const productSpecificFormSection: ICardSection[] = config.detailsCards.productSpecificCards.map(
    (card: ICardSection) => {
      return {
        ...card,
        title: formatCapitalize(
          card.fields.find((field: IFieldConfig) => isViewField(field) && field.type === FieldType.Title)?.value
        )
      }
    }
  )

  const formSections: ICardSection[] = []
  formSections.push(generateEditHeaderCardForPolicyValues(policy))
  formSections.push(...productSpecificFormSection)

  return {
    form: forms.editPolicyValuesForm,
    url: `v1/policies/${policy.id}/update`,
    initialValues,
    successMessage: t('policyValuesForm.successMessage'),
    formSections
  }
}

export default generatePolicyValuesFormCards
