import cx from 'classnames'
import { get, isNil } from 'lodash'
import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Field, FormAction, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'
import {
  Alert,
  AlertType,
  Button,
  Divider,
  FormElement,
  InputType,
  MaskType,
  IGeneratedEvent,
  IFormValue
} from '@alteos/ui'

import {
  IExtendedFieldProps,
  IFieldConfig,
  IFieldToUpdate,
  IFormFieldConfig,
  IHandleFieldChangeOptions
} from '../../../interfaces/common'
import {
  isAlertFormFieldConfig,
  isButtonFormFieldConfig,
  isDateFormFieldConfig,
  isDividerViewFieldConfig,
  isElementViewFieldConfig,
  isFileFormFieldConfig,
  isMaskedFormFieldConfig,
  isSelectFormFieldConfig,
  isStringFormFieldConfig,
  isTextFormFieldConfig,
  isTitleViewFieldConfig
} from '../../../interfaces/guards'
import { useDynamicTranslation } from '../../../modules/i18n/hooks'
import { getCurrentLanguage } from '../../../modules/i18n/selectors'
import { requireNotNull } from '../../../utils'
import { getConditionResult } from '../../../utils/getConditionResult'
import shouldRenderNull from '../../../utils/shouldRenderNull'
import { ExpandableCard, ICardSection } from '../../Cards'
import ElementItem from '../../Cards/Card/items/ElementItem'
import { FileUploaderFormElement } from '../../FileUploader/FileUploaderFormElement'
import { FormType } from '../FormType'
import { IFieldSpecificProps, IFormConfig, Validator } from '../interfaces'
import { generateValidationSchema, getFieldIdValue, getFieldSpecificProps } from '../utils'

import styles from './styles.module.scss'

interface IProps extends ICardSection {
  formValues?: Record<string, any>
  source?: Record<string, any>
  isSubmitting: boolean
  helpers?: IFormConfig['helpers']
  icon?: string
  isFullWidth?: boolean
  formType: FormType
  reduxFieldChange: (form: string, field: string, value: IFormValue) => FormAction
}

type RenderFieldProps = IExtendedFieldProps & WrappedFieldProps

const FieldComponent: FC<RenderFieldProps> = ({
  input,
  meta,
  formValues,
  reduxFieldChange,
  fieldConfig,
  source,
  disabled: disabledFromProps
}: RenderFieldProps): JSX.Element => {
  const currentLanguage: ISupportedLanguage = useSelector(getCurrentLanguage)

  const { error, touched, form }: WrappedFieldMetaProps = meta
  const { label, editType, size, minimum, maximum, handleFieldChangeOptions }: IFormFieldConfig = fieldConfig

  const placeholder: string = useDynamicTranslation(fieldConfig.placeholder)
  const note: string = useDynamicTranslation(fieldConfig.note)
  const showYearDropdown: boolean = isDateFormFieldConfig(fieldConfig) && fieldConfig.showYearDropdown !== false
  const isClearable: boolean = isSelectFormFieldConfig(fieldConfig) && fieldConfig.isClearable === true
  let alertType: string | undefined
  if (isAlertFormFieldConfig(fieldConfig)) {
    alertType = fieldConfig.alertType
  }
  const toUpperCase: boolean =
    (isStringFormFieldConfig(fieldConfig) || isTextFormFieldConfig(fieldConfig)) && fieldConfig.toUpperCase === true
  const mask: MaskType | null = isMaskedFormFieldConfig(fieldConfig) ? fieldConfig.mask : null

  const {
    options,
    content,
    title,
    disabled: computedDisabled,
    defaultOption
  }: IFieldSpecificProps = getFieldSpecificProps(fieldConfig, formValues, source, currentLanguage)

  const disabled: boolean = disabledFromProps || computedDisabled

  const handleChange = (event: IGeneratedEvent): void => {
    input.onChange(event.target.value)

    if (typeof handleFieldChangeOptions !== 'undefined') {
      const { fieldsToUpdate }: IHandleFieldChangeOptions = handleFieldChangeOptions

      fieldsToUpdate.forEach((field: IFieldToUpdate) => {
        if (typeof field.conditions !== 'undefined') {
          const conditionsMet = getConditionResult(field.conditions, formValues, event.target.value)

          if (!conditionsMet) {
            return
          }
        }

        reduxFieldChange(form, field.name, field.newValue)
      })
    }
  }

  const type: string = typeof editType === 'undefined' ? InputType.string : editType

  const sizeCl: string = typeof size !== 'undefined' ? styles[size] : ''

  const typeCl: string = styles[type] !== 'undefined' ? styles[type] : ''

  // Need improvements here for selectbox-sortAlphabetically usage only in certain forms
  const isTerminationInputFields = ['terminationReason', 'extraInput.newCountryOfResidence'].includes(input.name)

  return (
    <FormElement
      sortAlphabetically={isTerminationInputFields ? true : false}
      {...input}
      className={cx(styles.element, sizeCl, typeCl)}
      error={error}
      touched={touched}
      label={label}
      id={input.name}
      value={!isNil(fieldConfig.value) ? fieldConfig.value : input.value}
      // NOTE: tab index equal to zero means that the element should be focusable in sequential keyboard navigation,
      // after any positive tabindex values and it's order is defined by the document's source order.
      tabIndex={0}
      onChange={handleChange}
      // TODO: following props are field specific
      // and may or may not be required, find a better solution
      mask={mask}
      isClearable={isClearable}
      toUpperCase={toUpperCase}
      note={note}
      placeholder={placeholder}
      type={type}
      minimum={minimum}
      maximum={maximum}
      disabled={disabled}
      title={title}
      showYearDropdown={showYearDropdown}
      options={options}
      content={content}
      alertType={alertType}
      defaultOption={defaultOption}
    />
  )
}

interface IFieldRenderWrapperProps {
  field: IFieldConfig
  formValues?: { [key: string]: any }
  formType: FormType
  isSubmitting: boolean
  reduxFieldChange: IProps['reduxFieldChange']
  source: IProps['source']
  helpers: IProps['helpers']
}

const FieldRenderWrapper: FC<IFieldRenderWrapperProps> = ({
  formValues = {},
  field,
  isSubmitting,
  reduxFieldChange,
  formType,
  source
}: IFieldRenderWrapperProps) => {
  const language: ISupportedLanguage = useSelector(getCurrentLanguage)
  const label: string | undefined = useDynamicTranslation(field.label)

  let getFormValuesByFieldId: IFormValue | undefined
  if (!isNil(field.id)) {
    getFormValuesByFieldId = get(formValues, field.id)
  }
  const fieldValue: IFormValue = field.value ?? getFormValuesByFieldId
  const isEditable: boolean = (field as any).editable ?? true
  const isDisabledIfConditionMet: boolean =
    !isNil(field.disabledIf) &&
    Object.entries(formValues).length > 0 &&
    getConditionResult(field.disabledIf, formValues)

  const isFieldDisabled: boolean =
    field.disabled === true || isSubmitting || (formType === FormType.EDIT && !isEditable) || isDisabledIfConditionMet

  if (field.hidden === true || field.viewOnly === true) {
    return null
  }

  if (shouldRenderNull(field, fieldValue, formValues)) {
    return null
  }

  // NOTE: place to ad-hoc add support for view components in edit views..
  // TODO: unify rendering paths, view components should just work
  if (isDividerViewFieldConfig(field)) {
    return <Divider className={styles.divider} />
  }

  if (isElementViewFieldConfig(field)) {
    return <ElementItem field={field} value={field.value} isInline={true} source={formValues} formValues={formValues} />
  }

  if (isTitleViewFieldConfig(field)) {
    return <h4 className={styles.subtitle}>{field.value}</h4>
  }

  if (isAlertFormFieldConfig(field)) {
    const alertType: AlertType = field.alertType ?? 'info'
    return (
      <Alert
        dataTest={`alert-${alertType}`}
        className={styles.alert}
        content={getTranslation(language, field.content)}
        alertType={alertType}
      />
    )
  }

  if (isFileFormFieldConfig(field)) {
    return <FileUploaderFormElement field={field} disabled={isFieldDisabled} />
  }

  if (isButtonFormFieldConfig(field)) {
    return (
      <Button
        icon={isSubmitting ? 'loader' : ''}
        disabled={isSubmitting || isFieldDisabled}
        tabIndex={0}
        dataTest={field.dataTest}
        type={field.buttonType ? field.buttonType : 'submit'}
        handleClick={field.handleClick ? field.handleClick : () => {}}
        color={field.color ? field.color : 'primary'}
        noMinWidth={field.noMinWidth === true ? field.noMinWidth : false}
        className={field.style ? field.style : ''}
      >
        {getTranslation(language, requireNotNull(field.label, 'field.label'))}
      </Button>
    )
  }

  const validationSchema: Validator[] = generateValidationSchema(field)

  return (
    <Field
      formValues={formValues}
      component={FieldComponent}
      validate={validationSchema}
      fieldConfig={{ ...field, label }}
      name={getFieldIdValue(field)}
      source={source}
      reduxFieldChange={reduxFieldChange}
      formType={formType}
      disabled={isFieldDisabled}
    />
  )
}

const FormSection: FC<IProps> = ({
  fields,
  title: titleFromProps,
  isStatic,
  isSubmitting,
  disabled,
  disabledIf,
  formValues,
  formType,
  isInitiallyExpanded,
  source,
  helpers = {},
  icon,
  isFullWidth,
  reduxFieldChange
}: IProps) => {
  const title = useDynamicTranslation(titleFromProps)

  if (typeof formValues === 'undefined') {
    return null
  }

  const isDisabledIfConditionMet = !isNil(disabledIf) && getConditionResult(disabledIf, formValues)

  const content: ReactElement = (
    <div className={styles.elementsContainer}>
      {fields.map((field: IFieldConfig, index: number) => (
        <FieldRenderWrapper
          key={index}
          field={field}
          formValues={formValues}
          formType={formType}
          isSubmitting={isSubmitting || isDisabledIfConditionMet}
          reduxFieldChange={reduxFieldChange}
          source={source}
          helpers={helpers}
        />
      ))}
    </div>
  )

  return (
    <ExpandableCard
      className={styles.container}
      title={title}
      isStatic={isStatic}
      isInitiallyExpanded={isInitiallyExpanded}
      disabled={disabled}
      expandedContent={content}
      icon={icon}
      isFullWidth={isFullWidth}
    />
  )
}

export default FormSection
