import { IAction } from '../../../../interfaces/store'
import { IGroupsState, IPartnerResponse } from '../../interfaces'
import {
  LOAD_GROUPS_FAILURE,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS
} from '../constants'

export const initialState: IGroupsState = {
  isFetchingGroups: false,
  groups: [],
  errorMessage: null
}

function reducer(state: IGroupsState = initialState, action: IAction<any>): IGroupsState {
  switch (action.type) {
    case LOAD_GROUPS_REQUEST: {
      return {
        ...state,
        isFetchingGroups: true
      }
    }

    case LOAD_GROUPS_SUCCESS: {
      const { payload }: IAction<IPartnerResponse> = action
      if (typeof payload.groups === 'undefined') return state
      return {
        ...state,
        groups: payload.groups
      }
    }

    case LOAD_GROUPS_FAILURE: {
      return {
        ...state,
        isFetchingGroups: false
      }
    }

    case UPDATE_GROUP_REQUEST: {
      return {
        ...state
      }
    }

    case UPDATE_GROUP_SUCCESS: {
      return {
        ...state
      }
    }

    case UPDATE_GROUP_FAILURE: {
      return {
        ...state
      }
    }

    default:
      return state
  }
}

export default reducer
