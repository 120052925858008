import { isNil } from 'lodash'
import { FC, useCallback } from 'react'
import { Field, WrappedFieldProps } from 'redux-form'

import { IFileFormFieldConfig } from '../../interfaces/common'
import { IFileWrapper } from './interfaces'

import FileUploader from '.'

interface IProps {
  field: IFileFormFieldConfig
  disabled: boolean
}

interface IFileUploaderFormElementInternalProps extends WrappedFieldProps {
  field: IFileFormFieldConfig
}

const FileUploaderFormElementInternal: FC<IFileUploaderFormElementInternalProps> = ({
  input,
  field
}: IFileUploaderFormElementInternalProps) => {
  return (
    <FileUploader
      id={field.id as string}
      files={input.value}
      allowedFormats={field.allowedFormats}
      maxFileSize={field.maxFileSize}
      maxFileCount={field.maxFileCount}
      disabled={field.disabled}
      setFiles={(files: IFileWrapper[]) => {
        input.onChange(files)
      }}
    />
  )
}

export const FileUploaderFormElement: FC<IProps> = ({ disabled, field }: IProps) => {
  const validator: (files: IFileWrapper[]) => 'tooLarge' | 'invalidFileType' | 'required' | undefined = useCallback(
    (files: IFileWrapper[]) => {
      if (field.required === true && files.length < 1) {
        return 'required'
      }

      const firstWithError: IFileWrapper | undefined = files.find((f: IFileWrapper) => !isNil(f.errorCode))
      if (!isNil(firstWithError)) {
        return firstWithError.errorCode
      }
    },
    [field.required]
  )

  return (
    <Field
      component={FileUploaderFormElementInternal}
      disabled={disabled}
      name={field.id}
      validate={validator}
      props={{ field }}
    />
  )
}
