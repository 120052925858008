import { ReactElement, useEffect } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { EditModeLabel } from '../../../../components/sideViewComponents'
import Container from '../../../../components/sideViewComponents/Container'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { IReferralPartner } from '../../interfaces/referralPartner'
import { loadReferralPartnerStart } from '../../store/actions/referralPartner'
import mapReferralPartner from '../../store/utils/mapReferralPartner'
import { ApproveForm } from './ApproveForm'
import { ApproveRejectForm } from './ApproveRejectForm'
import { TerminateForm } from './TerminateForm'
import { UpdatePartnerForm } from './UpdatePartnerForm'
import { isRejectEnabled } from './utils/generateApproveRejectFormCards'
import { isTerminateEnabled } from './utils/generateTerminateFormCards'

import styles from './partnerActivation.module.scss'

export const EditReferralPartnerForm = (): ReactElement | null => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')

  const dispatch: Dispatch = useDispatch()

  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )
  useEffect(() => {
    dispatch(loadReferralPartnerStart(referralPartner.id))
  }, [dispatch, referralPartner.id])

  const isSubmittingRejection: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.isRejectingReferralPartner
  )

  if (typeof referralPartner === 'undefined') {
    return null
  }
  const completeReferralPartner: IReferralPartner = mapReferralPartner(referralPartner)
  const terminateShouldAppear = isTerminateEnabled(completeReferralPartner, isSubmittingRejection)
  const rejectShouldAppear = isRejectEnabled(completeReferralPartner, isSubmittingRejection)

  return (
    <>
      <Container
        className={styles.title}
        title={t('referralPartner.edit.title')}
        icon="batch-actions"
        label={<EditModeLabel />}
      />
      <UpdatePartnerForm />
      {!terminateShouldAppear && !rejectShouldAppear && <ApproveForm />}
      {!terminateShouldAppear && rejectShouldAppear && <ApproveRejectForm />}
      {terminateShouldAppear && <TerminateForm />}
    </>
  )
}
