import { Middleware, AnyAction } from 'redux'

const ENABLE_LOGGING = false
const LOG_META = false

// eslint-disable-next-line @typescript-eslint/typedef
export const debugLoggerMiddleware: Middleware = (_) => (next) => (action: AnyAction) => {
  let shouldLog: boolean = process.env.NODE_ENV === 'development' && ENABLE_LOGGING
  if (shouldLog && !LOG_META) {
    shouldLog = !(action.type as string).startsWith('@')
  }
  if (shouldLog) {
    /* eslint-disable no-console */
    console.group('Action', action.type)
    console.log('Payload', action.payload)
    console.groupEnd()
  }
  next(action)
}
