import isNil from 'lodash/isNil'
import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ICardSection } from '../../../../../components/Cards'
import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig, CustomerPayload, PolicyObjectPayload } from '../../../../../components/Form/interfaces'
import { Container, EditModeLabel } from '../../../../../components/sideViewComponents'
import { IUiConfig } from '../../../api/productConfigApi'
import { forms, SIDE_VIEW_ANIMATION_DURATION } from '../../../constants'
import { ModeType, PolicySideViewType } from '../../../dictionaries'
import { IPolicy, IPolicyActions, IPolicyObject, IPolicyRisk } from '../../../interfaces'
import { generateReduxFormConfig } from '../../../utils'

import styles from './styles.module.scss'

interface IProps {
  cards: ICardSection[]
  policy: IPolicy
  actions: IPolicyActions
  config: IUiConfig
  isSubmitting: boolean
}

type Payload = Pick<IPolicy, 'values' | 'metadata'> & {
  customer: CustomerPayload
  objects: PolicyObjectPayload[]
  effectiveAt: string
  effectiveRequestedAt: string
  updateReason: string
  helpers: {
    applyChanges: boolean
  }
  device?: {
    id: string
    activationDate: string
  }
}

function getInitialPolicyHolderValues(policy: IPolicy): Payload {
  const result: Payload = {
    customer: {
      email: policy.customer.email,
      phone: policy.customer.phone,
      values: {
        addressCo: policy.customer.values.addressCo,
        addressPlz: policy.customer.values.addressPlz,
        salutation: policy.customer.values.salutation,
        addressCity: policy.customer.values.addressCity,
        addressStreet: policy.customer.values.addressStreet,
        addressHouseNumber: policy.customer.values.addressHouseNumber,
        dateOfBirth: policy.customer.values.dateOfBirth,
        companyName: policy.customer.values.companyName
      },
      lastName: policy.customer.lastName,
      firstName: policy.customer.firstName
    },
    values: policy.values,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    objects: policy.objects.map(({ coverageStartsAt, coverageEndsAt, risks, ...rest }: IPolicyObject) => {
      return {
        ...rest,
        risks: risks.map(({ name, values }: IPolicyRisk) => {
          return { name, values }
        })
      }
    }),
    metadata: policy.metadata,
    effectiveAt: '',
    effectiveRequestedAt: '',
    updateReason: '',
    helpers: {
      applyChanges: true
    }
  }

  if (!isNil(policy.device)) {
    result['device'] = policy.device
  }

  return result
}

const PolicyHolderDetailsForm: FC<IProps> = ({ cards, policy, actions, isSubmitting }: IProps) => {
  const [t]: UseTranslationResponse<'policy'> = useTranslation('policy')
  const dispatch = useDispatch()

  const config: IFormConfig = {
    form: forms.editPolicyHolderForm,
    url: `v1/policies/${policy.id}/update`,
    successMessage: t('policyHolderDetails.form.successMessage'),
    initialValues: getInitialPolicyHolderValues(policy),
    formSections: cards
  }

  const props: IFormProps = generateReduxFormConfig(config, dispatch, {
    formType: FormType.EDIT,
    isSubmitting,
    onClose: () => {
      actions.setModal(null)
      setTimeout(() => {
        actions.setModal({
          type: PolicySideViewType.PolicyHolderDetails,
          mode: ModeType.View
        })
      }, SIDE_VIEW_ANIMATION_DURATION)
    }
  })

  return (
    <>
      <Container
        className={styles.title}
        title={t('policyHolderDetails.form.title')}
        icon="pencil"
        label={<EditModeLabel />}
      />
      <Form {...props} />
    </>
  )
}

export default PolicyHolderDetailsForm
