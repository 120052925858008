export enum ClaimSubStatus {
  // For claim status Created
  NotYetInProgress = 'notYetInProgress',

  // For claim status InProgress
  InformationIsBeingChecked = 'informationIsBeingChecked',
  FurtherInformationOrDocumentsRequested = 'furtherInformationOrDocumentsRequested',
  ReviewingOfFurtherFindingsOrDocuments = 'reviewingOfFurtherFindingsOrDocuments',

  // For claim status Approved And Partially Approved
  WaitingForReceiptsOrInvoices = 'waitingForReceiptsOrInvoices',
  PaymentInformationRequested = 'paymentInformationRequested',
  PaymentPending = 'paymentPending',

  // For claim status Approved
  Paid = 'paid',

  // For claim status Partially Approved
  PartiallyPaid = 'partiallyPaid',

  // For claim status Closed
  ApprovedAndPaid = 'approvedAndPaid',
  PartiallyApprovedAndPaid = 'partiallyApprovedAndPaid',
  RejectedWithoutPayment = 'rejectedWithoutPayment',

  // For claim status Rejected And Closed
  WithoutPayment = 'withoutPayment'
}
