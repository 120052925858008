import { isAxiosError } from 'axios'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { IRequestPagination } from '@alteos/ui'

import { ToastType } from '../../../dictionaries'
import { IAction, IStore } from '../../../interfaces/store'
import { triggerErrorPopup } from '../../../store/utils/triggerErrorPopup'
import { actions as layoutActions, openSideView } from '../../Layout'
import { isClientError } from '../../Payments/store/sagas/upcoming'
import { invoicesApi } from '../api'
import { MODULE_NAME } from '../constants'
import {
  IInvoice,
  IInvoicePreviewResponse,
  IInvoiceStatusEnum,
  IInvoicesResponse,
  InvoiceTypeEnum
} from '../interfaces'
import {
  loadInvoicePreviewSuccess,
  loadInvoicesSuccess,
  openActionsList,
  saveErrorMessage,
  sendEmailSuccess
} from './actions'
import {
  LOAD_INVOICES_FAILURE,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICE_PREVIEW_FAILURE,
  LOAD_INVOICE_PREVIEW_REQUEST,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SET_INVOICE_SIDE_VIEW
} from './constants'

export function* loadInvoices({
  payload
}: IAction<IRequestPagination & { invoiceTypes?: InvoiceTypeEnum[] }>): Generator {
  try {
    const invoicesState = yield* select((state: IStore) => state[MODULE_NAME])

    const response: IInvoicesResponse = yield* call(
      invoicesApi.loadInvoices,
      invoicesState.pagination,
      payload.invoiceTypes
    )

    yield* put(loadInvoicesSuccess(response))
  } catch (error) {
    yield* put(saveErrorMessage('Failed to fetch invoices', LOAD_INVOICES_FAILURE, error))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

export function* loadInvoicePreview({ payload }: IAction<{ invoiceId: string }>): Generator {
  try {
    const response: IInvoicePreviewResponse = yield* call(invoicesApi.loadInvoicePreview, payload.invoiceId)

    yield* put(loadInvoicePreviewSuccess(response))
  } catch (error) {
    yield* put(
      saveErrorMessage(`Failed to fetch invoice preview: ${payload.invoiceId}`, LOAD_INVOICE_PREVIEW_FAILURE, error)
    )

    if (isAxiosError(error) && isClientError(error)) return
    else throw error
  }
}

export function* sendEmail({ payload }: IAction<{ invoiceId: string }>): Generator {
  try {
    const invoicesState = yield* select((state: IStore) => state[MODULE_NAME])
    const invoice = invoicesState.invoices.find((invoice) => invoice.id === payload.invoiceId)
    const withPayment = invoice && shouldSendWithPayment(invoice)

    yield* call(invoicesApi.sendEmail, payload.invoiceId, withPayment)

    yield* put(sendEmailSuccess())
    yield* put(
      layoutActions.displayToast({
        message: `The email for the invoice ${payload.invoiceId} is send to the recipients.`,
        type: ToastType.Success
      })
    )
    yield* put(openActionsList(null))
  } catch (error) {
    yield* put(
      saveErrorMessage(`Failed to send the email to the invoice: ${payload.invoiceId}`, SEND_EMAIL_FAILURE, error)
    )

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

function shouldSendWithPayment(invoice: IInvoice) {
  const isGroupedInvoice = invoice?.type === InvoiceTypeEnum.GroupedInvoice
  const hasSinglePaymentOrder = invoice?.paymentOrders?.length === 1
  const isInvoiceProcessing = invoice.metadata?.processingType === 'invoice'
  const paid = invoice.status === IInvoiceStatusEnum.Completed

  return !isGroupedInvoice && hasSinglePaymentOrder && isInvoiceProcessing && !paid
}

function* watchLoadInvoicesStart(): Generator {
  yield* takeLatest<any>(LOAD_INVOICES_REQUEST, loadInvoices)
}

function* watchLoadInvoicePreviewStart(): Generator {
  yield* takeLatest<any>(LOAD_INVOICE_PREVIEW_REQUEST, loadInvoicePreview)
}

function* watchOpenInvoiceSideView(): Generator {
  yield* takeLatest<any>(SET_INVOICE_SIDE_VIEW, openSideView)
}

function* watchSendEmailStart(): Generator {
  yield* takeLatest<any>(SEND_EMAIL_REQUEST, sendEmail)
}

export default (): Generator[] => [
  watchLoadInvoicesStart(),
  watchOpenInvoiceSideView(),
  watchLoadInvoicePreviewStart(),
  watchSendEmailStart()
]
