import { IRequestPagination } from '@alteos/ui'

import { IFilter } from '../../components/Filters/interfaces'

export const PAYMENTS_ROUTE = '/payments'
export const MODULE_NAME = 'payments'
export const DEFAULT_LIMIT = 10
export const DEFAULT_PAGINATION: IRequestPagination = {
  offset: 0,
  limit: DEFAULT_LIMIT
}

export const DEFAULT_FILTERS: IFilter[] = [
  {
    active: false,
    key: 'effectiveFrom',
    value: 'EffectiveFrom',
    componentType: 'datePickerRange',
    selectedFilter: '',
    withSearch: false,
    options: null,
    isCountable: true
  }
]

export enum forms {
  UpdatePaymentMethod = 'updatePaymentMethod'
}
