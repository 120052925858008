import i18next from 'i18next'
import isEqual from 'lodash/isEqual'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { SubmissionError } from 'redux-form'

import { InputType } from '@alteos/ui'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { IStore } from '../../../../../interfaces/store'
import { closeSideView } from '../../../../Layout/store/actionCreators'
import { forms, MODULE_NAME } from '../../../constants'
import { IPartnerPortalRole } from '../../../interfaces'
import { createPartnerPortalUser, getPartnerPortalRoles } from '../../../store/actions/partnerPortal'
import { setSideView } from '../../../store/actions/referralPartner'
import { FormContainer } from '../FormContainer'

import styles from '../partnerPortal.module.scss'

export default (): ReactElement => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')
  const [disabledButtonSave, setDisabledButtonSave] = useState(true)

  const dispatch: Dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPartnerPortalRoles())
  }, [dispatch])

  const roles: IPartnerPortalRole[] = useSelector((store: IStore) => store[MODULE_NAME].partnerPortalState.roles)
  const formData = useSelector((store: IStore) => store.form[forms.addPartnerPortalUser])

  const updatePartnerFormConfig: IFormConfig = {
    form: forms.addPartnerPortalUser,
    url: '',
    initialValues: {},
    successMessage: `${t('partnerPortal.successMessageCreate')}`,
    formSections: [
      {
        title: t('partnerPortal.createNewUser'),
        icon: 'details',
        fields: [
          {
            id: 'firstName',
            editType: InputType.string,
            required: true,
            maximum: 100,
            size: 'half',
            label: t('partnerPortal.firstName')
          },
          {
            id: 'lastName',
            editType: InputType.string,
            required: true,
            maximum: 100,
            size: 'half',
            label: t('partnerPortal.lastName')
          },
          {
            id: 'email',
            editType: InputType.email,
            maximum: 100,
            required: true,
            size: 'half',
            label: t('partnerPortal.email')
          },
          {
            id: 'roles',
            editType: InputType.select,
            options: roles.map((role) => ({
              key: role.id,
              value: role.name
            })),
            required: true,
            size: 'half',
            maximum: 100,
            label: t('partnerPortal.roles')
          },
          {
            id: 'partnerKey',
            editType: InputType.string,
            maximum: 100,
            size: 'half',
            label: t('partnerPortal.partnerKey')
          },
          {
            id: 'integrationPartnerId',
            editType: InputType.string,
            maximum: 100,
            size: 'half',
            label: t('partnerPortal.integrationPartnerId')
          },
          {
            editType: InputType.button,
            label: t('partnerPortal.cancelChanges'),
            dataTest: 'cancel-changes-SP-button',
            buttonType: 'reset',
            handleClick: () => {
              dispatch(setSideView(null))
              dispatch(closeSideView())
            },
            color: 'tertiary',
            noMinWidth: true,
            style: styles.formButton
          },
          {
            editType: InputType.button,
            label: t('partnerPortal.saveChanges'),
            dataTest: 'save-changes-SP-button',
            disabled: disabledButtonSave,
            noMinWidth: true
          }
        ]
      }
    ]
  }

  const handleDataChange = function (initValues: { [key: string]: string }, _: any, props: any) {
    const isObjectEqual = isEqual(initValues, props.initialValues)
    setDisabledButtonSave(isObjectEqual)
  }

  const triggerFieldRequiredError = (fieldName: string) => {
    throw new SubmissionError({
      [fieldName]: i18next.t('common:formValidation.required'),
      _error: 'User creation failed!'
    })
  }

  const handleOnSubmit = function () {
    if (Boolean(formData?.values?.partnerKey) && Boolean(formData?.values?.integrationPartnerId) === false) {
      triggerFieldRequiredError('integrationPartnerId')
    } else if (Boolean(formData?.values?.partnerKey) === false && Boolean(formData?.values?.integrationPartnerId)) {
      triggerFieldRequiredError('partnerKey')
    }
    setDisabledButtonSave(true)
    dispatch(createPartnerPortalUser(updatePartnerFormConfig))
  }

  const addUserFormProps: IFormProps = {
    ...updatePartnerFormConfig,
    hasFormButtons: true,
    formType: FormType.CREATE,
    dataTest: 'add-user-form',
    onClose: () => dispatch(setSideView(null)),
    onDataChange: handleDataChange,
    onSubmit: handleOnSubmit
  }

  return (
    <FormContainer>
      <Form {...addUserFormProps} />
    </FormContainer>
  )
}
