export const GET_POLICY_BY_ID_QUERY = `
  query getPolicyById($policyId: String!, $withClaims: Boolean! = true) {
    policyById(id: $policyId) {
      package {
        name
      }
      purchase {
        id
        description
        processingData
        accountingData
        collectionData {
            type
            paymentMethods
        }
        obligationData {
            stages {
              duration 
            }
        }
      }
      id
      values
      prettyId
      product {
        id
        name
        displayName
        version
        latestVersion
      }
      scheduledActionsCount {
        total
        scheduled
      }
      status
      cancellationReason
      withdrawalReason
      cancellationRequestedAt
      withdrawalRequestedAt
      issuedAt
      boundAt
      cancelledAt
      expiredAt
      withdrawnAt
      startsAt
      endsAt
      createdAt
      updatedAt
      customerId
      customer {
        type
        email
        firstName
        lastName
        phone
        values
      }
      account {
        type
        email
        firstName
        lastName
        values
        policies {
            id
            prettyId
            status
            product {
                displayName
            }
            pagination {
                totalCount
            }
        }
      }
      partner {
        id
        name
        extendendInformation {
            companyAddressCity
            companyAddressHouseNumber
            companyAddressPlz
            companyAddressStreet
            companyName  
        }
      }
      isTesting
      claims @include(if: $withClaims) {
        id
        prettyId
        status
        subStatus
        claimedAt
        values
        totalReserveAmount
      }
      objects {
        name
        risks {
            name
            premium
            deductiblePerContract
            deductiblePerYear
            deductiblePerIncident
            sumInsuredPerContract
            sumInsuredPerYear
            sumInsuredPerIncident
            values
        }
        coverageStartsAt
        coverageEndsAt
        values
      }
      metadata
      documents {
        claimId
        createdAt
        name
        path
        purpose
        url
        version
        previousDocuments {
            claimId
            createdAt
            name
            path
            purpose
            url
            version
        }
      }
      taxes {
        name
        amount
      }
      addons { 
        name
      }
      paymentData {
        taxRate
        premium
        taxes
        gross
        fullPremium
        fullTaxes
        fullGross
        annualPremium
        annualTax
        annualPremiumExclDiscounts
        annualTaxExclDiscounts
        premiumExclDiscounts
        taxesExclDiscounts
        grossExclDiscounts
        discountRate
        processingType
      }
      device {
        id
        activationDate
        partnerId
        activatedAt
      }
    }
  }
`
