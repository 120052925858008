import { FC } from 'react'

import { ITranslatable } from '@alteos/product-configuration'

import StatusItem from '../../../../components/Cards/Card/items/StatusItem'
import { claimSubStatusFromString } from '../../../../utils/stringToEnum'
import { useDynamicTranslation } from '../../../i18n/hooks'
import { ClaimSubStatusLabel } from './ClaimSubStatusLabel'

interface IProps {
  value: string | null
  label: ITranslatable | undefined
}

export const ClaimSubStatusItem: FC<IProps> = ({ value, label }: IProps) => {
  const claimStatus = claimSubStatusFromString(value)
  const translatedLabel: string = useDynamicTranslation(label)
  return (
    <StatusItem label={translatedLabel}>
      <ClaimSubStatusLabel subStatus={claimStatus} />
    </StatusItem>
  )
}
