import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { reset } from 'redux-form'
import { put, select, takeLatest, call, debounce } from 'typed-redux-saga'

import { Action } from '@alteos/dictionaries/dist/Action'
import { InputType, IFormValues } from '@alteos/ui'

import { IFileWrapper } from '../../../components/FileUploader/interfaces'
import { IFilter, IFilters } from '../../../components/Filters/interfaces'
import { IFormConfig } from '../../../components/Form/interfaces'
import { ToastType } from '../../../dictionaries'
import { IProduct, IPartner } from '../../../interfaces/common'
import { IAction, IStore } from '../../../interfaces/store'
import { isGraphQLOrNetworkError } from '../../../services/api/exceptions'
import { triggerErrorPopup } from '../../../store/utils/triggerErrorPopup'
import { localStorageService } from '../../../utils/localStorageService'
import { hasPermission } from '../../Auth/components/PermissionGuard/hasPermission'
import { Permissions } from '../../Auth/interfaces'
import { getUserPermissions } from '../../Auth/store/selectors'
import { openSideView, actions as layoutActions } from '../../Layout'
import { policiesApi } from '../api'
import {
  IPartnersResponse,
  IPoliciesResponse,
  IProductsResponse,
  IDatePickerValues,
  IFilterNormalizeParams
} from '../interfaces'
import { makePoliciesFiltersOptions } from '../utils'
import {
  terminatePoliciesFailure,
  terminatePoliciesSuccess,
  importPoliciesFailure,
  importPoliciesSuccess,
  saveErrorMessage,
  saveFiltersParams,
  savePolicies
} from './actionCreators'
import {
  TERMINATE_POLICIES_START,
  GET_FILTERS_PARAMS_REQUEST,
  GET_POLICIES_REQUEST,
  IMPORT_POLICIES_START,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  POLICIES_FILTER_PARAMS_LS_KEY,
  REMOVE_FILTER,
  RESET_FILTERS,
  SET_FILTER,
  ADD_FILTER
} from './constants'

function* loadPolicies(payload: any): Generator {
  let filter: IFilters = {}
  const activeFilters: IFilter[] = (yield* select((state: IStore) =>
    state.policies.filtersParams.filter((item: IFilter) => item.selectedFilter !== '')
  )) as IFilter[]

  if (activeFilters.length > 0) {
    activeFilters.map((activeFilter: IFilter) => {
      if (activeFilter.selectedFilter === '') {
        return null
      }
      if (activeFilter.componentType === InputType.datePickerRange) {
        const { fromDate, toDate }: IDatePickerValues = activeFilter.selectedFilter as IDatePickerValues

        const filterPayload: IDatePickerValues = { fromDate, toDate }

        filter = { ...filter, ...filterPayload }
      } else {
        const { key, selectedFilter }: IFilter = activeFilter
        const filterPayload: { [x: string]: string | IDatePickerValues } = { [key]: selectedFilter }

        filter = { ...filter, ...filterPayload }
      }
      return null
    })
  }

  let response: IPoliciesResponse | undefined

  try {
    response = yield* call(policiesApi.loadPolicies, payload, filter)
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch policies', error))
    if (isGraphQLOrNetworkError(error)) {
      yield* put(
        layoutActions.displayToast({
          message: i18next.t('policy:toast.failedToLoadPolicies'),
          type: ToastType.Error
        })
      )
      yield* put(savePolicies([]))
      return
    } else {
      throw error
    }
  }

  yield* put(savePolicies(response.policies))
}

function* loadFiltersParams(): Generator {
  const permissions: Permissions = (yield* select(getUserPermissions)) as Permissions
  let partnersResponse: AxiosResponse<IPartnersResponse> | null = null
  let productsResponse: AxiosResponse<IProductsResponse> | null = null

  const activeFilters: IFilter[] = (yield* select((state: IStore) =>
    state.policies.filtersParams.filter((item: IFilter) => item.selectedFilter !== '')
  )) as IFilter[]

  try {
    if (hasPermission(permissions, [Action.PartnerGet, Action.PartnerList])) {
      partnersResponse = yield* call(policiesApi.getPartnersList)
    }
    if (hasPermission(permissions, Action.ProductConfigurationList)) {
      productsResponse = yield* call(policiesApi.getProductsList)
    }
  } catch (error: any) {
    yield* put(saveErrorMessage('Failed to fetch filter params', error))
    throw error
  }

  const sortedPartners: IFilterNormalizeParams[] = (partnersResponse?.data.partners ?? [])
    .sort((partnerA: IPartner, partnerB: IPartner) => partnerA.name.localeCompare(partnerB.name))
    .map((partner: IPartner) => {
      return {
        id: partner.id,
        value: partner.name
      }
    })

  const sortedProducts: IFilterNormalizeParams[] = (productsResponse?.data.products ?? [])
    .sort((productA: IProduct, productB: IProduct) => productA.displayName.localeCompare(productB.displayName))
    .map((product: IProduct) => {
      return {
        id: product.id,
        value: product.displayName
      }
    })
  const filters = makePoliciesFiltersOptions(sortedProducts, sortedPartners, activeFilters)
  localStorageService.setItem(POLICIES_FILTER_PARAMS_LS_KEY, filters)
  yield* put(saveFiltersParams(filters))
}

function* persistFiltersToLocalStorage(): Generator {
  const filters = yield* select((state: IStore) => state.policies.filtersParams)
  localStorageService.setItem(POLICIES_FILTER_PARAMS_LS_KEY, filters)
}

function* importPolicies({ payload }: IAction<IFormConfig>): Generator {
  const { form: formName, successMessage }: IFormConfig = payload

  const formValues: IFormValues = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as IFormValues
  const { partnerId, policyFiles }: IFormValues = formValues

  if (Array.isArray(policyFiles)) {
    const data: FormData = new FormData()
    const { file }: IFileWrapper = policyFiles[0]
    data.append('files', file)

    try {
      yield* put(layoutActions.setSideViewIsProcessingData(true))
      yield* call(policiesApi.importPolicies, partnerId, data)
    } catch (error) {
      yield* put(importPoliciesFailure())
      yield* put(layoutActions.setSideViewIsProcessingData(false))
      yield* triggerErrorPopup(error)
      return
    }

    yield* put(importPoliciesSuccess())
    yield* put(reset(formName))
    yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
    yield* put(layoutActions.setSideViewIsProcessingData(false))
  }

  return
}

function* terminatePolicies({ payload }: IAction<IFormConfig>): Generator {
  const { form: formName, successMessage }: IFormConfig = payload

  const formValues: IFormValues = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as IFormValues

  const { policyFiles }: IFormValues = formValues

  if (Array.isArray(policyFiles)) {
    const data: FormData = new FormData()
    const { file }: IFileWrapper = policyFiles[0]
    data.append('files', file)

    try {
      yield* put(layoutActions.setSideViewIsProcessingData(true))
      yield* call(policiesApi.terminatePolicies, data)
    } catch (error) {
      yield* put(terminatePoliciesFailure())
      yield* put(layoutActions.setSideViewIsProcessingData(false))
      yield* triggerErrorPopup(error)
      return
    }

    yield* put(terminatePoliciesSuccess())
    yield* put(reset(formName))
    yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
    yield* put(layoutActions.setSideViewIsProcessingData(false))
  }
  return
}

function* watchApiLoadPoliciesStart(): Generator {
  yield* takeLatest<any>(GET_POLICIES_REQUEST, loadPolicies)
}

function* watchApiLoadFiltersParamsStart(): Generator {
  yield* takeLatest<any>(GET_FILTERS_PARAMS_REQUEST, loadFiltersParams)
}

function* watchImportPoliciesStart(): Generator {
  yield* takeLatest<any>(IMPORT_POLICIES_START, importPolicies)
}

function* watchTerminatePoliciesStart(): Generator {
  yield* takeLatest<any>(TERMINATE_POLICIES_START, terminatePolicies)
}

function* watchOpenModal(): Generator {
  yield* takeLatest<any>(SET_ACTIVE_SIDE_VIEW_TYPE, openSideView)
}

function* watchFilterChanges(): Generator {
  const SYNC_DEBOUNCE = 500
  yield* debounce(SYNC_DEBOUNCE, [ADD_FILTER, SET_FILTER, REMOVE_FILTER, RESET_FILTERS], persistFiltersToLocalStorage)
}

// 3. Root saga
export default (): Generator[] => [
  watchApiLoadPoliciesStart(),
  watchApiLoadFiltersParamsStart(),
  watchImportPoliciesStart(),
  watchTerminatePoliciesStart(),
  watchOpenModal(),
  watchFilterChanges()
]
