import { IFormConfig } from '../../../../components/Form/interfaces'
import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { IGroup, IPartnerResponse, IPartnerState } from '../../interfaces'
import { IUpdatePermissionResponse } from '../../interfaces/permissions'
import {
  LOAD_GROUPS_FAILURE,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS
} from '../constants'

export function loadGroupsStart(payload: string): IAction<string> {
  return {
    type: LOAD_GROUPS_REQUEST,
    payload
  }
}
export function loadGroupsSuccess(payload: IPartnerResponse): IAction<IPartnerResponse> {
  return {
    type: LOAD_GROUPS_SUCCESS,
    payload
  }
}

export function loadGroupsFailure(): IBasicAction {
  return {
    type: LOAD_GROUPS_FAILURE
  }
}

export function updateGroupStart(
  group: IGroup,
  formConfig: IFormConfig,
  partner: IPartnerState
): IAction<IFormConfig & IGroup & IPartnerState> {
  return {
    type: UPDATE_GROUP_REQUEST,
    payload: { ...formConfig, ...group, ...partner }
  }
}

export function updateGroupSuccess(payload: IUpdatePermissionResponse): IAction<any> {
  return {
    type: UPDATE_GROUP_SUCCESS,
    payload
  }
}

export function updateGroupFailure(): IBasicAction {
  return {
    type: UPDATE_GROUP_FAILURE
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: LOAD_GROUPS_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}
