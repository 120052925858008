import { IFormProps } from '../../../components/Form/Form'
import { IFormConfig, IFormSuccessConfig } from '../../../components/Form/interfaces'
import { ISaveErrorMessagePayload } from '../../../interfaces/action'
import { IAction, IBasicAction } from '../../../interfaces/store'
import {
  GET_POLICY_AND_CONFIG_FAILURE,
  GET_POLICY_AND_CONFIG_REQUEST,
  GET_POLICY_AND_CONFIG_SUCCESS,
  SET_PURCHASE,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SUBMIT_FORM_START,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
  UPDATE_IBAN_START,
  UPDATE_IBAN_SUCCESS,
  UPDATE_IBAN_ERROR,
  RESET_STATE,
  SET_POLICY,
  CREATE_NOTE_START,
  CREATE_NOTE_STOP,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERROR,
  UPDATE_NOTE_START,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERROR,
  EDIT_NOTE_START,
  EDIT_NOTE_FINISH,
  LOAD_NOTES_START,
  LOAD_NOTES_SUCCESS,
  LOAD_NOTES_ERROR,
  SET_EDITING_NOTE_ID,
  LOAD_CLAIM_DETAILS_START,
  LOAD_CLAIM_DETAILS_SUCCESS,
  LOAD_CLAIM_DETAILS_FAILURE,
  GET_TERMINATION_RULES_START,
  GET_TERMINATION_RULES_SUCCESS,
  GET_TERMINATION_RULES_FAILURE,
  EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START,
  EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START,
  EXECUTE_TERMINATION_RULE_SUCCESS,
  EXECUTE_TERMINATION_RULE_FAILURE,
  GET_REFUND_AMOUNT_START,
  GET_REFUND_AMOUNT_FAILURE,
  GET_REFUND_AMOUNT_SUCCESS,
  EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_FAILURE,
  EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_FAILURE,
  SET_MANDATE
} from '../constants'
import {
  ICreateNoteActionPayload,
  INote,
  IPolicy,
  IClaim,
  IPolicyAndConfig,
  IUpdateNoteActionPayload,
  ModalPayload,
  ILoadClaimActionPayload,
  IPurchase,
  IGetTerminationRulesResponsePayload,
  IExecuteTerminationRuleResponsePayload,
  IGetRefundAmountResponsePayload,
  IMandate
} from '../interfaces'

export function getPolicyAndConfig(payload: string): IAction<string> {
  return {
    type: GET_POLICY_AND_CONFIG_REQUEST,
    payload
  }
}

export function setPolicy(payload: IPolicy): IAction<IPolicy> {
  return {
    type: SET_POLICY,
    payload
  }
}

export function savePolicyAndConfig(payload: IPolicyAndConfig): IAction<IPolicyAndConfig> {
  return {
    type: GET_POLICY_AND_CONFIG_SUCCESS,
    payload
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError']
): IAction<ISaveErrorMessagePayload> {
  return {
    type: GET_POLICY_AND_CONFIG_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function setModal(payload: ModalPayload): IAction<ModalPayload> {
  return {
    type: SET_ACTIVE_SIDE_VIEW_TYPE,
    payload
  }
}

export function submitFormStart(payload: IFormProps): IAction<IFormProps> {
  return {
    type: SUBMIT_FORM_START,
    payload
  }
}

export function submitFormSuccess(config: IFormSuccessConfig): IAction<IFormSuccessConfig> {
  return {
    type: SUBMIT_FORM_SUCCESS,
    payload: config
  }
}

export function submitFormFailure(): IBasicAction {
  return {
    type: SUBMIT_FORM_ERROR
  }
}

export function updateIbanStart(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: UPDATE_IBAN_START,
    payload
  }
}

export function updateIbanSuccess(config: IFormSuccessConfig): IAction<IFormSuccessConfig> {
  return {
    type: UPDATE_IBAN_SUCCESS,
    payload: config
  }
}

export function updateIbanFailure(): IBasicAction {
  return {
    type: UPDATE_IBAN_ERROR
  }
}

export function createNoteStart(text: string, isStandaloneCreate: boolean): IAction<ICreateNoteActionPayload> {
  return {
    type: CREATE_NOTE_START,
    payload: {
      text,
      isStandaloneCreate
    }
  }
}

export function createNoteStop(): IBasicAction {
  return {
    type: CREATE_NOTE_STOP
  }
}

export function createNoteSuccess(): IBasicAction {
  return {
    type: CREATE_NOTE_SUCCESS
  }
}

export function createNoteFailure(): IBasicAction {
  return {
    type: CREATE_NOTE_ERROR
  }
}

export function updateNoteStart(noteId: string, text: string): IAction<IUpdateNoteActionPayload> {
  return {
    type: UPDATE_NOTE_START,
    payload: {
      noteId,
      text
    }
  }
}

export function updateNoteSuccess(): IBasicAction {
  return {
    type: UPDATE_NOTE_SUCCESS
  }
}

export function updateNoteFailure(): IBasicAction {
  return {
    type: UPDATE_NOTE_ERROR
  }
}

export function setEditingNoteId(payload: string): IAction<string> {
  return {
    type: SET_EDITING_NOTE_ID,
    payload
  }
}

export function editNoteStart(payload: string): IAction<string> {
  return {
    type: EDIT_NOTE_START,
    payload
  }
}

export function editNoteFinish(): IBasicAction {
  return {
    type: EDIT_NOTE_FINISH
  }
}

export function loadNotesStart(payload: number): IAction<number> {
  return {
    type: LOAD_NOTES_START,
    payload
  }
}

export function loadNotesSuccess(notes: INote[]): IAction<INote[]> {
  return {
    type: LOAD_NOTES_SUCCESS,
    payload: notes
  }
}

export function loadNotesFailure(): IBasicAction {
  return {
    type: LOAD_NOTES_ERROR
  }
}

export function loadClaimDetails(payload: ILoadClaimActionPayload): IAction<ILoadClaimActionPayload> {
  return {
    type: LOAD_CLAIM_DETAILS_START,
    payload
  }
}

export function getTerminationRules(): IBasicAction {
  return {
    type: GET_TERMINATION_RULES_START
  }
}

export function getTerminationRulesSuccess(
  rules: IGetTerminationRulesResponsePayload
): IAction<IGetTerminationRulesResponsePayload> {
  return {
    type: GET_TERMINATION_RULES_SUCCESS,
    payload: rules
  }
}

export function getTerminationRulesFailure(): IBasicAction {
  return {
    type: GET_TERMINATION_RULES_FAILURE
  }
}

export function executeTerminationRuleWithoutExtraInputFields(): IBasicAction {
  return {
    type: EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START
  }
}

export function executeTerminationRuleWithoutExtraInputFieldsFailure(): IBasicAction {
  return {
    type: EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_FAILURE
  }
}

export function executeTerminationRuleWithExtraInputFields(): IBasicAction {
  return {
    type: EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START
  }
}

export function executeTerminationRuleWithExtraInputFieldsFailure(): IBasicAction {
  return {
    type: EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_FAILURE
  }
}

export function executeTerminationRuleSuccess(
  executedRule: IExecuteTerminationRuleResponsePayload
): IAction<IExecuteTerminationRuleResponsePayload> {
  return {
    type: EXECUTE_TERMINATION_RULE_SUCCESS,
    payload: executedRule
  }
}

export function executeTerminationRuleFailure(): IBasicAction {
  return {
    type: EXECUTE_TERMINATION_RULE_FAILURE
  }
}

export function getRefundAmount(): IBasicAction {
  return { type: GET_REFUND_AMOUNT_START }
}

export function getRefundAmountSuccess(
  payload: IGetRefundAmountResponsePayload
): IAction<IGetRefundAmountResponsePayload> {
  return { type: GET_REFUND_AMOUNT_SUCCESS, payload }
}

export function getRefundAmountFailure(): IBasicAction {
  return { type: GET_REFUND_AMOUNT_FAILURE }
}
export function setFocusedClaim(payload: IClaim | null): IAction<IClaim | null> {
  return {
    type: LOAD_CLAIM_DETAILS_SUCCESS,
    payload
  }
}

export function loadClaimDetailsFailure(): IBasicAction {
  return {
    type: LOAD_CLAIM_DETAILS_FAILURE
  }
}

export function setPurchase(payload: IPurchase | null): IAction<IPurchase | null> {
  return {
    type: SET_PURCHASE,
    payload
  }
}

export function setMandate(payload: IMandate | null): IAction<IMandate | null> {
  return {
    type: SET_MANDATE,
    payload
  }
}
export function resetState(): IBasicAction {
  return {
    type: RESET_STATE
  }
}

export default {
  getPolicyAndConfig,
  savePolicyAndConfig,
  setModal,
  submitFormStart,
  submitFormSuccess,
  submitFormFailure,
  updateIbanStart,
  updateIbanSuccess,
  updateIbanFailure,
  createNoteStart,
  createNoteStop,
  updateNoteStart,
  setEditingNoteId,
  editNoteStart,
  editNoteFinish,
  loadNotesStart,
  resetState,
  loadClaimDetails,
  setFocusedClaim,
  loadClaimDetailsFailure,
  setPurchase,
  getTerminationRules,
  getRefundAmount,
  executeTerminationRuleWithoutExtraInputFields,
  executeTerminationRuleWithExtraInputFields
}
