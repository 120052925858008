import { Reducer, combineReducers, CombinedState } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

import { authReducer as auth } from '../modules/Auth'
import { i18nReducer as i18n } from '../modules/i18n/reducer'
import { reducer as invoices } from '../modules/Invoices'
import { reducer as layout } from '../modules/Layout'
import { modalReducer as modal } from '../modules/Modal/store/modalReducer'
import { reducer as partner } from '../modules/Partner'
import { reducer as partnersManagement } from '../modules/PartnersManagement'
import { reducer as payments } from '../modules/Payments'
import { reducer as policies } from '../modules/Policies'
import { reducer as policy } from '../modules/Policy'
import { IRootState } from './interfaces'

const rootReducer: Reducer<CombinedState<IRootState>> = combineReducers({
  auth,
  layout,
  policies,
  policy,
  partnersManagement,
  partner,
  i18n,
  modal,
  payments,
  invoices,
  form: reduxFormReducer
})

export default rootReducer
