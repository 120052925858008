import IBAN from 'iban'
import isNil from 'lodash/isNil'
import { FC, useEffect, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { Button, MaskedInput, Text, MaskType } from '@alteos/ui'

import { WarningIcon } from '../../../../../components/Icons/icons'
import { Modal } from '../../../../../components/Modal/Modal'
import { closeModal } from '../../../../Modal/store/actionCreators'
import { ModalId } from '../../../../Modal/store/interfaces'
import { IEndorsementModalResult } from '../../../interfaces'
import { setPurchase } from '../../../store/actionCreators'
import { getMandate, getModalId, getPurchaseData } from '../../../store/selectors'
import { mapPaymentData } from '../../../utils/mapPaymentData'

interface IProps {
  modalId: ModalId
}

export const SepaEndorsementModal: FC<IProps> = ({ modalId }: IProps) => {
  const [tp]: UseTranslationResponse<'policy'> = useTranslation('policy')
  const [tc]: UseTranslationResponse<'common'> = useTranslation('common')
  const dispatch: Dispatch<any> = useDispatch()
  const currentModalId: ModalId | null = useSelector(getModalId)
  const currentPurchaseData = useSelector(getPurchaseData)
  const mandate = useSelector(getMandate)
  const isOpen: boolean = currentModalId === modalId
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [iban, setIban] = useState('')
  // TODO: use shared validtion methods
  const isValid = firstName.length > 2 && lastName.length > 2 && IBAN.isValid(iban)

  useEffect(() => {
    const paymentData = mapPaymentData(mandate, currentPurchaseData?.processingData)

    const { firstName, lastName, iban } = paymentData
    if (isNil(firstName) || isNil(lastName) || isNil(iban)) {
      return
    }

    setFirstName(firstName)
    setLastName(lastName)
    setIban(iban)
  }, [mandate, currentPurchaseData?.processingData])

  return (
    <Modal.Root variant="warning" isOpen={isOpen} modalId={modalId}>
      <Modal.Header icon={<WarningIcon />} />
      <Modal.Content>
        <p>{tp('endorsement.sepaModalWarning')}</p>
        <Text
          name="firstName"
          label={tp('endorsement.firstName')}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Text
          name="lastName"
          label={tp('endorsement.lastName')}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <MaskedInput
          name="iban"
          label="IBAN:"
          mask={MaskType.iban}
          value={iban}
          placeholder="XX 00 0000 0000 0000 0000 00"
          onChange={(e) => setIban(e.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          small={true}
          noMinWidth={true}
          color="tertiary"
          dataTest="modal-endorse-cancel"
          handleClick={() => {
            const result: IEndorsementModalResult = {
              paymentDetailsUpdate: null
            }
            dispatch(closeModal(result))
            dispatch(setPurchase(null))
          }}
        >
          {tc('phrases.cancel')}
        </Button>
        <Button
          small={true}
          noMinWidth={true}
          color="primary"
          dataTest="modal-endorse-confirm"
          handleClick={() => {
            const result: IEndorsementModalResult = {
              paymentDetailsUpdate: {
                payment: {
                  firstName,
                  lastName,
                  iban,
                  processingType: 'sepa',
                  type: 'oneTime'
                }
              }
            }
            dispatch(closeModal(result))
            dispatch(setPurchase(null))
          }}
          disabled={!isValid}
        >
          {tc('phrases.confirm')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  )
}
