import { IDisputedPaymentsState } from './disputedPayment'
import { IUpcomingPaymentsState } from './upcomingPayments'

export enum PaymentTab {
  Upcoming = 'upcoming',
  Disputed = 'disputed'
}

export interface IPaymentTabs {
  activeTab: PaymentTab
}
export interface IPaymentsState {
  tabs: IPaymentTabs
  upcomingPayments: IUpcomingPaymentsState
  disputedPayments: IDisputedPaymentsState
}
