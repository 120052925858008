import { isNil } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Label from '../../../../components/Label'
import { LabelType } from '../../../../components/Label/dictionaries'
import { ClaimSubStatus } from '../../../../dictionaries/ClaimSubStatus'
import generateClaimSubStatusLabelType from '../../SideViewContent/ClaimDetails/utils/generateClaimSubStatusLabelType'

interface IProps {
  subStatus: ClaimSubStatus | null
}

export const ClaimSubStatusLabel: FC<IProps> = ({ subStatus }) => {
  const { t } = useTranslation('policy')
  const formattedValue: string = !isNil(subStatus) ? t(`claims.claimSubStatus.${subStatus}`) : '—'
  const mode = !isNil(subStatus) ? 'light' : 'normal'
  const labelType: LabelType = generateClaimSubStatusLabelType(subStatus)
  return <Label type={labelType} content={formattedValue} dataTest="claim-sub-status" mode={mode} />
}
