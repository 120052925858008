import isNil from 'lodash/isNil'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate, Icon, ITableDataPagination, Table, useTableStaticDataPagination } from '@alteos/ui'

import { Container } from '../../../../../components/sideViewComponents'
import { IDocumentTableRow, IPolicyDocument } from '../../../interfaces'
import sortByCreatedAt from '../../../utils/sortByCreatedAt'
import { generateDocumentDetailsTable } from '../utils/generateDocumentDetailsTable'

import styles from '../documentDetails.module.scss'

interface IProps {
  document: IPolicyDocument
}

const PreviousVersionsTable: FC<IProps> = ({ document }: IProps): ReactElement | null => {
  const { t } = useTranslation('policy')

  const tableRows: IDocumentTableRow[] | undefined = isNil(document?.previousDocuments)
    ? []
    : document?.previousDocuments.sort(sortByCreatedAt).map((item: IPolicyDocument) => ({
        createdAt: formatDate(item.createdAt),
        version: item.version,
        category: t(`document.categories.${document?.purpose}`),
        mediaType: item.name.split('.').pop(),
        action: (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <Icon name="download" />
          </a>
        )
      }))

  // NOTE: must be called prior to document null check due to React Hook rules
  const dataPagination: ITableDataPagination = useTableStaticDataPagination({
    data: tableRows ?? [],
    displayPagination: false
  })

  const { previousDocuments }: IPolicyDocument = document
  const tableColumns = generateDocumentDetailsTable(t)

  return (
    <>
      {previousDocuments !== null && (
        <Container className={styles.container} subtitle={t('documentDetails.allFileVersions')}>
          <Table
            columns={tableColumns}
            dataTest="document-details"
            dataPagination={dataPagination}
            emptyTableTitle={t('common:titles.emptyTable')}
          />
        </Container>
      )}
    </>
  )
}

export default PreviousVersionsTable
