import { sortBy } from 'lodash'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { Button, ButtonColor, ITableColumn, ITableDataPagination, Table, ToastType } from '@alteos/ui'

import { ExpandableCard } from '../../../../../components/Cards'
import { IStore } from '../../../../../interfaces/store'
import { displayToast } from '../../../../Layout/store/actionCreators'
import { partnerPortalApi } from '../../../api/partnerPortalApi'
import { MODULE_NAME } from '../../../constants'
import { IPartnerPortalPartnerUser, IReferralPartner } from '../../../interfaces'
import { getPartnerPortalUsersRequest } from '../../../store/actions/partnerPortal'

export default (): ReactElement => {
  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )
  const users: IPartnerPortalPartnerUser[] | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].partnerPortalState.partnerUsers[referralPartner.id]
  )
  const [tp]: UseTranslationResponse<'partner'> = useTranslation('partner')
  const [tc]: UseTranslationResponse<'common'> = useTranslation('common')
  const dispatch: Dispatch = useDispatch()
  const [processingUser, setProcessingUser] = useState<IPartnerPortalPartnerUser | null>(null)

  useEffect(() => {
    dispatch(getPartnerPortalUsersRequest(referralPartner!.id))
  }, [dispatch, referralPartner])

  const userAction = useCallback(
    (user: IPartnerPortalPartnerUser) => async () => {
      setProcessingUser(user)
      let actionName = 'block'

      if (user.blocked) {
        actionName = 'unblock'
      }
      try {
        // @ts-ignore
        await partnerPortalApi[`${actionName}User`](user.user_id)

        setTimeout(async () => {
          dispatch(
            displayToast({
              type: ToastType.Success,
              message: tp(`partnerPortal.${actionName}UserSuccess`)
            })
          )
          dispatch(getPartnerPortalUsersRequest(referralPartner!.id))
          setProcessingUser(null)
          // eslint-disable-next-line no-magic-numbers
        }, 1000)
      } catch (e) {
        dispatch(
          displayToast({
            type: ToastType.Error,
            message: tp(`partnerPortal.${actionName}UserFailed`)
          })
        )
        setProcessingUser(null)
      }
    },
    [dispatch, referralPartner, tp]
  )

  const getButtonData = (user: IPartnerPortalPartnerUser) => {
    const buttonData = {
      disabled: processingUser !== null,
      name: tp('partnerPortal.block'),
      color: 'primary'
    }
    if (user.blocked) {
      buttonData.name = tp('partnerPortal.unblock')
      buttonData.color = 'outline'
    }
    return buttonData
  }

  const tableColumns: ITableColumn[] = [
    {
      id: 'name',
      Header: tp('partnerPortal.name'),
      accessor: ({ given_name, family_name }: IPartnerPortalPartnerUser) => `${given_name} ${family_name}`
    },
    {
      id: 'email',
      Header: tp('partnerPortal.email'),
      accessor: 'email'
    },
    {
      id: 'email_verified',
      Header: tp('partnerPortal.isEmailVerified'),
      accessor: ({ email_verified }: IPartnerPortalPartnerUser) =>
        email_verified ? tc('phrases.yes') : tc('phrases.no')
    },
    {
      id: 'status',
      Header: tp('partnerPortal.blockUnblock'),
      accessor: (user: IPartnerPortalPartnerUser) => {
        const buttonData = getButtonData(user)
        return (
          <Button
            disabled={buttonData.disabled}
            dataTest="userAction"
            color={buttonData.color as ButtonColor}
            small
            noMinWidth
            handleClick={userAction(user)}
          >
            {buttonData.name}
          </Button>
        )
      }
    }
  ]

  return (
    <ExpandableCard
      title={tp('partnerPortal.userList')}
      isInitiallyExpanded
      expandedContent={
        <Table
          columns={tableColumns}
          dataTest="partner-portal-table"
          disableSortBy
          dataPagination={
            {
              data: sortBy(users, 'name'),
              displayPagination: false
            } as ITableDataPagination
          }
          emptyTableTitle={tc('titles.emptyTable')}
        />
      }
      icon={'table'}
      isFullWidth
    />
  )
}
