import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { IAction } from '../../../../../interfaces/store'
import { forms } from '../../../constants'
import ReferralPartnerStatus from '../../../dictionaries/ReferralPartnerStatus'
import { approveReferralPartnerStart, rejectReferralPartnerStart } from '../../../store/actions/referralPartner'

import styles from '../partnerActivation.module.scss'

export const isRejectEnabled = (source: Record<string, any>, isProcessing: boolean): boolean =>
  [ReferralPartnerStatus.New].includes(source.status) && !isProcessing

const generateApproveRejectFormCards = (
  t: TFunction,
  source: Record<string, any>,
  isProcessing: boolean,
  onSubmit: (arg: IAction<IFormConfig>) => void
): IFormConfig => {
  const isActivateButtonEnabled: boolean =
    [ReferralPartnerStatus.New, ReferralPartnerStatus.Rejected, ReferralPartnerStatus.Terminated].includes(
      source.status
    ) && !isProcessing
  const isRejectButtonEnabled: boolean = isRejectEnabled(source, isProcessing)

  const formConfig: IFormConfig = {
    form: forms.approveRejectReferralPartner,
    url: '',
    initialValues: { ...source, notes: '' },
    successMessage: `${t('referralPartner.edit.successMessageApprove')}`,
    formSections: [
      {
        title: t('referralPartner.edit.statusTitle'),
        icon: 'upload',
        fields: [
          {
            id: 'notes',
            editType: InputType.text,
            label: t('referralPartner.edit.notes'),
            placeholder: t('referralPartner.edit.notesPlaceholder'),
            maximum: 500,
            disabled: !isActivateButtonEnabled
          },
          {
            editType: InputType.button,
            label: t('referralPartner.edit.reject'),
            disabled: !isRejectButtonEnabled,
            dataTest: 'reject-changes-SP-button',
            color: 'tertiary',
            noMinWidth: true,
            style: styles.formButton,
            handleClick: () =>
              onSubmit(
                rejectReferralPartnerStart({
                  ...formConfig,
                  successMessage: `${t('referralPartner.edit.successMessageReject')}`
                })
              )
          },
          {
            editType: InputType.button,
            label: t('referralPartner.edit.activate'),
            disabled: !isActivateButtonEnabled,
            dataTest: 'activate-changes-SP-button',
            noMinWidth: true,
            buttonType: 'submit',
            handleClick: () => onSubmit(approveReferralPartnerStart(formConfig))
          }
        ]
      }
    ]
  }

  return formConfig
}

export default generateApproveRejectFormCards
