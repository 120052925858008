import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { InputType } from '@alteos/ui'

import { FieldValue } from '../components/Cards'
import { IDependentFieldOption } from '../components/Form/interfaces'
import { IFieldConfig, ISelectOption } from '../interfaces/common'
import { isFormField, isSelectFormFieldConfig } from '../interfaces/guards'
import { formatTimePeriodPrice } from './formatTimePeriodPrice'
import { ILocalFormatFactory } from './interfaces'

const mapContent: (
  field: IFieldConfig,
  formatFactory: ILocalFormatFactory,
  source: Record<string, any>
) => FieldValue = (field: IFieldConfig, formatFactory: ILocalFormatFactory, source: Record<string, any>) => {
  let result: FieldValue = get(source, field.id ?? '', field.value)

  if (isSelectFormFieldConfig(field) && !isNil(field.parentField) && !isNil(field.dependentOptions)) {
    const parentFieldValue: string | undefined = get(source, field.parentField)

    const parentFieldValues: ISelectOption[] | undefined = field.dependentOptions.find(
      (option: IDependentFieldOption) => option.key === parentFieldValue
    )?.values

    return parentFieldValues?.find((value: ISelectOption) => value.key === result)?.value
  }

  if (isFormField(field) && field.editType === InputType.select && field.options.length > 0) {
    return field.options.find((option: ISelectOption) => option.key === result)?.value ?? '&#8212;'
  }

  if (typeof field.formatFunction !== 'undefined') {
    try {
      if (typeof formatFactory[field.formatFunction as keyof ILocalFormatFactory] === 'function') {
        const resultString = result?.toString() ?? ''
        if (field.formatFunction === 'formatTimePeriodPrice') {
          result = formatTimePeriodPrice(resultString, source.purchase?.obligationData?.stages[0].duration)
        } else {
          result = formatFactory[field.formatFunction](resultString)
        }
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  if (isFormField(field) && field.editType === 'checkboxValue') {
    // TODO: move to translations
    if (result === true) {
      result = 'Ja'
    } else {
      result = 'Nein'
    }
  }

  if (typeof result === 'number') {
    result = result.toString()
  }

  return isNil(result) || result === '' ? '&#8212;' : result
}

export default mapContent
