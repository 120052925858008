import cx from 'classnames'
import isNil from 'lodash/isNil'
import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import { formatBytesHumanReadable } from '../../utils/formatBytesHumanReadable'
import { FileUploaderCloseIcon } from './icons/FileUploaderCloseIcon'
import { IFileWrapper } from './interfaces'

import styles from './style.module.scss'

interface IProps {
  file: IFileWrapper
  disabled: boolean
  dataTest: string
  onRemoveFileRequest(file: IFileWrapper): void
}

export const FileUploaderItem: FC<IProps> = ({ file, onRemoveFileRequest, disabled = false, dataTest }: IProps) => {
  const [t]: UseTranslationResponse<'common'> = useTranslation('common')
  const hasError = !isNil(file.errorCode)

  return (
    <div
      className={cx(styles.fileUploaderFileItem, {
        [styles.fileUploaderFileItemWithError]: hasError
      })}
      data-test={dataTest}
    >
      <div className={styles.fileUploaderFileItemLeft}>
        <div className={styles.fileUploaderFileItemName}>{file.file.name}</div>
        <div className={styles.fileUploaderFileItemSize}>{formatBytesHumanReadable(file.file.size)}</div>
        {hasError && (
          <div className={styles.fileUploaderFileError} data-test="file-uploader-error">
            {t(`fileUploader.error.${file.errorCode}`)}
          </div>
        )}
      </div>
      <button
        type="button"
        className={cx(styles.fileUploaderFileItemRight, { [styles.active]: !disabled })}
        data-test="file-uploader-remove-file"
        onClick={() => {
          if (!disabled) {
            onRemoveFileRequest(file)
          }
        }}
      >
        <FileUploaderCloseIcon />
      </button>
    </div>
  )
}
