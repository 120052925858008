import { InputType } from '@alteos/ui'

import FieldType from '../dictionaries/FieldType'
import {
  IAlertFormFieldConfig,
  IButtonFormFieldConfig,
  IDateFormFieldConfig,
  IDividerViewFieldConfig,
  IElementViewFieldConfig,
  IFieldConfig,
  IFileFormFieldConfig,
  IFormFieldConfig,
  IMaskedFormFieldConfig,
  ISelectFormFieldConfig,
  IStringFormFieldConfig,
  ITextFormFieldConfig,
  ITitleViewFieldConfig,
  IViewFieldConfig
} from './common'

export function isViewField(field: IFieldConfig): field is IViewFieldConfig {
  return Object.prototype.hasOwnProperty.call(field, 'type')
}

export function isFormField(field: IFieldConfig): field is IFormFieldConfig {
  return Object.prototype.hasOwnProperty.call(field, 'editType')
}

// VIEW FIELDS : start
export function isDividerViewFieldConfig(field: IFieldConfig): field is IDividerViewFieldConfig {
  return (field as any).type === FieldType.Divider || (field as any).editType === InputType.divider
}

export function isTitleViewFieldConfig(field: IFieldConfig): field is ITitleViewFieldConfig {
  return (field as any).type === FieldType.Title
}

export function isElementViewFieldConfig(field: IFieldConfig): field is IElementViewFieldConfig {
  return (field as any).type === FieldType.Element
}

export function isTextViewFieldConfig(field: IFieldConfig): field is IElementViewFieldConfig {
  return (
    (field as any).type === FieldType.Text ||
    (typeof (field as any).type === 'undefined' && typeof (field as any).editType === 'undefined')
  )
}

// VIEW FIELDS : end

// FORM FIELDS : start

export function isMaskedFormFieldConfig(field: IFieldConfig): field is IMaskedFormFieldConfig {
  return (field as any).editType === InputType.masked
}

export function isAlertFormFieldConfig(field: IFieldConfig): field is IAlertFormFieldConfig {
  return (field as any).editType === InputType.alert
}

export function isFileFormFieldConfig(field: IFieldConfig): field is IFileFormFieldConfig {
  return (field as any).editType === InputType.file
}

export function isButtonFormFieldConfig(field: IFieldConfig): field is IButtonFormFieldConfig {
  return (field as any).editType === InputType.button
}

export function isSelectFormFieldConfig(field: IFieldConfig): field is ISelectFormFieldConfig {
  return (field as any).editType === InputType.select
}

export function isDateFormFieldConfig(field: IFieldConfig): field is IDateFormFieldConfig {
  return (field as any).editType === InputType.date
}

export function isStringFormFieldConfig(field: IFieldConfig): field is IStringFormFieldConfig {
  return (field as any).editType === InputType.string
}

export function isTextFormFieldConfig(field: IFieldConfig): field is ITextFormFieldConfig {
  return (field as any).editType === InputType.text
}

// FORM FIELDS : end
