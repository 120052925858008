import isNil from 'lodash/isNil'

import { IRequestPagination } from '@alteos/ui'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { IPurchase } from '../../Policy/interfaces'
import {
  IFilterPayload,
  IGetPaymentLinkStatusesResponsePayload,
  IUpcomingPayments,
  IUpcomingPaymentsApi,
  IUpdatePaymentMethodRequestPayload
} from '../interfaces'

class UpcomingPaymentsApi implements IUpcomingPaymentsApi {
  constructor(private api: Api) {}

  loadUpcomingPayments = async (
    { limit, offset }: IRequestPagination,
    filters?: IFilterPayload,
    search?: string
  ): Promise<IUpcomingPayments[]> => {
    let upcomingPaymentsUrl = 'v1/purchases/upcoming-policy-payments'
    const params: URLSearchParams = new URLSearchParams([
      ['offset', offset.toString()],
      ['limit', limit.toString()]
    ])

    if (search) params.set('search', search)

    if (!isNil(filters)) {
      params.set('startAt', filters.startDate.toString())
      params.set('endAt', filters?.endDate.toString())
    }

    const response = await this.api.get(upcomingPaymentsUrl, params)
    return response.data
  }

  changePaymentMethod = async (payload: IUpdatePaymentMethodRequestPayload): Promise<IPurchase> => {
    const URL = 'v1/purchases/payment-method'

    const response = await this.api.patch(URL, { ...payload })
    return response.data
  }

  createPaymentLink = async (paymentScheduleId: string): Promise<void> => {
    const URL = 'v1/payments/payment-link'

    await this.api.post(URL, { paymentScheduleId })
  }

  createBulkPaymentLinks = async (paymentScheduleIds: string[]): Promise<void> => {
    const URL = 'v1/payments/payment-links'

    await this.api.post(URL, { paymentScheduleIds })
  }

  getPaymentLinks = async (paymentScheduleIds: string[]): Promise<IGetPaymentLinkStatusesResponsePayload> => {
    const URL = 'v1/payments/payment-link-statuses'

    const response = await this.api.post(URL, { paymentScheduleIds })
    return response.data
  }
}

export const upcomingPaymentsApi: IUpcomingPaymentsApi = new UpcomingPaymentsApi(defaultApiInstance)
