import { EffectCallback, useLayoutEffect, useRef } from 'react'

export function useOnMountEffect(effectCb: EffectCallback): void {
  const isCalledOnce = useRef(false)

  useLayoutEffect(() => {
    if (!isCalledOnce.current) {
      isCalledOnce.current = true
      return effectCb()
    }
  }, [effectCb])
}
