import { FC, ReactElement, useEffect } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ExpandableCard } from '../../../../components/Cards'
import { Loader } from '../../../../components/Loader'
import { Container } from '../../../../components/sideViewComponents'
import { IRootState } from '../../../../store/interfaces'
import { requireNotNull } from '../../../../utils'
import { ConfirmationModal } from '../../../Modal/Modals/ConfirmationModal'
import { MODULE_NAME } from '../../constants'
import { loadClaimDetails } from '../../store/actionCreators'
import {
  EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID,
  PAYOUT_WITHOUT_STATUS_CHANGE_CONFIRMATION_MODAL_ID
} from '../ClaimDetails/EditClaimForm/consts'
import { CONFIRM_PAYOUT_CLOSING_CLAIM_MODAL_ID } from './constants'
import ClaimObligationForm from './Forms/ClaimObligationForm'
import ClaimReserveForm from './Forms/ClaimReserveForm'
import ClaimUpdateStatusForm from './Forms/ClaimUpdateStatusForm'
import TransactionsHistoryTable from './TransactionsHistoryTable'

import styles from './styles.module.scss'

interface IProps {}

const ClaimTransactionsForm: FC<IProps> = (): ReactElement | null => {
  const { t }: UseTranslationResponse<'policy'> = useTranslation(['policy'])
  const closeClaimWarningModalContent = t('editClaimForm.closeClaimWarningModalContent')
  const modalParams = useSelector((state: IRootState) => state[MODULE_NAME].modalParams)
  const claim = useSelector((state: IRootState) => state[MODULE_NAME].focusedClaim)
  const claimId = requireNotNull(modalParams?.id, 'modalParams?.id')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadClaimDetails({ id: claimId, syncClaimsDocuments: false }))
  }, [dispatch, claimId])

  if (claim === null) {
    return <Loader name="transactions-form" />
  }

  const claimTitle = `${t('editClaimForm.headerCard.title')}: ${claim.prettyId}`

  return (
    <>
      <Container className={styles.title} title={claimTitle} icon="card" />
      <ClaimReserveForm claim={claim} />
      <ClaimObligationForm claim={claim} />
      <ClaimUpdateStatusForm claim={claim} />
      <ConfirmationModal modalId={CONFIRM_PAYOUT_CLOSING_CLAIM_MODAL_ID}>
        <p>{t('editClaimForm.closeClaimWarningModalContent')}</p>
      </ConfirmationModal>
      <ConfirmationModal modalId={EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID}>
        <p>{closeClaimWarningModalContent}</p>
      </ConfirmationModal>
      <ConfirmationModal modalId={PAYOUT_WITHOUT_STATUS_CHANGE_CONFIRMATION_MODAL_ID}>
        <p>{t('policyClaimsTransactionsForm.obligationForm.statusReminderText')}</p>
      </ConfirmationModal>
      <ExpandableCard
        className={styles.card}
        title={t('policyClaimsTransactionsForm.transactionsHistory.title')}
        expandedContent={<TransactionsHistoryTable claim={claim} t={t} />}
      />
    </>
  )
}

export default ClaimTransactionsForm
