/* eslint max-params: 0 */
import { isAfter, isBefore, endOfDay, startOfDay } from 'date-fns'
import i18next from 'i18next'
import { isValid as isValidIban } from 'iban'
import isNil from 'lodash/isNil'

import { CommonRegExp, InputType, IFormValues, isNilOrEmptyString } from '@alteos/ui'

import { IFieldConfig, IFormFieldConfig } from '../../../interfaces/common'
import { Validator } from '../interfaces'

export const required: Validator = (
  value: number | string | boolean | undefined | null,
  _allValues: IFormValues
): undefined | string => {
  const errorMessage: string = i18next.t('common:formValidation.required')

  if (typeof value === 'string' && value.trim() === '') {
    return errorMessage
  }
  if (typeof value === 'undefined' || value === null) {
    return errorMessage
  }
}

export const email: Validator = (value: string | undefined = ''): undefined | string => {
  if (isNil(value) || value === '') {
    return
  }

  const regex: RegExp = CommonRegExp.email

  if (!regex.test(value.toLowerCase())) {
    return i18next.t('common:formValidation.invalidEmail')
  }
  return
}

export const string: Validator = (value: string | undefined = ''): undefined | string => {
  if (isNil(value) || value === '') {
    return
  }

  const regex = /(^\s+)/

  if (regex.test(value)) {
    return i18next.t('common:formValidation.invalidString')
  }
  return
}

export const minimum: Validator = (
  value: string | number | undefined,
  _allValues: IFormValues,
  props: Record<string, any>,
  fieldId: string
) => {
  if (isNil(value) || value === '') {
    return
  }
  const selectedField: IFormFieldConfig = props.allFields.find((field: IFieldConfig) => field.id === fieldId)

  if (isNil(selectedField?.minimum) || selectedField.minimum === '') {
    return
  }

  if (selectedField.editType === InputType.number) {
    const numberValue = Number(value)

    if (!isNaN(numberValue) && numberValue < selectedField.minimum) {
      return i18next.t('common:formValidation.tooSmallNumber')
    }
    return
  }

  if (selectedField.editType === InputType.date) {
    const dateTime = new Date(value).getTime()
    const minimumDate = startOfDay(new Date(selectedField.minimum.toString()))

    if (!isNaN(dateTime) && isBefore(dateTime, minimumDate)) {
      return i18next.t('common:formValidation.minimumDateViolated')
    }
    return
  }

  const valueLength: number = value.toString().length

  if (valueLength < selectedField.minimum) {
    return i18next.t('common:formValidation.tooShort')
  }
}

export const maximum: Validator = (
  value: string | number | undefined,
  _allValues: IFormValues,
  props: Record<string, any>,
  fieldId: string
) => {
  if (isNil(value) || value === '') {
    return
  }
  const selectedField: IFormFieldConfig = props.allFields.find((field: IFieldConfig) => field.id === fieldId)

  if (isNil(selectedField?.maximum) || selectedField.maximum === '') {
    return
  }

  if (selectedField.editType === InputType.number) {
    const numberValue = Number(value)

    if (!isNaN(numberValue) && numberValue > selectedField.maximum) {
      return i18next.t('common:formValidation.tooBigNumber')
    }
    return
  }

  if (selectedField.editType === InputType.date) {
    const dateTime = new Date(value).getTime()
    const maximumDate = endOfDay(new Date(selectedField.maximum.toString()))

    if (!isNaN(dateTime) && isAfter(dateTime, maximumDate)) {
      return i18next.t('common:formValidation.maximumDateViolated')
    }
    return
  }

  const valueLength: number = value.toString().length

  if (valueLength > selectedField.maximum) {
    return i18next.t('common:formValidation.tooLong')
  }
}

export const regex: Validator = (
  value: string | undefined,
  _allValues: IFormValues,
  props: Record<string, any>,
  fieldId: string
): undefined | string => {
  const selectedField: IFormFieldConfig = props.allFields.find((field: IFieldConfig) => field.id === fieldId)

  if (isNil(value) || typeof selectedField.regex === 'undefined' || value === '') {
    return
  }

  if (typeof selectedField.regex === 'string' && Object.keys(CommonRegExp).includes(selectedField.regex)) {
    selectedField.regex = CommonRegExp[selectedField.regex]
  }

  const re = new RegExp(selectedField.regex)

  if (!re.test(value)) {
    return i18next.t('common:formValidation.invalidFormat')
  }
  return
}

// NOTE: backwards compatibility: to be removed after dave-4765 is in prod
export const phone: Validator = (value: string | undefined | null): undefined | string => {
  if (isNil(value) || value === '') {
    return
  }
  if (!CommonRegExp.phone.test(value)) {
    return i18next.t('common:formValidation.invalidPhone')
  }
  return
}

export const number: Validator = (value: string | number | undefined): undefined | string => {
  if (typeof value === 'number') {
    if (isNaN(value)) {
      return i18next.t('common:formValidation.invalidNumbers')
    }
    return
  } else if (typeof value === 'string') {
    const numberValue = Number(value)
    if (isNaN(numberValue)) {
      return i18next.t('common:formValidation.invalidNumbers')
    }
    return
  } else {
    return i18next.t('common:formValidation.invalidNumbers')
  }
}

export const iban: Validator = (value: string = ''): string | undefined => {
  const trimmedIban: string = value.replace(' ', '')
  if (isNilOrEmptyString(trimmedIban) || isValidIban(trimmedIban)) {
    return
  }
  return i18next.t('common:formValidation.invalidIban')
}
