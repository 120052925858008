import cx from 'classnames'
import { FC, KeyboardEvent, ReactElement, useCallback, useEffect, useState } from 'react'

import { ITranslatable } from '@alteos/product-configuration/dist/translation/ITranslatable'
import { Icon } from '@alteos/ui'

import { IUseState } from '../../../interfaces/common'
import { useDynamicTranslation } from '../../../modules/i18n/hooks'

import styles from './expandableCard.module.scss'

const HIDE_CONTENT_DELAY = 500

interface IProps {
  title?: ITranslatable
  icon?: string
  expandedContent: ReactElement
  isExpandable?: boolean
  isInitiallyExpanded?: boolean
  isStatic?: boolean
  disabled?: boolean
  withLargeHeading?: boolean
  className?: string
  isFullWidth?: boolean
  handleClick?: () => void
}

const ExpandableCard: FC<IProps> = ({
  title: propsTitle = '',
  icon = 'details',
  expandedContent,
  isExpandable = true,
  isInitiallyExpanded = true,
  disabled = false,
  isStatic = false,
  withLargeHeading = false,
  className = '',
  isFullWidth = false,
  handleClick
}: IProps) => {
  const title: string = useDynamicTranslation(propsTitle)
  const [isExpanded, setIsExpanded]: IUseState<boolean> = useState<boolean>(isInitiallyExpanded)
  const [isContentVisible, setIsContentVisiable]: IUseState<boolean> = useState<boolean>(isInitiallyExpanded)
  const iconType: string = isExpanded ? 'arrow-up' : 'arrow-down'

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsContentVisiable(isExpanded)
      },
      isExpanded ? 0 : HIDE_CONTENT_DELAY
    )
    return () => {
      clearTimeout(timer)
    }
  }, [isExpanded])

  const onClick: () => void = useCallback(() => {
    setIsExpanded((expanded: boolean) => !expanded)
  }, [])

  const onKeyPress: ({ charCode }: KeyboardEvent<HTMLDivElement>) => void = ({
    charCode
  }: KeyboardEvent<HTMLDivElement>) => {
    const spaceCode = 13
    const enterCode = 32
    if (charCode === spaceCode || charCode === enterCode) {
      if (isExpandable) {
        onClick()
      }
      if (typeof handleClick !== 'undefined') {
        handleClick()
      }
    }
  }

  const isInteractive = !isStatic && isExpandable && !disabled

  return (
    <div className={cx(styles.container, className, { [styles.isDisabled]: disabled })}>
      {title !== '' ? (
        <>
          <div
            className={cx(styles.heading, {
              [styles.isClickable]: isInteractive
            })}
            role="button"
            data-test={isInteractive ? `expand-button-${title}` : `${title}`}
            onClick={() => {
              if (isInteractive) {
                return onClick()
              }
              return
            }}
            onKeyPress={(event: KeyboardEvent<HTMLDivElement>) => {
              if (isInteractive) {
                return onKeyPress(event)
              }
              return
            }}
            tabIndex={isInteractive ? 0 : -1}
          >
            <div className={styles.titleContainer}>
              <div className={styles.iconContainer}>
                <Icon name={icon} className={styles.icon} />
              </div>
              {withLargeHeading ? (
                <h1 data-test={`expand-card-title-${title}`}>{title}</h1>
              ) : (
                <h2 data-test={`expand-card-title-${title}`}>{title}</h2>
              )}
            </div>
            {isStatic || !isExpandable || disabled ? null : (
              <span className={styles.buttonContainer}>
                <Icon name={isExpandable ? iconType : 'cross-big'} />
              </span>
            )}
          </div>
          <div
            data-test={`expandable-content-${title}`}
            className={cx(styles.expandedContent, {
              [styles.isCollapsed]: !isExpanded || disabled
            })}
          >
            {isContentVisible ? expandedContent : null}
          </div>
        </>
      ) : (
        <div
          data-test="expandable-content-without-title"
          className={cx(styles.expandedContent, styles.withoutTitle, { [styles.fullWidth]: isFullWidth })}
        >
          {expandedContent}
        </div>
      )}
    </div>
  )
}

export default ExpandableCard
