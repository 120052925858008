import cx from 'classnames'
import { FC, ChangeEvent, KeyboardEvent, useRef, useState, MutableRefObject } from 'react'

import { Button, Icon } from '@alteos/ui'

import { IUseState } from '../../interfaces/common'
import isValueWrong from './utils/isValueWrong'

import styles from './searchTags.module.scss'

interface IProps {
  maxTagsAmount?: number
  placeholder?: string
  buttonLabel?: string
  appliedTags: string[]
  isProcessing: boolean
  dataTest: string
  defaultValue: string
  errors: { length: string; characters: string }
  validationRegExp?: RegExp
  handleReset: () => void
  handleTagsChange: (value: string) => void
  handleTagDelete: (value: string) => void
}

const MAX_SYMBOLS_AMOUNT = 120
const isValueTooLong = (value: string): boolean => value.length > MAX_SYMBOLS_AMOUNT

const SearchTags: FC<IProps> = ({
  placeholder,
  buttonLabel,
  appliedTags,
  isProcessing,
  maxTagsAmount = 1,
  dataTest,
  handleTagsChange,
  handleTagDelete,
  handleReset,
  defaultValue = '',
  errors,
  validationRegExp
}: IProps) => {
  const [focused, setFocused]: IUseState<boolean> = useState<boolean>(false)
  const [value, setValue]: IUseState<string> = useState(defaultValue)
  const [error, setError]: IUseState<string> = useState('')
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  function handleFocus() {
    setFocused(true)
  }

  function handleBlur() {
    setFocused(false)
  }

  function checkValue(valueToCheck: string): void {
    if (isValueTooLong(valueToCheck)) {
      setError(errors.length)
      return
    }
    if (isValueWrong(valueToCheck, validationRegExp)) {
      setError(errors.characters)
      return
    }
    setError('')
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value: newValue }: HTMLInputElement = event.currentTarget

    checkValue(newValue)
    setValue(newValue)
  }

  function handleApplyClick() {
    const trimmedValue: string = value.trim()

    if (trimmedValue !== '') {
      handleTagsChange(trimmedValue)

      if (inputRef.current !== null) {
        inputRef.current.blur()
      }
    }
  }

  function handleKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && !isProcessing && error === '') {
      handleApplyClick()
    }
  }

  function deleteTag(tag: string) {
    handleTagDelete(tag)
  }

  function resetValues() {
    setFocused(false)
    setValue('')
    setError('')
    handleReset()
  }

  const containerCl: string = cx(styles.container, { [styles.focused]: focused })
  const wrapperCl: string = cx(styles.wrapper, { [styles.focused]: focused })

  const tags: string[] = maxTagsAmount > 1 ? appliedTags : []

  return (
    <div className={containerCl}>
      <div className={wrapperCl}>
        {appliedTags.length > 0 ? (
          <button
            className={cx(styles.resetButton, styles.icon)}
            type="button"
            data-test={`${dataTest}-reset`}
            onClick={resetValues}
          >
            <Icon name="cross-filled-small" tint={{ color: 'neutral', shade: '76' }} />
          </button>
        ) : (
          <div className={styles.icon}>
            <Icon name="search-small" size="24px" />
          </div>
        )}

        {tags.map((tag: string) => (
          <button
            key={tag}
            type="button"
            className={styles.tagButton}
            data-test={`${dataTest}-remove-tag-${tag}`}
            onClick={() => deleteTag(tag)}
          >
            {tag}
            <Icon name="cross-small" tint={{ color: 'neutral', shade: '76' }} size="24px" />
          </button>
        ))}

        <input
          className={styles.input}
          value={value}
          data-test={`${dataTest}-input`}
          placeholder={placeholder}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />

        {value.trim() !== '' && (
          <Button
            icon="enter"
            handleClick={handleApplyClick}
            dataTest={`${dataTest}-apply-button`}
            color="outline"
            small
            noMinWidth
            disabled={isProcessing || error !== ''}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
      {error !== '' && (
        <div className={styles.searchError} data-test="search-error-message">
          {error}
        </div>
      )}
    </div>
  )
}

export default SearchTags
