import cx from 'classnames'
import { Location } from 'history'
import { FC, ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Bubble, Icon } from '@alteos/ui'

import styles from './navLink.module.scss'

interface IProps {
  url: string
  icon: string
  label: string
}

const NavLink: FC<IProps> = ({ icon, url, label }: IProps): ReactElement => {
  const location: Location = useLocation()

  const cl: string = cx(styles.container, {
    [styles.active]: location.pathname.includes(url)
  })
  return (
    <Link to={url} className={cl} data-test={`nav-link-${url}`} title={label}>
      <Icon name={icon} />
      <Bubble className="bubble_navlink" dataTest="bubble-navlink">
        {label}
      </Bubble>
    </Link>
  )
}

export default NavLink
