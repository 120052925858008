import { AxiosResponse } from 'axios'
import isNil from 'lodash/isNil'
import { call, put, select, take } from 'typed-redux-saga'

import { Action } from '@alteos/dictionaries/dist/Action'

import { ICardSection } from '../../../../components/Cards'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { ClaimStatus } from '../../../../dictionaries'
import ToastType from '../../../../dictionaries/ToastType'
import { IFieldConfig } from '../../../../interfaces/common'
import { IAction, IStore } from '../../../../interfaces/store'
import api from '../../../../services/api/api'
import { getRouter } from '../../../../store/utils/getRouter'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { getUserPermissions, hasPermission, Permissions } from '../../../Auth'
import { actions as layoutActions } from '../../../Layout'
import { waitForConfirmModalClose } from '../../../Modal/Modals/ConfirmationModal/sagas'
import { showModal } from '../../../Modal/store/actionCreators'
import { POLICY_NOT_FOUND_ROUTE } from '../../../Static'
import { claimApi, policyApi } from '../../api'
import { forms, LOAD_CLAIM_DETAILS_FAILURE, LOAD_CLAIM_DETAILS_SUCCESS } from '../../constants'
import {
  IClaim,
  IClaimResponse,
  ILoadClaimActionPayload,
  IMappedPolicyResponsePayload,
  IPolicy
} from '../../interfaces'
import {
  EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID,
  EDIT_CLAIM_WITH_STATUS_CHANGE_CONFIRMATION_MODAL_ID,
  PAYOUT_WITHOUT_STATUS_CHANGE_CONFIRMATION_MODAL_ID
} from '../../SideViewContent/ClaimDetails/EditClaimForm/consts'
import { CONFIRM_PAYOUT_CLOSING_CLAIM_MODAL_ID } from '../../SideViewContent/ClaimTransactionsForm/constants'
import { getPayloadFromPlainObject } from '../../utils'
import {
  loadClaimDetailsFailure,
  saveErrorMessage,
  setFocusedClaim,
  setModal,
  setPolicy,
  submitFormFailure,
  loadClaimDetails as loadClaimDetailsAction,
  submitFormSuccess
} from '../actionCreators'

interface ICreateClaimResponse {
  id: string
}

interface ICreateClaimTransactionResponse {
  id: string
}

interface IEditClaimResponse {
  id: string
}

function* getClaim(claimId: string): Generator {
  let claimResponse: IClaimResponse | undefined
  try {
    claimResponse = yield* call(claimApi.getClaim, claimId)
  } catch (error: any) {
    yield* put(saveErrorMessage('Failed to get claim', error))
    throw error
  }

  if (typeof claimResponse === 'undefined') {
    throw new Error('claimResponse is undefined at getClaim')
  }

  const claim: IClaim = claimResponse.claimById

  yield* put(setFocusedClaim(claim))
}

function* getPolicy(): Generator {
  const router = yield* getRouter()
  const permissions: Permissions = (yield* select(getUserPermissions)) as unknown as Permissions
  const withClaims: boolean = hasPermission(permissions, [Action.ClaimGet, Action.ClaimList])
  const policyId: string = (yield* select((state: IStore) => state.policy.policy?.id)) as string

  let policyResponse: IMappedPolicyResponsePayload | undefined
  try {
    policyResponse = yield* call(policyApi.getPolicy, policyId, { withClaims })
  } catch (error) {
    yield* put(saveErrorMessage('Failed to get policy by Id', error))
    throw error
  }

  const policy: IPolicy | null = policyResponse.policyById

  if (isNil(policy)) {
    yield* put(saveErrorMessage('Policy not found', null))
    yield* call(router.navigate, POLICY_NOT_FOUND_ROUTE)
    return
  }

  yield* put(setPolicy(policy))
}

export function* loadClaimDetails({ payload }: IAction<ILoadClaimActionPayload>): Generator {
  const { id, syncClaimsDocuments }: ILoadClaimActionPayload = payload
  if (syncClaimsDocuments) {
    yield* syncClaimDocuments(id)
  }
  yield* getClaim(id)
}

export function* syncClaimDocuments(id: string): Generator {
  try {
    yield* call(claimApi.syncClaimDocuments, id)
  } catch (error) {
    yield* put(loadClaimDetailsFailure())
    return
  }
}

export function* submitClaimCreateForm(payload: IFormConfig): Generator {
  const { form: formName, formSections, initialValues, successMessage, url }: IFormConfig = payload
  const formValues: Record<string, unknown> = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as Record<string, unknown>

  const allFields: IFieldConfig[] = formSections.reduce(
    (result: IFieldConfig[], form: ICardSection) => [...result, ...form.fields],
    []
  )
  const data: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)
  // TODO: for now, we don't need to create CRM ticket for claims on Dave
  data.createCrmTicket = false

  const note: string | undefined = (formValues as { helpers?: Record<string, any> }).helpers?.note
  if (typeof note !== 'undefined' && note.trim() !== '') {
    data.note = note
  }

  let response: AxiosResponse<ICreateClaimResponse> | undefined

  try {
    response = yield* call(api.post, url, data)
  } catch (error) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }

  if (typeof response === 'undefined') {
    yield* put(submitFormFailure())
    // TODO: probably not needed
    throw new Error('undefined response at submitClaimCreateForm')
  }

  yield getPolicy()

  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))

  yield* put(setModal(null))
  yield* put(layoutActions.closeSideView())

  yield* put(submitFormSuccess({ formName, withReset: true }))
}

export function* submitClaimTransactionCreateForm(payload: IFormConfig): Generator {
  const { form: formName, formSections, initialValues, successMessage, url }: IFormConfig = payload
  const formValues: Record<string, unknown> = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as Record<string, unknown>

  const allFields: IFieldConfig[] = formSections.reduce(
    (result: IFieldConfig[], form: ICardSection) => [...result, ...form.fields],
    []
  )
  const data: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)

  let response: AxiosResponse<ICreateClaimTransactionResponse> | undefined

  if (data['isClosingClaim'] === true) {
    yield* put(showModal(CONFIRM_PAYOUT_CLOSING_CLAIM_MODAL_ID))
    const confirmed: boolean = yield* waitForConfirmModalClose()
    if (!confirmed) {
      yield* put(submitFormSuccess({ formName, withReset: false }))
      return
    }
  }

  if (formName === forms.createTransactionObligationForm && formValues['isClosingClaim'] !== true) {
    yield* put(showModal(PAYOUT_WITHOUT_STATUS_CHANGE_CONFIRMATION_MODAL_ID))
    const confirmed: boolean = yield* waitForConfirmModalClose()
    if (!confirmed) {
      yield* put(submitFormSuccess({ formName, withReset: false }))
      return
    }
  }

  try {
    response = yield* call(api.post, url, data)
  } catch (error) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }

  if (typeof response === 'undefined') {
    yield* put(submitFormFailure())
    yield triggerErrorPopup('Unexpected error during claim transaction creation')
    return
  }

  const claimId: string = (yield* select((state: IStore) => state.policy.focusedClaim?.id)) as string
  yield getClaim(claimId)
  yield getPolicy()
  yield* put(submitFormSuccess({ formName, withReset: true }))
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
}

export function* submitClaimEditForm(payload: IFormConfig): Generator {
  const { formSections, initialValues, successMessage, form: formName, url }: IFormConfig = payload
  const formValues: Record<string, unknown> = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as Record<string, unknown>
  const claimId: string = (yield* select((state: IStore) => state.policy.focusedClaim?.id)) as string

  const allFields: IFieldConfig[] = formSections.reduce(
    (result: IFieldConfig[], form: ICardSection) => [...result, ...form.fields],
    []
  )
  const data: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)

  const note: string | undefined = (formValues as { helpers?: Record<string, any> }).helpers?.note
  if (typeof note !== 'undefined' && note.trim() !== '') {
    data.note = note
  }

  let response: AxiosResponse<IEditClaimResponse> | undefined

  if (formValues['status'] === ClaimStatus.Closed) {
    yield* put(showModal(EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID))
    const confirmed: boolean = yield* waitForConfirmModalClose()
    if (!confirmed) {
      yield* put(submitFormSuccess({ formName, withReset: false }))
      return
    }
  } else {
    yield* put(showModal(EDIT_CLAIM_WITH_STATUS_CHANGE_CONFIRMATION_MODAL_ID))
    const confirmed: boolean = yield* waitForConfirmModalClose()
    if (!confirmed) {
      yield* put(submitFormSuccess({ formName, withReset: false }))
      return
    }
  }

  try {
    response = yield* call(api.post, url, data)
  } catch (error) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }

  if (typeof response === 'undefined') {
    yield* put(submitFormFailure())
    throw new Error('undefined response at submitClaimEditForm')
  }

  yield* getPolicy()
  yield* put(loadClaimDetailsAction({ id: claimId, syncClaimsDocuments: true }))
  yield* take([LOAD_CLAIM_DETAILS_SUCCESS, LOAD_CLAIM_DETAILS_FAILURE])

  yield* put(submitFormSuccess({ formName, withReset: true }))
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
}

export function* submitEditClaimStatusForm(payload: IFormConfig): Generator {
  const { formSections, initialValues, successMessage, form: formName, url }: IFormConfig = payload
  const formValues: Record<string, unknown> = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as Record<string, unknown>
  const claim = yield* select((state: IStore) => state.policy.focusedClaim)

  const allFields: IFieldConfig[] = formSections.reduce(
    (result: IFieldConfig[], form: ICardSection) => [...result, ...form.fields],
    []
  )
  const data: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)

  const note: string | undefined = (formValues as { helpers?: Record<string, any> }).helpers?.note
  if (typeof note !== 'undefined' && note.trim() !== '') {
    data.note = note
  }

  let response: AxiosResponse<IEditClaimResponse> | undefined

  if (data['status'] === ClaimStatus.Closed) {
    yield* put(showModal(EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID))
    const confirmed: boolean = yield* waitForConfirmModalClose()
    if (!confirmed) {
      yield* put(submitFormSuccess({ formName, withReset: false }))
      return
    }
  }

  try {
    response = yield* call(api.post, url, data)
  } catch (error) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }

  if (typeof response === 'undefined') {
    yield* put(submitFormFailure())
    throw new Error('undefined response at submitEditClaimStatusForm')
  }

  yield* getPolicy()
  yield* put(loadClaimDetailsAction({ id: claim!.id, syncClaimsDocuments: true }))
  yield* take([LOAD_CLAIM_DETAILS_SUCCESS, LOAD_CLAIM_DETAILS_FAILURE])

  yield* put(submitFormSuccess({ formName, withReset: true }))
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
}
