import { ReactElement } from 'react'

import { ICardSection } from '../../../components/Cards'
import { PolicyStatus, PolicyUpdateReason } from '../../../dictionaries'
import CollectionScheduleType from '../../../dictionaries/CollectionScheduleType'
import ProcessingType from '../../../dictionaries/ProcessingType'
import { ISelectOption, IValues } from '../../../interfaces/common'
import { IUiConfig } from '../api/productConfigApi/productConfigDto'
import { PolicySideViewType, ModeType, PolicyTab } from '../dictionaries'
import { IClaim, ISideViewParams, INote } from '../interfaces'
import policyActions from '../store/actionCreators'

export interface IAddon {
  name: string
}

export interface IGeneratePolicyHolderDetailsCards {
  headerCard: ICardSection
  editHeaderCard: ICardSection
  cards: ICardSection[]
}

export interface IIconFactory {
  [key: string]: ReactElement
}

export interface IDocumentTableRow {
  createdAt: string
  version: number
  category?: string
  mediaType?: string
  action: ReactElement
}
export interface IPolicyDocument {
  claimId: string | null
  createdAt: string
  name: string
  path: string
  previousDocuments: IPolicyDocument[] | null
  purpose: string
  url: string
  version: number
}

export interface IPolicyRisk {
  name: string
  values: { [key: string]: string | number | boolean }
}

export interface IPolicyObject {
  name: string
  risks: IPolicyRisk[]
  values: { [key: string]: string | number | boolean }
  coverageStartsAt: string
  coverageEndsAt: string
}

export interface ICustomer {
  type: string
  email: string
  phone: string | null
  values: {
    addressPlz: string
    addressCity: string
    addressStreet: string
    addressHouseNumber: string
    addressCo?: string
    salutation?: string
    dateOfBirth?: string
    zendeskID?: string
    companyName?: string
  }
  lastName: string
  firstName: string
}

export interface IPaymentData {
  taxRate: number
  premium: number
  taxes: number
  gross: number
  fullPremium: number
  fullTaxes: number
  fullGross: number
  annualPremium: number
  annualTax: number
  annualPremiumExclDiscounts: number
  annualTaxExclDiscounts: number
  premiumExclDiscounts: number
  taxesExclDiscounts: number
  grossExclDiscounts: number
  discountRate: number
}

export interface IPurchaseAmount {
  premium: number
  tax: number
  gross: number
}

export interface ITransaction {
  id: string
  status: string
  description: string
  createdAt: string
  dueAt: string | null
  periodStartsAt: string | null
  periodEndsAt: string | null
  type: string
  premium: number
  tax: number
  gross: number
  fee: number
}

export interface IPurchase {
  id: string
  status: string
  description: string
  createdAt: string
  updatedAt: string
  collectionData: {
    paymentMethods: string[]
    type: CollectionScheduleType
  }
  processingData: IProcessingData
  accountingData: {
    amount: IPurchaseAmount
  }
  obligationData: {
    stages: { duration: string }[]
  }
}

export interface IProcessingData {
  iban: string
  type: ProcessingType
  consentedAt: string
  firstName: string
  lastName: string
  email: string
  sendSuccessNotification: boolean
  amount: IPurchaseAmount
}

export interface IPolicyResponse {
  id: string
  prettyId: string
  productId: string
  productVersion: string
  partnerId: string
  customerId: string
  status: PolicyStatus
  cancellationReason: string | null
  withdrawalReason: string | null
  accountingScheduleType: string
  paymentScheduleType: string
  premium: number
  tax: number
  gross: number
  account: {
    values: any | undefined
    policies: IPolicyResponse[]
  }
  addons: IAddon[] | null
  customer: ICustomer
  metadata: Record<string, unknown>
  createdAt: string
  createdBy: string
  updatedAt: string
  issuedAt: string | null
  startsAt: string
  endsAt: string | null
  cancelledAt: string | null
  expiredAt: string | null
  withdrawnAt: string | null
  cancellationRequestedAt: string | null
  withdrawalRequestedAt: string | null
  boundAt: string | null
  isTesting: boolean
  hasClaims: boolean
  product: {
    id: string
    version: string
    latestVersion: string
    name: string
    displayName: string
  }
  package?: {
    name: string
  }
  partner: IPolicyPartner
  paymentData: IPaymentData
  documents: IPolicyDocument[] | null
  purchase: IPurchase | null
  claims: IClaim[]
  objects: IPolicyObject[]
  values: { [key: string]: string | number | boolean }
  pagination?: {
    totalCount: number
  }
  device?: {
    id: string
    activationDate: string
    activatedAt: string
    partnerId: string
  }
}

export interface IPolicyModuleState {
  activeTab: PolicyTab
  isFetching: boolean
  isExecutingTerminationRuleWithExtraInputFields: boolean
  isExecutingTerminationRuleWithoutExtraInputFields: boolean
  policy: IPolicy | null
  purchaseData: IPurchase | null
  errorMessage?: string | null
  activeSideViewType: PolicySideViewType | null
  modalParams?: ISideViewParams
  config: IUiConfig | null
  mode: ModeType
  isSubmitting: boolean
  isNoteCreating: boolean
  isNoteUpdating: boolean
  editingNoteId: string | null
  notes: INote[]
  areNotesLoading: boolean
  focusedClaim: IClaim | null
  terminationData: {
    rules: ITerminationRule[] | null
    executedRule: IExecuteTerminationRuleResponsePayload | null
    refundAmount: IGetRefundAmountResponsePayload | null
  }
  mandate: IMandate | null
}

export interface IPolicyAndConfig {
  policy: IPolicy
  config: IUiConfig
}

export interface IPolicyPartner {
  id: string
  name: string
  extendendInformation: IPolicyShopPartnerExtendedInfo | null
}

export interface IModalPayload {
  type: PolicySideViewType
  mode?: ModeType
  params?: ISideViewParams
}

export interface IPolicyShopPartnerExtendedInfo {
  companyAddressCity: string
  companyAddressHouseNumber: string
  companyAddressPlz: string
  companyAddressStreet: string
  companyName: string
}

export interface IPolicyUpdateRequest {
  customer: ICustomer
  values: IValues
  objects: IPolicyObject[]
  metadata: IValues
  effectiveAt: string
  effectiveRequestedAt: string
  updateReason: PolicyUpdateReason
}

export interface IPolicyUpdateResponse {
  id: string
}

export interface IPolicyRenewRequestPayload {
  prettyId: string
  product: {
    productVersion: string
    productId: string
  }
  customer: ICustomer
  values: IValues
  package: string
  addons: string[]
  quoteId?: string
}

export interface IPolicyRenewResponsePayload {
  policyId: string
  prettyId: string
}

export interface IGetPolicyResponsePayload {
  policyById: IPolicyResponse | null
}

export interface IPolicy {
  id: string
  prettyId: string
  productId: string
  partnerId: string
  customerId: string
  status: PolicyStatus
  cancellationReason: string | null
  withdrawalReason: string | null
  accountingScheduleType: string
  paymentScheduleType: string
  premium: number
  tax: number
  gross: number
  account: {
    values: any | undefined
    policies: IPolicyResponse[]
  }
  addons: {
    [addonName: string]: boolean
  }
  customer: ICustomer
  metadata: Record<string, unknown>
  createdAt: string
  createdBy: string
  updatedAt: string
  issuedAt: string | null
  startsAt: string
  endsAt: string | null
  cancelledAt: string | null
  expiredAt: string | null
  withdrawnAt: string | null
  cancellationRequestedAt: string | null
  withdrawalRequestedAt: string | null
  boundAt: string | null
  isTesting: boolean
  hasClaims: boolean
  product: {
    id: string
    version: string
    latestVersion: string
    name: string
    displayName: string
  }
  package?: {
    name: string
  }
  partner: IPolicyPartner
  paymentData: IPaymentData
  documents: IPolicyDocument[] | null
  purchase: IPurchase | null
  scheduledActionsCount: {
    total: number
    scheduled: number
  } | null
  claims: IClaim[]
  objects: IPolicyObject[]
  values: { [key: string]: string | number | boolean }
  pagination?: {
    totalCount: number
  }
  device?: {
    id: string
    activationDate: string
    activatedAt: string
    partnerId: string
  }
  transactions: ITransaction[]
}

export interface IMappedPolicyResponsePayload {
  policyById: IPolicy | null
}

export interface IQuotePolicyRequestPayload {
  addons: string[]
  package: string
  product: {
    productVersion: string
    productId: string
  }
  values: Record<string, unknown>
  expirationDateRequired?: boolean
}

export interface IUpdatePaymentDetailsRequestPayload {
  firstName: string
  lastName: string
  iban: string
}

export interface IPayPolicyRequestPayload {
  payment: {
    // TODO: add support for other payment methods
    type: 'oneTime'
    processingType: 'sepa'
    firstName: string
    lastName: string
    iban: string
  }
}

export interface IPayPolicyWithInvoiceRequestPayload {
  payment: {
    processingType: ProcessingType.invoice
    paymentSchedule: object
  }
}

export interface IGetPolicyFlags {
  withClaims: boolean
}

export type IUrlParamId = Partial<{
  id: string
}>

export type ModalPayload = IModalPayload | null

export type IPolicyActions = typeof policyActions

// NOTE: Terminate policy interfaces
export interface IGetTerminationRulesRequestPayload {
  policyId: string
  initiator: Initiator
  requestedAt: Date
}

export enum Initiator {
  Insurer = 'insurer',
  Policyholder = 'policyholder',
  Purchaser = 'purchaser'
}

export interface ITerminationRule {
  code: string
  name: string
  description: string
  initiator: Initiator
  terminationType: string
  extraInputFields: IExtraInputField[]
}

export interface IExtraInputField {
  name: string
  type: string
  options?: ISelectOption[]
  placeholder?: string | null
  required?: boolean
  searchable?: boolean
  minimum?: string | null
  maximum?: string | null
}

export interface IGetTerminationRulesResponsePayload {
  rules: ITerminationRule[]
}

export interface IExecuteTerminationRuleRequestPayload {
  initiator: Initiator
  requestedAt: Date
  extraInput: object
}
export enum RefundType {
  Full = 'full',
  ProRated = 'proRated',
  None = 'none'
}

export interface IExecuteTerminationRuleResponsePayload {
  effectiveAtDefault: Date
  effectiveAtRange: { minimum: Date | null; maximum: Date | null }
  requestedAt: Date
  grossRefundAmount: number
  grossCreditInvoiceAmount: number
  refundType: RefundType
}

export interface IGetRefundAmountRequestPayload {
  initiator: Initiator
  ruleCode: string
  requestedAt: Date
  effectiveAtFinal: Date
  extraInput: object
}

export interface IGetRefundAmountResponsePayload {
  grossRefundAmount: number
  grossCreditInvoiceAmount: number
}

export interface IExecuteTerminationRequestPayload {
  ruleCode: string
  initiator: Initiator
  requestedAt: Date
  effectiveAtFinal: Date
  extraInput: Object
}

// NOTE: schedule endorsement process

export interface IScheduleEndorsementResponsePayload {
  policyId: string
}

export interface IScheduleEndorsementRequestPayload {
  quoteId: string
  renewPolicyPayload: IPolicyRenewRequestPayload
  payPolicyPayload: IPayPolicyRequestPayload | IPayPolicyWithInvoiceRequestPayload
}

export interface IUpdateMandateRequestPayload {
  firstName: string
  lastName: string
  iban: string
  consentedAt: Date
  reference: string
}

export interface IMandate {
  reference: string
  type: string
  payerInformation: IPayerInformation
  creditorInformation: ICreditorInformation
  signedOn: string
}

export interface IPayerInformation {
  firstName: string
  lastName: string
  iban: string
  bic?: string
}

interface ICreditorInformation {
  name: string
  address: string
  id: string
}
