import { reset } from 'redux-form'
import { takeLatest, put } from 'typed-redux-saga'

import { IFormConfig, IFormSuccessConfig } from '../../../components/Form/interfaces'
import { IAction } from '../../../interfaces/store'
import { openSideView } from '../../Layout'
import {
  CREATE_NOTE_START,
  EDIT_NOTE_START,
  GET_POLICY_AND_CONFIG_REQUEST,
  LOAD_NOTES_START,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SUBMIT_FORM_START,
  UPDATE_NOTE_START,
  LOAD_CLAIM_DETAILS_START,
  forms,
  SUBMIT_FORM_SUCCESS,
  UPDATE_IBAN_START,
  UPDATE_IBAN_SUCCESS,
  GET_TERMINATION_RULES_START,
  EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START,
  EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START,
  GET_REFUND_AMOUNT_START
} from '../constants'
import {
  loadClaimDetails,
  submitClaimCreateForm,
  submitClaimEditForm,
  submitClaimTransactionCreateForm,
  submitEditClaimStatusForm
} from './sagas/claims'
import { createNote, loadNotes, updateNote, editNoteStart } from './sagas/notes'
import {
  loadPolicyAndConfig,
  submitEditForm,
  submitEditObjectsForm,
  updateIban,
  submitCreateScheduledTerminationForm
} from './sagas/policies'
import {
  getTerminationRules,
  executeTerminationRuleWithExtraInputFields,
  getRefundAmount,
  submitPolicyTerminationForm,
  executeTerminationRuleWithoutExtraInputFields
} from './sagas/termination'

function* submitForm({ payload }: IAction<IFormConfig>): Generator {
  const { form: formName }: IFormConfig = payload

  switch (formName) {
    case forms.createClaimForm: {
      yield submitClaimCreateForm(payload)
      break
    }

    case forms.editClaimForm: {
      yield submitClaimEditForm(payload)
      break
    }

    case forms.createTransactionObligationForm:
    case forms.createTransactionReserveForm: {
      yield submitClaimTransactionCreateForm(payload)
      break
    }

    case forms.editTransactionUpdateStatusForm: {
      yield submitEditClaimStatusForm(payload)
      break
    }

    case forms.editPolicyValuesForm:
    case forms.editPolicyHolderForm:
      yield* submitEditForm(payload)
      break
    case forms.editPolicyObjectForm: {
      yield* submitEditObjectsForm(payload)
      break
    }
    case forms.createScheduledTerminationForm: {
      yield* submitCreateScheduledTerminationForm(payload)
      break
    }
    case forms.terminatePolicyForm: {
      yield* submitPolicyTerminationForm(payload)
      break
    }
    default: {
      throw new Error(`Unsupported form type: '${formName}'`)
    }
  }
}

function* onFormSuccess(action: IAction<IFormSuccessConfig>): Generator {
  if (action.payload.withReset === true) {
    yield* put(reset(action.payload.formName))
  }
}

function* watchGetPolicyStart(): Generator {
  yield* takeLatest(GET_POLICY_AND_CONFIG_REQUEST, loadPolicyAndConfig)
}

function* watchOpenModal(): Generator {
  yield* takeLatest(SET_ACTIVE_SIDE_VIEW_TYPE, openSideView)
}

function* watchFormSubmission(): Generator {
  yield* takeLatest(SUBMIT_FORM_START, submitForm)
}

function* watchFormSuccess(): Generator {
  yield* takeLatest(SUBMIT_FORM_SUCCESS, onFormSuccess)
}

function* watchIbanUpdate(): Generator {
  yield* takeLatest(UPDATE_IBAN_START, updateIban)
}

function* watchIbanUpdateSuccess(): Generator {
  yield* takeLatest(UPDATE_IBAN_SUCCESS, onFormSuccess)
}

function* watchNoteCreate(): Generator {
  yield* takeLatest(CREATE_NOTE_START, createNote)
}

function* watchNoteEditStart(): Generator {
  yield* takeLatest(EDIT_NOTE_START, editNoteStart)
}

function* watchNoteUpdate(): Generator {
  yield* takeLatest(UPDATE_NOTE_START, updateNote)
}

function* watchNotesLoad(): Generator {
  yield* takeLatest(LOAD_NOTES_START, loadNotes)
}

function* watchLoadClaimDetails(): Generator {
  yield* takeLatest(LOAD_CLAIM_DETAILS_START, loadClaimDetails)
}

function* watchGetTerminationRules(): Generator {
  yield* takeLatest(GET_TERMINATION_RULES_START, getTerminationRules)
}

function* watchExecuteTerminationRuleWithExtraInputFields(): Generator {
  yield* takeLatest(EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START, executeTerminationRuleWithExtraInputFields)
}

function* watchExecuteTerminationRuleWithoutExtraInputFields(): Generator {
  yield* takeLatest(
    EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START,
    executeTerminationRuleWithoutExtraInputFields
  )
}

function* watchGetRefundAmount(): Generator {
  yield* takeLatest(GET_REFUND_AMOUNT_START, getRefundAmount)
}

export default (): Generator[] => [
  watchGetPolicyStart(),
  watchOpenModal(),
  watchFormSubmission(),
  watchFormSuccess(),
  watchIbanUpdate(),
  watchIbanUpdateSuccess(),
  watchNoteCreate(),
  watchNoteEditStart(),
  watchNoteUpdate(),
  watchNotesLoad(),
  watchLoadClaimDetails(),
  watchGetTerminationRules(),
  watchExecuteTerminationRuleWithExtraInputFields(),
  watchGetRefundAmount(),
  watchExecuteTerminationRuleWithoutExtraInputFields()
]
