import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IRootState } from '../../../../../store/interfaces'
import { forms, MODULE_NAME } from '../../../constants'
import { IClaim } from '../../../interfaces'
import { generateReduxFormConfig } from '../../../utils'
import generateClaimObligationFormCard from './utils/generateClaimObligationFormCard'

type Props = { claim: IClaim }

const ClaimObligationForm: FC<Props> = ({ claim }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['policy'])
  const isSubmitting = useSelector((state: IRootState) => state[MODULE_NAME].isSubmitting)

  const obligationFormConfig = generateClaimObligationFormCard(t, claim)

  const obligationFormProps: IFormProps = generateReduxFormConfig(obligationFormConfig, dispatch, {
    hasFormButtons: true,
    form: forms.createTransactionObligationForm,
    dataTest: forms.createTransactionObligationForm,
    formType: FormType.CREATE,
    isSubmitting
  })

  return <Form {...obligationFormProps} />
}

export default ClaimObligationForm
