import { AxiosResponse } from 'axios'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { IReferralPartnerChangeStatusRequest, IReferralPartnerUpdateRequest } from '../interfaces/referralPartner'
import * as Endpoint from './endpointConsts'

export class ReferralPartnerApi {
  constructor(private api: Api) {}

  approveReferralPartner = async (
    selectedRequestId: string,
    referralPartnerChangeStatusRequest?: IReferralPartnerChangeStatusRequest
  ): Promise<void> => {
    const url = Endpoint.REFERRAL_PARTNER_APPROVE.replace(Endpoint.REFERRAL_PARTNER_ID, selectedRequestId)
    await this.api.post(url, referralPartnerChangeStatusRequest)
  }

  rejectReferralPartner = async (
    selectedRequestId: string,
    referralPartnerChangeStatusRequest?: IReferralPartnerChangeStatusRequest
  ): Promise<void> => {
    const url = Endpoint.REFERRAL_PARTNER_REJECT.replace(Endpoint.REFERRAL_PARTNER_ID, selectedRequestId)
    await this.api.post(url, referralPartnerChangeStatusRequest)
  }

  terminateReferralPartner = async (
    selectedRequestId: string,
    referralPartnerChangeStatusRequest?: IReferralPartnerChangeStatusRequest
  ): Promise<void> => {
    const url = Endpoint.REFERRAL_PARTNER_TERMINATE.replace(Endpoint.REFERRAL_PARTNER_ID, selectedRequestId)
    await this.api.post(url, referralPartnerChangeStatusRequest)
  }

  updateReferralPartner = async (
    partnerId: string,
    partnerUpdateRequest: IReferralPartnerUpdateRequest
  ): Promise<void> => {
    const url = Endpoint.REFERRAL_PARTNER_UPDATE.replace(Endpoint.REFERRAL_PARTNER_ID, partnerId)
    await this.api.post(url, partnerUpdateRequest)
  }

  getReferralPartner = async (key: string): Promise<AxiosResponse> => {
    const url = `v1/referral-partners/${key}`

    return await this.api.get(url)
  }

  checkSubdomainAvailability = async (subdomain: string): Promise<AxiosResponse> => {
    return await this.api.post(Endpoint.REFERRAL_PARTNER_CHECK_SUBDOMAIN_AVAILABILITY, { subdomain })
  }
}

export const referralPartnerApi: ReferralPartnerApi = new ReferralPartnerApi(defaultApiInstance)
