import { InitOptions } from 'i18next'

import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'

import { login_de, login_en } from '../Auth'
import { invoices_de, invoices_en } from '../Invoices'
import { partner_de, partner_en } from '../Partner'
import partnersManagement_de from '../PartnersManagement/i18n/de/partnersManagement.json'
import partnersManagement_en from '../PartnersManagement/i18n/en/partnersManagement.json'
import { payments_de, payments_en } from '../Payments'
import { policies_de, policies_en } from '../Policies'
import { countryCodes_de, countryCodes_en, policy_de, policy_en } from '../Policy'
import { static_de, static_en } from '../Static'
import common_de from './de/common.json'
import common_en from './en/common.json'
import { languageLocalStorage } from './languageLocalStorage'

const language: ISupportedLanguage = languageLocalStorage.getLanguageOrDefault()

const initI18n: InitOptions = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: `${language}`,
  resources: {
    en: {
      common: common_en,
      login: login_en,
      policies: policies_en,
      policy: policy_en,
      countryCodes: countryCodes_en,
      static: static_en,
      partnersManagement: partnersManagement_en,
      referralPartners: partnersManagement_en,
      partner: partner_en,
      payments: payments_en,
      invoices: invoices_en
    },
    de: {
      common: common_de,
      login: login_de,
      policies: policies_de,
      policy: policy_de,
      countryCodes: countryCodes_de,
      static: static_de,
      partnersManagement: partnersManagement_de,
      referralPartners: partnersManagement_de,
      partner: partner_de,
      payments: payments_de,
      invoices: invoices_de
    }
  }
}

export default initI18n
