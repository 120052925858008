/* eslint-disable @typescript-eslint/typedef */
import { MODULE_NAME } from '../constants'

export const GET_POLICIES_REQUEST = `GET_${MODULE_NAME}_REQUEST` as const
export const GET_POLICIES_SUCCESS = `GET_${MODULE_NAME}_SUCCESS` as const
export const GET_POLICIES_FAILURE = `GET_${MODULE_NAME}_FAILURE` as const

export const GET_FILTERS_PARAMS_REQUEST = `${MODULE_NAME}_GET_FILTERS_PARAMS_REQUEST` as const
export const GET_FILTERS_PARAMS_SUCCESS = `${MODULE_NAME}_GET_FILTERS_PARAMS_SUCCESS` as const
export const GET_FILTERS_PARAMS_FAILURE = `${MODULE_NAME}_GET_FILTERS_PARAMS_FAILURE` as const

export const SET_PAGINATION = `${MODULE_NAME}_SET_PAGINATION` as const
export const ADD_FILTER = `${MODULE_NAME}_ADD_FILTER` as const
export const SET_FILTER = `${MODULE_NAME}_SET_FILTER` as const
export const REMOVE_FILTER = `${MODULE_NAME}_REMOVE_FILTER` as const
export const RESET_FILTERS = `${MODULE_NAME}_RESET_FILTERS` as const

export const SET_ACTIVE_SIDE_VIEW_TYPE = `${MODULE_NAME}_SET_SIDE_VIEW` as const

export const IMPORT_POLICIES_START = `${MODULE_NAME}_IMPORT_POLICIES_REQUEST` as const
export const IMPORT_POLICIES_SUCCESS = `${MODULE_NAME}_IMPORT_POLICIES_SUCCESS` as const
export const IMPORT_POLICIES_FAILURE = `${MODULE_NAME}_IMPORT_POLICIES_FAILURE` as const

export const TERMINATE_POLICIES_START = `${MODULE_NAME}_TERMINATE_POLICIES_REQUEST` as const
export const TERMINATE_POLICIES_SUCCESS = `${MODULE_NAME}_TERMINATE_POLICIES_SUCCESS` as const
export const TERMINATE_POLICIES_FAILURE = `${MODULE_NAME}_TERMINATE_POLICIES_FAILURE` as const

export const POLICIES_FILTER_PARAMS_LS_KEY = `${MODULE_NAME}.filterParams` as const
