import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { change, isValid } from 'redux-form'
import { call, delay, put, select } from 'typed-redux-saga'

import { IFormValues, isNilOrEmptyString, formatFactory } from '@alteos/ui'

import { IFormConfig } from '../../../../components/Form/interfaces'
import { IStore } from '../../../../interfaces/store'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils/requireNotNull'
import { policyApi } from '../../api'
import { forms } from '../../constants'
import {
  IExecuteTerminationRequestPayload,
  IExecuteTerminationRuleRequestPayload,
  IGetRefundAmountRequestPayload,
  IGetTerminationRulesRequestPayload,
  Initiator
} from '../../interfaces'
import {
  createNoteStart,
  executeTerminationRuleFailure,
  executeTerminationRuleSuccess,
  executeTerminationRuleWithExtraInputFieldsFailure,
  executeTerminationRuleWithoutExtraInputFieldsFailure,
  getRefundAmountFailure,
  getRefundAmountSuccess,
  getTerminationRulesFailure,
  getTerminationRulesSuccess,
  submitFormFailure,
  submitFormSuccess
} from '../actionCreators'
import { onPolicyUpdateComplete } from './policies'

const DELAY_TIME = 500
const policySelector = (state: IStore) => requireNotNull(state['policy'].policy)
const terminationDataSelector = (state: IStore) => requireNotNull(state['policy'].terminationData)
const formName = forms.terminatePolicyForm

export function* getTerminationRules(): Generator {
  const policy = yield* select(policySelector)

  const formValues = (yield select((state: IStore) => state.form[formName].values)) as IFormValues
  const { initiator, requestedAt } = formValues

  if (isNilOrEmptyString(initiator as Initiator) || isNilOrEmptyString(requestedAt as string)) {
    yield* put(getTerminationRulesFailure())
    return
  }

  const requestPayload = { initiator, requestedAt: requestedAt }

  try {
    // NOTE: Reset other form values on each either initiator or requestedAt change
    yield* put(change(formName, 'refundAmount', ''))
    yield* put(change(formName, 'creditInvoiceAmount', ''))
    yield* put(change(formName, 'effectiveAt', ''))
    yield* put(change(formName, 'terminationReason', ''))
    yield* put(change(formName, 'extraInput', ''))
    const response = yield* call(
      policyApi.getTerminationRules,
      policy.id,
      requestPayload as IGetTerminationRulesRequestPayload
    )
    yield* put(getTerminationRulesSuccess(response))
  } catch (error) {
    yield* put(getTerminationRulesFailure())
    yield triggerErrorPopup(error)
    return
  }
}

export function* executeTerminationRuleWithoutExtraInputFields(): Generator {
  const policy = yield* select(policySelector)

  const formValues = (yield select((state: IStore) => state.form[formName].values)) as IFormValues
  const { initiator, requestedAt, terminationReason } = formValues

  const terminationData = yield* select(terminationDataSelector)
  const { rules } = terminationData

  if (isNil(rules) || isNil(terminationReason)) {
    yield* put(executeTerminationRuleWithoutExtraInputFieldsFailure())
    return
  }

  const ruleCode = requireNotNull(rules.find((rule) => rule.name === terminationReason)).code
  const extraInputFields = requireNotNull(rules.find((rule) => rule.name === terminationReason)).extraInputFields

  // NOTE: Reset extra input, effectiveAt and refundAmount values when re-selecting reason
  yield* put(change(formName, 'extraInput', {}))
  yield* put(change(formName, 'effectiveAt', ''))
  yield* put(change(formName, 'refundAmount', ''))
  yield* put(change(formName, 'creditInvoiceAmount', ''))

  // NOTE: Stop saga if rule has extra input field
  const hasExtraInputFields = extraInputFields.length > 0

  if (hasExtraInputFields) {
    yield* put(executeTerminationRuleWithoutExtraInputFieldsFailure())
    return
  }

  const requestPayload = {
    initiator,
    requestedAt,
    extraInput: {}
  }

  yield* call(
    makeRequestToExecuteTerminationRule,
    formName,
    policy.id,
    ruleCode,
    requestPayload as IExecuteTerminationRuleRequestPayload
  )
}

export function* executeTerminationRuleWithExtraInputFields(): Generator {
  const policy = yield* select(policySelector)

  const isFormValid = yield* select((state: IStore) => isValid(formName)(state))

  const formValues = (yield select((state: IStore) => state.form[formName].values)) as IFormValues
  const { initiator, requestedAt, terminationReason, extraInput } = formValues

  const terminationData = yield* select(terminationDataSelector)
  const { rules } = terminationData

  if (isNil(rules) || isNil(terminationReason)) {
    yield* put(executeTerminationRuleWithExtraInputFieldsFailure())
    return
  }

  const ruleCode = requireNotNull(rules.find((rule) => rule.name === terminationReason)).code
  const extraInputFields = requireNotNull(rules.find((rule) => rule.name === terminationReason)).extraInputFields

  // NOTE: Stop saga if rule does not have extra input fields or if form is not valid
  if (isEmpty(extraInputFields) || !isFormValid) {
    yield* put(executeTerminationRuleWithExtraInputFieldsFailure())
    return
  }

  const requestPayload = {
    initiator,
    requestedAt,
    // TODO: remove when a proper solution for optional extra input will be implemented: https://versicorp.atlassian.net/browse/DYADI-552
    extraInput: ruleCode === 'can09' && typeof extraInput === 'undefined' ? {} : extraInput
  }

  yield* delay(DELAY_TIME)

  yield* call(
    makeRequestToExecuteTerminationRule,
    formName,
    policy.id,
    ruleCode,
    requestPayload as IExecuteTerminationRuleRequestPayload
  )
}

export function* getRefundAmount(): Generator {
  const { formatPrice } = formatFactory

  const policy = yield* select(policySelector)

  const formValues = (yield select((state: IStore) => state.form[formName].values)) as IFormValues
  const { initiator, requestedAt, effectiveAt, terminationReason, extraInput } = formValues

  const terminationData = yield* select(terminationDataSelector)
  const { rules, executedRule } = terminationData

  if (isNil(rules) || isNil(executedRule)) {
    yield* put(executeTerminationRuleFailure())
    return
  }

  // NOTE: Do not send request if effectiveAt value is the same as effectiveAtDefault of executedRule
  if (effectiveAt === executedRule.effectiveAtDefault) {
    yield* put(change(formName, 'refundAmount', formatPrice(executedRule.grossRefundAmount)))
    yield* put(change(formName, 'creditInvoiceAmount', formatPrice(executedRule.grossCreditInvoiceAmount)))
    yield* put(getRefundAmountFailure())
    return
  }

  const ruleCode = requireNotNull(rules.find((rule) => rule.name === terminationReason)).code

  const requestPayload = {
    initiator,
    ruleCode,
    requestedAt,
    effectiveAtFinal: effectiveAt,
    // TODO: remove when a proper solution for optional extra input will be implemented: https://versicorp.atlassian.net/browse/DYADI-552
    extraInput: ruleCode === 'can09' && typeof extraInput === 'undefined' ? {} : extraInput
  }

  try {
    const response = yield* call(policyApi.getRefundAmount, policy.id, requestPayload as IGetRefundAmountRequestPayload)
    // NOTE: Set refund amount to grossRefundAmount from response
    yield* put(change(formName, 'refundAmount', formatPrice(response.grossRefundAmount)))
    yield* put(change(formName, 'creditInvoiceAmount', formatPrice(response.grossCreditInvoiceAmount)))
    yield* put(getRefundAmountSuccess(response))
  } catch (error) {
    yield* put(getRefundAmountFailure())
    yield triggerErrorPopup(error)
    return
  }
}

export function* submitPolicyTerminationForm(payload: IFormConfig): Generator {
  const policy = yield* select(policySelector)

  const { successMessage }: IFormConfig = payload
  const formValues = (yield select((state: IStore) => state.form[formName].values)) as IFormValues
  const { initiator, requestedAt, effectiveAt, terminationReason, extraInput } = formValues

  const terminationData = yield* select(terminationDataSelector)
  const { rules } = terminationData

  if (isNil(rules)) {
    yield* put(executeTerminationRuleFailure())
    return
  }
  const ruleCode = requireNotNull(rules.find((rule) => rule.name === terminationReason)).code
  const note = (formValues as { helpers?: Record<string, any> }).helpers?.note

  const requestPayload = {
    ruleCode,
    initiator,
    requestedAt,
    effectiveAtFinal: effectiveAt,
    // TODO: remove when a proper solution for optional extra input will be implemented: https://versicorp.atlassian.net/browse/DYADI-552
    extraInput: ruleCode === 'can09' && typeof extraInput === 'undefined' ? {} : extraInput,
    note
  }

  // NOTE: Send request to save note separately
  if (typeof note === 'string' && !isNilOrEmptyString(note)) {
    yield* put(createNoteStart(note, false))
  }

  try {
    yield* call(policyApi.executeTermination, policy.id, requestPayload as IExecuteTerminationRequestPayload)
    yield* put(submitFormSuccess({ formName, withReset: true }))
    yield* call(onPolicyUpdateComplete, {
      policyId: policy.id,
      formName,
      successMessage
    })
  } catch (error) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }
}

// NOTE: Helper function to execute termination rule request
function* makeRequestToExecuteTerminationRule(
  formName: string,
  policyId: string,
  ruleCode: string,
  requestPayload: IExecuteTerminationRuleRequestPayload
): Generator {
  try {
    const response = yield* call(
      policyApi.executeTerminationRule,
      policyId,
      ruleCode,
      requestPayload as IExecuteTerminationRuleRequestPayload
    )
    // NOTE: Set effectiveAt to default value
    yield* put(change(formName, 'effectiveAt', response.effectiveAtDefault))
    yield* put(executeTerminationRuleSuccess(response))
  } catch (error) {
    yield* put(executeTerminationRuleFailure())
    yield triggerErrorPopup(error)
  }
}
