import { TFunction } from 'react-i18next'

import { ITableColumn } from '@alteos/ui'

export function generateDocumentDetailsTable(t: TFunction): ITableColumn[] {
  return [
    {
      Header: t('common:phrases.createdAt'),
      accessor: 'createdAt'
    },
    {
      Header: t('documentDetails.table.version'),
      accessor: 'version'
    },
    {
      Header: t('documentDetails.table.category'),
      accessor: 'category'
    },
    {
      Header: t('documentDetails.table.mediaType'),
      accessor: 'mediaType'
    },
    {
      Header: t('documentDetails.table.action'),
      accessor: 'action'
    }
  ]
}
