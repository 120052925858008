import axios from 'axios'

import { API_URL, APP_KEY, CLIENT_ID } from '../../../../config'
import { IAuthResponse, ICurrentUser, ILoginResponse, IRefreshResponse } from './authServiceDtos'

export interface IAuthService {
  login(username: string, password: string): Promise<ILoginResponse>
  refreshAccessToken(refreshToken: string): Promise<IRefreshResponse>
}

const axiosInstance = axios.create()
axiosInstance.defaults.headers.common['X-App-Key'] = APP_KEY

export const authService: IAuthService = {
  async login(username: string, password: string): Promise<ILoginResponse> {
    const authPayload = {
      grant_type: 'password',
      client_secret: '',
      client_id: CLIENT_ID,
      username,
      password
    }
    const { data: token } = await axiosInstance.post<IAuthResponse>(`${API_URL}v1/authentication/token`, authPayload)
    const { data: userDetails } = await axiosInstance.get<ICurrentUser>(`${API_URL}v1/accounts/me`, {
      headers: {
        Authorization: `Bearer ${token.access_token}`
      }
    })
    return {
      userId: userDetails.userId,
      permissions: userDetails.permissions,
      refreshToken: token.refresh_token ?? null,
      token: token.access_token,
      name: userDetails.name
    }
  },

  async refreshAccessToken(refreshToken: string): Promise<IRefreshResponse> {
    const response = await axiosInstance.post(`${API_URL}v1/authentication/token`, {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    })
    return response.data
  }
}
