import { IAction, IBasicAction } from '../../../interfaces/store'
import {
  CLOSE_SIDE_VIEW,
  OPEN_SIDE_VIEW,
  DISPLAY_TOAST_MESSAGE,
  CLOSE_TOAST_MESSAGE,
  SET_SIDE_VIEW_IS_PROCESSING_DATA
} from '../constants'
import { IToastProps } from '../interfaces/internal'

export function openSideView(): IBasicAction {
  return {
    type: OPEN_SIDE_VIEW
  }
}

export function closeSideView(): IBasicAction {
  return {
    type: CLOSE_SIDE_VIEW
  }
}

export function displayToast(payload: IToastProps): IAction<IToastProps> {
  return {
    type: DISPLAY_TOAST_MESSAGE,
    payload
  }
}

export function closeToast(): IBasicAction {
  return {
    type: CLOSE_TOAST_MESSAGE
  }
}

export function setSideViewIsProcessingData(payload: boolean): IAction<boolean> {
  return {
    type: SET_SIDE_VIEW_IS_PROCESSING_DATA,
    payload
  }
}

export default {
  openSideView,
  closeSideView,
  displayToast,
  closeToast,
  setSideViewIsProcessingData
}
