import i18next from 'i18next'
import { FC, ReactElement } from 'react'

import Label from '../../Label'
import { LabelMode, LabelType } from '../../Label/dictionaries'

import styles from './editModeLabel.module.scss'

interface IProps {
  dataTest?: string
}

const EditModeLabel: FC<IProps> = ({ dataTest = 'edit-label' }: IProps): ReactElement => {
  return (
    <Label
      content={i18next.t('common:phrases.editMode')}
      mode={LabelMode.filled}
      type={LabelType.info}
      dataTest={dataTest}
      className={styles.container}
    />
  )
}

export default EditModeLabel
