import cx from 'classnames'
import { FC, ReactElement } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import { Button } from '@alteos/ui'

import ExpandableCard from '../../Cards/ExpandableCard'

import styles from './styles.module.scss'

interface IProps {
  isSubmitting: boolean
  isPristine: boolean
  isFloating: boolean
  closeModal: () => void
  ref?: any
}

const FormButtons: FC<IProps> = ({ closeModal, isSubmitting, isPristine, isFloating }: IProps) => {
  const [t]: UseTranslationResponse<'common'> = useTranslation('common')
  const saveButtonContent: string = isSubmitting === true ? t('form.buttons.saving') : t('form.buttons.save')

  const content: ReactElement = (
    <div
      className={cx(styles.container, { [styles.isFloating]: isFloating })}
      data-test={`floating-submit-container-${isFloating ? 'on' : 'off'}`}
    >
      <Button
        className={styles.firstButton}
        color="tertiary"
        type="button"
        small
        noMinWidth
        disabled={isSubmitting}
        dataTest="form-cancel-edit-button"
        handleClick={closeModal}
      >
        {t('form.buttons.cancelEditing')}
      </Button>
      <Button
        className={cx({ [styles.isSubmitting]: isSubmitting })}
        color="primary"
        icon={isSubmitting ? 'loader' : 'tick'}
        type="submit"
        disabled={isPristine}
        small
        noMinWidth
        dataTest="form-submit-button"
      >
        {saveButtonContent}
      </Button>
    </div>
  )
  return <ExpandableCard title={t('form.buttons.title')} icon="tick" expandedContent={content} isStatic />
}

export default FormButtons
