import { IFilter } from '../../components/Filters/interfaces'
import { LabelType } from '../../components/Label/dictionaries'

export const MODULE_NAME: 'partnersManagement' = 'partnersManagement' as const

export const PARTNERS_MANAGEMENT_ROUTE = '/partners-management'

export const DEFAULT_LIMIT = 20
export const DEFAULT_PAGINATION = { limit: DEFAULT_LIMIT, offset: 0, total: 0 }
export const DEFAULT_SORTING = { sort_by: 'created_at', sort_order: 'desc' }
export const DEFAULT_FILTERS: IFilter[] = [
  {
    active: false,
    key: 'createdAt',
    value: 'Created At',
    componentType: 'datePickerRange',
    selectedFilter: '',
    withSearch: false,
    options: null,
    isCountable: true
  },
  {
    active: false,
    key: 'status',
    value: 'Status',
    componentType: null,
    withSearch: false,
    selectedFilter: '',
    options: [
      {
        id: 'new',
        value: '',
        translationKey: 'filters.statuses.new',
        labelType: LabelType.warn
      },
      {
        id: 'rejected',
        value: '',
        translationKey: 'filters.statuses.rejected',
        labelType: LabelType.neutral
      },
      {
        id: 'approved',
        value: '',
        translationKey: 'filters.statuses.approved',
        labelType: LabelType.success
      },
      {
        id: 'terminated',
        value: '',
        translationKey: 'filters.statuses.terminated',
        labelType: LabelType.error
      }
    ],
    isCountable: true
  }
]
