import { FC, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import {
  Button,
  formatFactory,
  ITableColumn,
  ITableDataPagination,
  PAGE_LIMITS,
  Table,
  useTableStaticDataPagination
} from '@alteos/ui'

import { IUseState } from '../../../../interfaces/common'
import Tag from '../../../Tag'
import { ITag } from '../../interfaces'

import styles from './risks.module.scss'

interface IProps {
  tags: ITag[]
  columns: ITableColumn[]
  title: string
}

const Tags: FC<IProps> = ({ tags, columns, title }: IProps) => {
  const [tableView, setTableView]: IUseState<boolean> = useState<boolean>(false)
  const [t]: UseTranslationResponse<'common'> = useTranslation('common')
  const { formatCamelCase } = formatFactory

  const dataPagination: ITableDataPagination = useTableStaticDataPagination({
    data: tags,
    initialLimit: PAGE_LIMITS[0]
  })

  return (
    <div className={styles.container}>
      {tableView === false ? (
        <>
          <div className={styles.tagsContainer}>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.content}>
              {tags.map((item: ITag, index: number) => (
                <Tag className={styles.cardTag} dataTest={`${item.name}-test`} key={index}>
                  {formatCamelCase(item.name)}
                </Tag>
              ))}
            </div>
          </div>
          <Button
            className={styles.button}
            icon="arrow-down"
            dataTest="open-tags-table"
            color="outline"
            small
            handleClick={() => setTableView(true)}
          >
            {t('buttons.moreData')}
          </Button>
        </>
      ) : (
        <>
          <Table
            columns={columns}
            dataPagination={dataPagination}
            dataTest="tags-table"
            emptyTableTitle={t('common:titles.emptyTable')}
          />
          <Button
            className={styles.button}
            icon="arrow-up"
            dataTest="open-tags-table"
            color="outline"
            small
            handleClick={() => setTableView(false)}
          >
            {t('buttons.lessData')}
          </Button>
        </>
      )}
    </div>
  )
}

export default Tags
