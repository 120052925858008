import cx from 'classnames'
import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { isTranslatable } from '@alteos/product-configuration/dist/translation/isTranslatable'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'
import { LandingButton } from '@alteos/ui'

import { FieldType } from '../../../dictionaries'
import { IBasicProps, IViewFieldConfig } from '../../../interfaces/common'
import { getCurrentLanguage } from '../../../modules/i18n/selectors'
import { mapContent, localFormatFactory } from '../../../utils'
import shouldRenderNull from '../../../utils/shouldRenderNull'
import { assertString } from '../../../utils/typeAsserts'
import { FieldValue, IButton, ICardSection } from '../interfaces'
import ButtonItem from './items/ButtonItem'
import DividerItem from './items/DividerItem'
import ElementItem from './items/ElementItem'
import FileNameItem from './items/FileNameItem'
import HeadingItem from './items/HeadingItem'
import DefaultStatusItem from './items/StatusItem/DefaultStatusItem'
import TagsItem from './items/TagsItem'
import TextItem from './items/TextItem'
import TitleItem from './items/TitleItem'

import styles from './card.module.scss'

interface IProps extends IBasicProps {
  content: ICardSection
  source?: Record<string, any>
  helpers?: Record<string, any>
  isNode?: boolean
  withBackground?: boolean
  inline?: boolean
  buttons?: IButton[]
}

const Card: FC<IProps> = ({
  content,
  source,
  buttons,
  className = '',
  isNode = false,
  withBackground = false,
  inline: isInline = false,
  helpers
}: IProps): ReactElement => {
  const language: ISupportedLanguage = useSelector(getCurrentLanguage)
  return (
    <div
      className={cx(
        styles.card,
        {
          [styles.isNode]: isNode,
          [styles.withBackground]: withBackground,
          [styles.inline]: isInline
        },
        className
      )}
    >
      <div className={styles.content}>
        {content.fields.map((field: IViewFieldConfig, index: number) => {
          let value: FieldValue =
            typeof source !== 'undefined' ? mapContent(field, localFormatFactory, source) : field.value

          if (isTranslatable(value)) {
            value = getTranslation(language, value)
          }

          if (shouldRenderNull(field, value, { ...source, helpers }) || field.editOnly === true) {
            return null
          }
          switch (field.type) {
            case FieldType.Divider:
              return <DividerItem key={index} />
            case FieldType.Title:
              return <TitleItem key={index} field={field} value={value} />
            case FieldType.Tags:
              return <TagsItem key={index} field={field} value={value} />
            case FieldType.FileName:
              return <FileNameItem key={index} field={field} value={value} />
            case FieldType.Button:
              return <ButtonItem key={index} field={field} isInline={isInline} />
            case FieldType.Element:
              return <ElementItem key={index} field={field} value={value} isInline={isInline} source={source} />
            case FieldType.Heading:
              return <HeadingItem key={index} field={field} value={value} isInline={isInline} />
            case FieldType.Status:
              assertString(value)
              return <DefaultStatusItem key={index} isInline={isInline} field={field} value={value} />
            default:
              return <TextItem key={index} field={field} value={value} isInline={isInline} dataTest={field.dataTest} />
          }
        })}
      </div>
      {typeof buttons !== 'undefined' ? (
        <div className={styles.buttonsContainer}>
          {buttons.map((button: IButton, index: number) => {
            return <LandingButton {...button} type="button" key={index} dataTest={`card-button-${button.dataTest}`} />
          })}
        </div>
      ) : null}
    </div>
  )
}

export default Card
