import isNil from 'lodash/isNil'

import { ClaimStatus, PolicyStatus } from '../dictionaries'
import { ClaimSubStatus } from '../dictionaries/ClaimSubStatus'
import { StatusEnum } from '../dictionaries/StatusEnum'

const lookup: Map<StatusEnum, unknown[]> = new Map()
lookup.set(StatusEnum.Claims, Object.values(ClaimStatus))
lookup.set(StatusEnum.Policy, Object.values(PolicyStatus))
lookup.set(StatusEnum.ClaimsSubStatus, Object.values(ClaimSubStatus))

// NOTE: When adding a new enum mapping add a new overload here for
// better typescript support
export function getEnumValues(status: StatusEnum.Claims): ClaimStatus[]
export function getEnumValues(status: StatusEnum.ClaimsSubStatus): ClaimSubStatus[]
export function getEnumValues(status: StatusEnum.Policy): PolicyStatus[]

export function getEnumValues(status: StatusEnum): unknown[] {
  const item: unknown[] | undefined = lookup.get(status)
  if (isNil(item)) {
    throw new Error(`Missing enum mapping: '${status}'`)
  }
  return item
}
