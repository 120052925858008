import get from 'lodash/get'
import has from 'lodash/has'

import { InputType, MaskType } from '@alteos/ui'

import { IFieldConfig } from '../../../interfaces/common'
import { isFormField, isMaskedFormFieldConfig } from '../../../interfaces/guards'
import { Validator } from '../interfaces'
import * as validators from './validators'

const generateValidationSchema = (field: IFieldConfig): Validator[] => {
  const result: Validator[] = []
  const possibleValidators: [string, Validator][] = Object.entries(validators)

  possibleValidators.forEach(([name, validator]: [string, Validator]) => {
    if (typeof get(field, name) === 'boolean' && get(field, name) === false) {
      return
    }

    if (has(field, name)) {
      result.push(validator)
    }
  })

  if (isFormField(field) && field.editType === InputType.string) {
    result.push(validators.string)
  }

  if (isFormField(field) && field.editType === InputType.number) {
    result.push(validators.number)
  }

  if (isFormField(field) && field.editType === InputType.email) {
    result.push(validators.email)
  }

  if (isMaskedFormFieldConfig(field) && field.mask === MaskType.iban) {
    result.push(validators.iban)
  }

  return result
}

export default generateValidationSchema
