/* eslint-disable @typescript-eslint/typedef */
import axios from 'axios'
import cx from 'classnames'
import { BAD_REQUEST } from 'http-status-codes'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { IQuoteResponsePayload } from '@alteos/quotation-client/dist/interfaces/IQuoteResponsePayload'
import { formatFactory, IFormValues } from '@alteos/ui'

import { KeyValuePairItem } from '../../../../../components/KeyValuePairItem'
import ToastType from '../../../../../dictionaries/ToastType'
import { useAsyncLoader } from '../../../../../hooks/useAsyncLoader'
import useDebounce from '../../../../../hooks/useDebounce'
import { displayToast } from '../../../../Layout/store/actionCreators'
import { policyApi } from '../../../api/policyApi'
import { IQuotePolicyRequestPayload, IPolicy } from '../../../interfaces'
import { getQuotationPayload } from '../../../utils'

import styles from './style.module.scss'

interface IProps {
  formValues: IFormValues
  policy: IPolicy
}

const DEBOUNCE_TIMER = 500
const { formatEuroPrice } = formatFactory

export const PremiumRelatedSection: FC<IProps> = ({ formValues, policy }: IProps) => {
  const { t } = useTranslation('policy')
  const dispatch = useDispatch()
  const debouncedFormValues = useDebounce(formValues, DEBOUNCE_TIMER)
  const [initialQuotationResponse, setInitialQuotationResponse] = useState<IQuoteResponsePayload | null>(null)
  const { data: receivedQuotationResponse, isFetching } = useAsyncLoader(async () => {
    const getQuotationReq: IQuotePolicyRequestPayload = getQuotationPayload(debouncedFormValues, policy)
    try {
      return await policyApi.getPolicyQuotation(getQuotationReq)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === BAD_REQUEST) {
        dispatch(displayToast({ type: ToastType.Error, message: error.response.data.message }))
        return null
      } else {
        throw error
      }
    }
  }, [debouncedFormValues])

  useEffect(() => {
    if (receivedQuotationResponse !== null && initialQuotationResponse === null) {
      setInitialQuotationResponse(receivedQuotationResponse)
    }
  }, [initialQuotationResponse, receivedQuotationResponse])

  const premiumValues = getFormattedPremiumValues(initialQuotationResponse, receivedQuotationResponse, isFetching)

  // TODO: Use inline TextItem here from Cards component instead of KeyValuePairItem
  return (
    <div
      className={cx(styles.container, {
        [styles.isFetching]: isFetching
      })}
    >
      <KeyValuePairItem
        label={t('policyObjectForm.premiumRelated.currentPremium')}
        value={premiumValues.currentValue}
        dataTest="currentPremium"
      />
      <KeyValuePairItem
        label={t('policyObjectForm.premiumRelated.change')}
        value={premiumValues.changeValue}
        variant="bold"
        dataTest="change"
      />
      <KeyValuePairItem
        label={t('policyObjectForm.premiumRelated.newPremium')}
        value={premiumValues.newValue}
        variant="bold"
        dataTest="newPremium"
      />
      <div />
    </div>
  )
}

function getFormattedPremiumValues(
  initialQuotationResponse: IQuoteResponsePayload | null,
  receivedQuotationResponse: IQuoteResponsePayload | null,
  isFetching: boolean
) {
  if (initialQuotationResponse === null || isFetching) {
    return {
      newValue: null,
      changeValue: null,
      currentValue: null
    }
  }
  if (receivedQuotationResponse === null) {
    return {
      newValue: null,
      changeValue: null,
      currentValue: formatEuroPrice(initialQuotationResponse.gross)
    }
  }
  return {
    newValue: formatEuroPrice(receivedQuotationResponse.gross),
    changeValue: formatEuroPrice(receivedQuotationResponse.gross - initialQuotationResponse.gross),
    currentValue: formatEuroPrice(initialQuotationResponse.gross)
  }
}
