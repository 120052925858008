/* eslint-disable no-console */
import { toPath } from 'lodash'
import isNil from 'lodash/isNil'

import { ICondition } from '@alteos/ui'

import { ICardSection } from '../../../../components/Cards'
import { IFieldConfig } from '../../../../interfaces/common'
import { IConfigResponse } from './productConfigDto'

export function mapProductConfig(response: IConfigResponse): IConfigResponse {
  visitFields(response.configuration.claimsCards.individualClaimCard, 'individualClaimCard')
  visitFields(response.configuration.claimsCards.claimDetailsCards, 'claimDetailsCards')
  visitFields(response.configuration.detailsCards.objects, 'objects')
  visitFields(response.configuration.policyHolderCards?.individualClaimCard, 'individualClaimCard')
  visitFields(response.configuration.policyHolderCards?.policyHolderDetailsCards, 'policyHolderDetailsCards')
  return response
}

function visitFields(sections: ICardSection[], sectionName: string) {
  if (isNil(sections)) {
    console.warn(`Policy product is missing required section: "${sectionName}".`)
    return
  }
  for (const section of sections) {
    for (const field of section.fields) {
      if (!isNil(field.availableIf)) visitAvailableIf(field, field.availableIf)
    }
  }
}

function visitAvailableIf(field: IFieldConfig, availableIfConds: ICondition[]) {
  for (const conds of availableIfConds) {
    const path: string[] = toPath(conds.field)
    if (path[0] === 'addons' && !Number.isNaN(parseInt(path[1], 10))) {
      console.warn(`availableIf: "${JSON.stringify(field)}" addons use array notation, should use object notation.`)
    }
  }
}
