import { isNil, omit } from 'lodash'

import { addNewFilter, removeActiveFilter, resetFilters, setActiveFilter } from '../../../../components/Filters/utils'
import { IAction } from '../../../../interfaces/store'
import { LOGOUT } from '../../../Auth/constants'
import { DEFAULT_FILTERS, DEFAULT_PAGINATION, DEFAULT_SORTING } from '../../constants'
import {
  IPartnerQueryRequest,
  IReferralPartnersResponse,
  IReferralPartnersState
} from '../../interfaces/referralPartners'
import {
  ADD_FILTER_REFERRAL_PARTNER,
  LOAD_REFERRAL_PARTNERS_FAILURE,
  LOAD_REFERRAL_PARTNERS_REQUEST,
  LOAD_REFERRAL_PARTNERS_SUCCESS,
  REMOVE_FILTER_REFERRAL_PARTNER,
  RESET_FILTERS_REFERRAL_PARTNER,
  SET_FILTER_REFERRAL_PARTNER,
  ON_CHANGE_FILTERS_REFERRAL_PARTNER
} from '../constants'

export const initialState: IReferralPartnersState = {
  isFetchingReferralPartners: false,
  referralPartners: [],
  pagination: DEFAULT_PAGINATION,
  query: DEFAULT_SORTING,
  errorMessage: null,
  filters: DEFAULT_FILTERS
}

function reducer(state: IReferralPartnersState = initialState, action: IAction<any>): IReferralPartnersState {
  switch (action.type) {
    case LOAD_REFERRAL_PARTNERS_REQUEST: {
      const { payload }: Partial<IAction<IPartnerQueryRequest>> = action

      if (isNil(payload)) {
        return state
      }

      return {
        ...state,
        isFetchingReferralPartners: true,
        pagination: {
          ...state.pagination,
          ...omit(payload, 'query')
        },
        ...(isNil(payload.query)
          ? {}
          : {
              query: {
                ...state.query,
                ...payload.query
              }
            })
      }
    }

    case LOAD_REFERRAL_PARTNERS_SUCCESS: {
      const { payload }: IAction<IReferralPartnersResponse> = action
      return {
        ...state,
        isFetchingReferralPartners: false,
        referralPartners: payload.partnerRequests,
        pagination: {
          ...state.pagination,
          total: payload.totalCount
        }
      }
    }

    case LOAD_REFERRAL_PARTNERS_FAILURE: {
      return {
        ...state,
        isFetchingReferralPartners: false,
        pagination: DEFAULT_PAGINATION
      }
    }

    case ON_CHANGE_FILTERS_REFERRAL_PARTNER:
      return {
        ...state,
        pagination: DEFAULT_PAGINATION
      }

    case ADD_FILTER_REFERRAL_PARTNER:
      return {
        ...state,
        filters: addNewFilter(state.filters!, action.payload)
      }

    case SET_FILTER_REFERRAL_PARTNER:
      return {
        ...state,
        filters: setActiveFilter(state.filters!, action.payload)
      }

    case REMOVE_FILTER_REFERRAL_PARTNER:
      return {
        ...state,
        filters: removeActiveFilter(state.filters!, action.payload)
      }

    case RESET_FILTERS_REFERRAL_PARTNER:
      return {
        ...state,
        filters: resetFilters(state.filters!)
      }

    case LOGOUT: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
