import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { Button } from '@alteos/ui'

import { WarningIcon } from '../../../../../components/Icons/icons'
import { Modal } from '../../../../../components/Modal/Modal'
import { ProcessingType } from '../../../../../dictionaries'
import { closeModal } from '../../../../Modal/store/actionCreators'
import { ModalId } from '../../../../Modal/store/interfaces'
import { IEndorsementModalResult } from '../../../interfaces'
import { setPurchase } from '../../../store/actionCreators'
import { getModalId } from '../../../store/selectors'

interface IProps {
  modalId: ModalId
}

export const NonSepaEndorsementModal: FC<IProps> = ({ modalId }: IProps) => {
  const [tp]: UseTranslationResponse<'policy'> = useTranslation('policy')
  const [tc]: UseTranslationResponse<'common'> = useTranslation('common')
  const dispatch: Dispatch<any> = useDispatch()
  const currentModalId: ModalId | null = useSelector(getModalId)
  const isOpen: boolean = currentModalId === modalId

  return (
    <Modal.Root variant="warning" isOpen={isOpen} modalId={modalId}>
      <Modal.Header icon={<WarningIcon />} />
      <Modal.Content>
        <p>{tp('endorsement.nonSepaModalWarning')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          small={true}
          noMinWidth={true}
          color="tertiary"
          dataTest="modal-endorse-cancel"
          handleClick={() => {
            const result: IEndorsementModalResult = {
              paymentDetailsUpdate: null
            }
            dispatch(closeModal(result))
            dispatch(setPurchase(null))
          }}
        >
          {tc('phrases.cancel')}
        </Button>
        <Button
          small={true}
          noMinWidth={true}
          color="primary"
          dataTest="modal-endorse-confirm"
          handleClick={() => {
            const result: IEndorsementModalResult = {
              paymentDetailsUpdate: {
                payment: {
                  processingType: ProcessingType.invoice,
                  paymentSchedule: {}
                }
              }
            }
            dispatch(closeModal(result))
            dispatch(setPurchase(null))
          }}
        >
          {tc('phrases.confirm')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  )
}
