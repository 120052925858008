import { AUTH_CLEAR_ERROR, LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS, LOGOUT, REFRESH_TOKEN } from '../constants'
import {
  IAuthClearErrorAction,
  ILoginFailAction,
  ILoginStartAction,
  ILoginSuccessAction,
  ILogoutAction,
  IRefreshTokenAction
} from '../interfaces'

export function loginStart(payload: ILoginStartAction['payload']): ILoginStartAction {
  return {
    type: LOGIN_START,
    payload
  }
}

export function loginSuccess(payload: ILoginSuccessAction['payload']): ILoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    payload
  }
}

export function loginFail(payload: ILoginFailAction['payload']): ILoginFailAction {
  return {
    type: LOGIN_FAILURE,
    payload
  }
}

export function logout(): ILogoutAction {
  return {
    type: LOGOUT,
    payload: {}
  }
}

export function refreshToken(payload: IRefreshTokenAction['payload']): IRefreshTokenAction {
  return {
    type: REFRESH_TOKEN,
    payload
  }
}

export function clearAuthError(): IAuthClearErrorAction {
  return {
    type: AUTH_CLEAR_ERROR,
    payload: {}
  }
}
