import isEqual from 'lodash/isEqual'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { FormErrors } from 'redux-form'

import { ToastType } from '@alteos/ui'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { FormData } from '../../../../../components/Form/ReduxForm'
import { IStore } from '../../../../../interfaces/store'
import { displayToast } from '../../../../Layout/store/actionCreators'
import { partnerApi } from '../../../api/partnerApi'
import { MODULE_NAME } from '../../../constants'
import { IReferralPartnerProduct } from '../../../interfaces'
import { IReferralPartner } from '../../../interfaces/referralPartner'
import { setSideView, updateReferralPartnerStart } from '../../../store/actions/referralPartner'
import mapReferralPartner from '../../../store/utils/mapReferralPartner'
import { asyncValidateReferralPartnerForm } from '../utils/asyncValidateReferralPartnerForm'
import generateUpdatePartnerCards from '../utils/generateUpdatePartnerCards'
import { shouldAsyncValidateReferralPartnerForm } from '../utils/shouldAsyncValidate'
import { validateUpdateReferralPartnerForm } from '../utils/validateUpdateReferralPartnerForm'

export const UpdatePartnerForm = (): ReactElement => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')
  const [disabledButtonSaveUpdatePartnerForm, setDisabledButtonSaveUpdatePartnerForm] = useState(true)
  const [allProductsConfiguration, setAllProductsConfiguration] = useState<IReferralPartnerProduct[]>([])

  const dispatch: Dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await partnerApi.getProductsConfiguration()
      setAllProductsConfiguration(response)
    }
    try {
      void fetchData()
    } catch (e) {
      dispatch(
        displayToast({
          type: ToastType.Error,
          message: 'Failed to fetch products'
        })
      )
    }
  }, [dispatch])

  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )

  const isUpdatingReferralPartner: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.isUpdatingReferralPartner
  )

  const completeReferralPartner: IReferralPartner = mapReferralPartner(referralPartner)
  let updatePartnerFormConfig: IFormConfig = generateUpdatePartnerCards(
    t,
    completeReferralPartner,
    allProductsConfiguration,
    disabledButtonSaveUpdatePartnerForm,
    dispatch
  )

  const handleDataChangeUpdateProductFormProps = function (initValues: { [key: string]: string }, _: any, props: any) {
    const isObjectEqual = isEqual(initValues, props.initialValues)
    setDisabledButtonSaveUpdatePartnerForm(isObjectEqual)
  }

  const handleOnSubmitUpdateProductFormProps = function () {
    setDisabledButtonSaveUpdatePartnerForm(true)
    dispatch(updateReferralPartnerStart(updatePartnerFormConfig))
  }

  const updatePartnerFormProps: IFormProps = {
    ...updatePartnerFormConfig,
    validate: validateUpdateReferralPartnerForm as (values: Record<string, any>) => FormErrors<FormData, string>,
    asyncValidate: asyncValidateReferralPartnerForm as any,
    asyncChangeFields: ['subdomain'],
    source: completeReferralPartner,
    hasFormButtons: true,
    isSubmitting: isUpdatingReferralPartner,
    formType: FormType.EDIT,
    dataTest: 'referralpartner-product-edit-form',
    shouldAsyncValidate: shouldAsyncValidateReferralPartnerForm,
    onClose: () => dispatch(setSideView(null)),
    onDataChange: handleDataChangeUpdateProductFormProps,
    onSubmit: handleOnSubmitUpdateProductFormProps
  }

  return <Form {...updatePartnerFormProps} />
}
