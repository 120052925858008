import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import LanguageSelect from '../../../../components/LanguageSelect'
import LogoButton from '../../../../components/LogoButton'
import { useOnMountEffect } from '../../../../hooks/useOnMountEffect'
import { requireString } from '../../../../utils'
import { Advertise, LoginForm } from '../../components'
import { loginStart, logout } from '../../store/actionCreators'
import { getIsUserAuthenticated } from '../../store/selectors'

import styles from './login.module.scss'

interface IRedirectState {
  redirectTo?: string
}

export const LoginPage: FC = () => {
  const isAuthenticated = useSelector(getIsUserAuthenticated)
  const location = useLocation()
  const [t]: UseTranslationResponse<'login'> = useTranslation('login')
  const redirectToState = (location.state as IRedirectState) ?? {}
  const redirectTo: string = redirectToState.redirectTo ?? '/'
  const dispatch = useDispatch()

  useOnMountEffect(() => {
    // NOTE: Opening up the /login page while being currently authenticated will result in a sign-out
    if (isAuthenticated) {
      dispatch(logout())
    }
  })

  if (isAuthenticated) {
    return <Navigate to={redirectTo} />
  }

  function handleSubmit(formValues: Record<string, unknown>) {
    const username = requireString(formValues.username, 'formValues.username')
    const password = requireString(formValues.password, 'formValues.password')
    dispatch(loginStart({ username, password }))
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <LogoButton />
        <div className={styles.form}>
          <div className={styles.title} data-test="login-title">
            {t('login.title')}
          </div>
          <LoginForm onSubmit={handleSubmit} />
        </div>
        <div className={styles.footer}>
          <div className={styles.copyright}>© 2023 Alteos GmbH</div>
          <LanguageSelect />
        </div>
      </div>
      <Advertise />
    </div>
  )
}
