import cx from 'classnames'
import { FC, ReactNode } from 'react'

import { IBasicProps } from '../../interfaces/common'

import styles from './tag.module.scss'

interface IProps extends IBasicProps {
  children: ReactNode
}

const Tag: FC<IProps> = ({ children, className = '', dataTest }: IProps) => (
  <div className={cx(styles.container, className)} data-test={dataTest}>
    {children}
  </div>
)

export default Tag
