import { IRequestPagination } from '@alteos/ui'

import { addNewFilter, removeActiveFilter, resetFilters, setActiveFilter } from '../../../../components/Filters/utils'
import { IAction } from '../../../../interfaces/store'
import { LOGOUT } from '../../../Auth/constants'
import { DEFAULT_FILTERS, DEFAULT_PAGINATION } from '../../constants'
import {
  IDisputedPayments,
  IDisputedPaymentsState,
  IDisputedSetSideViewPayload,
  ISelectedMultiplePayment,
  ISelectedPayment
} from '../../interfaces'
import {
  ADD_FILTER_DISPUTED_PAYMENTS,
  CHANGE_IBAN_FAILURE,
  CHANGE_IBAN_REQUEST,
  CHANGE_IBAN_SUCCESS,
  LOAD_DISPUTED_PAYMENTS_FAILURE,
  LOAD_DISPUTED_PAYMENTS_REQUEST,
  LOAD_DISPUTED_PAYMENTS_SUCCESS,
  ON_CHANGE_FILTERS_DISPUTED_PAYMENTS,
  REMOVE_FILTER_DISPUTED_PAYMENTS,
  RESET_FILTERS_DISPUTED_PAYMENTS,
  RESOLVE_DISPUTED_PAYMENT_CANCELLED,
  RESOLVE_DISPUTED_PAYMENT_FAILURE,
  RESOLVE_DISPUTED_PAYMENT_INITIATED,
  RESOLVE_DISPUTED_PAYMENT_REQUEST,
  RESOLVE_DISPUTED_PAYMENT_SUCCESS,
  RESOLVE_MULTIPLE_DISPUTED_PAYMENT_INITIATED,
  RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SET_FILTER_DISPUTED_PAYMENTS
} from '../constants'

export const initialState: IDisputedPaymentsState = {
  isFetchingDisputedPayments: false,
  isResolvingDisputedPayment: false,
  isChangingIBAN: false,
  activeSideViewType: null,
  resolvingDisputedPaymentInitiated: false,
  resolvingMultipleDisputedPaymentInitiated: false,
  disputedPayments: [],
  selectedPayment: null,
  pagination: DEFAULT_PAGINATION,
  hasMore: true,
  errorMessage: null,
  filters: DEFAULT_FILTERS
}

function reducer(state: IDisputedPaymentsState = initialState, action: IAction<any>): IDisputedPaymentsState {
  switch (action.type) {
    case LOAD_DISPUTED_PAYMENTS_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }

      return {
        ...state,
        isFetchingDisputedPayments: true,
        pagination: {
          ...state.pagination,
          limit: payload.limit,
          offset: payload.offset
        },
        search: payload.query
      }
    }

    case LOAD_DISPUTED_PAYMENTS_SUCCESS: {
      const { payload }: IAction<IDisputedPayments[]> = action

      return {
        ...state,
        isFetchingDisputedPayments: false,
        hasMore: payload.length >= state.pagination.limit,
        disputedPayments: state.pagination.offset === 0 ? payload : [...state.disputedPayments, ...payload],
        pagination: {
          ...state.pagination
        }
      }
    }

    case LOAD_DISPUTED_PAYMENTS_FAILURE: {
      return {
        ...state,
        isFetchingDisputedPayments: false,
        pagination: DEFAULT_PAGINATION
      }
    }

    case ON_CHANGE_FILTERS_DISPUTED_PAYMENTS:
      return {
        ...state,
        pagination: DEFAULT_PAGINATION
      }

    case ADD_FILTER_DISPUTED_PAYMENTS:
      return {
        ...state,
        filters: addNewFilter(state.filters!, action.payload)
      }

    case SET_FILTER_DISPUTED_PAYMENTS:
      return {
        ...state,
        filters: setActiveFilter(state.filters!, action.payload)
      }

    case REMOVE_FILTER_DISPUTED_PAYMENTS:
      return {
        ...state,
        filters: removeActiveFilter(state.filters!, action.payload)
      }

    case RESET_FILTERS_DISPUTED_PAYMENTS:
      return {
        ...state,
        filters: resetFilters(state.filters!)
      }

    case RESOLVE_DISPUTED_PAYMENT_INITIATED: {
      const { payload }: IAction<ISelectedPayment> = action

      return {
        ...state,
        resolvingDisputedPaymentInitiated: true,
        selectedPayment: {
          policyId: payload.policyId,
          policyPrettyId: payload.policyPrettyId,
          transactionId: payload.transactionId
        }
      }
    }

    case RESOLVE_MULTIPLE_DISPUTED_PAYMENT_INITIATED: {
      const { payload }: IAction<ISelectedMultiplePayment> = action
      return {
        ...state,
        resolvingMultipleDisputedPaymentInitiated: true,
        selectedPayment: {
          policyId: payload.policyId,
          policyPrettyId: payload.policyPrettyId,
          transactionId: payload.transactionIds.join(',')
        }
      }
    }

    case RESOLVE_DISPUTED_PAYMENT_CANCELLED: {
      return {
        ...state,
        resolvingDisputedPaymentInitiated: false,
        resolvingMultipleDisputedPaymentInitiated: false,
        selectedPayment: null
      }
    }

    case RESOLVE_DISPUTED_PAYMENT_REQUEST: {
      return {
        ...state,
        isResolvingDisputedPayment: true,
        selectedPayment: null,
        resolvingDisputedPaymentInitiated: false
      }
    }

    case RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST: {
      return {
        ...state,
        isResolvingDisputedPayment: true,
        selectedPayment: null,
        resolvingMultipleDisputedPaymentInitiated: false
      }
    }

    case RESOLVE_DISPUTED_PAYMENT_SUCCESS: {
      const { payload }: IAction<{ policyId: string }> = action

      return {
        ...state,
        disputedPayments: state.disputedPayments.filter((payment) => payment.policyId !== payload.policyId),
        isResolvingDisputedPayment: false
      }
    }

    case RESOLVE_DISPUTED_PAYMENT_FAILURE: {
      return {
        ...state,
        isResolvingDisputedPayment: false
      }
    }

    case SET_ACTIVE_SIDE_VIEW_TYPE: {
      const { payload }: IAction<IDisputedSetSideViewPayload | null> = action
      if (payload === null) {
        return {
          ...state,
          activeSideViewType: null,
          selectedPayment: null
        }
      }

      return {
        ...state,
        activeSideViewType: payload.type,
        selectedPayment: payload.params
      }
    }

    case CHANGE_IBAN_REQUEST: {
      return {
        ...state,
        isChangingIBAN: true
      }
    }

    case CHANGE_IBAN_SUCCESS: {
      return {
        ...state,
        isChangingIBAN: false
      }
    }

    case CHANGE_IBAN_FAILURE: {
      return {
        ...state,
        isChangingIBAN: false
      }
    }

    case LOGOUT: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
