import { AxiosResponse } from 'axios'

import { IRequestPagination } from '@alteos/ui'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { IPartnersResponse } from '../interfaces'

export class PartnersApi {
  constructor(private api: Api) {}

  getPartners = async ({ offset, limit, query }: IRequestPagination): Promise<IPartnersResponse> => {
    const endpoiont = `/v1/partners?offset=${offset}&limit=${limit}&query=${query}`

    const response: AxiosResponse<IPartnersResponse> = await this.api.get(endpoiont)
    return response.data
  }
}

export const partnersApi: PartnersApi = new PartnersApi(defaultApiInstance)
