import { FormState } from 'redux-form'
import { call, put, select } from 'typed-redux-saga'

import { ToastType } from '@alteos/ui'

import { IFormConfig } from '../../../../components/Form/interfaces'
import { IAction, IStore } from '../../../../interfaces/store'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils'
import { actions as layoutActions } from '../../../Layout'
import { partnerApi } from '../../api/partnerApi'
import { referralPartnerApi } from '../../api/referralPartnerApi'
import { MODULE_NAME } from '../../constants'
import {
  IProductsResponse,
  IReferralPartner,
  IReferralPartnerResponse,
  IReferralPartnerUpdateRequest
} from '../../interfaces'
import {
  approveReferralPartnerFailure,
  approveReferralPartnerSuccess,
  getReferralPartnerLogFailure,
  getReferralPartnerLogStart,
  getReferralPartnerLogSuccess,
  loadProductsReferralPartnerFailure,
  loadProductsReferralPartnerSuccess,
  loadReferralPartnerFailure,
  loadReferralPartnerStart,
  loadReferralPartnerSuccess,
  rejectReferralPartnerFailure,
  rejectReferralPartnerSuccess,
  saveErrorMessage,
  terminateReferralPartnerFailure,
  terminateReferralPartnerSuccess,
  updateReferralPartnerFailure,
  updateReferralPartnerSuccess
} from '../actions/referralPartner'
import mapReferralPartner from '../utils/mapReferralPartner'
import { prepareUpdateReferralPartnerBody } from '../utils/prepareUpdateReferralPartnerBody'

export function* loadReferralPartner({ payload }: IAction<{ partnerId: string }>): Generator {
  try {
    const response: IReferralPartnerResponse = yield* call(partnerApi.getReferralPartnerRequest, payload.partnerId)
    const referralPartner = mapReferralPartner(response.data) as IReferralPartner
    yield* put(loadReferralPartnerSuccess(referralPartner))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch referral partner details', error))
    yield* put(loadReferralPartnerFailure())
  }
}

export function* loadReferralPartnerLog({ payload }: IAction<{ partnerId: string }>): Generator {
  try {
    const response = yield* call(partnerApi.getPartnerLog, payload.partnerId)
    const sortedLogs = response.sort(
      (a, b) => new Date(b.createdAt as unknown as Date).getTime() - new Date(a.createdAt as unknown as Date).getTime()
    )
    yield* put(getReferralPartnerLogSuccess(sortedLogs))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch referral partner log', error))
    yield* put(getReferralPartnerLogFailure())
  }
}

export function* loadProductsReferralPartner(): Generator {
  try {
    const response: IProductsResponse = yield* call(partnerApi.getProductsReferralPartnerRequest)
    yield* put(loadProductsReferralPartnerSuccess(response.data.products))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch referral partner details', error))
    yield* put(loadProductsReferralPartnerFailure())
  }
}

export function* approveReferralPartner({ payload }: IAction<IFormConfig>): Generator {
  const { successMessage }: IFormConfig = payload

  const selectedRequestId: string | undefined = (yield* select(
    (state: IStore) => state[MODULE_NAME].referralPartnerState?.referralPartner.id
  )) as string

  const formName: string = payload.form
  const formValues: FormState = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const { values }: { [fieldName: string]: any } | undefined = formValues
  const body = { notes: values['notes'] }

  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(referralPartnerApi.approveReferralPartner, selectedRequestId, body)
    yield* put(getReferralPartnerLogStart(selectedRequestId))
  } catch (error) {
    yield* put(approveReferralPartnerFailure())
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* triggerErrorPopup(error)
    return
  }

  yield* put(loadReferralPartnerStart(selectedRequestId))
  yield* put(approveReferralPartnerSuccess())
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
  yield* put(layoutActions.setSideViewIsProcessingData(false))

  return
}

export function* updateReferralPartner({ payload }: IAction<IFormConfig>): Generator {
  const { successMessage }: IFormConfig = payload

  const formName: string = payload.form

  const formValues: FormState = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const { values }: { [fieldName: string]: any } | undefined = formValues

  const partnerId: string = values.id

  const body: IReferralPartnerUpdateRequest = prepareUpdateReferralPartnerBody(values)
  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(referralPartnerApi.updateReferralPartner, partnerId, body)
    yield* put(getReferralPartnerLogStart(partnerId))
  } catch (error) {
    yield* put(updateReferralPartnerFailure())
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* triggerErrorPopup(error)
    return
  }

  yield* put(loadReferralPartnerStart(partnerId))

  yield* put(updateReferralPartnerSuccess())
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
  yield* put(layoutActions.setSideViewIsProcessingData(false))

  return
}

export function* rejectReferralPartner({ payload }: IAction<IFormConfig>): Generator {
  const { successMessage }: IFormConfig = payload

  const selectedRequestId: string | undefined = (yield* select(
    (state: IStore) => state[MODULE_NAME].referralPartnerState.referralPartner.id
  )) as string

  const formName: string = payload.form
  const formValues: FormState = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const { values }: { [fieldName: string]: any } | undefined = formValues
  const body = { notes: values['notes'] }

  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(referralPartnerApi.rejectReferralPartner, selectedRequestId, body)
    yield* put(getReferralPartnerLogStart(selectedRequestId))
  } catch (error) {
    yield* put(rejectReferralPartnerFailure())
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* triggerErrorPopup(error)
    return
  }

  yield* put(loadReferralPartnerStart(selectedRequestId))
  yield* put(rejectReferralPartnerSuccess())
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
  yield* put(layoutActions.setSideViewIsProcessingData(false))

  return
}

export function* terminateReferralPartner({ payload }: IAction<IFormConfig>): Generator {
  const { successMessage }: IFormConfig = payload

  const selectedRequestId: string | undefined = (yield* select(
    (state: IStore) => state[MODULE_NAME].referralPartnerState.referralPartner.id
  )) as string

  const formName: string = payload.form
  const formValues: FormState = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const { values }: { [fieldName: string]: any } | undefined = formValues
  const body = { notes: values['notes'] }

  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(referralPartnerApi.terminateReferralPartner, selectedRequestId, body)
    yield* put(getReferralPartnerLogStart(selectedRequestId))
  } catch (error) {
    yield* put(terminateReferralPartnerFailure())
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* triggerErrorPopup(error)
    return
  }

  yield* put(loadReferralPartnerStart(selectedRequestId))
  yield* put(terminateReferralPartnerSuccess())
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
  yield* put(layoutActions.setSideViewIsProcessingData(false))

  return
}
