import { LabelType } from '../../../../../components/Label/dictionaries'
import { ClaimStatus } from '../../../../../dictionaries'

function generateClaimLabelType(value: ClaimStatus | null): LabelType {
  switch (value) {
    case ClaimStatus.Closed:
    case ClaimStatus.Reclosed:
    case ClaimStatus.Rejected:
      return LabelType.neutral
    case ClaimStatus.InRecourse:
    case ClaimStatus.Approved:
      return LabelType.success
    case ClaimStatus.InProgress:
      return LabelType.success
    case ClaimStatus.Created:
    case ClaimStatus.Reopened:
    case ClaimStatus.PartiallyApproved:
      return LabelType.info
    default:
      return LabelType.neutral
  }
}

export default generateClaimLabelType
