/* eslint-disable @typescript-eslint/typedef */
import { MODULE_NAME } from '../constants'

export const SET_ACTIVE_PARTNER_TAB = `SET_ACTIVE_${MODULE_NAME}_TAB` as const

export const LOAD_REFERRAL_PARTNER_REQUEST = `${MODULE_NAME}_LOAD_REFERRAL_PARTNER_REQUEST` as const
export const LOAD_REFERRAL_PARTNER_SUCCESS = `${MODULE_NAME}_LOAD_REFERRAL_PARTNER_SUCCESS` as const
export const LOAD_REFERRAL_PARTNER_FAILURE = `${MODULE_NAME}_LOAD_REFERRAL_PARTNER_FAILURE` as const

export const LOAD_PRODUCTS_REQUEST = `${MODULE_NAME}_LOAD_PRODUCTS_REQUEST` as const
export const LOAD_PRODUCTS_SUCCESS = `${MODULE_NAME}_LOAD_PRODUCTS_SUCCESS` as const
export const LOAD_PRODUCTS_FAILURE = `${MODULE_NAME}_LOAD_PRODUCTS_FAILURE` as const

export const LOAD_PARTNER_REQUEST = `${MODULE_NAME}_LOAD_PARTNER_REQUEST` as const
export const LOAD_PARTNER_SUCCESS = `${MODULE_NAME}_LOAD_PARTNER_SUCCESS` as const
export const LOAD_PARTNER_FAILURE = `${MODULE_NAME}_LOAD_PARTNER_FAILURE` as const

export const LOAD_GROUPS_REQUEST = `${MODULE_NAME}_LOAD_GROUPS_REQUEST` as const
export const LOAD_GROUPS_SUCCESS = `${MODULE_NAME}_LOAD_GROUPS_SUCCESS` as const
export const LOAD_GROUPS_FAILURE = `${MODULE_NAME}_LOAD_GROUPS_FAILURE` as const

export const UPDATE_GROUP_REQUEST = `${MODULE_NAME}_UPDATE_GROUP_REQUEST` as const
export const UPDATE_GROUP_SUCCESS = `${MODULE_NAME}_UPDATE_GROUP_SUCCESS` as const
export const UPDATE_GROUP_FAILURE = `${MODULE_NAME}_UPDATE_GROUP_FAILURE` as const

export const LOAD_PERMISSIONS_REQUEST = `${MODULE_NAME}_LOAD_PERMISSIONS_REQUEST` as const
export const LOAD_PERMISSIONS_SUCCESS = `${MODULE_NAME}_LOAD_PERMISSIONS_SUCCESS` as const
export const LOAD_PERMISSIONS_FAILURE = `${MODULE_NAME}_LOAD_PERMISSIONS_FAILURE` as const

export const APPROVE_REFERRAL_PARTNER_REQUEST = `${MODULE_NAME}_APPROVE_REFERRAL_PARTNER_REQUEST` as const
export const APPROVE_REFERRAL_PARTNER_SUCCESS = `${MODULE_NAME}_APPROVE_REFERRAL_PARTNER_SUCCESS` as const
export const APPROVE_REFERRAL_PARTNER_FAILURE = `${MODULE_NAME}_APPROVE_REFERRAL_PARTNER_FAILURE` as const

export const SET_ACTIVE_SIDE_VIEW_TYPE = `${MODULE_NAME}_SET_SIDE_VIEW` as const

export const REJECT_REFERRAL_PARTNER_REQUEST = `${MODULE_NAME}_REJECT_REFERRAL_PARTNER_REQUEST` as const
export const REJECT_REFERRAL_PARTNER_SUCCESS = `${MODULE_NAME}_REJECT_REFERRAL_PARTNER_SUCCESS` as const
export const REJECT_REFERRAL_PARTNER_FAILURE = `${MODULE_NAME}_REJECT_REFERRAL_PARTNER_FAILURE` as const

export const TERMINATE_REFERRAL_PARTNER_REQUEST = `${MODULE_NAME}_TERMINATE_REFERRAL_PARTNER_REQUEST` as const
export const TERMINATE_REFERRAL_PARTNER_SUCCESS = `${MODULE_NAME}_TERMINATE_REFERRAL_PARTNER_SUCCESS` as const
export const TERMINATE_REFERRAL_PARTNER_FAILURE = `${MODULE_NAME}_TERMINATE_REFERRAL_PARTNER_FAILURE` as const

export const UPDATE_REFERRAL_PARTNER_REQUEST = `${MODULE_NAME}_UPDATE_REFERRAL_PARTNER_REQUEST` as const
export const UPDATE_REFERRAL_PARTNER_SUCCESS = `${MODULE_NAME}_UPDATE_REFERRAL_PARTNER_SUCCESS` as const
export const UPDATE_REFERRAL_PARTNER_FAILURE = `${MODULE_NAME}_UPDATE_REFERRAL_PARTNER_FAILURE` as const

export const GET_REFERRAL_PARTNER_LOG_REQUEST = `${MODULE_NAME}_GET_REFERRAL_PARTNER_LOG_REQUEST` as const
export const GET_REFERRAL_PARTNER_LOG_SUCCESS = `${MODULE_NAME}_GET_REFERRAL_PARTNER_LOG_SUCCESS` as const
export const GET_REFERRAL_PARTNER_LOG_FAILURE = `${MODULE_NAME}_GET_REFERRAL_PARTNER_LOG_FAILURE` as const

export const GET_PARTNER_PORTAL_ROLES_REQUEST = `${MODULE_NAME}_GET_PARTNER_PORTAL_ROLES_REQUEST` as const
export const GET_PARTNER_PORTAL_ROLES_SUCCESS = `${MODULE_NAME}_GET_PARTNER_PORTAL_ROLES_SUCCESS` as const
export const GET_PARTNER_PORTAL_ROLES_FAILURE = `${MODULE_NAME}_GET_PARTNER_PORTAL_ROLES_FAILURE` as const

export const CREATE_PARTNER_PORTAL_USER_REQUEST = `${MODULE_NAME}_CREATE_PARTNER_PORTAL_USER_REQUEST` as const
export const CREATE_PARTNER_PORTAL_USER_SUCCESS = `${MODULE_NAME}_CREATE_PARTNER_PORTAL_USER_SUCCESS` as const
export const CREATE_PARTNER_PORTAL_USER_FAILURE = `${MODULE_NAME}_CREATE_PARTNER_PORTAL_USER_FAILURE` as const

export const IMPORT_PARTNER_PORTAL_USERS_REQUEST = `${MODULE_NAME}_IMPORT_PARTNER_PORTAL_USERS_REQUEST` as const
export const IMPORT_PARTNER_PORTAL_USERS_SUCCESS = `${MODULE_NAME}_IMPORT_PARTNER_PORTAL_USERS_SUCCESS` as const
export const IMPORT_PARTNER_PORTAL_USERS_FAILURE = `${MODULE_NAME}_IMPORT_PARTNER_PORTAL_USERS_FAILURE` as const

export const GET_PARTNER_PORTAL_USERS_REQUEST = `${MODULE_NAME}_GET_PARTNER_PORTAL_USERS_REQUEST` as const
export const GET_PARTNER_PORTAL_USERS_SUCCESS = `${MODULE_NAME}_GET_PARTNER_PORTAL_USERS_SUCCESS` as const
export const GET_PARTNER_PORTAL_USERS_FAILURE = `${MODULE_NAME}_GET_PARTNER_PORTAL_USERS_FAILURE` as const
