import { IAction } from '../../../../interfaces/store'
import { IPermissionsResponse, IPermissionsState } from '../../interfaces/permissions'
import { LOAD_PERMISSIONS_FAILURE, LOAD_PERMISSIONS_REQUEST, LOAD_PERMISSIONS_SUCCESS } from '../constants'

export const initialState: IPermissionsState = {
  isFetchingPermissions: false,
  permissions: {},
  errorMessage: null
}

function reducer(state: IPermissionsState = initialState, action: IAction<any>): IPermissionsState {
  switch (action.type) {
    case LOAD_PERMISSIONS_REQUEST: {
      return {
        ...state,
        isFetchingPermissions: true
      }
    }

    case LOAD_PERMISSIONS_SUCCESS: {
      const { payload }: IAction<IPermissionsResponse> = action
      return {
        ...state,
        permissions: payload
      }
    }

    case LOAD_PERMISSIONS_FAILURE: {
      return {
        ...state,
        isFetchingPermissions: false
      }
    }

    default:
      return state
  }
}

export default reducer
