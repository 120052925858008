import { InputType } from '@alteos/ui'

import { IDatePickerValues, IFilter } from '../../../../components/Filters/interfaces'
import { IFilterPayload } from '../../interfaces'

export const getFilterPayload = (filters: IFilter[] | undefined): IFilterPayload | undefined => {
  if (!filters) return

  let filterPayload: IFilterPayload | undefined

  const activeFilters = filters!.filter((item: IFilter) => item.selectedFilter !== '')
  if (activeFilters.length > 0) {
    activeFilters.forEach((activeFilter: IFilter) => {
      if (activeFilter.componentType === InputType.datePickerRange) {
        filterPayload = {
          ...filterPayload,
          startDate: new Date((activeFilter.selectedFilter as IDatePickerValues).fromDate).getTime(),
          endDate: new Date((activeFilter.selectedFilter as IDatePickerValues).toDate).getTime()
        }
      }
    })

    return filterPayload
  }
}
