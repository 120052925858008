import i18next from 'i18next'
import isNil from 'lodash/isNil'

import { formatFactory } from '@alteos/ui'

import { ICardSection } from '../../../components/Cards'
import { FieldType } from '../../../dictionaries'
import { IFieldConfig } from '../../../interfaces/common'
import { isViewField } from '../../../interfaces/guards'
import { IUiConfig } from '../api/productConfigApi'

function extendUiConfig(config: IUiConfig): void {
  const { formatCamelCase, formatEuroPrice } = formatFactory
  config.detailsCards.objects.forEach((item: ICardSection) => {
    item.fields.forEach((field: IFieldConfig) => {
      if (isViewField(field) && field.type === FieldType.Tags) {
        field.tagsColumns = [
          {
            Header: i18next.t('policy:risks.name'),
            accessor: (originalRow: any) => {
              let value = ''

              if (!isNil(originalRow.name)) {
                value = originalRow.name
              }
              return formatCamelCase(value)
            }
          },
          {
            Header: i18next.t('policy:risks.sumInsured'),
            accessor: (originalRow: any, _index: number) => {
              let value = ''

              if (!isNil(originalRow.sumInsuredPerIncident)) {
                value = originalRow.sumInsuredPerIncident
              }
              if (!isNil(originalRow.sumInsuredPerContract)) {
                value = originalRow.sumInsuredPerContract
              }
              if (!isNil(originalRow.sumInsuredPerYear)) {
                value = originalRow.sumInsuredPerYear
              }
              return formatEuroPrice(value)
            }
          },
          {
            Header: i18next.t('policy:risks.deductible'),
            accessor: (originalRow: any, _index: number) => {
              let value = ''

              if (!isNil(originalRow.deductiblePerIncident)) {
                value = originalRow.deductiblePerIncident
              }
              if (!isNil(originalRow.deductiblePerContract)) {
                value = originalRow.deductiblePerContract
              }
              if (!isNil(originalRow.deductiblePerYear)) {
                value = originalRow.deductiblePerYear
              }
              return formatEuroPrice(value)
            }
          }
        ]
      }
    })
  })
}

export default extendUiConfig
