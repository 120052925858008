/* eslint-disable @typescript-eslint/typedef */
import { FormEvent, useRef, useState, FC, useLayoutEffect } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'

import { ICardSection } from '../Cards'
import { IFormProps, IProvidedFormProps } from './Form'
import FormButtons from './FormButtons'
import FormSection from './FormSection'
import { isContainerVisible, isLongForm } from './utils'

// NOTE: helper type, used to merge your props with Redux Provided Props
type InjectReduxProps<P, FD> = InjectedFormProps<FD, P> & P

// NOTE: These are the props you will have to pass to the form
export type Props = IFormProps & IProvidedFormProps

export type FormData = Record<string, unknown>

type AllProps = InjectReduxProps<Props, FormData>

export const UnconnectedForm: FC<AllProps> = ({
  handleSubmit,
  onClose,
  onInvalidFieldsError,
  onFieldChange,
  form: formName,
  formState,
  dataTest,
  formSections,
  source,
  helpers,
  submitting,
  isSubmittingExternal,
  isFullWidth,
  formType,
  hasFormButtons,
  invalid,
  pristine
}) => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const formButtonsContainerRef = useRef<HTMLDivElement | null>(null)
  const [isFloatingButtons, setIsFloatingButtons] = useState(false)

  const formId = `form-${formName}`

  useFloatActionButtonsOnScroll(setIsFloatingButtons, formButtonsContainerRef)

  if (formState === null) {
    // TODO: investigate why we are sometimes rendering un-initilized forms.
    return <div data-test={`empty-form-${formId}`} />
  }

  const formValues = formState.values

  function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (invalid) {
      onInvalidFieldsError()
    }
    handleSubmit(event)
  }
  return (
    <form id={formId} onSubmit={handleOnSubmit} noValidate ref={formRef} data-test={dataTest}>
      {formSections.map((section: ICardSection, index: number) => (
        <FormSection
          {...section}
          key={index}
          formValues={formValues}
          source={source}
          helpers={helpers}
          isSubmitting={submitting || isSubmittingExternal}
          reduxFieldChange={onFieldChange}
          formType={formType}
          isFullWidth={isFullWidth}
        />
      ))}
      <div ref={formButtonsContainerRef}>
        {hasFormButtons === true ? null : (
          <FormButtons
            closeModal={onClose}
            isSubmitting={submitting || isSubmittingExternal}
            isPristine={pristine}
            isFloating={isLongForm(formRef?.current) && isFloatingButtons && !pristine}
          />
        )}
      </div>
    </form>
  )
}
// NOTE: workaround for issues around HMR...
// redux-form is just bad and outdated
const destroyOnUnmount = ['test', 'production'].includes(process.env.NODE_ENV!)

export const ReduxForm = reduxForm<FormData, Props>({
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
  // NOTE: Setting [destroyOnUnmount] to 'false' will cause problems
  // with <StrictMode />
  destroyOnUnmount: destroyOnUnmount
})(UnconnectedForm)

function useFloatActionButtonsOnScroll(
  setIsFloatingButtons: (isFloating: boolean) => void,
  formButtonsContainerRef: React.MutableRefObject<HTMLDivElement | null>
) {
  useLayoutEffect(() => {
    const scrollNode = document.getElementById('side-view-container')
    if (scrollNode === null) {
      return
    }
    const listener = (event: Event) => {
      setIsFloatingButtons(
        isContainerVisible((event.currentTarget as HTMLElement).scrollTop, formButtonsContainerRef.current)
      )
    }
    scrollNode.addEventListener('scroll', listener)
    return () => {
      scrollNode.removeEventListener('scroll', listener)
    }
  }, [formButtonsContainerRef, setIsFloatingButtons])
}
