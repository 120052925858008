/* eslint-disable @typescript-eslint/typedef */
import { MODULE_NAME } from '../constants'

export const SET_ACTIVE_PARTERNS_MANAGEMENT_TAB = `SET_ACTIVE_${MODULE_NAME}_TAB` as const

export const LOAD_REFERRAL_PARTNERS_REQUEST = `${MODULE_NAME}_LOAD_REFERRAL_PARTNERS_REQUEST` as const
export const LOAD_REFERRAL_PARTNERS_SUCCESS = `${MODULE_NAME}_LOAD_REFERRAL_PARTNERS_SUCCESS` as const
export const LOAD_REFERRAL_PARTNERS_FAILURE = `${MODULE_NAME}_LOAD_REFERRAL_PARTNERS_FAILURE` as const

export const ADD_FILTER_REFERRAL_PARTNER = `${MODULE_NAME}_ADD_FILTER_REFERRAL_PARTNER` as const
export const SET_FILTER_REFERRAL_PARTNER = `${MODULE_NAME}_SET_FILTER_REFERRAL_PARTNER` as const
export const REMOVE_FILTER_REFERRAL_PARTNER = `${MODULE_NAME}_REMOVE_FILTER_REFERRAL_PARTNER` as const
export const RESET_FILTERS_REFERRAL_PARTNER = `${MODULE_NAME}_RESET_FILTERS_REFERRAL_PARTNER` as const
export const ON_CHANGE_FILTERS_REFERRAL_PARTNER = `${MODULE_NAME}_ON_CHANGE_FILTERS_REFERRAL_PARTNER` as const

export const LOAD_PARTNERS_REQUEST = `${MODULE_NAME}_LOAD_PARTNERS_REQUEST` as const
export const LOAD_PARTNERS_SUCCESS = `${MODULE_NAME}_LOAD_PARTNERS_SUCCESS` as const
export const LOAD_PARTNERS_FAILURE = `${MODULE_NAME}_LOAD_PARTNERS_FAILURE` as const
