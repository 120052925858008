import { IPagination } from '@alteos/ui'

export const INVOICES_ROUTE = '/invoices'
export const MODULE_NAME = 'invoices'
export const DEFAULT_LIMIT = 10
export const DEFAULT_PAGINATION: IPagination = {
  offset: 0,
  limit: DEFAULT_LIMIT,
  total: DEFAULT_LIMIT
}
export const SET_ACTIVE_INVOICES_TAB = `SET_ACTIVE_${MODULE_NAME}_TAB` as const
