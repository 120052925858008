import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { EnhancedCard } from '../../../../../../../components/Cards'
import icons from '../../../../../assets/documentIcons'
import { IPolicyDocument } from '../../../../../interfaces'
import { generatePolicyDocumentsParts } from '../utils/generatePolicyDocumentsParts'

import styles from '../documents.module.scss'

interface IProps {
  document: IPolicyDocument
  index?: number
  isDetailsMode?: boolean
  dataTest?: string
}

const DocumentCard: FC<IProps> = ({
  document,
  index = 0,
  isDetailsMode = false,
  dataTest = `open-document-${document.name}`
}: IProps): ReactElement | null => {
  const { t } = useTranslation('policy')
  const dispatch = useDispatch()

  const fileExtension = document.name.split('.').pop()
  const icon = typeof fileExtension === 'string' ? icons[fileExtension] : null
  const { documentCards, documentButtons } = generatePolicyDocumentsParts(document, dispatch, t, index)
  const buttons = isDetailsMode
    ? [{ ...documentButtons[1], dataTest: `modal-document-download-${document.name}` }]
    : documentButtons

  return (
    <EnhancedCard
      sidebarIcon={icon}
      card={documentCards}
      className={styles.card}
      dataTest={dataTest}
      cardButtons={buttons}
    />
  )
}

export default DocumentCard
