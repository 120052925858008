import { IFilter } from '../../../../components/Filters/interfaces'
import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { IPartnerQueryRequest, IReferralPartnersResponse } from '../../interfaces/referralPartners'
import {
  LOAD_REFERRAL_PARTNERS_FAILURE,
  LOAD_REFERRAL_PARTNERS_REQUEST,
  LOAD_REFERRAL_PARTNERS_SUCCESS,
  ADD_FILTER_REFERRAL_PARTNER,
  SET_FILTER_REFERRAL_PARTNER,
  REMOVE_FILTER_REFERRAL_PARTNER,
  RESET_FILTERS_REFERRAL_PARTNER,
  ON_CHANGE_FILTERS_REFERRAL_PARTNER
} from '../constants'

export function loadReferralPartnersStart(payload?: Partial<IPartnerQueryRequest>): IAction<any> {
  return {
    type: LOAD_REFERRAL_PARTNERS_REQUEST,
    payload
  }
}

export function loadReferralPartnersFailure(): IBasicAction {
  return {
    type: LOAD_REFERRAL_PARTNERS_FAILURE
  }
}

export function loadReferralPartnersSuccess(payload: IReferralPartnersResponse): IAction<any> {
  return {
    type: LOAD_REFERRAL_PARTNERS_SUCCESS,
    payload
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: LOAD_REFERRAL_PARTNERS_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function addActiveFilterReferralPartner(payload: string): IAction<string> {
  return {
    type: ADD_FILTER_REFERRAL_PARTNER,
    payload
  }
}

export function setFilterReferralPartner(payload: IFilter): IAction<IFilter> {
  return {
    type: SET_FILTER_REFERRAL_PARTNER,
    payload
  }
}

export function removeFilterReferralPartner(payload: string): IAction<string> {
  return {
    type: REMOVE_FILTER_REFERRAL_PARTNER,
    payload
  }
}

export function resetFiltersReferralPartner(): IBasicAction {
  return {
    type: RESET_FILTERS_REFERRAL_PARTNER
  }
}

export function onChangeFiltersReferralPartner(payload?: Partial<IPartnerQueryRequest>): IAction<any> {
  return {
    type: ON_CHANGE_FILTERS_REFERRAL_PARTNER,
    payload
  }
}
