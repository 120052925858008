import { takeLatest } from 'typed-redux-saga'

import { openSideView } from '../../Layout'
import {
  APPROVE_REFERRAL_PARTNER_REQUEST,
  CREATE_PARTNER_PORTAL_USER_REQUEST,
  GET_PARTNER_PORTAL_ROLES_REQUEST,
  GET_PARTNER_PORTAL_USERS_REQUEST,
  GET_REFERRAL_PARTNER_LOG_REQUEST,
  IMPORT_PARTNER_PORTAL_USERS_REQUEST,
  LOAD_GROUPS_REQUEST,
  LOAD_PARTNER_REQUEST,
  LOAD_PERMISSIONS_REQUEST,
  LOAD_PRODUCTS_REQUEST,
  LOAD_REFERRAL_PARTNER_REQUEST,
  REJECT_REFERRAL_PARTNER_REQUEST,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  TERMINATE_REFERRAL_PARTNER_REQUEST,
  UPDATE_GROUP_REQUEST,
  UPDATE_REFERRAL_PARTNER_REQUEST
} from './constants'
import { loadGroups, updateGroup } from './sagas/groups'
import { loadPartner } from './sagas/partner'
import {
  createPartnerPortalUser,
  getPartnerPortalRoles,
  getPartnerPortalUsers,
  importPartnerPortalUsers
} from './sagas/partnerPortal'
import { loadPermissions } from './sagas/permissions'
import {
  approveReferralPartner,
  loadProductsReferralPartner,
  loadReferralPartner,
  loadReferralPartnerLog,
  rejectReferralPartner,
  terminateReferralPartner,
  updateReferralPartner
} from './sagas/referralPartner'

function* watchLoadGroupsStart(): Generator {
  yield* takeLatest<any>(LOAD_GROUPS_REQUEST, loadGroups)
}

function* watchLoadPartnerStart(): Generator {
  yield* takeLatest<any>(LOAD_PARTNER_REQUEST, loadPartner)
}

function* watchLoadPermissionsStart(): Generator {
  yield* takeLatest<any>(LOAD_PERMISSIONS_REQUEST, loadPermissions)
}

function* watchUpdateGroupStart(): Generator {
  yield* takeLatest<any>(UPDATE_GROUP_REQUEST, updateGroup)
}

// Referral Partners watchers
function* watchLoadReferralPartnerStart(): Generator {
  yield* takeLatest<any>(LOAD_REFERRAL_PARTNER_REQUEST, loadReferralPartnerLog)
}

function* watchLoadReferralPartnerLogStart(): Generator {
  yield* takeLatest<any>(GET_REFERRAL_PARTNER_LOG_REQUEST, loadReferralPartner)
}

function* watchLoadProductsReferralPartnerStart(): Generator {
  yield* takeLatest<any>(LOAD_PRODUCTS_REQUEST, loadProductsReferralPartner)
}

function* watchApproveReferralPartnerStart(): Generator {
  yield* takeLatest<any>(APPROVE_REFERRAL_PARTNER_REQUEST, approveReferralPartner)
}

function* watchOpenModal(): Generator {
  yield* takeLatest<any>(SET_ACTIVE_SIDE_VIEW_TYPE, openSideView)
}

function* watchUpdateReferralPartnerStart(): Generator {
  yield* takeLatest<any>(UPDATE_REFERRAL_PARTNER_REQUEST, updateReferralPartner)
}

function* watchRejectReferralPartnerStart(): Generator {
  yield* takeLatest<any>(REJECT_REFERRAL_PARTNER_REQUEST, rejectReferralPartner)
}

function* watchTerminateReferralPartnerStart(): Generator {
  yield* takeLatest<any>(TERMINATE_REFERRAL_PARTNER_REQUEST, terminateReferralPartner)
}

function* watchGetPartnerPortalRolesStart(): Generator {
  yield* takeLatest<any>(GET_PARTNER_PORTAL_ROLES_REQUEST, getPartnerPortalRoles)
}

function* watchCreatePartnerPortalUserStart(): Generator {
  yield* takeLatest<any>(CREATE_PARTNER_PORTAL_USER_REQUEST, createPartnerPortalUser)
}

function* watchGetPartnerPortalUsersStart(): Generator {
  yield* takeLatest<any>(GET_PARTNER_PORTAL_USERS_REQUEST, getPartnerPortalUsers)
}

function* watchImportPartnerPortalUsersStart(): Generator {
  yield* takeLatest<any>(IMPORT_PARTNER_PORTAL_USERS_REQUEST, importPartnerPortalUsers)
}

// 3. Root saga
export default (): Generator[] => [
  watchLoadPermissionsStart(),
  watchLoadGroupsStart(),
  watchUpdateGroupStart(),
  watchLoadPartnerStart(),
  watchLoadReferralPartnerStart(),
  watchLoadProductsReferralPartnerStart(),
  watchApproveReferralPartnerStart(),
  watchOpenModal(),
  watchUpdateReferralPartnerStart(),
  watchRejectReferralPartnerStart(),
  watchTerminateReferralPartnerStart(),
  watchLoadReferralPartnerLogStart(),
  watchGetPartnerPortalRolesStart(),
  watchCreatePartnerPortalUserStart(),
  watchImportPartnerPortalUsersStart(),
  watchGetPartnerPortalUsersStart()
]
