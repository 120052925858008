import get from 'lodash/get'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'
import { InputType, IFormValue, IFormValues } from '@alteos/ui'

import { IFieldConfig, IPathToDependentArray, ISelectOption } from '../../../interfaces/common'
import { isFormField } from '../../../interfaces/guards'
import { IPolicyObject, IPolicyRisk } from '../../../modules/Policy/interfaces'
import { IDependentFieldOption, IFieldSpecificProps } from '../interfaces'

const getFieldSpecificProps = (
  field: IFieldConfig,
  formValues: IFormValues,
  source: Record<string, any>,
  language: ISupportedLanguage
): IFieldSpecificProps => {
  // TODO: fixup the IFieldConfig and make this work properly
  // .. remove the 'any'. Probably could then get rid of the 'undefined' checks
  const { parentField, dependentOptions, pathToArray, pathToDependentArray }: any = field as any
  let { disabled = false }: IFieldConfig = field
  let options: ISelectOption[] | undefined
  let defaultOption: string | undefined

  if (isFormField(field) && field.editType === InputType.select) {
    options = field.options
    defaultOption = field.defaultOption
  }

  if (typeof parentField !== 'undefined' && typeof formValues !== 'undefined') {
    const parentFieldValue: IFormValue = get(formValues, parentField)
    options = dependentOptions?.find((option: IDependentFieldOption) => option.key === parentFieldValue)?.values
  }
  if (typeof pathToArray !== 'undefined') {
    const targetPolicyObject: IPolicyObject[] = get(source, pathToArray, [])

    options = targetPolicyObject.map((object: IPolicyObject) => ({ key: object.name, value: object.name }))
  }

  if (typeof pathToDependentArray !== 'undefined' && typeof formValues !== 'undefined') {
    const { parentField: dependentPartnerField, path, slug }: IPathToDependentArray = pathToDependentArray

    const parentValue: IFormValue = get(formValues, dependentPartnerField)
    const possibleTargetPolicyObjects: IPolicyObject[] = get(source, path, [])
    const targetPolicyObject: IPolicyObject | undefined = possibleTargetPolicyObjects.find(
      (object: IPolicyObject) => object.name === parentValue
    )

    if (typeof targetPolicyObject !== 'undefined') {
      options = (targetPolicyObject as any)[slug].map((risk: IPolicyRisk) => ({ key: risk.name, value: risk.name }))
    } else {
      disabled = true
    }
  }

  let content: string | undefined = (field as any).content
  let title: string | undefined
  if (isFormField(field) && field.editType === InputType.checkbox) {
    content = getTranslation(language, field.label ?? '')
    title = getTranslation(language, field.title ?? '')
  }

  return {
    options,
    content,
    title,
    disabled,
    defaultOption
  }
}

export default getFieldSpecificProps
