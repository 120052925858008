import cx from 'classnames'
import { FC } from 'react'

import { Divider } from '@alteos/ui'

import styles from '../../card.module.scss'

const DividerItem: FC = () => <Divider className={cx(styles.divider, styles.item)} />

export default DividerItem
