import { IPagination } from '@alteos/ui'

import { IBasicStore } from '../../../interfaces/store'
import { IPaymentOrder } from './paymentOrders'

export enum InvoicesTab {
  Individuals = 'individuals',
  Groups = 'groups'
}

export interface IInvoiceTabs {
  activeTab: InvoicesTab
}
export interface IPaymentsState {
  tabs: IInvoiceTabs
  invoices: IInvoicesState
}

export interface IBillingCycle {
  startDate: string
  endDate: string
}
export interface IInvoice {
  id: string
  invoiceNumber: string
  currency: string
  invoiceDate: string
  type: InvoiceTypeEnum
  totalAmount: number
  duePeriod: number
  metadata: IInvoiceMetadata
  created: string
  sealed: boolean
  status: IInvoiceStatusEnum
  paymentOrders: IPaymentOrder[]
  lineItems: ILineItem[]
  recipients: IRecipients[]
  partnerName: string
  billingCycle?: IBillingCycle
  pdfLink?: string
}

export interface ILineItem {
  id: string
  amount: number
  amountExcludingTax: number
  currency: string
  description: string
  productId: string
  quantity: number
  tax: number
  type: string
  metadata?: any
}

export interface IRecipients {
  name: string
  email: string
  type: RecipientTypeEnum
  description: string
  phone?: string
  taxId?: string
  vatId?: string
}

export enum CycleEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  BIANNUAL = 'BIANNUAL',
  YEARLY = 'YEARLY'
}

export enum RecipientTypeEnum {
  Partner = 'PARTNER',
  Customer = 'CUSTOMER'
}

export interface IInvoicesState extends IBasicStore {
  isFetchingInvoices: boolean
  isFetchingInvoicePreview: boolean
  invoicePreview: IInvoicePreview[] | null
  showActionsInvoiceId: string | null
  tabs: IInvoiceTabs
  invoices: IInvoice[]
  pagination: IPagination
  activeSideView: boolean
}

export interface IInvoicesResponse {
  invoices: IInvoice[]
  count: number
}

export enum InvoiceTypeEnum {
  CreditInvoice = 'CREDIT_INVOICE',
  DebitInvoice = 'DEBIT_INVOICE',
  GroupedInvoice = 'GROUPED_INVOICE',
  InvoiceBroker = 'INVOICE_BROKER',
  CommissionNote = 'COMMISSION_NOTE',
  Courtage = 'COURTAGE',
  PaymentOrder = 'PAYMENT_ORDER'
}

export interface IInvoiceMetadata {
  externalReferenceId?: string
  source?: string
  partnerId?: string
  policyPrettyId?: string
  processingType?: string
}

export enum IInvoiceStatusEnum {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum IInvoiceStateEnum {
  Paid = 'PAID',
  Overdue = 'OVERDUE',
  PartiallyPaid = 'PARTIALLY-PAID',
  PendingPayment = 'PENDING-PAYMENT',
  InProgress = 'IN-PROGRESS',
  Unknown = 'UNKNOWN'
}

export type PageSize = 'A0' | 'A1' | 'A2' | 'A3' | 'A4' | 'A5' | 'A6' | 'A7' | 'A8'

export interface IInvoicePreview {
  templateId: string
  index: number
  html: string
  orientation: 'portrait' | 'landscape'
  pageSize: PageSize
}
export interface IInvoicePreviewResponse {
  invoicePreview: IInvoicePreview[]
}
