import { IProductConfigExtension } from '../../interfaces/referralPartners'

export interface IProductConfigExtensionValues {
  values: {
    commissionRate?: number
    salesCategory?: string
  }
}

export interface IProductConfigExtensionDropdownValues {
  values: {
    commissionRate?: string
    salesCategory?: string
  }
}

export const mapCommissionRateValueToNumber = (
  productConfigExtension: IProductConfigExtension
): IProductConfigExtension =>
  Object.fromEntries(
    Object.entries(productConfigExtension).map(
      (value: [string, IProductConfigExtensionDropdownValues]): [string, IProductConfigExtensionValues] => {
        let commissionRate: number | undefined
        if (typeof value[1]?.values?.commissionRate !== 'undefined') {
          commissionRate = parseFloat(value[1].values.commissionRate)
        }

        return [value[0], { ...value[1], values: { ...value[1].values, commissionRate } }]
      }
    )
  )

export const mapCommissionRateValueToString = (
  productConfigExtension: IProductConfigExtension
): IProductConfigExtension =>
  Object.fromEntries(
    Object.entries(productConfigExtension).map(
      (value: [string, IProductConfigExtensionValues]): [string, IProductConfigExtensionDropdownValues] => {
        let commissionRate: string | undefined
        if (typeof value[1]?.values?.commissionRate !== 'undefined') {
          commissionRate = value[1].values.commissionRate.toString()
        }
        return [value[0], { ...value[1], values: { ...value[1].values, commissionRate } }]
      }
    )
  )
