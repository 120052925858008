import { FC, ReactElement } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { Action } from '@alteos/dictionaries/dist/Action'

import { IBasicDispatchProps, IStore } from '../../../../interfaces/store'
import { isPolicyActive } from '../../../../utils'
import { Permissions } from '../../../Auth'
import { hasPermission } from '../../../Auth/components/PermissionGuard/hasPermission'
import { getUserPermissions } from '../../../Auth/store/selectors'
import { closeSideView } from '../../../Layout/store/actionCreators'
import { MODULE_NAME } from '../../constants'
import { ModeType } from '../../dictionaries'
import { IGeneratePolicyHolderDetailsCards } from '../../interfaces'
import { default as actionsToBind } from '../../store/actionCreators'
import PolicyHolderDetailsForm from './Form'
import generatePolicyholderSideViewCards from './utils/generatePolicyholderSideViewCards'
import PolicyHolderDetailsView from './View'

import styles from './styles.module.scss'

type IStoreProps = Pick<IStore[typeof MODULE_NAME], 'config' | 'policy' | 'isSubmitting' | 'mode'>

type Props = IStoreProps & WithTranslation & IBasicDispatchProps

const PolicyHolderDetails: FC<Props> = ({
  policy = null,
  mode,
  actions,
  config,
  isSubmitting,
  t
}: Props): ReactElement | null => {
  const permissions: Permissions = useSelector(getUserPermissions)
  if (policy === null || config === null) {
    return null
  }
  const showEditPolicyHolderButton: boolean =
    isPolicyActive(policy.status) &&
    hasPermission(permissions, [Action.PolicyWithdraw, Action.PolicyCancel, Action.PolicyUpdate])

  const { headerCard, editHeaderCard, cards }: IGeneratePolicyHolderDetailsCards = generatePolicyholderSideViewCards(
    policy,
    config,
    actions.setModal,
    actions.closeSideView,
    t,
    showEditPolicyHolderButton
  )

  const isView: boolean = mode === ModeType.View

  return (
    <div className={styles.container}>
      {isView ? (
        <PolicyHolderDetailsView headerCard={headerCard} cards={cards} actions={actions} policy={policy} />
      ) : (
        <PolicyHolderDetailsForm
          cards={[editHeaderCard, ...cards]}
          policy={policy}
          config={config}
          actions={actions}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  )
}

function mapStateToProps(state: IStore): IStoreProps {
  return {
    policy: state[MODULE_NAME].policy,
    mode: state[MODULE_NAME].mode,
    config: state[MODULE_NAME].config,
    isSubmitting: state[MODULE_NAME].isSubmitting
  }
}

function mapDispatchToProps(dispatch: reduxDispatch): IBasicDispatchProps {
  return {
    actions: bindActionCreators({ ...actionsToBind, closeSideView }, dispatch)
  }
}

export default withTranslation([MODULE_NAME])(connect(mapStateToProps, mapDispatchToProps)(PolicyHolderDetails))
