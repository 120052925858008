import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import StatusContent from '../../components/StatusContent'

const NotFoundPage: FC = () => {
  const [t]: UseTranslationResponse<'static'> = useTranslation('static')

  return (
    <StatusContent
      title={t('notFound.title')}
      content={t('notFound.content')}
      subContent={t('notFound.subContent')}
      button={{ to: '/policies', label: t('notFound.button'), icon: 'arrow-left' }}
      dataTest="not-found"
    />
  )
}

export default NotFoundPage
