import cx from 'classnames'
import { FC } from 'react'

import { IFileNameViewFieldConfig } from '../../../../../interfaces/common'
import { FieldValue } from '../../../interfaces'

import cardStyles from '../../card.module.scss'
import styles from './fileName.module.scss'

interface IProps {
  field: IFileNameViewFieldConfig
  value: FieldValue
}

const FileNameItem: FC<IProps> = ({ field, value }: IProps) => {
  if (typeof value !== 'string') {
    return null
  }

  const extension: string | undefined = value.split('.').pop()

  if (typeof extension !== 'undefined') {
    const name: string = value.replace(`.${extension}`, '')
    return (
      <p className={cx(styles.container, cardStyles.item)} data-test={field.id}>
        {name}
        <span>{`.${extension}`}</span>
      </p>
    )
  }

  return <p className={styles.container}>{value}</p>
}

export default FileNameItem
