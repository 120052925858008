import i18next from 'i18next'
import * as ReactDOM from 'react-dom/client'
import { hotjar } from 'react-hotjar'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import { DaveUiEventType, Parcels } from '@alteos/dave-ui-lib'
import { FeatureFlagProvider } from '@alteos/feature-flag-react'
import { TestingFlagGroup, TestingFlags } from '@alteos/ui'

import App from './App'
import { HOTJAR_KEY, UNLEASH_APP, UNLEASH_TOKEN, UNLEASH_URL } from './config'
import { logout, refreshToken } from './modules/Auth/store/actionCreators'
import initI18n from './modules/i18n/i18nConfiguration'
import Toast from './modules/Layout/components/Toast'
import { events } from './platform/events'
import api from './services/api/api'
import { configureStore } from './store'
import { bootstrap } from './utils'
import initializeDataDogRum from './utils/initializeDataDogRum'
import { requireNotNull } from './utils/requireNotNull'
import 'normalize.css'
import './components/Filters/react-date-style-override.css'

import '@alteos/ui/dist/style.css'

initializeDataDogRum()
hotjar.initialize(Number(HOTJAR_KEY), 0)
bootstrap()

TestingFlags.enable(TestingFlagGroup.frontend)

const rootHtmlElement = requireNotNull(document.getElementById('root'), '#root HTMLElement')
const rootNode = ReactDOM.createRoot(rootHtmlElement)

async function init(): Promise<any> {
  await i18next.init(initI18n)
  const { store } = configureStore()
  events.subscribeLatest((event) => {
    if (event.src === Parcels.Dave) {
      // NOTE: no need to reply dave events
      return
    }
    switch (event.type) {
      case DaveUiEventType.UserSignedOut: {
        store.dispatch(logout())
        break
      }
      case DaveUiEventType.UserTokenRefreshed: {
        store.dispatch(refreshToken({ refreshToken: event.refreshToken, token: event.token }))
        break
      }
    }
  })

  api.bindToStore(store)

  rootNode.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <FeatureFlagProvider url={UNLEASH_URL || ''} clientKey={UNLEASH_TOKEN || ''} appName={UNLEASH_APP || ''}>
          <App />
        </FeatureFlagProvider>
        <Toast />
      </I18nextProvider>
    </Provider>
  )
}

void init()

if (process.env.NODE_ENV === 'development') {
  events.subscribeLatest((event) => {
    // eslint-disable-next-line no-console
    console.debug(`APP GLOBAL EVENT: '${event.type}'`, event)
  })
}
