import cx from 'classnames'
import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

interface IProps {
  children: ReactNode
  noPadding?: boolean
}

export const ModalContent: FC<IProps> = ({ noPadding = false, children }: IProps) => {
  return (
    <div
      className={cx(styles.modalContent, {
        [styles.noPadding]: noPadding
      })}
    >
      {children}
    </div>
  )
}
