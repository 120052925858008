import addDays from 'date-fns/addDays'
import { TFunction } from 'i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { IDateFormFieldConfig } from '../../../../../interfaces/common'
import { forms } from '../../../constants'
import { IPolicy } from '../../../interfaces'

export const generateScheduledTerminationFormCardsV2 = (
  policy: IPolicy,
  currentDate: string,
  t: TFunction
): IFormConfig => {
  const requestedAtField: IDateFormFieldConfig = {
    id: 'requestedAt',
    editType: InputType.date,
    size: 'half',
    label: t('scheduledTerminationForm.label.requestedAt'),
    required: true,
    disabled: true
  }

  const now = new Date(currentDate)
  const minimalDate = addDays(now, 1).toISOString()

  const effectiveAtField: IDateFormFieldConfig = {
    editType: InputType.date,
    id: 'effectiveAt',
    required: true,
    label: t('scheduledTerminationForm.label.effectiveAt'),
    size: 'half',
    minimum: minimalDate
  }

  const initiatorField = {
    id: 'initiator',
    editType: InputType.select,
    required: true,
    label: t('policyTerminationForm.initiator'),
    disabled: true,
    options: [
      {
        key: 'insurer',
        value: 'Insurer'
      }
    ]
  }

  const terminationNameField = {
    id: 'ruleCode',
    editType: InputType.select,
    required: true,
    label: t('policyTerminationForm.terminationReason.title'),
    disabled: true,
    options: [
      {
        key: 'oth05',
        value: t('policyTerminationForm.terminationReason.reasons.InsurerEndorsement')
      }
    ]
  }

  const fields = [requestedAtField, effectiveAtField, initiatorField, terminationNameField]

  const formBase = {
    form: forms.createScheduledTerminationForm,
    url: '',
    initialValues: {
      ruleCode: 'oth05',
      initiator: 'insurer',
      requestedAt: currentDate,
      extraInput: {},
      effectiveAt: ''
    },
    formSections: [
      {
        fields
      }
    ],
    successMessage: t('scheduledActions.terminationForm.successMessage')
  }

  return formBase
}
