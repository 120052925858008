import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container } from '../../../../components/sideViewComponents'
import { ClaimStatus } from '../../../../dictionaries'
import { IStore } from '../../../../interfaces/store'
import { getCurrentLanguage } from '../../../i18n/selectors'
import { forms, getClaimSubStatuses, MODULE_NAME } from '../../constants'
import { setFocusedClaim, setModal } from '../../store/actionCreators'
import { generateReduxFormConfig } from '../../utils'
import generateCreateClaimFormCards from './utils/generateCreateClaimFormCards'

import styles from './styles.module.scss'

const CreateClaimForm: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('policy')
  const language = useSelector(getCurrentLanguage)
  const config = useSelector((state: IStore) => state[MODULE_NAME].config)
  const policy = useSelector((state: IStore) => state[MODULE_NAME].policy)
  const isSubmitting = useSelector((state: IStore) => state[MODULE_NAME].isSubmitting)
  const formValues = useSelector((state: IStore) => state.form[forms.createClaimForm]?.values)

  const selectedClaimStatus = formValues?.status
  const subStatuses = getClaimSubStatuses(selectedClaimStatus as ClaimStatus)

  if (config === null || policy === null) {
    return null
  }

  const formConfig: IFormConfig = generateCreateClaimFormCards(config, policy, t, language, subStatuses)

  const props: IFormProps = generateReduxFormConfig(formConfig, dispatch, {
    source: policy,
    isSubmitting,
    formType: FormType.CREATE,
    onClose: () => {
      dispatch(setModal(null))
      dispatch(setFocusedClaim(null))
    }
  })

  return (
    <div className={styles.container}>
      <Container className={styles.title} title={t('createClaimForm.title')} icon="pencil" />
      <Form {...props} />
    </div>
  )
}

export default CreateClaimForm
