import { formatFactory, formatEuroCurrency } from '@alteos/ui'

import { TimePeriod } from '../dictionaries'
import { ISODateFormats } from '../modules/Policy/dictionaries'

export const formatTimePeriodPrice = (value: number | string, period: string | undefined | null): string => {
  const { formatCapitalize } = formatFactory
  let formattedPeriod
  switch (period) {
    case ISODateFormats.monthly:
      formattedPeriod = TimePeriod.Month
      break
    case ISODateFormats.yearly:
      formattedPeriod = TimePeriod.Year
      break
    default:
      formattedPeriod = 'Total'
  }

  return `${formatEuroCurrency(value, '—')}/${formatCapitalize(formattedPeriod)}`
}
