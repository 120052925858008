import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncValidateCallback, change, FormAction, FormErrors, FormState } from 'redux-form'

import { IFormValue } from '@alteos/ui'

import { ToastType } from '../../dictionaries'
import { IFieldConfig } from '../../interfaces/common'
import { closeSideView, displayToast } from '../../modules/Layout/store/actionCreators'
import { IRootState } from '../../store/interfaces'
import { ICardSection } from '../Cards/interfaces'
import { FormType } from './FormType'
import { IFormConfig } from './interfaces'
import { FormData, ReduxForm } from './ReduxForm'

export interface IFormProps {
  formType: FormType
  formSections: ICardSection[]
  initialValues: Record<string, any>
  source?: Record<string, any>
  helpers?: IFormConfig['helpers']
  hasFormButtons?: boolean
  dataTest?: string
  form: string
  onClose?: () => void
  onSubmit?: () => void
  validate?: (values: Record<string, any>) => FormErrors<FormData, string>
  asyncValidate?: (
    values: Record<string, any>,
    dispatch: Function,
    props: IFormProps
  ) => Promise<undefined | FormErrors<FormData, string>>
  shouldAsyncValidate?: ((params: AsyncValidateCallback<FormData, string>) => boolean) | undefined
  asyncChangeFields?: string[]
  isSubmitting?: boolean
  isFullWidth?: boolean
  onDataChange?: (values: { [key: string]: string }, dispatch: any, props: any) => void
}

export interface IProvidedFormProps {
  formState: FormState | null
  onFieldChange(form: string, field: string, value: IFormValue): FormAction
  onInvalidFieldsError(): void
  onClose: () => void
  isSubmittingExternal: boolean
  allFields: IFieldConfig[]
}

export const Form: FC<IFormProps> = (props: IFormProps) => {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const allFields = useMemo(() => props.formSections.flatMap((it) => it.fields), [props.formSections])
  const formState: FormState | null = useSelector((state: IRootState) => state.form[props.form]) ?? null
  function handleOnInvalidFieldsError() {
    dispatch(displayToast({ type: ToastType.Error, message: t('formValidation.formFieldsError') }))
  }

  function handleOnFieldChange(form: string, field: string, value: IFormValue) {
    const action = change(form, field, value)
    dispatch(action)
    return action
  }

  function handleOnChange(values: { [key: string]: string }, dispatch: any, props: any) {
    props.onDataChange?.(values, dispatch, props)
  }

  function handleOnCloseRequest() {
    props.onClose?.()
    dispatch(closeSideView())
  }

  return (
    <ReduxForm
      {...props}
      isSubmittingExternal={props.isSubmitting ?? false}
      formState={formState}
      allFields={allFields}
      onSubmit={props.onSubmit}
      onChange={handleOnChange}
      onInvalidFieldsError={handleOnInvalidFieldsError}
      onClose={handleOnCloseRequest}
      onFieldChange={handleOnFieldChange}
    />
  )
}
