import { IFilter } from '../interfaces'

const removeActiveFilter: (coll: IFilter[], param: string) => IFilter[] = (
  coll: IFilter[],
  param: string
): IFilter[] => {
  return coll.map((item: IFilter) => {
    return {
      ...item,
      selectedFilter: item.key === param ? '' : item.selectedFilter
    }
  })
}

export default removeActiveFilter
