import { FC, ReactElement, useMemo } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container, EditModeLabel } from '../../../../components/sideViewComponents'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { IPolicyActions } from '../../interfaces'
import actionsToBind from '../../store/actionCreators'
import generateEditIbanFormCards from './utils/generateEditIbanFormCards'

import styles from './editIbanForm.module.scss'

const EditIbanForm: FC<WithTranslation> = ({ t }: WithTranslation): ReactElement | null => {
  const policy: IStore['policy']['policy'] | null = useSelector((state: IStore) => state[MODULE_NAME].policy)
  const mandate: IStore['policy']['mandate'] | null = useSelector((state: IStore) => state[MODULE_NAME].mandate)
  const isSubmitting: boolean = useSelector((state: IStore) => state[MODULE_NAME].isSubmitting)
  const dispatch: reduxDispatch = useDispatch()
  const actions: IPolicyActions = useMemo(() => bindActionCreators(actionsToBind, dispatch), [dispatch])

  if (policy === null) {
    return null
  }

  const formConfig: IFormConfig = generateEditIbanFormCards(t, policy, mandate)
  const props: IFormProps = {
    ...formConfig,
    formType: FormType.EDIT,
    isSubmitting,
    onClose: () => actions.setModal(null),
    onSubmit: () => actions.updateIbanStart(formConfig)
  }

  return (
    <>
      <Container
        className={styles.title}
        title={t('policy:editIbanForm.title')}
        icon="pencil"
        label={<EditModeLabel />}
      />
      <Form {...props} />
    </>
  )
}

export default withTranslation(['policy'])(EditIbanForm)
