import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Navigate, useRouteError } from 'react-router-dom'

import { ToastType } from '../../../../dictionaries'
import { isUnauthorizedException } from '../../../../services/api/exceptions'
import { LOGIN_ROUTE } from '../../../Auth'
import { logout } from '../../../Auth/store/actionCreators'
import { displayToast } from '../../../Layout/store/actionCreators'
import StatusContent from '../../components/StatusContent'

export const RouterErrorElement: FC = () => {
  const error = useRouteError()
  const dispatch = useDispatch()
  const [t]: UseTranslationResponse<'static'> = useTranslation('static')

  if (isUnauthorizedException(error)) {
    dispatch(logout())
    dispatch(displayToast({ type: ToastType.Warn, message: t('error.sessionExpired') }))
    return <Navigate to={{ pathname: LOGIN_ROUTE }} />
  }

  return (
    <StatusContent
      title={t('error.title')}
      content={t('error.content')}
      subContent={t('error.subContent')}
      button={{ to: '/login', label: t('notFound.button'), icon: 'arrow-left' }}
      dataTest="error"
    />
  )
}
