import { ReactElement } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { IStore } from '../../../../../interfaces/store'
import { MODULE_NAME } from '../../../constants'
import { IReferralPartner } from '../../../interfaces/referralPartner'
import { setSideView, terminateReferralPartnerStart } from '../../../store/actions/referralPartner'
import mapReferralPartner from '../../../store/utils/mapReferralPartner'
import generateTerminateFormCards from '../utils/generateTerminateFormCards'

export const TerminateForm = (): ReactElement => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')

  const dispatch: Dispatch = useDispatch()

  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )

  const isSubmittingRejection: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.isRejectingReferralPartner
  )

  const completeReferralPartner: IReferralPartner = mapReferralPartner(referralPartner)

  const terminateFormConfig: IFormConfig = generateTerminateFormCards(t, completeReferralPartner, isSubmittingRejection)

  const terminateFormProps: IFormProps = {
    ...terminateFormConfig,
    dataTest: 'referralpartner-terminate-form',
    source: completeReferralPartner,
    hasFormButtons: true,
    isSubmitting: isSubmittingRejection,
    formType: FormType.CREATE,
    onClose: () => dispatch(setSideView(null)),
    onSubmit: () => dispatch(terminateReferralPartnerStart(terminateFormConfig))
  }

  return <Form {...terminateFormProps} />
}
