import { FC } from 'react'

import { Card, ExpandableCard, ICardSection } from '../../../../../components/Cards'
import { IPolicy, IPolicyActions } from '../../../interfaces'

import styles from '../styles.module.scss'

interface IProps {
  headerCard: ICardSection
  cards: ICardSection[]
  actions: IPolicyActions
  policy: IPolicy
}

const PolicyHolderDetailsView: FC<IProps> = ({ headerCard, cards, actions, policy }: IProps) => {
  return (
    <>
      <ExpandableCard
        className={styles.card}
        title={headerCard.title as string}
        withLargeHeading={true}
        isExpandable={false}
        icon="claims"
        expandedContent={<Card className={styles.subCard} content={{ fields: headerCard.fields }} source={policy} />}
        handleClick={() => actions.setModal(null)}
      />
      {cards.map((card: ICardSection, index: number) => (
        <ExpandableCard
          className={styles.card}
          title={card.title as string}
          key={index}
          expandedContent={<Card className={styles.subCard} content={{ fields: card.fields }} source={policy} inline />}
        />
      ))}
    </>
  )
}

export default PolicyHolderDetailsView
