import { IRequestPagination } from '@alteos/ui'

import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { IPartnersResponse } from '../../interfaces'
import { LOAD_PARTNERS_FAILURE, LOAD_PARTNERS_REQUEST, LOAD_PARTNERS_SUCCESS } from '../constants'

export const DEFAULT_LIMIT = 20

const DEFAULT_REQUEST_PAGINATION: IRequestPagination = { offset: 0, limit: DEFAULT_LIMIT, query: '' }

export function loadPartnersStart(
  payload: IRequestPagination = DEFAULT_REQUEST_PAGINATION
): IAction<IRequestPagination> {
  return {
    type: LOAD_PARTNERS_REQUEST,
    payload
  }
}
export function loadPartnersSuccess(payload: IPartnersResponse): IAction<any> {
  return {
    type: LOAD_PARTNERS_SUCCESS,
    payload
  }
}

export function loadPartnersFailure(): IBasicAction {
  return {
    type: LOAD_PARTNERS_FAILURE
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: LOAD_PARTNERS_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}
