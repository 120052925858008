import { IAddon, IMappedPolicyResponsePayload, IPolicy, IPolicyResponse } from '../../interfaces'

export function mapPolicyResponse(policyById: IPolicyResponse): IMappedPolicyResponsePayload {
  const { addons, ...basePolicy } = policyById
  return { policyById: { ...basePolicy, addons: mapAddons(addons) } }
}

function mapAddons(addons: IPolicyResponse['addons']): IPolicy['addons'] {
  return (addons ?? []).reduce((acc: IPolicy['addons'], next: IAddon) => {
    return { ...acc, [next.name]: true }
  }, {})
}
