import { IUpcomingPayments } from '../../interfaces'

export function updateUpcomingPayments(
  upcomingPayments: IUpcomingPayments[],
  selectedPaymentIds: string[],
  updatedValues: Partial<IUpcomingPayments>
): IUpcomingPayments[] {
  const selectedPaymentIdsSet = new Set(selectedPaymentIds)

  return upcomingPayments.map((upcomingPayment) => {
    if (selectedPaymentIdsSet.has(upcomingPayment.paymentScheduleId)) {
      return {
        ...upcomingPayment,
        ...updatedValues
      }
    }
    return upcomingPayment
  })
}
