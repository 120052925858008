enum ProcessingType {
  sepa = 'sepa',
  card = 'card',
  paypal = 'paypal',
  klarnaDirectDebit = 'klarna-direct-debit',
  klarnaBankTransfer = 'klarna-bank-transfer',
  klarnaSliceIt = 'klarna-slice-it',
  klarnaInvoice = 'klarna-pay-later',
  invoice = 'invoice',
  none = 'none'
}

export default ProcessingType
