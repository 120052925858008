import axios from 'axios'
import isNil from 'lodash/isNil'
import { put } from 'typed-redux-saga'

import ToastType from '../../dictionaries/ToastType'
import { actions as layoutActions } from '../../modules/Layout'

export function* triggerErrorPopup(error: unknown, customErrorMessage?: string): Generator {
  let errorMessage = ''

  if (axios.isAxiosError(error) && !isNil(error.response)) {
    errorMessage = error.response.data.message
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    errorMessage = 'Unexpected error'
  }

  yield* put(
    layoutActions.displayToast({
      message: `${errorMessage}${customErrorMessage ?? ''}`,
      type: ToastType.Error
    })
  )
}
