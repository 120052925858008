import { IAction } from '../../../interfaces/store'
import {
  CLOSE_SIDE_VIEW,
  OPEN_SIDE_VIEW,
  DISPLAY_TOAST_MESSAGE,
  CLOSE_TOAST_MESSAGE,
  SET_SIDE_VIEW_IS_PROCESSING_DATA
} from '../constants'
import { ILayoutState } from '../interfaces/shared'

export const initialState: ILayoutState = {
  sideViewOpen: false,
  toastProps: null,
  sideViewIsProcessingData: false
}

function reducer(state: ILayoutState = initialState, action: IAction<any>): ILayoutState {
  switch (action.type) {
    case OPEN_SIDE_VIEW:
      return {
        ...state,
        sideViewOpen: true
      }
    case CLOSE_SIDE_VIEW:
      return {
        ...state,
        sideViewOpen: false
      }

    case DISPLAY_TOAST_MESSAGE:
      return {
        ...state,
        toastProps: action.payload
      }
    case CLOSE_TOAST_MESSAGE:
      return {
        ...state,
        toastProps: null
      }

    case SET_SIDE_VIEW_IS_PROCESSING_DATA:
      return {
        ...state,
        sideViewIsProcessingData: action.payload
      }

    default:
      return state
  }
}

export default reducer
