import get from 'lodash/get'
import isNil from 'lodash/isNil'
import toPath from 'lodash/toPath'

import { RelationalOperator, IFormValue, ICondition } from '@alteos/ui'

import { requireNotNull } from './requireNotNull'

export function getConditionResult(
  conditions: ICondition[],
  source: Record<string, any>,
  newValue?: IFormValue
): boolean {
  for (let condition of conditions) {
    let dependentValue: IFormValue = typeof condition.field === 'undefined' ? newValue : get(source, condition.field)

    if (isNil(dependentValue)) {
      // NOTE: dependant value not found
      // try to rewrite the conditions, might be legacy notation
      const field: string = requireNotNull(condition.field, 'Condition missing field')
      const rewriteResult: [ICondition, IFormValue] | null = rewriteCond(condition, field, source)
      if (!isNil(rewriteResult)) {
        condition = rewriteResult[0]
        dependentValue = rewriteResult[1]
      }
    }

    const comparedValue: IFormValue | IFormValue[] = condition.value
    if (!evalCondition(condition, dependentValue, comparedValue)) {
      return false
    } else {
      continue
    }
  }

  return true
}

function evalCondition(condition: ICondition, dependentValue: IFormValue, comparedValue: IFormValue): boolean {
  switch (condition.operator) {
    case RelationalOperator.eq: {
      return dependentValue === comparedValue
    }
    case RelationalOperator.notEq: {
      return dependentValue !== comparedValue
    }
    case RelationalOperator.more: {
      if (typeof dependentValue === 'string' && typeof comparedValue === 'string') {
        return dependentValue > comparedValue
      }
      throw new Error('Unsupported condition')
    }
    case RelationalOperator.moreOrEqual: {
      if (typeof dependentValue === 'string' && typeof comparedValue === 'string') {
        return dependentValue >= comparedValue
      }
      throw new Error('Unsupported condition')
    }
    case RelationalOperator.less: {
      if (typeof dependentValue === 'string' && typeof comparedValue === 'string') {
        return dependentValue < comparedValue
      }
      throw new Error('Unsupported condition')
    }
    case RelationalOperator.lessOrEqual: {
      if (typeof dependentValue === 'string' && typeof comparedValue === 'string') {
        return dependentValue <= comparedValue
      }
      throw new Error('Unsupported condition')
    }
    case RelationalOperator.oneOf: {
      if (Array.isArray(comparedValue)) {
        return comparedValue.includes(dependentValue)
      } else {
        throw new Error('Compared value must be an array')
      }
    }
    default:
      throw new Error('Unsupported operator passed in')
  }
}

function rewriteCond(
  condition: ICondition,
  field: string,
  source: Record<string, any> = {}
): [ICondition, IFormValue] | null {
  const fieldPathParsed: string[] = toPath(field)

  // NOTE: https://versicorp.atlassian.net/browse/DAVE-7391
  // If it is an addon field with legacy array notation - try use object nation
  if (fieldPathParsed[0] === 'addons' && !Number.isNaN(parseInt(fieldPathParsed[1], 10))) {
    const newPath = `addons.${condition.value}`
    return [{ field: newPath, operator: RelationalOperator.eq, value: true }, get(source, newPath)]
  }

  return null
}
