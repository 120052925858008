import { AxiosResponse } from 'axios'

import defaultApiInstance, { Api } from '../../../services/api/api'
import {
  INewPartnerPortalUser,
  IPartnerPortalPartnerUser,
  IPartnerPortalRole,
  IPartnerPortalUser,
  IUploadPartnerPortalUsersResponse
} from '../interfaces'
import * as Endpoint from './endpointConsts'

export class PartnerPortalApi {
  constructor(private api: Api) {}

  getRoles = async (): Promise<IPartnerPortalRole[]> => {
    const response: AxiosResponse<{
      data: IPartnerPortalRole[]
      message: string
      status: string
    }> = await this.api.get(Endpoint.PARTNER_PORTAL_ROLES)
    return response.data.data
  }

  createUser = async (data: INewPartnerPortalUser): Promise<IPartnerPortalUser> => {
    const response: AxiosResponse<IPartnerPortalUser> = await this.api.post(Endpoint.PARTNER_PORTAL_USERS, data)
    return response.data
  }

  uploadUsers = async (data: FormData): Promise<IUploadPartnerPortalUsersResponse> => {
    const response: AxiosResponse<IUploadPartnerPortalUsersResponse> = await this.api.post(
      Endpoint.PARTNER_PORTAL_USERS_UPLOAD,
      data
    )
    return response.data
  }

  getPartnerUsers = async (partnerId: string): Promise<IPartnerPortalPartnerUser[]> => {
    const response: AxiosResponse<{
      data: IPartnerPortalPartnerUser[]
      message: string
      status: string
    }> = await this.api.get(`${Endpoint.PARTNER_PORTAL_USERS}/${partnerId}`)
    return response.data.data
  }

  blockUser = async (userId: string): Promise<IPartnerPortalUser> => {
    const response: AxiosResponse<IPartnerPortalUser> = await this.api.post(
      Endpoint.PARTNER_PORTAL_USER_BLOCK.replace(Endpoint.PARTNER_PORTAL_USER_ID, userId)
    )
    return response.data
  }

  unblockUser = async (userId: string): Promise<IPartnerPortalUser> => {
    const response: AxiosResponse<IPartnerPortalUser> = await this.api.post(
      Endpoint.PARTNER_PORTAL_USER_UNBLOCK.replace(Endpoint.PARTNER_PORTAL_USER_ID, userId)
    )
    return response.data
  }
}

export const partnerPortalApi: PartnerPortalApi = new PartnerPortalApi(defaultApiInstance)
