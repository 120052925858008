import { FC } from 'react'

import { ITagsViewFieldConfig } from '../../../../../interfaces/common'
import { useDynamicTranslation } from '../../../../../modules/i18n/hooks'
import { FieldValue, ITag } from '../../../interfaces'
import Tags from '../../Tags'

interface IProps {
  field: ITagsViewFieldConfig
  value: FieldValue
}

const TagsItem: FC<IProps> = ({ field, value }: IProps) => {
  const label: string = useDynamicTranslation(field.label)
  if (typeof field.tagsColumns !== 'undefined' && typeof field.label !== 'undefined') {
    return <Tags tags={value as ITag[]} columns={field.tagsColumns} title={label} />
  }
  return null
}

export default TagsItem
