import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { LOGIN_ROUTE, Permissions, PermissionConstraint } from '../../modules/Auth'
import { hasPermission } from '../../modules/Auth/components/PermissionGuard/hasPermission'
import { getIsUserAuthenticated, getUserPermissions } from '../../modules/Auth/store/selectors'
import NotFoundPage from '../../modules/Static/screens/NotFound'

export interface IAuthGuardProps {
  children?: ReactNode
}

export const AuthGuard: FC<IAuthGuardProps> = ({ children }) => {
  const location = useLocation()
  const isAuthenticated: boolean = useSelector(getIsUserAuthenticated)
  if (!isAuthenticated) {
    return <Navigate to={LOGIN_ROUTE} state={{ redirectTo: location?.pathname }} />
  }
  return <>{children}</>
}

export interface IPermissionGuardProps {
  children?: ReactNode
  permissions: PermissionConstraint
}

export const PermissionGuard: FC<IPermissionGuardProps> = ({ children, permissions }) => {
  const currentPermissions: Permissions = useSelector(getUserPermissions)
  if (!hasPermission(currentPermissions, permissions)) {
    return <NotFoundPage />
  }
  return <div>{children}</div>
}
