import get from 'lodash/get'

import { IFieldConfig } from '../../../../../interfaces/common'
import { requireNotNull } from '../../../../../utils/requireNotNull'
import { IPolicy, IPolicyRenewRequestPayload } from '../../../interfaces'
import { getPayloadFromPlainObject } from '../../../utils'

export function getRenewPayload(
  formValues: Record<string, any>,
  initialValues: Record<string, unknown>,
  allFields: IFieldConfig[],
  originalPolicy: IPolicy,
  updateReason: string
): IPolicyRenewRequestPayload {
  const payloadObject: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)

  if (payloadObject.objects.length > 1) {
    throw new Error(`cannot renew policy: '${originalPolicy.prettyId}' required at most 1 object `)
  }

  const packageName: string = requireNotNull(get(formValues, 'package'), 'package name is required') as string

  const addons: string[] = Object.entries(formValues.addons as any)
    .filter(([_, value]: any) => value === true)
    .map(([key, _]: any) => key)

  return {
    prettyId: originalPolicy.prettyId,
    customer: payloadObject.customer,
    addons,
    package: packageName,
    product: {
      productId: originalPolicy.product.id,
      productVersion: originalPolicy.product.latestVersion
    },
    values: {
      ...(payloadObject.objects[0]?.values ?? {}),
      ...payloadObject.values,
      effectiveAt: payloadObject.effectiveAt,
      updateReason: updateReason,
      updatedAt: payloadObject.effectiveRequestedAt,
      isEndorsement: true
    }
  }
}
