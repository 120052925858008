import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

import { IFieldConfig } from '../../../../../interfaces/common'

export function isPremiumRelatedFieldChanged(
  initialData: Record<string, unknown>,
  allData: Record<string, unknown>,
  quotationSectionFields: IFieldConfig[]
): boolean {
  for (const field of quotationSectionFields) {
    if (!isNil(field.id) && !isEqual(get(allData, field.id), get(initialData, field.id))) {
      return true
    }
  }
  return false
}
