import styles from './styles.module.scss'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

export const ModalActions: React.FC<IProps> = ({ children }: React.PropsWithChildren<IProps>) => {
  return (
    <div className={styles.modalActions} data-test="modal-actions">
      {children}
    </div>
  )
}
