import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@alteos/ui'

import { WarningIcon } from '../../../../components/Icons/icons'
import { Modal } from '../../../../components/Modal/Modal'
import { getModalId } from '../../../Policy/store/selectors'
import { closeModal } from '../../store/actionCreators'
import { ModalId } from '../../store/interfaces'

interface IProps {
  modalId: ModalId
  children?: ReactNode | undefined
}

export const ConfirmationModal: React.FC<IProps> = ({ modalId, children }: IProps) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const currentModalId = useSelector(getModalId)
  const isOpen = currentModalId === modalId

  return (
    <Modal.Root variant="warning" isOpen={isOpen} modalId={modalId}>
      <Modal.Header icon={<WarningIcon />} />
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button
          small={true}
          noMinWidth={true}
          color="tertiary"
          dataTest="confirmation-modal-cancel"
          handleClick={() => {
            dispatch(closeModal(false))
          }}
        >
          {t('phrases.cancel')}
        </Button>
        <Button
          small={true}
          noMinWidth={true}
          color="primary"
          dataTest="confirmation-modal-confirm"
          handleClick={() => {
            dispatch(closeModal(true))
          }}
        >
          {t('phrases.confirm')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  )
}
