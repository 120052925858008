import { take } from 'typed-redux-saga'

import { CLOSE_MODAL } from '../../../../Modal/store/consts'
import { ICloseModalAction } from '../../../../Modal/store/interfaces'
import { IEndorsementModalResult } from '../../../interfaces'

export function* waitForEndorsementModalClose(): Generator<unknown, IEndorsementModalResult> {
  const result: ICloseModalAction<IEndorsementModalResult> = (yield* take(CLOSE_MODAL)) as any
  return result.payload.data
}
