import i18next from 'i18next'
import { takeLatest, call } from 'typed-redux-saga'

import { DaveUiEventType, Parcels } from '@alteos/dave-ui-lib'

import { events } from '../../platform/events'
import { I18N_SET_LANGUAGE } from './constants'
import { ISetLanguage } from './interfaces'
import { languageLocalStorage } from './languageLocalStorage'

export function* onLanguageChangedSideEffects(action: ISetLanguage): Generator {
  yield* call([i18next, 'changeLanguage'], action.payload.language)
  languageLocalStorage.setLanguage(action.payload.language)
  events.emit({ type: DaveUiEventType.LanguageSet, language: action.payload.language, src: Parcels.Dave })
}

function* watchLoginStart(): Generator {
  yield* takeLatest(I18N_SET_LANGUAGE, onLanguageChangedSideEffects)
}

export default (): Generator[] => [watchLoginStart()]
