import { InputType, isNilOrEmptyString } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { Loader } from '../../../../../components/Loader'
import { FieldType } from '../../../../../dictionaries'
import {
  IDividerViewFieldConfig,
  IElementViewFieldConfig,
  ITitleViewFieldConfig
} from '../../../../../interfaces/common'
import { forms } from '../../../constants'
import { Initiator } from '../../../interfaces'
import { IGeneratePolicyTerminationFormCardsArgs } from '../interfaces/IGeneratePolicyTerminationFormCardsArgs'
import { displayPolicyTerminationSuccessMessage } from './displayPolicyTerminationSuccessMessage'
import { mapExtraInputField } from './mapExtraInputField'

export const generatePolicyTerminationFormCards = ({
  rules,
  executedRule,
  refundAmount,
  isFetching,
  isExecutingTerminationRuleWithExtraInputFields,
  isExecutingTerminationRuleWithoutExtraInputFields,
  t,
  terminationReason
}: IGeneratePolicyTerminationFormCardsArgs): IFormConfig => {
  const terminationTypeValue =
    rules === null ? '' : rules.find((rule) => rule.name === terminationReason)?.terminationType

  const isTerminationReasonSelected = !isNilOrEmptyString(terminationReason)

  const extraInputFields = rules?.find((rule) => rule.name === terminationReason)?.extraInputFields ?? []

  const effectiveAtRangeMinimum =
    executedRule?.effectiveAtRange.minimum === null ? '' : executedRule?.effectiveAtRange.minimum

  const effectiveAtRangeToMaximum =
    executedRule?.effectiveAtRange.maximum === null ? '' : executedRule?.effectiveAtRange.maximum

  const effectiveAt = executedRule?.effectiveAtDefault

  return {
    form: forms.terminatePolicyForm,
    url: '',
    initialValues: {},
    formSections: [
      {
        title: t('policyTerminationForm.updateInformation'),
        fields: [
          {
            id: 'initiator',
            editType: InputType.select,
            label: t('policyTerminationForm.initiator'),
            required: true,
            options: [
              {
                value: t('policyTerminationForm.insurer'),
                key: Initiator.Insurer
              },
              {
                value: t('policyTerminationForm.policyHolder'),
                key: Initiator.Policyholder
              },
              {
                value: t('policyTerminationForm.purchaser'),
                key: Initiator.Purchaser
              }
            ]
          },
          {
            id: 'requestedAt',
            editType: InputType.date,
            label: t('policyTerminationForm.requestedAt'),
            required: true
          },
          {
            type: FieldType.Element,
            value: () => {
              return <Loader name="termination-form-rules-loader" />
            },
            hidden: rules !== null || !isFetching
          } as IElementViewFieldConfig,
          {
            type: FieldType.Divider,
            hidden: rules === null
          } as IDividerViewFieldConfig,
          {
            id: 'terminationReason',
            editType: InputType.select,
            required: true,
            label: t('policyTerminationForm.terminationReason.title'),
            options: (rules ?? []).map((rule) => {
              return {
                value: t(`policyTerminationForm.terminationReason.reasons.${rule.name}`),
                key: rule.name
              }
            }),
            hidden: rules === null
          },
          {
            type: FieldType.Divider,
            hidden: !isTerminationReasonSelected
          } as IDividerViewFieldConfig,
          {
            id: 'terminationType',
            editType: InputType.string,
            disabled: true,
            label: t('policyTerminationForm.terminationType.title'),
            value: t(`policyTerminationForm.terminationType.types.${terminationTypeValue}`),
            hidden: rules === null || !isTerminationReasonSelected
          },
          {
            type: FieldType.Title,
            value: t('policyTerminationForm.extraInput.title'),
            hidden: extraInputFields.length === 0
          } as ITitleViewFieldConfig,
          ...extraInputFields.map((extraInputField) => mapExtraInputField(extraInputField, t)),
          {
            type: FieldType.Element,
            value: () => {
              return <Loader name="termination-form-rule-loader" />
            },
            hidden:
              executedRule !== null ||
              (!isExecutingTerminationRuleWithExtraInputFields && !isExecutingTerminationRuleWithoutExtraInputFields)
          } as IElementViewFieldConfig,
          {
            id: 'effectiveAt',
            editType: InputType.date,
            label: t('policyTerminationForm.effectiveAt'),
            minimum: effectiveAtRangeMinimum,
            maximum: effectiveAtRangeToMaximum,
            hidden: executedRule === null,
            required: true
          },
          {
            type: FieldType.Divider,
            hidden: executedRule === null
          } as IDividerViewFieldConfig,
          {
            type: FieldType.Element,
            value: () => {
              return <Loader name="termination-form-refund-amount-loader" />
            },
            hidden: (executedRule === null && refundAmount === null) || !isFetching
          } as IElementViewFieldConfig,
          {
            id: 'refundAmount',
            editType: InputType.string,
            disabled: true,
            label: t('policyTerminationForm.refundAmount'),
            hidden: executedRule === null
          },
          {
            id: 'creditInvoiceAmount',
            editType: InputType.string,
            disabled: true,
            label: t('policyTerminationForm.creditInvoiceAmount'),
            hidden: executedRule === null
          },
          {
            id: 'helpers.note',
            editType: InputType.text,
            label: t('policyTerminationForm.note'),
            placeholder: t('policyTerminationForm.notePlaceholder'),
            maximum: 500,
            hidden: executedRule === null
          }
        ]
      }
    ],
    successMessage: displayPolicyTerminationSuccessMessage(effectiveAt, t)
  }
}
