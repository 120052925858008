import { CombinedState, combineReducers, Reducer } from 'redux'

import { IAction } from '../../../interfaces/store'
import { IPaymentsState, IPaymentTabs, PaymentTab } from '../interfaces'
import { SET_ACTIVE_PAYMENTS_TAB } from './constants'
import disputedPaymentReducer from './reducers/disputed'
import upcomingPaymentReducer from './reducers/upcoming'
import { getInitialPaymentsPageTab } from './utils/getInitialTab'

function paymentReducer(
  state: IPaymentTabs = { activeTab: getInitialPaymentsPageTab() },
  action: IAction<any>
): IPaymentTabs {
  switch (action.type) {
    case SET_ACTIVE_PAYMENTS_TAB:
      return { activeTab: action.payload as PaymentTab }
    default:
      return state
  }
}

const reducer: Reducer<CombinedState<IPaymentsState>, IAction<any>> = combineReducers({
  tabs: paymentReducer,
  upcomingPayments: upcomingPaymentReducer,
  disputedPayments: disputedPaymentReducer
})

export default reducer
