import isNil from 'lodash/isNil'
import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import {
  IFormFieldConfig,
  ISelectFormFieldConfig,
  IDateFormFieldConfig,
  ISelectOption
} from '../../../../../interfaces/common'
import { IExtraInputField } from '../../../interfaces'

export const mapExtraInputField = (extraInputField: IExtraInputField, t: TFunction): IFormFieldConfig => {
  const baseExtraInputField = {
    id: `extraInput.${extraInputField.name}`,
    editType: extraInputField.type as any,
    label: t(`policyTerminationForm.extraInput.fields.${extraInputField.name}`),
    required: extraInputField.required ?? true,
    placeholder: extraInputField.placeholder ?? null
  }

  const mapOption = (option: ISelectOption): ISelectOption => {
    if (extraInputField.name === 'newCountryOfResidence' && option.key) {
      return {
        key: option.key,
        value: t(`countryCodes:${option.key}`)
      }
    }
    return option
  }

  switch (extraInputField.type) {
    case InputType.select:
      return {
        ...baseExtraInputField,
        options: !isNil(extraInputField.options) ? extraInputField.options.map(mapOption) : null,
        searchable: extraInputField.searchable ?? false
      } as ISelectFormFieldConfig

    case InputType.date:
      return {
        ...baseExtraInputField,
        minimum: extraInputField.minimum ?? null,
        maximum: extraInputField.maximum ?? null
      } as IDateFormFieldConfig

    default:
      return baseExtraInputField
  }
}
