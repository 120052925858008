import { takeLatest } from 'typed-redux-saga'

import { openSideView } from '../../Layout'
import {
  CHANGE_IBAN_REQUEST,
  CREATE_BULK_PAYMENT_LINKS_REQUEST,
  CREATE_PAYMENT_LINK_REQUEST,
  GET_PAYMENT_LINK_REQUEST,
  LOAD_DISPUTED_PAYMENTS_REQUEST,
  LOAD_UPCOMING_PAYMENTS_REQUEST,
  ON_CHANGE_FILTERS_DISPUTED_PAYMENTS,
  ON_CHANGE_FILTERS_UPCOMING_PAYMENTS,
  RESOLVE_DISPUTED_PAYMENT_REQUEST,
  RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST,
  SET_ACTIVE_PAYMENTS_TAB,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  START_POLLING,
  UPDATE_PAYMENT_METHOD_REQUEST
} from './constants'
import {
  changeIBAN,
  loadDisputedPayments,
  resolveDisputedPayment,
  resolveMultipleDisputedPayments
} from './sagas/disputed'
import { setActivePaymentsTab } from './sagas/index'
import {
  createBulksPaymentLinks,
  createPaymentLink,
  getPaymentLinks,
  loadUpcomingPayments,
  startPollingSaga,
  updatePaymentMethod
} from './sagas/upcoming'

function* watchLoadDisputedPaymentsStart(): Generator {
  yield* takeLatest<any>(LOAD_DISPUTED_PAYMENTS_REQUEST, loadDisputedPayments)
}

function* watchLoadUpcomingPaymentsStart(): Generator {
  yield* takeLatest<any>(LOAD_UPCOMING_PAYMENTS_REQUEST, loadUpcomingPayments)
}

function* watchOpenSideView(): Generator {
  yield* takeLatest<any>(SET_ACTIVE_SIDE_VIEW_TYPE, openSideView)
}

function* watchFilterChangesUpcomingPayments(): Generator {
  yield* loadUpcomingPayments()
}

function* watchFilterChangesUpcomingPaymentsStart(): Generator {
  yield* takeLatest<any>(ON_CHANGE_FILTERS_UPCOMING_PAYMENTS, watchFilterChangesUpcomingPayments)
}

function* watchFilterChangesDisputedPayments(): Generator {
  yield* loadDisputedPayments()
}

function* watchFilterChangesDisputedPaymentsStart(): Generator {
  yield* takeLatest<any>(ON_CHANGE_FILTERS_DISPUTED_PAYMENTS, watchFilterChangesDisputedPayments)
}

function* watchUpdatePaymentMethodStart(): Generator {
  yield* takeLatest<any>(UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethod)
}

function* watchChangeIBANStart(): Generator {
  yield* takeLatest<any>(CHANGE_IBAN_REQUEST, changeIBAN)
}

function* watchCreatePaymentLinkStart(): Generator {
  yield* takeLatest<any>(CREATE_PAYMENT_LINK_REQUEST, createPaymentLink)
}

function* watchResolveDisputedPaymentStart(): Generator {
  yield* takeLatest<any>(RESOLVE_DISPUTED_PAYMENT_REQUEST, resolveDisputedPayment)
}

function* watchResolveMultipleDisputedPaymentsStart(): Generator {
  yield* takeLatest<any>(RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST, resolveMultipleDisputedPayments)
}

function* watchBulkCreatePaymentLinksStart(): Generator {
  yield* takeLatest<any>(CREATE_BULK_PAYMENT_LINKS_REQUEST, createBulksPaymentLinks)
}

function* watchGetPaymentLinkStart(): Generator {
  yield* takeLatest<any>(GET_PAYMENT_LINK_REQUEST, getPaymentLinks)
}

function* watchStartPollingSaga(): Generator {
  yield* takeLatest(START_POLLING, startPollingSaga)
}

function* watchTabChange(): Generator {
  yield* takeLatest(SET_ACTIVE_PAYMENTS_TAB, setActivePaymentsTab)
}

export default (): Generator[] => [
  watchLoadUpcomingPaymentsStart(),
  watchLoadDisputedPaymentsStart(),
  watchOpenSideView(),
  watchFilterChangesUpcomingPaymentsStart(),
  watchFilterChangesDisputedPaymentsStart(),
  watchResolveDisputedPaymentStart(),
  watchResolveMultipleDisputedPaymentsStart(),
  watchChangeIBANStart(),
  watchUpdatePaymentMethodStart(),
  watchCreatePaymentLinkStart(),
  watchGetPaymentLinkStart(),
  watchBulkCreatePaymentLinksStart(),
  watchStartPollingSaga(),
  watchTabChange()
]
