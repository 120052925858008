import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { forms } from '../../../constants'
import ReferralPartnerStatus from '../../../dictionaries/ReferralPartnerStatus'

export const isTerminateEnabled = (source: Record<string, any>, isProcessing: boolean): boolean =>
  [ReferralPartnerStatus.Approved].includes(source.status) && !isProcessing

const generateTerminateFormCards = (t: TFunction, source: Record<string, any>, isProcessing: boolean): IFormConfig => {
  const isTerminateButtonEnabled: boolean = isTerminateEnabled(source, isProcessing)

  const formConfig: IFormConfig = {
    form: forms.terminateReferralPartner,
    url: '',
    initialValues: { ...source, notes: '' },
    successMessage: `${t('referralPartner.edit.successMessageTerminate')}`,
    formSections: [
      {
        title: t('referralPartner.edit.statusTitle'),
        icon: 'upload',
        fields: [
          {
            id: 'notes',
            editType: InputType.text,
            label: t('referralPartner.edit.notes'),
            placeholder: t('referralPartner.edit.notesPlaceholder'),
            disabled: !isTerminateButtonEnabled,
            maximum: 500
          },
          {
            editType: InputType.button,
            label: t('referralPartner.edit.terminate'),
            disabled: !isTerminateButtonEnabled,
            dataTest: 'terminate-SP-button',
            noMinWidth: true,
            color: 'tertiary'
          }
        ]
      }
    ]
  }

  return formConfig
}

export default generateTerminateFormCards
