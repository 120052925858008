import isNil from 'lodash/isNil'

import { Action } from '@alteos/dictionaries/dist/Action'

import { PermissionConstraint, Permissions } from '../../interfaces'

export function hasPermission(currentPermissions: Permissions, permissionConstraint: PermissionConstraint): boolean {
  if (isNil(permissionConstraint)) {
    return true
  } else if (Array.isArray(permissionConstraint)) {
    return permissionConstraint.every((it: Action) => currentPermissions.includes(it))
  } else {
    return currentPermissions.includes(permissionConstraint)
  }
}
