import { CLOSE_MODAL, SHOW_MODAL } from './consts'
import { IAppModalState, IModalAction } from './interfaces'

const defaultModalState: IAppModalState = {
  modalId: null
}

export function modalReducer(state: IAppModalState = defaultModalState, action: IModalAction): IAppModalState {
  switch (action.type) {
    case CLOSE_MODAL:
      return { ...state, modalId: null }
    case SHOW_MODAL:
      return { ...state, modalId: action.payload.modalId }
    default:
      return state
  }
}
