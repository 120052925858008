import { IFilter } from '../interfaces'

const addNewFilter: (coll: IFilter[], key: string) => IFilter[] = (coll: IFilter[], key: string): IFilter[] => {
  return coll.map((item: IFilter) => {
    return {
      ...item,
      active: item.key === key
    }
  })
}

export default addNewFilter
