import { z } from 'zod'

import { localStorageService } from '../../../utils/localStorageService'

export class TokenCache {
  getAccessToken = (): string | null => localStorageService.getItem('accessToken', z.string())
  setAccessToken = (token: string): void => localStorageService.setItem('accessToken', token)

  getRefreshToken = (): string | null => localStorageService.getItem('refreshToken', z.string())
  setRefreshToken = (token: string): void => localStorageService.setItem('refreshToken', token)
  clearRefreshToken = (): void => {
    localStorageService.clearItem('refreshToken')
  }

  clearTokens = (): void => {
    localStorageService.clearItem('refreshToken')
    localStorageService.clearItem('accessToken')
  }
}

export const tokenCache = new TokenCache()
