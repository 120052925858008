import isNil from 'lodash/isNil'
import { Store, applyMiddleware, compose, createStore, AnyAction } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { APP_ENV } from '../config'
import { authCache } from '../modules/Auth/service/authCache'
import { tokenCache } from '../modules/Auth/service/tokenCache'
import { loginSuccess } from '../modules/Auth/store/actionCreators'
import { router } from '../routing'
import { debugLoggerMiddleware } from './customMiddleware'
import { IRootState } from './interfaces'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const sagaMiddleware = createSagaMiddleware()
sagaMiddleware.setContext({ router: router })

const composeEnhancers: any =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && APP_ENV !== 'prod'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

export function configureStore(initState: Partial<IRootState> = {}): {
  store: Store<IRootState, AnyAction>
} {
  const store: Store<IRootState, AnyAction> = createStore(
    rootReducer,
    initState,
    composeEnhancers(applyMiddleware(sagaMiddleware, debugLoggerMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  tryAuthFromCache(store)

  return { store }
}
function tryAuthFromCache(store: Store<IRootState, AnyAction>) {
  const user = authCache.getCurrent()
  const token = tokenCache.getAccessToken()
  const refreshToken = tokenCache.getRefreshToken()
  if (!isNil(user) && !isNil(token)) {
    store.dispatch(loginSuccess({ loginResponse: { ...user, token, refreshToken } }))
  }
}
