import { CombinedState, combineReducers, Reducer } from 'redux'

import { IAction } from '../../../interfaces/store'
import { DEFAULT_PAGINATION, DEFAULT_SORTING } from '../constants'
import PartnersManagementTab from '../dictionaries/PartnersManagementTab'
import { IPartnersManagementState, ITabs } from '../interfaces'
import { SET_ACTIVE_PARTERNS_MANAGEMENT_TAB } from './constants'
import partnersReducer from './reducers/partners'
import referralPartnersReducer from './reducers/referralPartners'

export const initialState: IPartnersManagementState = {
  tabs: { activeTab: PartnersManagementTab.ReferralPartner },
  partners: {
    isFetchingPartners: false,
    partners: [],
    pagination: DEFAULT_PAGINATION,
    errorMessage: null,
    query: ''
  },
  referralPartners: {
    isFetchingReferralPartners: false,
    referralPartners: [],
    pagination: DEFAULT_PAGINATION,
    errorMessage: null,
    query: DEFAULT_SORTING
  }
}

function partnersManagementReducer(
  state: ITabs = { activeTab: PartnersManagementTab.ReferralPartner },
  action: IAction<any>
): ITabs {
  switch (action.type) {
    case SET_ACTIVE_PARTERNS_MANAGEMENT_TAB:
      return { activeTab: action.payload as PartnersManagementTab }

    default:
      return state
  }
}

const reducer: Reducer<CombinedState<IPartnersManagementState>, IAction<any>> = combineReducers({
  tabs: partnersManagementReducer,
  partners: partnersReducer,
  referralPartners: referralPartnersReducer
})

export default reducer
