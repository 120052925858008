import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { reset } from 'redux-form'
import { call, put, select } from 'typed-redux-saga'

import ToastType from '../../../../dictionaries/ToastType'
import { IAction, IStore } from '../../../../interfaces/store'
import { actions as layoutActions } from '../../../Layout'
import { notesApi } from '../../api'
import { ICreateNoteActionPayload, INote, IUpdateNoteActionPayload } from '../../interfaces'
import { ADD_NOTE_FORM_NAME, EDIT_NOTE_FORM_NAME } from '../../screens/Policy/TabsContent/Notes/constants'
import {
  createNoteFailure,
  createNoteStop,
  createNoteSuccess,
  loadNotesFailure,
  loadNotesStart,
  loadNotesSuccess,
  setEditingNoteId,
  updateNoteFailure,
  updateNoteSuccess
} from '../actionCreators'

interface ICreateNoteResponse {
  id: string
}

interface IUpdateNoteResponse {
  id: string
}

function* createNoteInPolicyUpdate(text: string): Generator {
  const policyId: string = (yield* select((state: IStore) => state.policy.policy?.id)) as string

  let response: AxiosResponse<ICreateNoteResponse> | undefined
  let hasError = false
  try {
    response = yield* call(notesApi.createNoteInPolicyUpdate, policyId, text)
  } catch (error) {
    hasError = true
  }

  if (typeof response === 'undefined' || hasError) {
    yield* put(createNoteFailure())
    yield* put(
      layoutActions.displayToast({
        message: i18next.t('policy:forms.updateInformationSection.noteCreationError'),
        type: ToastType.Error
      })
    )
    return
  }

  yield* put(createNoteSuccess())
  yield* put(loadNotesStart(0))
}

function* createNoteStandalone(text: string): Generator {
  const editingNoteId: string | null = (yield* select((state: IStore) => state.policy.editingNoteId)) as string | null
  const isInEditMode: boolean = editingNoteId !== null
  if (isInEditMode) {
    yield* put(
      layoutActions.displayToast({
        message: i18next.t('policy:notesSideView.saveOrCancel'),
        type: ToastType.Warn
      })
    )
    yield* put(createNoteStop())
    return
  }

  const policyId: string = (yield* select((state: IStore) => state.policy.policy?.id)) as string

  let response: AxiosResponse<IUpdateNoteResponse> | undefined
  let hasError = false
  try {
    response = yield* call(notesApi.createNoteInPolicyUpdate, policyId, text)
  } catch (error) {
    hasError = true
  }

  yield* put(reset(ADD_NOTE_FORM_NAME))
  if (typeof response === 'undefined' || hasError) {
    yield* put(createNoteFailure())
    yield* put(
      layoutActions.displayToast({
        message: i18next.t('policy:forms.updateInformationSection.noteCreationError'),
        type: ToastType.Error
      })
    )
    return
  }

  yield* put(createNoteSuccess())
  yield* put(loadNotesStart(0))
  yield* put(
    layoutActions.displayToast({
      message: i18next.t('policy:notesSideView.addNote.successToast'),
      type: ToastType.Success
    })
  )
}

export function createNote({ payload }: IAction<ICreateNoteActionPayload>): Generator {
  const { text, isStandaloneCreate }: ICreateNoteActionPayload = payload
  if (isStandaloneCreate) {
    return createNoteStandalone(text)
  } else {
    return createNoteInPolicyUpdate(text)
  }
}

export function* editNoteStart({ payload }: IAction<string>): Generator {
  const editingNoteId: string | null = (yield* select((state: IStore) => state.policy.editingNoteId)) as string | null
  const isInEditMode: boolean = editingNoteId !== null
  if (isInEditMode) {
    yield* put(
      layoutActions.displayToast({
        message: i18next.t('policy:notesSideView.saveOrCancel'),
        type: ToastType.Warn
      })
    )
    return
  }
  yield* put(setEditingNoteId(payload))
}

export function* updateNote({ payload }: IAction<IUpdateNoteActionPayload>): Generator {
  const { noteId, text }: IUpdateNoteActionPayload = payload

  const policyId: string = (yield* select((state: IStore) => state.policy.policy?.id)) as string

  let response: AxiosResponse | undefined
  let hasError = false
  try {
    response = yield* call(notesApi.updateNote, policyId, noteId, text)
  } catch (error) {
    hasError = true
  }

  yield* put(reset(EDIT_NOTE_FORM_NAME))
  if (typeof response === 'undefined' || hasError) {
    yield* put(updateNoteFailure())
    yield* put(
      layoutActions.displayToast({
        message: i18next.t('policy:forms.updateInformationSection.noteCreationError'),
        type: ToastType.Error
      })
    )
    return
  }

  yield* put(updateNoteSuccess())
  yield* put(loadNotesStart(0))
  yield* put(
    layoutActions.displayToast({
      message: i18next.t('policy:notesSideView.editNote.successToast'),
      type: ToastType.Success
    })
  )
}

export function* loadNotes({ payload }: IAction<number>): Generator {
  const policyId: string = (yield* select((state: IStore) => state.policy.policy?.id)) as string
  const currentNotes: INote[] = (yield* select((state: IStore) => state.policy.notes)) as INote[]

  let response
  try {
    response = yield* call(notesApi.loadNotes, policyId, currentNotes, payload)
  } catch (error) {
    yield* put(loadNotesFailure())
    return
  }

  yield* put(loadNotesSuccess(response.notes))
}
