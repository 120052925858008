export enum PolicyUpdateReason {
  DamagedLicensePlate = 'damagedLicensePlate',
  InitialDataDiscrepancy = 'initialDataDiscrepancy',
  LicensePlateTheft = 'licensePlateTheft',
  CustomerRequest = 'customerRequest',
  ChangeOfRenter = 'changeOfRenter',
  GuaranteeWarranty = 'guaranteeWarranty',
  Relocation = 'relocation',
  DeviceActivation = 'deviceActivation',
  ChangeOfPolicyHolder = 'changeOfPolicyHolder',
  ChangeOfPersonalData = 'changeOfPersonalData',
  ChangeOfObjectData = 'changeOfObjectData',
  ChangeOfOtherData = 'changeOfOtherData',
  CorrectionOfPersonalData = 'correctionOfPersonalData',
  CorrectionOfObjectData = 'correctionOfObjectData',
  CorrectionOfOtherData = 'correctionOfOtherData',
  AddendumOfPersonalData = 'addendumOfPersonalData',
  AddendumOfObjectData = 'addendumOfObjectData',
  AddendumOfOtherData = 'addendumOfOtherData',
  ShiftingOfStartDate = 'shiftingOfStartDate'
}
