import { IUpdatePaymentMethodRequestPayload } from '../../interfaces'

export function prepareUpdatePaymentMethodBody(
  values: { [fieldName: string]: any },
  paymentScheduleId: string
): IUpdatePaymentMethodRequestPayload {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    iban: values.iban,
    paymentScheduleId
  }
}
