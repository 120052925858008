import { call, put } from 'typed-redux-saga'

import { IRequestPagination } from '@alteos/ui'

import { IAction } from '../../../../interfaces/store'
import { partnersApi } from '../../api/partnersApi'
import { IPartnersResponse } from '../../interfaces'
import { loadPartnersFailure, loadPartnersSuccess, saveErrorMessage } from '../actions/partners'

export function* loadPartners(action: IAction<IRequestPagination>): Generator {
  const { offset, limit, query }: IRequestPagination = action.payload

  try {
    const response: IPartnersResponse = yield* call(partnersApi.getPartners, {
      offset,
      limit,
      query
    })

    yield* put(loadPartnersSuccess(response))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch partners', error))
    yield* put(loadPartnersFailure())
  }
}
