/* eslint-disable no-magic-numbers */
import { datadogRum } from '@datadog/browser-rum'

import {
  API_URL,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SAMPLING_RATE,
  DATADOG_SITE,
  APP_ENV
} from '../config'

const initializeDataDogRum: () => void = (): void => {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: 'dave',
    env: APP_ENV,
    version: 'dave-release',
    sessionSampleRate: Number(DATADOG_SAMPLING_RATE), // add sessionReplaySampleRate and sessionSampleRate to be lower percentage for cost savings
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [API_URL]
  })
  datadogRum.startSessionReplayRecording()
}

export default initializeDataDogRum
