import React, { ReactElement, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from '../../../../../components/sideViewComponents'
import { IStore } from '../../../../../interfaces/store'
import { MODULE_NAME } from '../../../constants'
import { IInvoicePreview } from '../../../interfaces'
import alteosLogo from './alteos-logo.svg'

import styles from './invoicePreview.module.scss'

const replaceLogoSrc = (iframeDoc: Document, srcEndsWith: string): void => {
  const allImages = iframeDoc.querySelectorAll('img')
  const logoElements = Array.from(allImages).filter((img) => img.src.endsWith(srcEndsWith))

  logoElements.forEach((logoElement) => {
    logoElement.src = alteosLogo
  })
}

export const InvoicePreview: React.FC = (): ReactElement | null => {
  const { t } = useTranslation('invoices')
  const { invoicePreview, isFetchingInvoicePreview } = useSelector((state: IStore) => state[MODULE_NAME])
  const iframeRefs = useRef<(HTMLIFrameElement | null)[]>([])

  useEffect(() => {
    invoicePreview?.forEach((invoice: IInvoicePreview, index: number) => {
      const iframe = iframeRefs.current[index]
      if (!iframe) return

      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document
      if (!iframeDoc) return

      iframeDoc.open()
      iframeDoc.write(invoice.html)
      iframeDoc.close()

      replaceLogoSrc(iframeDoc, 'alteos.com/assets/alteos-logo.svg')
    })
  }, [invoicePreview])

  const renderInvoices = () =>
    invoicePreview?.map(({ pageSize = 'A4', orientation = 'portrait' }, index: number) => {
      if (orientation === 'landscape') return null

      const sizeClass = `frame${pageSize}`
      const combinedClassNames = `${styles.frameContainer} ${styles[sizeClass]}`

      return (
        <iframe
          key={index}
          ref={(el) => (iframeRefs.current[index] = el)}
          title={`Invoice Preview ${index}`}
          className={combinedClassNames}
        />
      )
    })

  return (
    <>
      <Container className={styles.title} title={t('invoices:sideView.title')} icon="add-note" />
      {isFetchingInvoicePreview ? null : !invoicePreview || !invoicePreview.length ? (
        <Container className={styles.notFound} title={t('invoices:sideView.notFound')} icon="cross-filled" />
      ) : (
        <>
          <Container className={styles.subtitle} subtitle={t('invoices:sideView.subtitle')} icon="details" />
          <div className={styles.invoicesContainer}>{renderInvoices()}</div>
        </>
      )}
    </>
  )
}
