enum PolicySideViewType {
  DocumentDetails = 'documentDetails',
  ClaimDetails = 'claimDetails',
  CreateClaimForm = 'createClaimForm',
  PolicyHolderDetails = 'policyHolderDetails',
  PolicyTerminationForm = 'policyTerminationForm',
  PolicyHolderDetailsForm = 'policyHolderDetailsForm',
  PolicyObjectForm = 'policyObjectForm',
  PolicyValuesForm = 'policyValuesForm',
  ClaimTransactionsForm = 'claimTransactionsForm',
  EditIbanForm = 'editIbanForm',
  ScheduledTerminationForm = 'scheduledTerminationForm'
}

export default PolicySideViewType
