export const GET_CLAIM_BY_ID_QUERY = `
  query getClaimById($claimId: String!) {
    claimById(id: $claimId) {
      id
      prettyId
      status
      subStatus
      values
      updatedAt
      currentReserveAmount
      transactions {
          type
          description
          updatedAt
          amount {
            value
          }
      }
      logs {
          action
          createdBy
          createdAt
          status
          note
      }
      documents {
          claimId
          createdAt
          name
          path
          purpose
          url
          version
      }
    }
  }
`
