import { AxiosError, isAxiosError } from 'axios'
import { call, delay, fork, put, select } from 'typed-redux-saga'

import { IFormConfig } from '../../../../components/Form/interfaces'
import { ToastType } from '../../../../dictionaries'
import { IAction, IStore } from '../../../../interfaces/store'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils'
import { actions as layoutActions } from '../../../Layout'
import { upcomingPaymentsApi } from '../../api'
import { MODULE_NAME } from '../../constants'
import { IUpcomingPayments } from '../../interfaces'
import {
  createBulkPaymentLinksSuccess,
  createPaymentLinkSuccess,
  getPaymentLinkSuccess,
  loadUpcomingPaymentsSuccess,
  saveErrorMessage,
  setSideView,
  updatePaymentMethodSuccess
} from '../actions/upcoming'
import {
  CREATE_BULK_PAYMENT_LINKS_FAILURE,
  CREATE_PAYMENT_LINK_FAILURE,
  GET_PAYMENT_LINK_FAILURE,
  LOAD_UPCOMING_PAYMENTS_FAILURE,
  START_POLLING,
  UPDATE_PAYMENT_METHOD_FAILURE
} from '../constants'
import { getFilterPayload } from '../utils/getFilterPayload'
import { prepareUpdatePaymentMethodBody } from '../utils/prepareUpdatePaymentMethodBody'

export function* loadUpcomingPayments(): Generator {
  try {
    const upcomingPaymentState = yield* select((state: IStore) => state[MODULE_NAME].upcomingPayments)

    let filterPayload = getFilterPayload(upcomingPaymentState.filters)

    const response: IUpcomingPayments[] = yield* call(
      upcomingPaymentsApi.loadUpcomingPayments,
      upcomingPaymentState.pagination,
      filterPayload,
      upcomingPaymentState.search
    )

    yield* put(loadUpcomingPaymentsSuccess(response))
  } catch (error) {
    yield* put(saveErrorMessage('Failed to fetch upcoming payments', LOAD_UPCOMING_PAYMENTS_FAILURE, error))
    throw error
  }
}

export function* createPaymentLink({ payload }: IAction<{ paymentScheduleId: string }>): Generator {
  try {
    yield* call(upcomingPaymentsApi.createPaymentLink, payload.paymentScheduleId)
    yield* put(createPaymentLinkSuccess())
    yield* call(getPaymentLinks, [payload.paymentScheduleId])
  } catch (error) {
    yield* put(saveErrorMessage('Failed to create payment link', CREATE_PAYMENT_LINK_FAILURE, error))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

export function* createBulksPaymentLinks({ payload }: IAction<{ paymentScheduleIds: string[] }>): Generator {
  try {
    yield* put(layoutActions.displayToast({ message: 'payment link creation is in progress', type: ToastType.Warn }))
    yield* call(upcomingPaymentsApi.createBulkPaymentLinks, payload.paymentScheduleIds)
    yield* put(createBulkPaymentLinksSuccess())
    yield* put({ type: START_POLLING, payload })
  } catch (error) {
    yield* put(saveErrorMessage('Failed to bulk create payment links', CREATE_BULK_PAYMENT_LINKS_FAILURE, error))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

function* pollSaga(payload: { paymentScheduleIds: string[] }): Generator {
  let upcomingPaymentState: IUpcomingPayments[] = []
  const pollingCount = 5
  const pollingDelay = 1000

  for (let i = 0; i < pollingCount; i++) {
    if (payload.paymentScheduleIds.length > 0) {
      yield* call(getPaymentLinks, payload.paymentScheduleIds)

      upcomingPaymentState = (yield* select(
        (state: IStore) => state[MODULE_NAME].upcomingPayments
      )).upcomingPayments.filter((payment) => payload.paymentScheduleIds.includes(payment.paymentScheduleId))

      // eslint-disable-next-line no-loop-func
      payload.paymentScheduleIds = payload.paymentScheduleIds.filter((id) => {
        return !upcomingPaymentState.some((upcomingPayment) => upcomingPayment.paymentLink)
      })
    }

    yield delay(pollingDelay)
  }

  let message =
    'Payment link(s) creation is either failed or still in progress, please refresh the page later and check again.'
  let type = ToastType.Warn

  if (upcomingPaymentState.every((payment) => payment.paymentLink)) {
    message = 'Payment link(s) creation is completed'
    type = ToastType.Success
  } else if (upcomingPaymentState.some((payment) => payment.paymentLink)) {
    message =
      "Some of the payment links are completed and some are either failed or still in progress, please refresh the page later and check again.'"
    type = ToastType.Warn
  }

  yield put(layoutActions.displayToast({ message, type }))
}

export function* startPollingSaga(action: IAction<{ paymentScheduleIds: string[] }>): Generator {
  yield fork(pollSaga, action.payload)
}

export function* getPaymentLinks(paymentScheduleIds: string[]): Generator {
  try {
    const paymentLinks = yield* call(upcomingPaymentsApi.getPaymentLinks, paymentScheduleIds)
    yield* put(getPaymentLinkSuccess(paymentLinks))
    return paymentLinks.paymentLinks
  } catch (error) {
    yield* put(saveErrorMessage('Failed to get payment link statuses', GET_PAYMENT_LINK_FAILURE, error))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

export function isClientError(error: AxiosError): boolean {
  // eslint-disable-next-line no-magic-numbers
  return error.request?.status >= 400 && error.request?.status < 500
}

export function* updatePaymentMethod({ payload }: IAction<IFormConfig & { paymentScheduleId: string }>): Generator {
  const { successMessage } = payload

  const formName = payload.form

  const formValues = requireNotNull(yield* select((state: IStore) => state.form[formName]))

  const { values }: { [fieldName: string]: any } | undefined = formValues

  const body = prepareUpdatePaymentMethodBody(values, payload.paymentScheduleId)
  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(upcomingPaymentsApi.changePaymentMethod, body)
    yield* put(updatePaymentMethodSuccess())
    yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* put(setSideView(null))
    yield* put(layoutActions.closeSideView())
  } catch (error) {
    yield* put(saveErrorMessage('Failed to update payment method', UPDATE_PAYMENT_METHOD_FAILURE, error))
    yield* put(layoutActions.setSideViewIsProcessingData(false))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}
