/* eslint-disable @typescript-eslint/typedef */
export const MODULE_NAME: 'auth' = 'auth' as const

export const LOGIN_START = `${MODULE_NAME}_START` as const
export const LOGIN_SUCCESS = `${MODULE_NAME}_SUCCESS` as const
export const LOGIN_FAILURE = `${MODULE_NAME}_FAILURE` as const
export const LOGOUT = `${MODULE_NAME}_LOGOUT` as const
export const REFRESH_TOKEN = `${MODULE_NAME}_REFRESH_TOKEN` as const
export const AUTH_CLEAR_ERROR = `${MODULE_NAME}_AUTH_CLEAR_ERROR` as const

export const LOGIN_ROUTE = '/login'
