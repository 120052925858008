import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IRootState } from '../../../../../store/interfaces'
import { forms, MODULE_NAME } from '../../../constants'
import { IClaim } from '../../../interfaces'
import { generateReduxFormConfig } from '../../../utils'
import generateClaimReserveFormCard from './utils/generateClaimReserveFormCard'

import styles from '../styles.module.scss'

type ClaimReserveProps = { claim: IClaim }

const ClaimReserveForm: FC<ClaimReserveProps> = ({ claim }: ClaimReserveProps): ReactElement => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector((state: IRootState) => state[MODULE_NAME].isSubmitting)
  const { t } = useTranslation(['policy'])

  const reserveFormConfig = generateClaimReserveFormCard(t, claim, styles)

  const reverseFormProps: IFormProps = generateReduxFormConfig(reserveFormConfig, dispatch, {
    hasFormButtons: true,
    form: forms.createTransactionReserveForm,
    dataTest: forms.createTransactionReserveForm,
    formType: FormType.CREATE,
    isSubmitting
  })

  return <Form {...reverseFormProps} />
}

export default ClaimReserveForm
