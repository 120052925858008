import { IFilter, IFilterOption } from '../../../components/Filters/interfaces'
import { IStore } from '../../../interfaces/store'
import { MODULE_NAME } from '../constants'

export const partnersSelector: any = (state: IStore) => {
  const partnersFilter: IFilter | undefined = state[MODULE_NAME].filtersParams.find(
    (filterParam: IFilter) => filterParam.key === 'partnerId'
  )

  if (typeof partnersFilter !== 'undefined' && partnersFilter.options !== null) {
    return partnersFilter.options.map(({ id, value }: IFilterOption) => ({ key: id, value }))
  }

  return []
}
