import cx from 'classnames'
import { FC, ReactNode, useEffect } from 'react'
import ReactFocusLock from 'react-focus-lock'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { Button, noop } from '@alteos/ui'

import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { SideViewSize } from '../../dictionaries'
import { ILayoutActions } from '../../interfaces/internal'
import actionsToBind from '../../store/actionCreators'

import styles from './sideView.module.scss'

interface IExternalProps {
  children: ReactNode
  size?: SideViewSize
  closeOnOutsideClick?: boolean
  dataTest: string
  className?: string
  handleClose?: () => void
}
interface IStoreProps {
  sideViewOpen: boolean
  sideViewIsProcessingData: boolean
}

interface IDispatchProps {
  actions: ILayoutActions
}

type IProps = IExternalProps & IStoreProps & IDispatchProps

const SideView: FC<IProps> = ({
  children,
  sideViewOpen,
  sideViewIsProcessingData,
  actions,
  size = SideViewSize.l,
  dataTest,
  closeOnOutsideClick = false,
  className = '',
  handleClose: handleCloseExternal
}: IProps) => {
  const containerCl: string = cx(styles.container, className, styles[size], { [styles.open]: sideViewOpen })

  const backgroundCl: string = cx(styles.backgroundContainer, styles[size], {
    [styles.open]: sideViewOpen
  })

  function handleClose(): void {
    if (typeof handleCloseExternal !== 'undefined') {
      handleCloseExternal()
    }

    actions.closeSideView()
  }

  // todo: Add types
  useEffect((): any => {
    const { closeSideView }: ILayoutActions = actions
    return () => closeSideView()
  }, [actions])

  return (
    <div className={containerCl}>
      <div
        className={backgroundCl}
        data-test="side-view-background"
        role="button"
        onClick={closeOnOutsideClick ? handleClose : noop}
      />
      <ReactFocusLock disabled={!sideViewOpen}>
        <div className={cx(styles.content, styles[size])} data-test={dataTest} id="side-view-container">
          <Button
            className={styles.closeButton}
            disabled={sideViewIsProcessingData}
            icon="cross-big"
            small
            color="outline"
            noMinWidth
            dataTest="close-side-view"
            handleClick={handleClose}
          />
          {children}
        </div>
      </ReactFocusLock>
    </div>
  )
}

function mapStateToProps(state: IStore): IStoreProps {
  return {
    sideViewOpen: state[MODULE_NAME].sideViewOpen,
    sideViewIsProcessingData: state[MODULE_NAME].sideViewIsProcessingData
  }
}

function mapDispatchToProps(dispatch: Dispatch): { actions: ILayoutActions } {
  return {
    actions: bindActionCreators(actionsToBind, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideView) as FC<IExternalProps>
