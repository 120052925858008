import i18next from 'i18next'

import { IFilter } from '../../../components/Filters/interfaces'
import { LabelType } from '../../../components/Label/dictionaries'
import { PolicyStatus } from '../../../dictionaries'
import { STATUS } from '../constants'

export function getDefaultPoliciesFilter(): IFilter {
  return {
    active: false,
    key: STATUS,
    value: 'Status',
    componentType: null,
    withSearch: false,
    selectedFilter: '',
    options: [
      {
        id: PolicyStatus.Issued,
        value: i18next.t('policies:policies.filters.policyStatus.issued'),
        labelType: LabelType.success
      },
      {
        id: PolicyStatus.Bound,
        value: i18next.t('policies:policies.filters.policyStatus.bound'),
        labelType: LabelType.warn
      },
      {
        id: PolicyStatus.Draft,
        value: i18next.t('policies:policies.filters.policyStatus.draft'),
        labelType: LabelType.neutral
      },
      {
        id: PolicyStatus.Cancelled,
        value: i18next.t('policies:policies.filters.policyStatus.cancelled'),
        labelType: LabelType.error
      },
      {
        id: PolicyStatus.Withdrawn,
        value: i18next.t('policies:policies.filters.policyStatus.withdrawn'),
        labelType: LabelType.error
      },
      {
        id: PolicyStatus.Expired,
        value: i18next.t('policies:policies.filters.policyStatus.expired'),
        labelType: LabelType.error
      }
    ],
    isCountable: true
  }
}
