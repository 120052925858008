import { AxiosResponse } from 'axios'

import defaultApiInstance, { Api } from '../../../../services/api/api'
import { mapProductConfig } from './mapProductConfig'
import { IConfigResponse } from './productConfigDto'

export class ProductConfigApi {
  constructor(private api: Api) {}
  getProductConfiguration = async (productId: string): Promise<IConfigResponse> => {
    const response: AxiosResponse<IConfigResponse> = await this.api.get(`v1/products/${productId}/ui/dave`)
    return mapProductConfig(response.data)
  }
}

export const productConfigApi: ProductConfigApi = new ProductConfigApi(defaultApiInstance)
