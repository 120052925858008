import { MODULE_NAME } from '../constants'

export const LOAD_UPCOMING_PAYMENTS_REQUEST = `LOAD_${MODULE_NAME}_REQUEST`
export const LOAD_UPCOMING_PAYMENTS_SUCCESS = `LOAD_${MODULE_NAME}_SUCCESS`
export const LOAD_UPCOMING_PAYMENTS_FAILURE = `LOAD_${MODULE_NAME}_FAILURE`
export const SET_ACTIVE_SIDE_VIEW_TYPE = `${MODULE_NAME}_SET_SIDE_VIEW`
export const SET_ACTIVE_PAYMENTS_TAB = `SET_ACTIVE_${MODULE_NAME}_TAB` as const

export const ADD_FILTER_UPCOMING_PAYMENTS = `${MODULE_NAME}_ADD_FILTER_UPCOMING_PAYMENTS`
export const SET_FILTER_UPCOMING_PAYMENTS = `${MODULE_NAME}_SET_FILTER_UPCOMING_PAYMENTS`
export const REMOVE_FILTER_UPCOMING_PAYMENTS = `${MODULE_NAME}_REMOVE_FILTER_UPCOMING_PAYMENTS`
export const RESET_FILTERS_UPCOMING_PAYMENTS = `${MODULE_NAME}_RESET_FILTERS_UPCOMING_PAYMENTS`
export const ON_CHANGE_FILTERS_UPCOMING_PAYMENTS = `${MODULE_NAME}_ON_CHANGE_FILTERS_UPCOMING_PAYMENTS`

export const UPDATE_PAYMENT_METHOD_REQUEST = `${MODULE_NAME}_UPDATE_PAYMENT_METHOD_REQUEST`
export const UPDATE_PAYMENT_METHOD_SUCCESS = `${MODULE_NAME}_UPDATE_PAYMENT_METHOD_SUCCESS`
export const UPDATE_PAYMENT_METHOD_FAILURE = `${MODULE_NAME}_UPDATE_PAYMENT_METHOD_FAILURE`

export const CREATE_PAYMENT_LINK_REQUEST = `${MODULE_NAME}_CREATE_PAYMENT_LINK_REQUEST`
export const CREATE_PAYMENT_LINK_SUCCESS = `${MODULE_NAME}_CREATE_PAYMENT_LINK_SUCCESS`
export const CREATE_PAYMENT_LINK_FAILURE = `${MODULE_NAME}_CREATE_PAYMENT_LINK_FAILURE`

export const CREATE_BULK_PAYMENT_LINKS_REQUEST = `${MODULE_NAME}_CREATE_BULK_PAYMENT_LINK_REQUEST`
export const CREATE_BULK_PAYMENT_LINKS_SUCCESS = `${MODULE_NAME}_CREATE_BULK_PAYMENT_LINKS_SUCCESS`
export const CREATE_BULK_PAYMENT_LINKS_FAILURE = `${MODULE_NAME}_CREATE_BULK_PAYMENT_LINKS_FAILURE`

export const GET_PAYMENT_LINK_REQUEST = `${MODULE_NAME}_GET_PAYMENT_LINK_REQUEST`
export const GET_PAYMENT_LINK_SUCCESS = `${MODULE_NAME}_GET_PAYMENT_LINK_SUCCESS`
export const GET_PAYMENT_LINK_FAILURE = `${MODULE_NAME}_GET_PAYMENT_LINK_FAILURE`

export const LOAD_DISPUTED_PAYMENTS_REQUEST = `LOAD_DISPUTED_${MODULE_NAME}_REQUEST`
export const LOAD_DISPUTED_PAYMENTS_SUCCESS = `LOAD_DISPUTED_${MODULE_NAME}_SUCCESS`
export const LOAD_DISPUTED_PAYMENTS_FAILURE = `LOAD_DISPUTED_${MODULE_NAME}_FAILURE`

export const ADD_FILTER_DISPUTED_PAYMENTS = `${MODULE_NAME}_ADD_FILTER_DISPUTED_PAYMENTS`
export const SET_FILTER_DISPUTED_PAYMENTS = `${MODULE_NAME}_SET_FILTER_DISPUTED_PAYMENTS`
export const REMOVE_FILTER_DISPUTED_PAYMENTS = `${MODULE_NAME}_REMOVE_FILTER_DISPUTED_PAYMENTS`
export const RESET_FILTERS_DISPUTED_PAYMENTS = `${MODULE_NAME}_RESET_FILTERS_DISPUTED_PAYMENTS`
export const ON_CHANGE_FILTERS_DISPUTED_PAYMENTS = `${MODULE_NAME}_ON_CHANGE_FILTERS_DISPUTED_PAYMENTS`

export const START_POLLING = `${MODULE_NAME}_START_POLLING`

export const RESOLVE_DISPUTED_PAYMENT_INITIATED = `${MODULE_NAME}_RESOLVE_DISPUTED_PAYMENT_INITIATED`
export const RESOLVE_DISPUTED_PAYMENT_CANCELLED = `${MODULE_NAME}_RESOLVE_DISPUTED_PAYMENT_CANCELLED`
export const RESOLVE_DISPUTED_PAYMENT_REQUEST = `${MODULE_NAME}_RESOLVE_DISPUTED_PAYMENT_REQUEST`
export const RESOLVE_DISPUTED_PAYMENT_SUCCESS = `${MODULE_NAME}_RESOLVE_DISPUTED_PAYMENT_SUCCESS`
export const RESOLVE_DISPUTED_PAYMENT_FAILURE = `${MODULE_NAME}_RESOLVE_DISPUTED_PAYMENT_FAILURE`

export const RESOLVE_MULTIPLE_DISPUTED_PAYMENT_INITIATED = `${MODULE_NAME}_RESOLVE_MULTIPLE_DISPUTED_PAYMENT_INITIATED`
export const RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST = `${MODULE_NAME}_RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST`

export const CHANGE_IBAN_REQUEST = `${MODULE_NAME}_CHANGE_IBAN_REQUEST`
export const CHANGE_IBAN_SUCCESS = `${MODULE_NAME}_CHANGE_IBAN_SUCCESS`
export const CHANGE_IBAN_FAILURE = `${MODULE_NAME}_CHANGE_IBAN_FAILURE`
