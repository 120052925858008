import { ReactElement, useCallback } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { isValid as isValidForm, submit as submitForm } from 'redux-form'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { IAction, IStore } from '../../../../../interfaces/store'
import { forms, MODULE_NAME } from '../../../constants'
import { IReferralPartner } from '../../../interfaces/referralPartner'
import { setSideView } from '../../../store/actions/referralPartner'
import mapReferralPartner from '../../../store/utils/mapReferralPartner'
import generateApproveRejectFormCards from '../utils/generateApproveRejectFormCards'
import { shouldAsyncValidateReferralPartnerForm } from '../utils/shouldAsyncValidate'

export const ApproveRejectForm = (): ReactElement => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')

  const dispatch: Dispatch = useDispatch()

  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )

  const isSubmittingActivation: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.isActivatingReferralPartner
  )

  const isPartnerStatusFormValid = isValidForm(forms.approveReferralPartner)(useSelector((store: IStore) => store))

  const handleApproveRejectSubmit = useCallback(
    (action: IAction<IFormConfig>) => {
      submitForm(forms.approveReferralPartner)

      if (isPartnerStatusFormValid) {
        dispatch(action)
      }
    },
    [isPartnerStatusFormValid, dispatch]
  )

  const completeReferralPartner: IReferralPartner = mapReferralPartner(referralPartner)
  const approveRejectFormConfig: IFormConfig = generateApproveRejectFormCards(
    t,
    completeReferralPartner,
    isSubmittingActivation,
    handleApproveRejectSubmit
  )

  const approveRejectFormProps: IFormProps = {
    ...approveRejectFormConfig,
    dataTest: 'referralpartner-activate-form',
    source: completeReferralPartner,
    hasFormButtons: true,
    isSubmitting: isSubmittingActivation,
    formType: FormType.CREATE,
    shouldAsyncValidate: shouldAsyncValidateReferralPartnerForm,
    onClose: () => dispatch(setSideView(null)),
    onSubmit: () => {}
  }

  return <Form {...approveRejectFormProps} />
}
