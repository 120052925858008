export enum LabelMode {
  light = 'light',
  filled = 'filled',
  outline = 'outline',
  normal = 'normal'
}

export enum LabelType {
  info = 'info',
  success = 'success',
  warn = 'warn',
  error = 'error',
  neutral = 'neutral'
}
