import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { IPermission } from '../../interfaces/permissions'
import { LOAD_PERMISSIONS_FAILURE, LOAD_PERMISSIONS_REQUEST, LOAD_PERMISSIONS_SUCCESS } from '../constants'

export function loadPermissionsStart(payload: string = ''): IAction<string> {
  return {
    type: LOAD_PERMISSIONS_REQUEST,
    payload
  }
}
export function loadPermissionsSuccess(payload: IPermission[]): IAction<any> {
  return {
    type: LOAD_PERMISSIONS_SUCCESS,
    payload
  }
}

export function loadPermissionsFailure(): IBasicAction {
  return {
    type: LOAD_PERMISSIONS_FAILURE
  }
}

export function saveErrorMessage(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: LOAD_PERMISSIONS_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}
