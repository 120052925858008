import { IReferralPartnersResponse } from '../../interfaces/referralPartners'
import mapReferralPartner from './mapReferralPartner'

const mapReferralPartnersList = (response: IReferralPartnersResponse): IReferralPartnersResponse => {
  response.partnerRequests = response.partnerRequests.map(mapReferralPartner)

  return response
}

export default mapReferralPartnersList
