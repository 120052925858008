import cx from 'classnames'
import { FC } from 'react'

import { ITitleViewFieldConfig } from '../../../../../interfaces/common'
import { FieldValue } from '../../../interfaces'

import styles from '../../card.module.scss'

interface IProps {
  field: ITitleViewFieldConfig
  value: FieldValue
}
const TitleItem: FC<IProps> = ({ field, value }: IProps) => (
  <h4 className={cx(styles.title, styles.item)} data-test={field.id}>
    {typeof value === 'string' ? value : null}
  </h4>
)

export default TitleItem
