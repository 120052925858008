enum FieldType {
  Title = 'title',
  Divider = 'divider',
  Heading = 'heading',
  Status = 'status',
  Text = 'text',
  Tags = 'tags',
  Element = 'element',
  FileName = 'fileName',
  Button = 'button'
}

export default FieldType
