import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../../components/Form/interfaces'
import { ClaimStatus } from '../../../../../../dictionaries'
import { forms, getClaimSubStatuses } from '../../../../constants'
import { IClaim } from '../../../../interfaces'
import { claimStatusFormFields } from '../../../ClaimDetails/utils/claimStatusFormFields'

const generateUpdateClaimStatusFormCard = (t: TFunction, claim: IClaim, selectedStatus: string): IFormConfig => {
  const subStatuses = getClaimSubStatuses(selectedStatus as ClaimStatus)

  const statusUpdateFormConfig: IFormConfig = {
    form: forms.editTransactionUpdateStatusForm,
    url: `v1/claims/${claim.id}/status/update`,
    initialValues: {
      status: claim.status,
      subStatus: claim.subStatus
    },
    successMessage: `${t('policyClaimsTransactionsForm.statusUpdateForm.successMessage')}`,
    formSections: [
      {
        title: t('policyClaimsTransactionsForm.statusUpdateForm.title'),
        fields: [
          ...claimStatusFormFields(t, subStatuses),
          {
            editType: InputType.button,
            label: t('policyClaimsTransactionsForm.statusUpdateForm.button')
          }
        ]
      }
    ]
  }

  return statusUpdateFormConfig
}

export default generateUpdateClaimStatusFormCard
