import { AxiosResponse } from 'axios'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { IPartnerResponse, IReferralPartnerLog, IReferralPartnerProduct } from '../interfaces'
import { IUpdatePermissionRequest, IPermissionsResponse, IUpdatePermissionResponse } from '../interfaces/permissions'
import { GENERIC_PARTNER_GET_PRODUCTS, GENERIC_PARTNER_ID, REFERRAL_PARTNERS } from './endpointConsts'

export class PartnerApi {
  constructor(private api: Api) {}

  getPartnerById = async (partnerId: string): Promise<IPartnerResponse> => {
    const endpoiont = `/v1/partners/${partnerId}`

    const response: AxiosResponse<IPartnerResponse> = await this.api.get(endpoiont)
    return response.data
  }

  getReferralPartnerRequest = async (referralPartnerId: string): Promise<AxiosResponse> => {
    const url = `${REFERRAL_PARTNERS}/${referralPartnerId}`
    return await this.api.get(url)
  }

  getProductsReferralPartnerRequest = async (): Promise<AxiosResponse> => {
    const url = GENERIC_PARTNER_GET_PRODUCTS.replace(GENERIC_PARTNER_ID, 'e230a2f4-344c-4eb8-8f1d-9137e9c9b2f3')
    return await this.api.get(url)
  }

  getPartnerGroupsByPartnerId = async (partnerId: string): Promise<IPartnerResponse> => {
    const endpoiont = `/v1/partners/${partnerId}/groups`

    const response: AxiosResponse<IPartnerResponse> = await this.api.get(endpoiont)
    return response.data
  }

  listPermissions = async (query?: string): Promise<IPermissionsResponse> => {
    const endpoiont = '/v1/permissions'
    const params: URLSearchParams = new URLSearchParams([['query', query ?? '']])

    const response: AxiosResponse<IPermissionsResponse> = await this.api.get(endpoiont, params)
    return response.data
  }

  updateGroupById = async (
    groupId: string,
    permission: IUpdatePermissionRequest
  ): Promise<IUpdatePermissionResponse> => {
    const endpoiont = `/v1/groups/${groupId}/update`

    const response: AxiosResponse<IUpdatePermissionResponse> = await this.api.post(endpoiont, permission)
    return response.data
  }

  getPartnerLog = async (partnerId: string): Promise<IReferralPartnerLog[]> => {
    const url = `${REFERRAL_PARTNERS}/${partnerId}/logs`
    return this.api.get(url).then((response) => response.data)
  }

  getProductsConfiguration = async (): Promise<IReferralPartnerProduct[]> => {
    const url = `${REFERRAL_PARTNERS}/products`
    return this.api.get(url).then((response) => response.data)
  }
}

export const partnerApi: PartnerApi = new PartnerApi(defaultApiInstance)
