import { isNil } from 'lodash'
import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CommonFieldInputProps, Field, InjectedFormProps, WrappedFieldMetaProps, reduxForm } from 'redux-form'

import { Button, FormElement } from '@alteos/ui'

import { required, email } from '../../../../components/Form/utils/validators'
import { IRootState } from '../../../../store/interfaces'
import { clearAuthError } from '../../store/actionCreators'

import styles from './loginForm.module.scss'

interface IFormProps extends InjectedFormProps {}

interface IRenderFieldProps {
  input: CommonFieldInputProps
  label: string
  type: string
  meta: WrappedFieldMetaProps
  icon: string
}

const renderField: FC<IRenderFieldProps> = ({
  input,
  label,
  type,
  // eslint-disable-next-line @typescript-eslint/typedef
  meta: { touched, error },
  icon
}: IRenderFieldProps): JSX.Element => {
  return (
    <FormElement
      type="string"
      error={error}
      touched={touched}
      {...input}
      label={label}
      passwordInput={type === 'password'}
      icon={{ name: icon }}
    />
  )
}

const Form: FC<IFormProps> = ({ handleSubmit, valid }: IFormProps) => {
  const isFetching = useSelector((state: IRootState) => state.auth.isFetching)
  const errorMessage = useSelector((state: IRootState) => state.auth.errorMessage)
  const dispatch = useDispatch()
  const [t]: UseTranslationResponse<('login' | 'common')[]> = useTranslation(['login', 'common'])
  const clearMessage = () => dispatch(clearAuthError())

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Field
        name="username"
        type="text"
        component={renderField}
        label="Email"
        validate={[required, email]}
        icon="user"
        onBlur={() => clearMessage()}
      />
      <Field
        name="password"
        type="password"
        component={renderField}
        label={t('common:form.password')}
        validate={[required]}
        icon="lock-closed"
        onBlur={() => clearMessage()}
      />
      <Button
        type="submit"
        disabled={!valid || isFetching}
        dataTest="submit-login"
        style={{ width: '100%' }}
        icon={isFetching ? 'loader' : ''}
      >
        {isFetching ? '' : t('login.sign_in')}
      </Button>
      {!isNil(errorMessage) ? <div className={styles.errorMessage}>{t(`common:form.${errorMessage}`)}</div> : null}
    </form>
  )
}

export default reduxForm({
  form: 'loginForm'
})(Form)
