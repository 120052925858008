import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from '../../../../components/sideViewComponents'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { IPolicyDocument } from '../../interfaces'
import DocumentCard from '../../screens/Policy/TabsContent/Documents/Cards/DocumentCard'
import PreviousVersionsTable from './Tables/PreviousVersionsTable'

import styles from './documentDetails.module.scss'

const DocumentDetails: FC = (): ReactElement | null => {
  const { t } = useTranslation('policy')
  const document = useSelector((state: IStore) => {
    const modalParams = state[MODULE_NAME].modalParams
    const documentId = modalParams?.id
    const document = state.policy.policy?.documents?.find(
      // NOTE: Temporary using index as id.
      (item: IPolicyDocument, index: number) => item.createdAt !== '' && index === Number(documentId)
    )
    return document ?? null
  })

  if (document === null) {
    return null
  }

  return (
    <>
      <Container
        className={styles.container}
        title={t('documentDetails.currentFileVersion')}
        dataTest={`modal-container-${document.name}`}
      >
        <DocumentCard document={document} isDetailsMode={true} dataTest={`modal-document-details-${document.name}`} />
      </Container>
      <PreviousVersionsTable document={document} />
    </>
  )
}

export default DocumentDetails
