import { take } from 'typed-redux-saga'

import { CLOSE_MODAL } from '../../store/consts'
import { ICloseModalAction } from '../../store/interfaces'
import { IConfirmationModalResult } from './interfaces'

export function* waitForConfirmModalClose(): Generator<unknown, boolean> {
  const result: ICloseModalAction<IConfirmationModalResult> = (yield* take(CLOSE_MODAL)) as any
  return result.payload.data
}
