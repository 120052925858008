import { IRequestPagination } from '@alteos/ui'

import { IAction } from '../../../interfaces/store'
import { LOGOUT } from '../../Auth/constants'
import { DEFAULT_PAGINATION, SET_ACTIVE_INVOICES_TAB } from '../constants'
import { IInvoicePreviewResponse, IInvoicesResponse, IInvoicesState, InvoicesTab } from '../interfaces'
import {
  LOAD_INVOICES_FAILURE,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICE_PREVIEW_FAILURE,
  LOAD_INVOICE_PREVIEW_REQUEST,
  LOAD_INVOICE_PREVIEW_SUCCESS,
  SET_INVOICE_SIDE_VIEW,
  SET_SHOW_ACTIONS
} from './constants'

export const initialState: IInvoicesState = {
  tabs: { activeTab: InvoicesTab.Groups },
  invoices: [],
  isFetchingInvoices: false,
  showActionsInvoiceId: null,
  pagination: DEFAULT_PAGINATION,
  errorMessage: null,
  activeSideView: false,
  isFetchingInvoicePreview: false,
  invoicePreview: null
}

function reducer(state: IInvoicesState = initialState, action: IAction<any>): IInvoicesState {
  switch (action.type) {
    case LOAD_INVOICES_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }

      return {
        ...state,
        isFetchingInvoices: true,
        pagination: {
          ...state.pagination,
          limit: payload.limit,
          offset: payload.offset
        }
      }
    }

    case LOAD_INVOICES_SUCCESS: {
      const { payload }: IAction<IInvoicesResponse> = action

      return {
        ...state,
        isFetchingInvoices: false,
        invoices: payload.invoices,
        pagination: {
          ...state.pagination,
          total: payload.count
        }
      }
    }

    case LOAD_INVOICES_FAILURE: {
      return {
        ...state,
        isFetchingInvoices: false,
        pagination: DEFAULT_PAGINATION
      }
    }

    case LOAD_INVOICE_PREVIEW_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }

      return {
        ...state,
        isFetchingInvoicePreview: true,
        invoicePreview: null
      }
    }

    case LOAD_INVOICE_PREVIEW_SUCCESS: {
      const { payload }: IAction<IInvoicePreviewResponse> = action

      return {
        ...state,
        isFetchingInvoicePreview: false,
        invoicePreview: payload.invoicePreview
      }
    }

    case LOAD_INVOICE_PREVIEW_FAILURE: {
      return {
        ...state,
        isFetchingInvoicePreview: false,
        invoicePreview: null
      }
    }

    case SET_SHOW_ACTIONS: {
      const { payload }: IAction<string | null> = action
      return {
        ...state,
        showActionsInvoiceId: payload || null
      }
    }

    case SET_ACTIVE_INVOICES_TAB:
      return { ...state, tabs: { activeTab: action.payload as InvoicesTab } }

    case SET_INVOICE_SIDE_VIEW: {
      const { payload }: IAction<string | null> = action
      if (payload === null) {
        return {
          ...state,
          activeSideView: false
        }
      }

      return {
        ...state,
        activeSideView: true,
        showActionsInvoiceId: null
      }
    }

    case LOGOUT: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
