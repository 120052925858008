import { ReactElement } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { IStore } from '../../../../../interfaces/store'
import { MODULE_NAME } from '../../../constants'
import { IReferralPartner } from '../../../interfaces/referralPartner'
import { approveReferralPartnerStart, setSideView } from '../../../store/actions/referralPartner'
import mapReferralPartner from '../../../store/utils/mapReferralPartner'
import generateApproveFormCards from '../utils/generateApproveFormCards'

export const ApproveForm = (): ReactElement => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')

  const dispatch: Dispatch = useDispatch()

  const referralPartner: IReferralPartner | undefined = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.referralPartner
  )

  const isSubmittingActivation: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.isActivatingReferralPartner
  )
  const isSubmittingRejection: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.isRejectingReferralPartner
  )

  const completeReferralPartner: IReferralPartner = mapReferralPartner(referralPartner)

  const approveFormConfig: IFormConfig = generateApproveFormCards(t, completeReferralPartner, isSubmittingActivation)

  const approveFormProps: IFormProps = {
    ...approveFormConfig,
    dataTest: 'referralpartner-approve-form',
    source: completeReferralPartner,
    hasFormButtons: true,
    isSubmitting: isSubmittingRejection,
    formType: FormType.CREATE,
    onClose: () => dispatch(setSideView(null)),
    onSubmit: () => dispatch(approveReferralPartnerStart(approveFormConfig))
  }

  return <Form {...approveFormProps} />
}
