import { IReferralPartner } from '../../interfaces/referralPartners'
import { mapCommissionRateValueToString } from './mapCommissionValueToNumber'

const mapReferralPartner = (request: IReferralPartner): IReferralPartner => {
  const products: any = {}

  const selectedProductIds: string[] =
    typeof request.productSelectionIds === 'string' ? request.productSelectionIds.split(',') : []
  for (const id of selectedProductIds) {
    products[id] = true
  }

  if (typeof request.productConfigExtension !== 'undefined' && request.productConfigExtension !== null) {
    request.productConfigExtension = mapCommissionRateValueToString(request.productConfigExtension)
  } else {
    request.productConfigExtension = {}
  }

  return { ...request, products, iban: request.IBAN }
}

export default mapReferralPartner
