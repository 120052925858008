import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'

import { I18N_SET_LANGUAGE } from './constants'
import { ISetLanguage } from './interfaces'

export function setLanguage(language: ISupportedLanguage): ISetLanguage {
  return {
    type: I18N_SET_LANGUAGE,
    payload: {
      language
    }
  }
}
