import { IProduct } from '../../../../interfaces/common'
import { IAction } from '../../../../interfaces/store'
import { IReferralPartner, IReferralPartnerLog, IReferralPartnerState, ISetSideViewPayload } from '../../interfaces'
import {
  APPROVE_REFERRAL_PARTNER_FAILURE,
  APPROVE_REFERRAL_PARTNER_REQUEST,
  APPROVE_REFERRAL_PARTNER_SUCCESS,
  GET_REFERRAL_PARTNER_LOG_SUCCESS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_REFERRAL_PARTNER_FAILURE,
  LOAD_REFERRAL_PARTNER_REQUEST,
  LOAD_REFERRAL_PARTNER_SUCCESS,
  REJECT_REFERRAL_PARTNER_FAILURE,
  REJECT_REFERRAL_PARTNER_REQUEST,
  REJECT_REFERRAL_PARTNER_SUCCESS,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  TERMINATE_REFERRAL_PARTNER_FAILURE,
  TERMINATE_REFERRAL_PARTNER_REQUEST,
  TERMINATE_REFERRAL_PARTNER_SUCCESS,
  UPDATE_REFERRAL_PARTNER_FAILURE,
  UPDATE_REFERRAL_PARTNER_REQUEST,
  UPDATE_REFERRAL_PARTNER_SUCCESS
} from '../constants'
import { defaultReferralPartner } from '../utils/defaultReferralPartner'

export const initialState: IReferralPartnerState = {
  isFetchingPartner: false,
  isActivatingReferralPartner: false,
  isUpdatingReferralPartner: false,
  isRejectingReferralPartner: false,
  isTerminatingReferralPartner: false,
  referralPartner: defaultReferralPartner,
  activeSideViewType: null,
  products: [],
  referralPartnerLog: [],
  errorMessage: null
}

function reducer(state: IReferralPartnerState = initialState, action: IAction<any>): IReferralPartnerState {
  switch (action.type) {
    case LOAD_REFERRAL_PARTNER_REQUEST: {
      return {
        ...state,
        isFetchingPartner: true
      }
    }

    case LOAD_PRODUCTS_SUCCESS: {
      const { payload }: IAction<IProduct[]> = action

      return {
        ...state,
        products: payload
      }
    }

    case LOAD_REFERRAL_PARTNER_SUCCESS: {
      const { payload }: IAction<IReferralPartner> = action

      return {
        ...state,
        referralPartner: payload,
        isFetchingPartner: false
      }
    }

    case GET_REFERRAL_PARTNER_LOG_SUCCESS: {
      const { payload }: IAction<IReferralPartnerLog[]> = action

      return {
        ...state,
        referralPartnerLog: payload
      }
    }

    case LOAD_REFERRAL_PARTNER_FAILURE: {
      return {
        ...state,
        isFetchingPartner: false
      }
    }

    case UPDATE_REFERRAL_PARTNER_REQUEST: {
      return {
        ...state,
        isUpdatingReferralPartner: true
      }
    }

    case UPDATE_REFERRAL_PARTNER_SUCCESS: {
      return {
        ...state,
        isUpdatingReferralPartner: false
      }
    }

    case UPDATE_REFERRAL_PARTNER_FAILURE: {
      return {
        ...state,
        isUpdatingReferralPartner: false
      }
    }

    case SET_ACTIVE_SIDE_VIEW_TYPE: {
      const { payload }: IAction<ISetSideViewPayload | null> = action

      if (payload === null) {
        return {
          ...state,
          activeSideViewType: null
        }
      }

      return {
        ...state,
        activeSideViewType: payload.type
      }
    }

    case APPROVE_REFERRAL_PARTNER_REQUEST: {
      return {
        ...state,
        isActivatingReferralPartner: true
      }
    }

    case APPROVE_REFERRAL_PARTNER_SUCCESS: {
      return {
        ...state,
        isActivatingReferralPartner: false
      }
    }

    case APPROVE_REFERRAL_PARTNER_FAILURE: {
      return {
        ...state,
        isActivatingReferralPartner: false
      }
    }

    case REJECT_REFERRAL_PARTNER_REQUEST: {
      return {
        ...state,
        isRejectingReferralPartner: true
      }
    }

    case REJECT_REFERRAL_PARTNER_SUCCESS: {
      return {
        ...state,
        isRejectingReferralPartner: false
      }
    }

    case REJECT_REFERRAL_PARTNER_FAILURE: {
      return {
        ...state,
        isRejectingReferralPartner: false
      }
    }

    case TERMINATE_REFERRAL_PARTNER_REQUEST: {
      return {
        ...state,
        isTerminatingReferralPartner: true
      }
    }

    case TERMINATE_REFERRAL_PARTNER_SUCCESS: {
      return {
        ...state,
        isTerminatingReferralPartner: false
      }
    }

    case TERMINATE_REFERRAL_PARTNER_FAILURE: {
      return {
        ...state,
        isTerminatingReferralPartner: false
      }
    }

    default:
      return state
  }
}

export default reducer
