import isNil from 'lodash/isNil'

import { IRequestPagination } from '@alteos/ui'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { TransactionType } from '../../Policy/dictionaries'
import { IDisputedPayments, IDisputedPaymentsApi, IFilterPayload, IPaymentDetails } from '../interfaces'

class DisputedPaymentsApi implements IDisputedPaymentsApi {
  constructor(private api: Api) {}

  isInvoiceTransaction(transactionId: string): boolean {
    return transactionId.includes('_ipo_')
  }

  loadDisputedPayments = async (
    { limit, offset }: IRequestPagination,
    filters?: IFilterPayload,
    search?: string
  ): Promise<IDisputedPayments[]> => {
    const disputedPaymentsUrl = 'v2/policy-transactions'
    const params: URLSearchParams = new URLSearchParams([
      ['type', TransactionType.Dispute],
      ['offset', offset.toString()],
      ['limit', limit.toString()]
    ])

    if (search) {
      params.set('search', search)
      params.set('limit', '-1')
    }

    if (!isNil(filters)) {
      params.set('startAt', filters.startDate.toString())
      params.set('endAt', filters?.endDate.toString())
    }

    const response = await this.api.get(disputedPaymentsUrl, params)
    return response.data
  }

  resolveDisputePayment = async (policyId: string, transactionId?: string): Promise<void> => {
    const isInvoiceService = !!transactionId && this.isInvoiceTransaction(transactionId)
    const resolveDisputeURL = isInvoiceService ? 'v2/proxy/invoice' : 'v1/purchases/resolve-disputed-collection'
    const resolveDisputePayload = isInvoiceService
      ? {
          method: 'patch',
          path: 'v1/payment-dispute',
          payload: {
            paymentOrderId: transactionId.slice(transactionId.indexOf('ipo_')),
            isResolved: true,
            remarks: 'Resolve dispute from Dave'
          }
        }
      : { policyId }
    await this.api.post(resolveDisputeURL, resolveDisputePayload)
  }

  resolveMultipleDisputePayments = async (payload: { policyId: string; transactionIds: string[] }): Promise<void> => {
    const { policyId, transactionIds } = payload
    if (transactionIds.every((transactionId) => this.isInvoiceTransaction(transactionId))) {
      await Promise.all(transactionIds.map((transactionId) => this.resolveDisputePayment(policyId, transactionId)))
    } else {
      await this.resolveDisputePayment(policyId)
    }
  }

  changeIBAN = async (
    policyId: string,
    payload: IPaymentDetails,
    policyPrettyId: string,
    transactionId: string
  ): Promise<void> => {
    await this.api.post('v2/proxy/mandate', {
      method: 'post',
      path: 'v1/payment/mandates',
      payload: {
        ...payload,
        consentedAt: new Date().toISOString(),
        reference: policyPrettyId
      }
    })
    if (!this.isInvoiceTransaction(transactionId)) {
      await this.api.post(`v1/policies/${policyId}/updatePaymentDetails`, payload)
    }
  }
}

export const disputedPaymentsApi: IDisputedPaymentsApi = new DisputedPaymentsApi(defaultApiInstance)
