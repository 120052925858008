import isNil from 'lodash/isNil'
import { FC, useState } from 'react'
import { TFunction } from 'react-i18next'

import {
  createTableDataPagination,
  formatFactory,
  ITableColumn,
  ITableDataPagination,
  ITableInitialState,
  ITableRowProps,
  ITableSortProps,
  sortDates,
  sortStrings,
  Table
} from '@alteos/ui'

import { IUseState } from '../../../../../interfaces/common'
import { IClaim, IClaimTransaction, IClaimTransactionTableRow } from '../../../interfaces'

interface IProps {
  claim: IClaim
  t: TFunction
}

const TOTAL_ROWS = 6

const TransactionsHistoryTable: FC<IProps> = ({ claim, t }: IProps) => {
  const [offset, setOffset]: IUseState<number> = useState(0)
  const [pageSize, setPageSize]: IUseState<number> = useState(TOTAL_ROWS)
  const total: number = claim.transactions.length
  const { formatDate, formatEuroPrice } = formatFactory

  const tableColumns: ITableColumn[] = [
    {
      Header: t('common:phrases.date'),
      accessor: 'date',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortDates(a.values.date, b.values.date)
      }
    },
    {
      Header: t('claims.transactions.payoutAmount'),
      accessor: (originalRow: any) => {
        let value = ''

        if (!isNil(originalRow.amount.value)) {
          value = originalRow.amount.value
        }
        return formatEuroPrice(value)
      }
    },
    {
      Header: t('common:phrases.type'),
      accessor: 'type',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.values.type, b.values.type)
      }
    },
    {
      Header: t('common:phrases.description'),
      accessor: 'description',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.original.description, b.original.description)
      }
    }
  ]

  const initialTableState: ITableInitialState = {
    pageSize,
    total,
    offset,
    sortBy: []
  }

  const transactionsToDisplay: IClaimTransaction[] = [...claim.transactions].splice(offset, pageSize)

  const tableRows: IClaimTransactionTableRow[] = transactionsToDisplay.map((transaction: IClaimTransaction) => ({
    type: t(`transactions.transactionType.${transaction.type}`),
    date: formatDate(transaction.updatedAt),
    description: transaction.description,
    amount: transaction.amount
  }))

  const dataPagination: ITableDataPagination = createTableDataPagination({
    data: tableRows,
    total,
    limit: pageSize,
    offset,
    setLimit: (value: number) => setPageSize(value),
    setOffset: (value: number) => setOffset(value),
    displayPagination: true
  })

  return (
    <Table
      columns={tableColumns}
      initialState={initialTableState}
      dataTest="transactions-history"
      dataPagination={dataPagination}
      disableSortBy={false}
      emptyTableTitle={t('common:titles.emptyTable')}
    />
  )
}

export default TransactionsHistoryTable
