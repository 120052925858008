import { FC } from 'react'

import { IStatusViewFieldConfig } from '../../../../../interfaces/common'
import Label from '../../../../Label'
import { LabelType } from '../../../../Label/dictionaries'

import StatusItem from './index'

interface IProps {
  isInline: boolean
  field: IStatusViewFieldConfig
  value: string
}

const DefaultStatusItem: FC<IProps> = ({ field, isInline, value }: IProps) => {
  const dataTest: string = 'defaultStatusLabel' + field.id ? `-${field.id}` : ''
  return (
    <StatusItem isInline={isInline} label={field.label}>
      <Label type={LabelType.neutral} content={value} dataTest={dataTest} mode="light" />
    </StatusItem>
  )
}

export default DefaultStatusItem
