import isNil from 'lodash/isNil'
import { FC, useState } from 'react'
import { TFunction } from 'react-i18next'

import {
  createTableDataPagination,
  formatFactory,
  ITableColumn,
  ITableInitialState,
  ITableRowProps,
  ITableSortProps,
  sortDates,
  sortStrings,
  Table
} from '@alteos/ui'

import { ClaimStatus } from '../../../../../../dictionaries'
import { ClaimStatusLabel } from '../../../../components/status/ClaimStatusLabel'
import { IClaim, IClaimHistoryTableRow, IClaimLog } from '../../../../interfaces'

interface IProps {
  claim: IClaim
  t: TFunction
}

const TOTAL_ROWS = 6

interface IClaimHistoryRow {
  row: {
    original: IClaimHistoryTableRow
  }
}

const ClaimHistoryStatusCell: FC<IClaimHistoryRow> = ({ row }: IClaimHistoryRow) => {
  return <ClaimStatusLabel status={row.original.status as ClaimStatus} />
}

const ClaimHistoryTable: FC<IProps> = ({ claim, t }: IProps) => {
  const [offset, setOffset] = useState(0)
  const [pageSize, setPageSize] = useState(TOTAL_ROWS)
  const total = claim.logs.length
  const { formatDate } = formatFactory

  const tableColumns: ITableColumn[] = [
    {
      Header: t('createClaimForm.historyTable.eventDate'),
      accessor: (originalRow: any) => {
        let value = ''

        if (!isNil(originalRow.eventDate)) {
          value = originalRow.eventDate
        }
        return formatDate(value)
      },
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortDates(a.original.eventDate, b.original.eventDate)
      }
    },
    {
      Header: t('createClaimForm.historyTable.type'),
      accessor: 'type',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.values.type, b.values.type)
      }
    },
    {
      Header: t('createClaimForm.historyTable.status'),
      accessor: 'status',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.values.type, b.values.type)
      },
      Cell: ClaimHistoryStatusCell
    },
    {
      Header: t('createClaimForm.historyTable.note'),
      accessor: 'note',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.values.type, b.values.type)
      }
    },
    {
      Header: t('createClaimForm.historyTable.updatedBy'),
      accessor: 'updatedBy',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.values.updatedBy, b.values.updatedBy)
      }
    }
  ]

  const initialTableState: ITableInitialState = {
    pageSize,
    total,
    offset,
    sortBy: []
  }

  const logsToDisplay = [...claim.logs].splice(offset, pageSize)

  const tableRows = logsToDisplay.map((log: IClaimLog) => ({
    eventDate: log.createdAt,
    type: t(`createClaimForm.logActions.${log.action}`),
    status: log.status,
    note: log.note,
    updatedBy: log.createdBy
  }))

  const dataPagination = createTableDataPagination({
    data: tableRows,
    total,
    limit: pageSize,
    offset,
    setLimit: (value: number) => setPageSize(value),
    setOffset: (value: number) => setOffset(value),
    displayPagination: true
  })

  return (
    <Table
      columns={tableColumns}
      initialState={initialTableState}
      dataTest="claims-history"
      dataPagination={dataPagination}
      disableSortBy={false}
      emptyTableTitle={t('common:titles.emptyTable')}
    />
  )
}

export default ClaimHistoryTable
