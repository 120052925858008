import { FC, ReactElement, Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'
import {
  DropdownMenu,
  DropdownOptionType,
  Icon,
  isLocalStorageEnabled,
  IMenuButtonProps,
  IMenuOption,
  ModalPosition
} from '@alteos/ui'

import { setLanguage as setLanguageAction } from '../../modules/i18n/actions'
import { getCurrentLanguage } from '../../modules/i18n/selectors'
import engFlag from './assets/eng.svg'
import gerFlag from './assets/ger.svg'

import styles from './languageSelect.module.scss'

const LanguageSelect: FC = (): ReactElement | null => {
  const dispatch: Dispatch<any> = useDispatch()
  const language: ISupportedLanguage = useSelector(getCurrentLanguage)

  const languageOptions: IMenuOption[] = [
    {
      type: DropdownOptionType.button,
      label: 'English',
      icon: <img src={engFlag} alt="english-flag" />,
      closeOnClick: true,
      dataTest: 'language-english',
      className: language === 'en' ? styles.inactive : styles.active,
      disabled: language === 'en',
      handleClick: () => {
        dispatch(setLanguageAction('en'))
      }
    },
    {
      type: DropdownOptionType.button,
      label: 'Deutsch',
      icon: <img src={gerFlag} alt="german-flag" />,
      closeOnClick: true,
      dataTest: 'language-deutsch',
      className: language === 'de' ? styles.inactive : styles.active,
      disabled: language === 'de',
      handleClick: () => {
        dispatch(setLanguageAction('de'))
      }
    }
  ]

  return isLocalStorageEnabled() ? (
    <DropdownMenu options={languageOptions} position={ModalPosition.topRight} containerDataTest="language-container">
      {({ setModalState, isModalOpen = false, buttonRef }: IMenuButtonProps) => (
        <button
          onClick={() => setModalState(!isModalOpen)}
          ref={buttonRef}
          className={styles.container}
          data-test="language-button-dialog"
          type="button"
        >
          {language === 'de' ? 'Deutsch' : 'English'} <Icon name="dropdown-arrow" />
        </button>
      )}
    </DropdownMenu>
  ) : null
}

export default LanguageSelect
