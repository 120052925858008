import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IAction } from '../../../../interfaces/store'
import { IPartnerPortalPartnerUser, IPartnerPortalRole, IPartnerPortalState } from '../../interfaces'
import {
  GET_PARTNER_PORTAL_ROLES_FAILURE,
  GET_PARTNER_PORTAL_ROLES_SUCCESS,
  GET_PARTNER_PORTAL_USERS_SUCCESS,
  IMPORT_PARTNER_PORTAL_USERS_FAILURE,
  IMPORT_PARTNER_PORTAL_USERS_REQUEST,
  IMPORT_PARTNER_PORTAL_USERS_SUCCESS
} from '../constants'

export const initialState: IPartnerPortalState = {
  roles: [],
  isImportingPartnerPortalUsers: false,
  errorMessage: null,
  partnerUsers: {}
}

function reducer(state: IPartnerPortalState = initialState, action: IAction<any>): IPartnerPortalState {
  switch (action.type) {
    case GET_PARTNER_PORTAL_ROLES_SUCCESS: {
      const { payload }: IAction<IPartnerPortalRole[]> = action

      return {
        ...state,
        roles: payload
      }
    }

    case GET_PARTNER_PORTAL_ROLES_FAILURE: {
      const { payload }: IAction<ISaveErrorMessagePayload> = action

      return {
        ...state,
        ...payload
      }
    }

    case IMPORT_PARTNER_PORTAL_USERS_REQUEST: {
      return {
        ...state,
        isImportingPartnerPortalUsers: true
      }
    }

    case IMPORT_PARTNER_PORTAL_USERS_SUCCESS: {
      return {
        ...state,
        isImportingPartnerPortalUsers: false
      }
    }

    case IMPORT_PARTNER_PORTAL_USERS_FAILURE: {
      return {
        ...state,
        isImportingPartnerPortalUsers: false
      }
    }

    case GET_PARTNER_PORTAL_USERS_SUCCESS: {
      const { payload }: IAction<{ [partnerId: string]: IPartnerPortalPartnerUser[] }> = action

      return {
        ...state,
        partnerUsers: {
          ...state.partnerUsers,
          ...payload
        }
      }
    }

    default:
      return state
  }
}

export default reducer
