import { FC, ReactElement } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { IStore } from '../../../interfaces/store'
import SideView from '../../Layout/components/SideView/index'
import { SideViewSize } from '../../Layout/dictionaries'
import { MODULE_NAME } from '../constants'
import { PoliciesSideViewType } from '../dictionaries'
import { IModuleActions } from '../interfaces'
import { default as actionsToBind } from '../store/actionCreators'
import BatchImportForm from './BatchImport'
import BatchTerminationForm from './BatchTermination'

type StoreProps = Pick<IStore[typeof MODULE_NAME], 'activeSideViewType'>

type DispatchProps = {
  actions: IModuleActions
}

type Props = StoreProps & DispatchProps

const SideViewContent: FC<Props> = ({ activeSideViewType, actions }: Props): ReactElement | null => {
  switch (activeSideViewType) {
    case PoliciesSideViewType.BatchImport:
      return (
        <SideView dataTest="batch-import-side-view" size={SideViewSize.l} handleClose={() => actions.setSideView(null)}>
          <BatchImportForm />
        </SideView>
      )
    case PoliciesSideViewType.BatchTermination:
      return (
        <SideView
          dataTest="batch-termination-side-view"
          size={SideViewSize.l}
          handleClose={() => actions.setSideView(null)}
        >
          <BatchTerminationForm />
        </SideView>
      )
    default:
      return (
        <SideView dataTest="empty-side-view">
          <></>
        </SideView>
      )
  }
}

function mapDispatchToProps(dispatch: reduxDispatch): DispatchProps {
  return {
    actions: bindActionCreators(actionsToBind, dispatch)
  }
}

function mapStateToProps(state: IStore): StoreProps {
  return {
    activeSideViewType: state[MODULE_NAME].activeSideViewType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideViewContent) as any
