import { IFormConfig } from '../../../../components/Form/interfaces'
import { ISaveErrorMessagePayload } from '../../../../interfaces/action'
import { IBasicAction, IAction } from '../../../../interfaces/store'
import { IPartnerPortalPartnerUser, IPartnerPortalRole } from '../../interfaces'
import {
  CREATE_PARTNER_PORTAL_USER_FAILURE,
  CREATE_PARTNER_PORTAL_USER_REQUEST,
  CREATE_PARTNER_PORTAL_USER_SUCCESS,
  GET_PARTNER_PORTAL_ROLES_FAILURE,
  GET_PARTNER_PORTAL_ROLES_REQUEST,
  GET_PARTNER_PORTAL_ROLES_SUCCESS,
  GET_PARTNER_PORTAL_USERS_FAILURE,
  GET_PARTNER_PORTAL_USERS_REQUEST,
  GET_PARTNER_PORTAL_USERS_SUCCESS,
  IMPORT_PARTNER_PORTAL_USERS_FAILURE,
  IMPORT_PARTNER_PORTAL_USERS_REQUEST,
  IMPORT_PARTNER_PORTAL_USERS_SUCCESS
} from '../constants'

export function getPartnerPortalRoles(): IBasicAction {
  return {
    type: GET_PARTNER_PORTAL_ROLES_REQUEST
  }
}

export function getPartnerPortalRolesSuccess(payload: IPartnerPortalRole[]): IAction<IPartnerPortalRole[]> {
  return {
    type: GET_PARTNER_PORTAL_ROLES_SUCCESS,
    payload
  }
}

export function getPartnerPortalRolesFailure(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: GET_PARTNER_PORTAL_ROLES_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function createPartnerPortalUser(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: CREATE_PARTNER_PORTAL_USER_REQUEST,
    payload
  }
}

export function createPartnerPortalUserSuccess(): IBasicAction {
  return {
    type: CREATE_PARTNER_PORTAL_USER_SUCCESS
  }
}

export function createPartnerPortalUserFailure(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: CREATE_PARTNER_PORTAL_USER_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function getPartnerPortalUsersRequest(payload: string): IAction<string> {
  return {
    type: GET_PARTNER_PORTAL_USERS_REQUEST,
    payload
  }
}

export function getPartnerPortalUsersSuccess(payload: { [partnerId: string]: IPartnerPortalPartnerUser[] }): IAction<{
  [partnerId: string]: IPartnerPortalPartnerUser[]
}> {
  return {
    type: GET_PARTNER_PORTAL_USERS_SUCCESS,
    payload
  }
}

export function getPartnerPortalUsersFailure(
  message: string,
  originalError: ISaveErrorMessagePayload['originalError'] = null
): IAction<ISaveErrorMessagePayload> {
  return {
    type: GET_PARTNER_PORTAL_USERS_FAILURE,
    payload: {
      message,
      originalError
    }
  }
}

export function importPartnerPortalUsers(payload: IFormConfig): IAction<IFormConfig> {
  return {
    type: IMPORT_PARTNER_PORTAL_USERS_REQUEST,
    payload
  }
}

export function importPartnerPortalUsersSuccess(): IBasicAction {
  return {
    type: IMPORT_PARTNER_PORTAL_USERS_SUCCESS
  }
}

export function importPartnerPortalUsersFailure(): IBasicAction {
  return {
    type: IMPORT_PARTNER_PORTAL_USERS_FAILURE
  }
}
