import { SHOW_MODAL, CLOSE_MODAL } from './consts'
import { IShowModalAction, ICloseModalAction, ModalId } from './interfaces'

export function showModal(modalId: ModalId): IShowModalAction {
  return {
    type: SHOW_MODAL,
    payload: {
      modalId
    }
  }
}

export function closeModal<T>(data: T): ICloseModalAction<T> {
  return {
    type: CLOSE_MODAL,
    payload: {
      data
    }
  }
}
