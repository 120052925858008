import { ReactElement } from 'react'
import { UseTranslationResponse, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch as reduxDispatch } from 'redux'

import { InputType } from '@alteos/ui'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { FieldType } from '../../../../../dictionaries'
import { IStore } from '../../../../../interfaces/store'
import { closeSideView } from '../../../../Layout/store/actionCreators'
import { MODULE_NAME, forms } from '../../../constants'
import { importPartnerPortalUsers } from '../../../store/actions/partnerPortal'
import { setSideView } from '../../../store/actions/referralPartner'
import { FormContainer } from '../FormContainer'

import styles from '../partnerPortal.module.scss'

export default (): ReactElement => {
  const [t]: UseTranslationResponse<'partner'> = useTranslation('partner')
  const dispatch: reduxDispatch = useDispatch()

  const isSubmitting: boolean = useSelector(
    (store: IStore) => store[MODULE_NAME].partnerPortalState.isImportingPartnerPortalUsers
  )

  const formValues = useSelector((state: IStore) => state.form[forms.importPortalUsersCSV]?.values) as {
    csv: unknown[]
  }

  const formConfig: IFormConfig = {
    form: forms.importPortalUsersCSV,
    url: '',
    initialValues: {
      csv: []
    },
    successMessage: `${t('partnerPortal.successMessageUploadCSV')}`,
    formSections: [
      {
        title: t('partnerPortal.uploadCSV'),
        icon: 'upload',
        fields: [
          {
            id: 'csv',
            dataTest: 'csv-import',
            editType: InputType.file,
            required: true,
            allowedFormats: ['csv'],
            disabled: isSubmitting,
            maxFileSize: 102400,
            maxFileCount: 1
          },
          {
            type: FieldType.Title,
            value: ''
          },
          {
            editType: InputType.button,
            label: t('partnerPortal.cancelChanges'),
            dataTest: 'cancel-changes-SP-button',
            buttonType: 'reset',
            handleClick: () => {
              dispatch(setSideView(null))
              dispatch(closeSideView())
            },
            color: 'tertiary',
            noMinWidth: true,
            style: styles.formButton
          },
          {
            editType: InputType.button,
            label: t('partnerPortal.upload'),
            dataTest: 'save-changes-SP-button',
            disabled: !formValues?.csv?.length,
            noMinWidth: true
          }
        ]
      }
    ]
  }

  const formProps: IFormProps = {
    ...formConfig,
    isSubmitting,
    hasFormButtons: true,
    formType: FormType.CREATE,
    onClose: () => dispatch(setSideView(null)),
    onSubmit: () => dispatch(importPartnerPortalUsers(formConfig))
  }

  return (
    <FormContainer>
      <Form {...formProps} />
    </FormContainer>
  )
}
