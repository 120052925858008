import { ClaimStatus } from '../../dictionaries'
import { ClaimSubStatus } from '../../dictionaries/ClaimSubStatus'

/* eslint-disable @typescript-eslint/typedef */
export const MODULE_NAME = 'policy' as const

export const SET_ACTIVE_POLICY_TAB = `SET_ACTIVE_${MODULE_NAME}_TAB` as const
export const SET_ACTIVE_SIDE_VIEW_TYPE = `${MODULE_NAME}_SET_ACTIVE_SIDE_VIEW_TYPE` as const

export const GET_POLICY_AND_CONFIG_REQUEST = `${MODULE_NAME}_GET_POLICY_AND_CONFIG_REQUEST` as const
export const GET_POLICY_AND_CONFIG_FAILURE = `${MODULE_NAME}_GET_POLICY_AND_CONFIG_FAILURE` as const
export const GET_POLICY_AND_CONFIG_SUCCESS = `${MODULE_NAME}_GET_POLICY_AND_CONFIG_SUCCESS` as const

export const SET_PURCHASE = `${MODULE_NAME}_SET_PURCHASE` as const
export const SET_MANDATE = `${MODULE_NAME}_SET_MANDATE` as const

export const SET_POLICY = `${MODULE_NAME}_SET_POLICY` as const

export const SUBMIT_FORM_START = `${MODULE_NAME}_SUBMIT_FORM_START` as const
export const SUBMIT_FORM_SUCCESS = `${MODULE_NAME}_SUBMIT_FORM_SUCCESS` as const
export const SUBMIT_FORM_ERROR = `${MODULE_NAME}_SUBMIT_FORM_ERROR` as const

export const UPDATE_IBAN_START = `${MODULE_NAME}_UPDATE_IBAN_START` as const
export const UPDATE_IBAN_SUCCESS = `${MODULE_NAME}_UPDATE_IBAN_SUCCESS` as const
export const UPDATE_IBAN_ERROR = `${MODULE_NAME}_UPDATE_IBAN_ERROR` as const

export const CREATE_NOTE_START = `${MODULE_NAME}_CREATE_NOTE_START` as const
export const CREATE_NOTE_STOP = `${MODULE_NAME}_CREATE_NOTE_STOP` as const
export const CREATE_NOTE_SUCCESS = `${MODULE_NAME}_CREATE_NOTE_SUCCESS` as const
export const CREATE_NOTE_ERROR = `${MODULE_NAME}_CREATE_NOTE_ERROR` as const

export const UPDATE_NOTE_START = `${MODULE_NAME}_UPDATE_NOTE_START` as const
export const UPDATE_NOTE_SUCCESS = `${MODULE_NAME}_UPDATE_NOTE_SUCCESS` as const
export const UPDATE_NOTE_ERROR = `${MODULE_NAME}_UPDATE_NOTE_ERROR` as const

export const SET_EDITING_NOTE_ID = `${MODULE_NAME}_SET_EDITING_NOTE_ID` as const

export const EDIT_NOTE_START = `${MODULE_NAME}_EDIT_NOTE_START` as const
export const EDIT_NOTE_FINISH = `${MODULE_NAME}_EDIT_NOTE_FINISH` as const

export const LOAD_NOTES_START = `${MODULE_NAME}_LOAD_NOTES_START` as const
export const LOAD_NOTES_SUCCESS = `${MODULE_NAME}_LOAD_NOTES_SUCCESS` as const
export const LOAD_NOTES_ERROR = `${MODULE_NAME}_LOAD_NOTES_ERROR` as const

export const LOAD_CLAIM_DETAILS_START = `${MODULE_NAME}LOAD_CLAIM_DETAILS_START` as const
export const LOAD_CLAIM_DETAILS_SUCCESS = `${MODULE_NAME}LOAD_CLAIM_DETAILS_SUCCESS` as const
export const LOAD_CLAIM_DETAILS_FAILURE = `${MODULE_NAME}LOAD_CLAIM_DETAILS_FAILURE` as const
export const GET_TERMINATION_RULES_START = `${MODULE_NAME}_GET_TERMINATION_RULES_START` as const
export const GET_TERMINATION_RULES_SUCCESS = `${MODULE_NAME}_GET_TERMINATION_RULES_SUCCESS` as const
export const GET_TERMINATION_RULES_FAILURE = `${MODULE_NAME}_GET_TERMINATION_RULES_FAILURE` as const
export const EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START =
  `${MODULE_NAME}_EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START` as const
export const EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_FAILURE =
  `${MODULE_NAME}_EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_FAILURE` as const
export const EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START =
  `${MODULE_NAME}_EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START ` as const

export const EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_FAILURE =
  `${MODULE_NAME}_EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_FAILURE ` as const
export const EXECUTE_TERMINATION_RULE_SUCCESS = `${MODULE_NAME}_EXECUTE_TERMINATION_RULE_SUCCESS` as const
export const EXECUTE_TERMINATION_RULE_FAILURE = `${MODULE_NAME}_EXECUTE_TERMINATION_RULE_FAILURE` as const
export const GET_REFUND_AMOUNT_START = `${MODULE_NAME}_GET_REFUND_AMOUNT_START` as const
export const GET_REFUND_AMOUNT_SUCCESS = `${MODULE_NAME}_GET_REFUND_AMOUNT_SUCCESS` as const
export const GET_REFUND_AMOUNT_FAILURE = `${MODULE_NAME}_GET_REFUND_AMOUNT_FAILURE` as const

export const RESET_STATE = `${MODULE_NAME}_RESET_STATE` as const

export const SIDE_VIEW_ANIMATION_DURATION = 300

export enum forms {
  editClaimForm = 'editClaimForm',
  createClaimForm = 'createClaimForm',
  editPolicyValuesForm = 'editPolicyValuesForm',
  editPolicyHolderForm = 'editPolicyHolderForm',
  editPolicyObjectForm = 'editPolicyObjectForm',
  terminatePolicyForm = 'terminatePolicyForm',
  createTransactionReserveForm = 'editTransactionReserveForm',
  createTransactionObligationForm = 'editTransactionObligationForm',
  editTransactionUpdateStatusForm = 'editTransactionUpdateStatusForm',
  editIbanForm = 'editIbanForm',
  createScheduledTerminationForm = 'createScheduledTerminationForm'
}

export const HAUSRAT_PRODUCT_ID = '0483cf55-f73c-4d2f-b791-fbdb226e0db1'

export const CLAIM_SUB_STATUS_MAP = {
  [ClaimStatus.Created]: [ClaimSubStatus.NotYetInProgress],
  [ClaimStatus.InProgress]: [
    ClaimSubStatus.InformationIsBeingChecked,
    ClaimSubStatus.FurtherInformationOrDocumentsRequested,
    ClaimSubStatus.ReviewingOfFurtherFindingsOrDocuments
  ],
  [ClaimStatus.Approved]: [
    ClaimSubStatus.WaitingForReceiptsOrInvoices,
    ClaimSubStatus.PaymentInformationRequested,
    ClaimSubStatus.PaymentPending,
    ClaimSubStatus.Paid
  ],
  [ClaimStatus.PartiallyApproved]: [
    ClaimSubStatus.WaitingForReceiptsOrInvoices,
    ClaimSubStatus.PaymentInformationRequested,
    ClaimSubStatus.PaymentPending,
    ClaimSubStatus.PartiallyPaid
  ],
  [ClaimStatus.Closed]: [
    ClaimSubStatus.ApprovedAndPaid,
    ClaimSubStatus.PartiallyApprovedAndPaid,
    ClaimSubStatus.RejectedWithoutPayment,
    ClaimSubStatus.WithoutPayment
  ],
  [ClaimStatus.Rejected]: [ClaimSubStatus.WithoutPayment]
}

export function getClaimSubStatuses(status: ClaimStatus): ClaimSubStatus[] {
  return CLAIM_SUB_STATUS_MAP[status as keyof typeof CLAIM_SUB_STATUS_MAP]
}
