import isNil from 'lodash/isNil'

function stringifyObject(object: Record<string, any> | null | undefined): Record<string, any> | null | undefined {
  if (isNil(object)) {
    return object
  }

  Object.entries(object).forEach(([key, value]: [string, unknown]) => {
    if (typeof value === 'object') {
      stringifyObject(value)
    }

    if (typeof value === 'number') {
      object[key] = value.toString()
    }
  })

  return object
}

export default stringifyObject
