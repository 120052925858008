import { IGroup, IPartnerResponse } from '../../../interfaces'

const deletePartnerNameFromGroupName = (partner: IPartnerResponse): IPartnerResponse => {
  partner.groups?.forEach((group: IGroup) => {
    group.name = group.name.replace(partner.name, '').trim()
  })

  return partner
}

export default deletePartnerNameFromGroupName
