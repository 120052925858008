import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'

import SideView from '../../../Layout/components/SideView'
import { SideViewSize } from '../../../Layout/dictionaries'
import { setInvoiceSideView } from '../../store/actions'
import { InvoicePreview } from './InvoicePreview'

const SideViewContent = (): ReactElement | null => {
  const dispatch = useDispatch()

  return (
    <SideView
      dataTest="invoice-preview-side-view"
      size={SideViewSize.l}
      handleClose={() => dispatch(setInvoiceSideView(null))}
    >
      <InvoicePreview />
    </SideView>
  )
}

export default SideViewContent
