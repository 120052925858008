import { ReactElement } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { Container, EditModeLabel } from '../../../../../components/sideViewComponents'
import { IStore } from '../../../../../interfaces/store'
import { MODULE_NAME } from '../../../constants'
import { PaymentTab } from '../../../interfaces'
import { changeIBANStart } from '../../../store/actions/disputed'
import { setSideView, updatePaymentMethodStart } from '../../../store/actions/upcoming'
import generateUpdatePaymentMethodForm from './utils/generateUpdatePaymentMethodForm'

import styles from './editPaymentMethod.module.scss'

export const EditPaymentMethodForm = (): ReactElement | null => {
  const [t]: UseTranslationResponse<'payments'> = useTranslation('payments')
  const dispatch = useDispatch()

  const paymentsState = useSelector((state: IStore) => state[MODULE_NAME])

  let updatePaymentMethodFormConfig = generateUpdatePaymentMethodForm(t, dispatch)

  const handleUpdatePaymentMethodForm = function () {
    if (paymentsState.tabs.activeTab === PaymentTab.Upcoming)
      dispatch(
        updatePaymentMethodStart(updatePaymentMethodFormConfig, paymentsState.upcomingPayments.selectedPaymentId!)
      )
    if (paymentsState.tabs.activeTab === PaymentTab.Disputed)
      dispatch(changeIBANStart(updatePaymentMethodFormConfig, paymentsState.disputedPayments.selectedPayment!))
  }

  const updatePaymentMethodFormProps: IFormProps = {
    ...updatePaymentMethodFormConfig,
    hasFormButtons: true,
    formType: FormType.EDIT,
    dataTest: 'payment-method-edit-form',
    onClose: () => dispatch(setSideView(null)),
    onSubmit: handleUpdatePaymentMethodForm
  }

  return (
    <>
      <Container
        className={styles.title}
        title={t('payments:upcomingPayments.edit.title')}
        icon="batch-actions"
        label={<EditModeLabel />}
      />
      <Form {...updatePaymentMethodFormProps} />
    </>
  )
}
