import i18next from 'i18next'

import { FormData } from '../../../../../components/Form/ReduxForm'
import { referralPartnerApi } from '../../../api/referralPartnerApi'

interface IUpdateReferralPartnerFormData extends FormData {
  initialValues: {
    subdomain: string
  }
  formState: {
    active?: string
  }
}
interface IUpdateReferralPartnerFormValues {
  subdomain: string
}

const subdomainValidationTimeout = 1500

let timeoutId: NodeJS.Timeout

const sleep = (ms: number) =>
  new Promise((resolve) => {
    timeoutId = setTimeout(resolve, ms)
  })

export const asyncValidateReferralPartnerForm = async (
  values: IUpdateReferralPartnerFormValues,
  _dispatch: Function,
  props: IUpdateReferralPartnerFormData
): Promise<void> => {
  clearTimeout(timeoutId)
  if (values.subdomain !== props.initialValues.subdomain) {
    if (props.formState.active === 'subdomain') {
      await sleep(subdomainValidationTimeout).then(async () => {
        await checkAvailability(values.subdomain)
      })
    } else {
      await checkAvailability(values.subdomain)
    }
  }
}

async function checkAvailability(subdomain: string): Promise<void> {
  let available = true
  try {
    const response = await referralPartnerApi.checkSubdomainAvailability(subdomain)
    if (response.data.available !== true) {
      available = false
    }
  } catch (error: any) {
    if (error.response.data.message === 'subdomain is reserved') {
      available = false
    } else {
      throw error
    }
  }

  if (available === false) {
    // eslint-disable-next-line no-throw-literal
    throw { subdomain: i18next.t('partner:referralPartner.edit.formValidation.subdomainInUse') }
  }
}
