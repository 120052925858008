import { z } from 'zod'

import { IPagination } from '@alteos/ui'

import { addNewFilter, removeActiveFilter, resetFilters, setActiveFilter } from '../../../components/Filters/utils'
import { IAction } from '../../../interfaces/store'
import { localStorageService } from '../../../utils/localStorageService'
import { LOGOUT } from '../../Auth/constants'
import { IPoliciesState } from '../interfaces'
import {
  ADD_FILTER,
  TERMINATE_POLICIES_START,
  TERMINATE_POLICIES_SUCCESS,
  TERMINATE_POLICIES_FAILURE,
  GET_FILTERS_PARAMS_FAILURE,
  GET_FILTERS_PARAMS_REQUEST,
  GET_FILTERS_PARAMS_SUCCESS,
  GET_POLICIES_FAILURE,
  GET_POLICIES_REQUEST,
  GET_POLICIES_SUCCESS,
  IMPORT_POLICIES_FAILURE,
  IMPORT_POLICIES_START,
  IMPORT_POLICIES_SUCCESS,
  REMOVE_FILTER,
  RESET_FILTERS,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SET_FILTER,
  SET_PAGINATION,
  POLICIES_FILTER_PARAMS_LS_KEY
} from './constants'

const defaultPagination: IPagination = { limit: 20, offset: 0, total: 0 }

export const initialState: IPoliciesState = {
  isFetchingPolicies: false,
  isFetchingFilterParams: false,
  policies: [],
  filtersParams: localStorageService.getItem(POLICIES_FILTER_PARAMS_LS_KEY, z.array(z.any())) ?? [],
  pagination: defaultPagination,
  query: { filter: '' },
  errorMessage: null,
  activeSideViewType: null,
  isImportingPolicies: false,
  isTerminatingPolicies: false
}

function reducer(state: IPoliciesState = initialState, action: IAction<any>): IPoliciesState {
  switch (action.type) {
    case LOGOUT:
      return { ...initialState }

    case GET_POLICIES_REQUEST:
      return {
        ...state,
        policies: initialState.policies,
        isFetchingPolicies: true
      }

    case GET_FILTERS_PARAMS_REQUEST:
      return {
        ...state,
        isFetchingFilterParams: true
      }

    case GET_POLICIES_SUCCESS: {
      let pagination: IPagination = defaultPagination
      if (action.payload.length > 0) {
        const { limit, offset, totalCount: total }: any = action.payload[0].pagination
        pagination = { limit, offset, total }
      }

      return {
        ...state,
        isFetchingPolicies: false,
        errorMessage: null,
        policies: action.payload,
        pagination
      }
    }

    case GET_POLICIES_FAILURE:
      return {
        ...state,
        isFetchingPolicies: false,
        errorMessage: action.payload,
        policies: initialState.policies
      }

    case GET_FILTERS_PARAMS_SUCCESS:
      return {
        ...state,
        isFetchingFilterParams: false,
        errorMessage: null,
        filtersParams: action.payload
      }

    case GET_FILTERS_PARAMS_FAILURE:
      return {
        ...state,
        isFetchingFilterParams: false,
        errorMessage: action.payload,
        filtersParams: initialState.filtersParams
      }

    case ADD_FILTER:
      return {
        ...state,
        filtersParams: addNewFilter(state.filtersParams, action.payload)
      }

    case SET_FILTER:
      return {
        ...state,
        filtersParams: setActiveFilter(state.filtersParams, action.payload)
      }

    case REMOVE_FILTER:
      return {
        ...state,
        filtersParams: removeActiveFilter(state.filtersParams, action.payload)
      }

    case RESET_FILTERS:
      return {
        ...state,
        filtersParams: resetFilters(state.filtersParams)
      }

    case SET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload }
      }

    case SET_ACTIVE_SIDE_VIEW_TYPE: {
      if (action.payload === null) {
        return {
          ...state,
          activeSideViewType: null
        }
      }

      return {
        ...state,
        activeSideViewType: action.payload.type
      }
    }

    case IMPORT_POLICIES_START: {
      return {
        ...state,
        isImportingPolicies: true
      }
    }

    case IMPORT_POLICIES_SUCCESS: {
      return {
        ...state,
        isImportingPolicies: false
      }
    }

    case IMPORT_POLICIES_FAILURE: {
      return {
        ...state,
        isImportingPolicies: false
      }
    }

    case TERMINATE_POLICIES_START: {
      return {
        ...state,
        isTerminatingPolicies: true
      }
    }

    case TERMINATE_POLICIES_SUCCESS: {
      return {
        ...state,
        isTerminatingPolicies: false
      }
    }

    case TERMINATE_POLICIES_FAILURE: {
      return {
        ...state,
        isTerminatingPolicies: false
      }
    }

    default:
      return state
  }
}

export default reducer
