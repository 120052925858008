import { FC, ReactElement } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { IBasicDispatchProps, IStore } from '../../../interfaces/store'
import SideView from '../../Layout/components/SideView/index'
import { SideViewSize } from '../../Layout/dictionaries'
import { MODULE_NAME, SIDE_VIEW_ANIMATION_DURATION } from '../constants'
import { ModeType, PolicySideViewType } from '../dictionaries'
import { default as actionsToBind } from '../store/actionCreators'
import ClaimDetails from './ClaimDetails'
import { ClaimTransactionsForm } from './ClaimTransactionsForm'
import CreateClaimForm from './CreateClaimForm'
import DocumentDetails from './DocumentDetails'
import EditIbanForm from './EditIbanForm'
import PolicyHolderDetails from './PolicyHolderDetails'
import PolicyObjectForm from './PolicyObjectForm'
import { PolicyTerminationForm } from './PolicyTerminationForm/PolicyTerminationForm'
import PolicyValuesForm from './PolicyValuesForm'
import ScheduledTerminationForm from './ScheduledTerminationForm'

type IStoreProps = Pick<IStore[typeof MODULE_NAME], 'activeSideViewType' | 'mode' | 'focusedClaim'>

type Props = IStoreProps & IBasicDispatchProps

const SideViewContent: FC<Props> = ({
  activeSideViewType,
  actions,
  mode,
  focusedClaim
}: Props): ReactElement | null => {
  const isEditMode: boolean = mode === ModeType.Edit

  switch (activeSideViewType) {
    case PolicySideViewType.DocumentDetails:
      return (
        <SideView handleClose={() => actions.setModal(null)} closeOnOutsideClick dataTest="policy-document-side-view">
          <DocumentDetails />
        </SideView>
      )
    case PolicySideViewType.ClaimDetails:
      return (
        <SideView
          handleClose={() => {
            actions.setModal(null)
            if (isEditMode) {
              setTimeout(() => {
                actions.setModal({
                  type: PolicySideViewType.ClaimDetails,
                  mode: ModeType.View,
                  params: {
                    id: focusedClaim?.id
                  }
                })
              }, SIDE_VIEW_ANIMATION_DURATION)
            }
          }}
          dataTest="claims-side-view-container"
          closeOnOutsideClick={!isEditMode}
          size={SideViewSize.l}
        >
          <ClaimDetails />
        </SideView>
      )
    case PolicySideViewType.CreateClaimForm:
      return (
        <SideView dataTest="claims-side-view-container" size={SideViewSize.l}>
          <CreateClaimForm />
        </SideView>
      )
    case PolicySideViewType.PolicyHolderDetails:
      return (
        <SideView
          handleClose={() => {
            actions.setModal(null)
            if (isEditMode) {
              setTimeout(() => {
                actions.setModal({
                  type: PolicySideViewType.PolicyHolderDetails,
                  mode: ModeType.View
                })
              }, SIDE_VIEW_ANIMATION_DURATION)
            }
          }}
          dataTest="policy-holder-form-side-view"
          closeOnOutsideClick={!isEditMode}
        >
          <PolicyHolderDetails />
        </SideView>
      )
    case PolicySideViewType.PolicyObjectForm:
      return (
        <SideView handleClose={() => actions.setModal(null)} dataTest="policy-object-form-side-view">
          <PolicyObjectForm />
        </SideView>
      )
    case PolicySideViewType.PolicyTerminationForm:
      return (
        <SideView handleClose={() => actions.setModal(null)} dataTest="policy-termination-form-side-view">
          <PolicyTerminationForm />
        </SideView>
      )
    case PolicySideViewType.PolicyValuesForm:
      return (
        <SideView handleClose={() => actions.setModal(null)} dataTest="policy-values-form-side-view">
          <PolicyValuesForm />
        </SideView>
      )
    case PolicySideViewType.ClaimTransactionsForm:
      return (
        <SideView handleClose={() => actions.setModal(null)} dataTest="transactions-form-side-view">
          <ClaimTransactionsForm />
        </SideView>
      )

    case PolicySideViewType.EditIbanForm:
      return (
        <SideView handleClose={() => actions.setModal(null)} dataTest="edit-iban-form-side-view">
          <EditIbanForm />
        </SideView>
      )

    case PolicySideViewType.ScheduledTerminationForm:
      return (
        <SideView handleClose={() => actions.setModal(null)} dataTest="scheduled-termination-form-side-view">
          <ScheduledTerminationForm />
        </SideView>
      )

    default:
      return (
        <SideView dataTest="null-side-view">
          <></>
        </SideView>
      )
  }
}

function mapDispatchToProps(dispatch: reduxDispatch): IBasicDispatchProps {
  return {
    actions: bindActionCreators(actionsToBind, dispatch)
  }
}

function mapStateToProps(state: IStore): IStoreProps {
  return {
    activeSideViewType: state.policy.activeSideViewType,
    mode: state[MODULE_NAME].mode,
    focusedClaim: state[MODULE_NAME].focusedClaim
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideViewContent) as any
