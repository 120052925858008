import { ISODateFormats, PaymentSchedule } from '../modules/Policy/dictionaries'

export const formatTimePeriodString = (value: string): string => {
  switch (value) {
    case ISODateFormats.monthly:
      return PaymentSchedule.Monthly
    case ISODateFormats.yearly:
      return PaymentSchedule.Yearly
    default:
      return '—'
  }
}
