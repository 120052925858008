import axios, { AxiosError } from 'axios'
import { CustomError } from 'ts-custom-error'

export class GraphQLException extends CustomError {
  constructor(public graphQLErrorStack: string[]) {
    super('An GraphQL error has occurred')
  }
}

export function isGraphQLError(obj: unknown): obj is GraphQLException {
  return obj instanceof GraphQLException
}

export function isGraphQLOrNetworkError(obj: unknown): obj is GraphQLException | AxiosError {
  return isGraphQLError(obj) || axios.isAxiosError(obj)
}

export class UnauthorizedException extends CustomError {
  constructor(cause: unknown) {
    super('UnauthorizedException: failed to refresh token')
    this.cause = cause
  }
}
export function isUnauthorizedException(obj: unknown): obj is UnauthorizedException {
  return obj instanceof UnauthorizedException
}
