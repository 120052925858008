import { IRequestPagination } from '@alteos/ui'

import { IFilter } from '../../../../components/Filters/interfaces'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { IAction, IBasicAction } from '../../../../interfaces/store'
import { DEFAULT_PAGINATION } from '../../constants'
import { IDisputedPayments, IDisputedSetSideViewPayload, ISelectedPayment } from '../../interfaces'
import {
  ADD_FILTER_DISPUTED_PAYMENTS,
  CHANGE_IBAN_REQUEST,
  CHANGE_IBAN_SUCCESS,
  LOAD_DISPUTED_PAYMENTS_FAILURE,
  LOAD_DISPUTED_PAYMENTS_REQUEST,
  LOAD_DISPUTED_PAYMENTS_SUCCESS,
  ON_CHANGE_FILTERS_DISPUTED_PAYMENTS,
  REMOVE_FILTER_DISPUTED_PAYMENTS,
  RESET_FILTERS_DISPUTED_PAYMENTS,
  RESOLVE_DISPUTED_PAYMENT_CANCELLED,
  RESOLVE_DISPUTED_PAYMENT_INITIATED,
  RESOLVE_DISPUTED_PAYMENT_REQUEST,
  RESOLVE_DISPUTED_PAYMENT_SUCCESS,
  RESOLVE_MULTIPLE_DISPUTED_PAYMENT_INITIATED,
  RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SET_FILTER_DISPUTED_PAYMENTS
} from '../constants'

export function loadDisputedPaymentsStart(
  payload: IRequestPagination = DEFAULT_PAGINATION
): IAction<IRequestPagination> {
  return {
    type: LOAD_DISPUTED_PAYMENTS_REQUEST,
    payload
  }
}
export function loadDisputedPaymentsSuccess(payload: IDisputedPayments[]): IAction<any> {
  return {
    type: LOAD_DISPUTED_PAYMENTS_SUCCESS,
    payload
  }
}

export function loadDisputedPaymentsFailure(): IBasicAction {
  return {
    type: LOAD_DISPUTED_PAYMENTS_FAILURE
  }
}

export function addActiveFilterDisputedPayments(payload: string): IAction<string> {
  return {
    type: ADD_FILTER_DISPUTED_PAYMENTS,
    payload
  }
}

export function setFilterDisputedPayments(payload: IFilter): IAction<IFilter> {
  return {
    type: SET_FILTER_DISPUTED_PAYMENTS,
    payload
  }
}

export function removeFilterDisputedPayments(payload: string): IAction<string> {
  return {
    type: REMOVE_FILTER_DISPUTED_PAYMENTS,
    payload
  }
}

export function resetFiltersDisputedPayments(): IBasicAction {
  return {
    type: RESET_FILTERS_DISPUTED_PAYMENTS
  }
}

export function onChangeFiltersDisputedPayments(payload?: Partial<IRequestPagination>): IAction<any> {
  return {
    type: ON_CHANGE_FILTERS_DISPUTED_PAYMENTS,
    payload
  }
}

export function resolveDisputePaymentConfirmed(payload: {
  policyId: string
  policyPrettyId: string
  transactionId: string
}): IAction<{ policyId: string; policyPrettyId: string; transactionId: string }> {
  return {
    type: RESOLVE_DISPUTED_PAYMENT_REQUEST,
    payload: {
      policyId: payload.policyId,
      policyPrettyId: payload.policyPrettyId,
      transactionId: payload.transactionId
    }
  }
}

export function resolveMultipleDisputePaymentsConfirmed(payload: {
  policyId: string
  policyPrettyId: string
  transactionIds: string[]
}): IAction<{ policyId: string; policyPrettyId: string; transactionIds: string[] }> {
  return {
    type: RESOLVE_MULTIPLE_DISPUTED_PAYMENT_REQUEST,
    payload
  }
}

export function resolveDisputePaymentSuccess(policyId: string): IAction<{ policyId: string }> {
  return {
    type: RESOLVE_DISPUTED_PAYMENT_SUCCESS,
    payload: {
      policyId
    }
  }
}

export function resolveDisputePaymentInitiated(payload: {
  policyId: string
  policyPrettyId: string
  transactionId: string
}): IAction<{ policyId: string; policyPrettyId: string; transactionId: string }> {
  return {
    type: RESOLVE_DISPUTED_PAYMENT_INITIATED,
    payload: {
      policyId: payload.policyId,
      policyPrettyId: payload.policyPrettyId,
      transactionId: payload.transactionId
    }
  }
}

export function resolveMultipleDisputePaymentsInitiated(payload: {
  policyId: string
  policyPrettyId: string
  transactionIds: string[]
}): IAction<{ policyId: string; policyPrettyId: string; transactionIds: string[] }> {
  return {
    type: RESOLVE_MULTIPLE_DISPUTED_PAYMENT_INITIATED,
    payload
  }
}

export function resolveDisputedPaymentCancelled(): IBasicAction {
  return {
    type: RESOLVE_DISPUTED_PAYMENT_CANCELLED
  }
}

export function setDisputedSideView(
  payload: IDisputedSetSideViewPayload | null
): IAction<IDisputedSetSideViewPayload | null> {
  return {
    type: SET_ACTIVE_SIDE_VIEW_TYPE,
    payload
  }
}

export function changeIBANStart(
  payload: IFormConfig,
  selectedPayment: ISelectedPayment
): IAction<IFormConfig & ISelectedPayment> {
  return {
    type: CHANGE_IBAN_REQUEST,
    payload: {
      ...payload,
      ...selectedPayment
    }
  }
}

export function changeIBANSuccess(): IBasicAction {
  return {
    type: CHANGE_IBAN_SUCCESS
  }
}
