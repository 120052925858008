import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { forms } from '../../../constants'

const generateBatchTerminationFormCards = (t: TFunction, disabled: boolean): IFormConfig => {
  const batchTerminationFormConfig: IFormConfig = {
    form: forms.batchTermination,
    url: 'v1/policies/cancel',
    initialValues: {
      policyFiles: []
    },
    successMessage: `${t('policies.batchTermination.successMessage')}`,
    formSections: [
      {
        title: t('policies.batchForm.sectionTitle'),
        icon: 'upload',
        fields: [
          {
            id: 'policyFiles',
            editType: InputType.file,
            required: true,
            allowedFormats: ['csv'],
            disabled,
            maxFileSize: 102400,
            maxFileCount: 1
          }
        ]
      }
    ]
  }

  return batchTerminationFormConfig
}

export default generateBatchTerminationFormCards
