import { call, put } from 'typed-redux-saga'

import { IAction } from '../../../../interfaces/store'
import { partnerApi } from '../../api/partnerApi'
import { IGroup, IPartnerResponse } from '../../interfaces'
import attachAppKey from '../../screens/PartnerLegacy/utils/attachAppKey'
import { loadPartnerFailure, loadPartnerSuccess, saveErrorMessage } from '../actions/partner'

export function* loadPartner({ payload }: IAction<{ partnerId: string; groups: IGroup[] }>): Generator {
  try {
    const response: IPartnerResponse = yield* call(partnerApi.getPartnerById, payload.partnerId)

    yield* put(loadPartnerSuccess(attachAppKey(response, payload.groups)))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch partner details', error))
    yield* put(loadPartnerFailure())
  }
}
