import { FC, ReactElement, useMemo } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container } from '../../../../components/sideViewComponents'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { IModuleActions } from '../../interfaces'
import actionsToBind from '../../store/actionCreators'
import generateBatchTerminationFormCards from './utils/generateBatchTerminationFormCards'

import styles from './batchTermination.module.scss'

const BatchTerminationForm: FC<WithTranslation> = ({ t }: WithTranslation): ReactElement => {
  const dispatch: reduxDispatch = useDispatch()
  const actions: IModuleActions = useMemo(() => bindActionCreators(actionsToBind, dispatch), [dispatch])
  const isSubmitting: boolean = useSelector((store: IStore) => store[MODULE_NAME].isTerminatingPolicies)

  const formConfig: IFormConfig = generateBatchTerminationFormCards(t, isSubmitting)

  const batchTerminationFormProps: IFormProps = {
    ...formConfig,
    isSubmitting,
    formType: FormType.CREATE,
    onClose: () => actions.setSideView(null),
    onSubmit: () => actions.terminatePoliciesStart(formConfig)
  }
  return (
    <>
      <Container className={styles.title} title={t('policies.batchTermination.title')} icon="batch-actions" />
      <Form {...batchTerminationFormProps} />
    </>
  )
}

export default withTranslation(['policies'])(BatchTerminationForm)
