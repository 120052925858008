import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { IStore } from '../../../interfaces/store'
import SideView from '../../Layout/components/SideView/index'
import { SideViewSize } from '../../Layout/dictionaries'
import { MODULE_NAME } from '../constants'
import ReferralPartnerSideViewType from '../dictionaries/ReferralPartnerSideViewType'
import { setSideView } from '../store/actions/referralPartner'
import { EditReferralPartnerForm } from './EditReferralPartnerForm'
import {
  AddUserFormSideView,
  PartnerPortalUserTableSideView,
  UploadUsersFormSideView
} from './PartnerPortalUserManagement'

const SideViewContent = (): ReactElement | null => {
  const activeSideViewType: ReferralPartnerSideViewType | null = useSelector(
    (store: IStore) => store[MODULE_NAME].referralPartnerState.activeSideViewType
  )
  const dispatch: Dispatch = useDispatch()
  switch (activeSideViewType) {
    case ReferralPartnerSideViewType.ReferralPartnerActivation:
      return (
        <SideView
          dataTest="edit-referralpartner-request-side-view"
          size={SideViewSize.l}
          handleClose={() => dispatch(setSideView(null))}
        >
          <EditReferralPartnerForm />
        </SideView>
      )
    case ReferralPartnerSideViewType.PartnerPortalNewUserForm:
      return (
        <SideView
          dataTest="partner-portal-side-view"
          size={SideViewSize.l}
          handleClose={() => dispatch(setSideView(null))}
        >
          <AddUserFormSideView />
          <PartnerPortalUserTableSideView />
        </SideView>
      )
    case ReferralPartnerSideViewType.PartnerPortalUploadUsersForm:
      return (
        <SideView
          dataTest="partner-portal-upload-side-view"
          size={SideViewSize.l}
          handleClose={() => dispatch(setSideView(null))}
        >
          <UploadUsersFormSideView />
        </SideView>
      )
    default:
      return (
        <SideView dataTest="empty-side-view">
          <></>
        </SideView>
      )
  }
}

export default SideViewContent
