import { Link } from 'react-router-dom'

import { bootstrap } from '@alteos/ui'

function initialize(): void {
  bootstrap({
    LinkFactory: Link,
    BodyScrollFactory: null
  })
}

export default initialize
