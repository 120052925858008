import { II18nAction, II18nState } from './interfaces'
import { languageLocalStorage } from './languageLocalStorage'

const initialState: II18nState = {
  currentLanguage: languageLocalStorage.getLanguageOrDefault()
}

export function i18nReducer(state: II18nState = initialState, action: II18nAction): II18nState {
  switch (action.type) {
    case 'I18N_SET_LANGUAGE':
      return {
        ...state,
        currentLanguage: action.payload.language
      }
    default:
      return state
  }
}
