import { ModalActions } from './ModalActions'
import { ModalContent } from './ModalContent'
import { ModalHeader } from './ModalHeader'
import { ModalRoot } from './ModalRoot'

// eslint-disable-next-line @typescript-eslint/typedef
export const Modal = {
  Root: ModalRoot,
  Header: ModalHeader,
  Content: ModalContent,
  Actions: ModalActions
}
