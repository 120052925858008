export const GET_NOTES_BY_ID_QUERY = `
  query GetNotesById($policyId: String!, $offset: Int!, $limit: Int!) {
    notes(policyId: $policyId, offset: $offset, limit: $limit) {
      id
      text
      createdAt
      updatedAt
      createdBy
      pagination {
          limit
          offset
          totalCount
      }
    }
  }
`
