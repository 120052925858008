import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IStore } from '../../../../interfaces/store'
import SideView from '../../../Layout/components/SideView'
import { SideViewSize } from '../../../Layout/dictionaries'
import { MODULE_NAME } from '../../constants'
import { IPaymentsState, PaymentTab, UpcomingPaymentsSideViewType, UpdateIBANSideViewType } from '../../interfaces'
import { setSideView } from '../../store/actions/upcoming'
import { EditPaymentMethodForm } from './EditPaymentMethodForm'

const SideViewContent = (): ReactElement | null => {
  const dispatch = useDispatch()

  const paymentState: IPaymentsState = useSelector((store: IStore) => store[MODULE_NAME])
  let activeSideViewType: UpcomingPaymentsSideViewType | UpdateIBANSideViewType | null =
    paymentState.tabs.activeTab === PaymentTab.Upcoming
      ? paymentState.upcomingPayments.activeSideViewType
      : paymentState.disputedPayments.activeSideViewType

  switch (activeSideViewType) {
    case UpcomingPaymentsSideViewType.PaymentMethodSideView:
    case UpdateIBANSideViewType.UpdateIBANSideView:
      return (
        <SideView
          dataTest="edit-payment-method-side-view"
          size={SideViewSize.l}
          handleClose={() => dispatch(setSideView(null))}
        >
          <EditPaymentMethodForm />
        </SideView>
      )
    default:
      return (
        <SideView dataTest="empty-side-view">
          <></>
        </SideView>
      )
  }
}

export default SideViewContent
