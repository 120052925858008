import { FC, ReactElement } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container, EditModeLabel } from '../../../../components/sideViewComponents'
import { IBasicDispatchProps, IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { default as actionsToBind } from '../../store/actionCreators'
import { generateReduxFormConfig } from '../../utils'
import generatePolicyValuesFormCards from './utils/generatePolicyValuesFormCards'

import styles from './styles.module.scss'

type IStoreProps = Pick<IStore[typeof MODULE_NAME], 'config' | 'policy' | 'isSubmitting'>

type Props = WithTranslation & IBasicDispatchProps & IStoreProps

const PolicyValuesForm: FC<Props> = ({ actions, t, policy, config, isSubmitting }: Props): ReactElement | null => {
  const dispatch = useDispatch()
  if (policy === null || config === null) {
    return null
  }

  const formConfig: IFormConfig = generatePolicyValuesFormCards(t, policy, config)
  const props: IFormProps = generateReduxFormConfig(formConfig, dispatch, { isSubmitting, formType: FormType.EDIT })

  return (
    <>
      <Container className={styles.title} title={t('policyValuesForm.title')} icon="pencil" label={<EditModeLabel />} />
      <Form {...props} />
    </>
  )
}

function mapStateToProps(state: IStore): IStoreProps {
  const policy: IStore['policy']['policy'] | null = state[MODULE_NAME].policy
  const config: IStore['policy']['config'] | null = state[MODULE_NAME].config
  const isSubmitting: boolean = state[MODULE_NAME].isSubmitting

  return {
    policy,
    config,
    isSubmitting
  }
}

function mapDispatchToProps(dispatch: reduxDispatch): IBasicDispatchProps {
  return {
    actions: bindActionCreators(actionsToBind, dispatch)
  }
}

export default withTranslation(['policy'])(connect(mapStateToProps, mapDispatchToProps)(PolicyValuesForm))
