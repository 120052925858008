import cx from 'classnames'
import isNil from 'lodash/isNil'
import { useContext, ReactElement, isValidElement } from 'react'

import { Icon } from '@alteos/ui'

import { IModalState, IModalVariant } from './interfaces'
import { ModalContext } from './ModalContext'

import styles from './styles.module.scss'

interface IProps {
  withCloseButton?: boolean
  onCloseRequest?(): void
  icon?: { name: string } | null | ReactElement
}

export const ModalHeader: React.FC<IProps> = ({
  withCloseButton = false,
  icon,
  children
}: React.PropsWithChildren<IProps>) => {
  const modalState: IModalState = useContext(ModalContext)
  const variant: IModalVariant = modalState.variant

  return (
    <div className={styles.modalHeaderRoot}>
      <div className={cx(styles.modalHeaderTopBar, styles[variant])}>
        {isValidElement(icon) || isNil(icon) ? (
          <div className={styles.modalHeaderTopIcon}>{icon}</div>
        ) : (
          <Icon name={icon.name} />
        )}
        {withCloseButton && <Icon name="cross-big" className={styles.modalHeaderTopBarCloseBtn} />}
      </div>
      <div className={styles.modalHeaderTitle}>{children}</div>
    </div>
  )
}
