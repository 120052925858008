import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'

import { getTranslation } from '@alteos/product-configuration/dist/translation/getTranslation'
import { ISupportedLanguage } from '@alteos/product-configuration/dist/translation/ISupportedLanguage'
import { ITranslatable } from '@alteos/product-configuration/dist/translation/ITranslatable'

import { getCurrentLanguage } from './selectors'

export function useDynamicTranslation(translatable: ITranslatable | undefined): string {
  const currentLanguage: ISupportedLanguage = useSelector(getCurrentLanguage)
  return isNil(translatable) ? '' : getTranslation(currentLanguage, translatable)
}
