/* eslint-disable @typescript-eslint/typedef */
export const MODULE_NAME = 'partner' as const

export const PARTNER_ROUTE = '/partner'
export const PARTNERS_ROUTE = '/partners'

export enum forms {
  approveReferralPartner = 'approveReferralPartner',
  updateReferralPartner = 'updateReferralPartner',
  rejectReferralPartner = 'rejectReferralPartner',
  terminateReferralPartner = 'terminateReferralPartner',
  approveRejectReferralPartner = 'approveRejectReferralPartner',
  addPartnerPortalUser = 'addPartnerPortalUser',
  importPortalUsersCSV = 'importPortalUsersCSV'
}

export const HEARING_AIDS_PRODUCT_ID = '81cb351f-5deb-4ffc-88de-69c909ea4d8f'
export const HEARING_AIDS_2023_PRODUCT_ID = 'a7f7c2e7-2e84-4988-b388-a98cbb21f1ab'
export const EXTENDED_GUARANTEE_PRODUCT_ID = 'ed3a53a0-6fd3-4d1e-ab78-ad487d99c4ad'
export const EBIKE_2024_PRODUCT_ID = 'c1cea053-1910-44e2-9600-e4cc726774a5'
export const EBIKE_LEASING_EXTENSION_PRODUCT_ID = 'c482c55f-07b8-4cbc-bb9a-8beff445c74c'
export const GENERIC_PARTNER_ID = 'e230a2f4-344c-4eb8-8f1d-9137e9c9b2f3'
