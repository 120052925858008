import { ReactElement } from 'react'

export function FileUploaderCloseIcon(): ReactElement {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.4" />
      <path d="M20 20L12 12M12 20L20 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
