/* eslint-disable @typescript-eslint/typedef */
export const MODULE_NAME = 'policies' as const

export const ISSUED_AT = 'issuedAt' as const
export const PRODUCT_ID = 'productId' as const
export const STATUS = 'status' as const
export const PARTNER_ID = 'partnerId' as const
export const QUERY = 'find' as const

export const POLICIES_ROUTE = '/policies'

export enum forms {
  batchImport = 'batchImport',
  batchTermination = 'batchTermination'
}
