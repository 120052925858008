import { FieldValue } from '../components/Cards'
import { IFieldConfig } from '../interfaces/common'
import { getConditionResult } from '../utils/getConditionResult'

export function shouldRenderNull(field: IFieldConfig, value: FieldValue, source: Record<string, any> = {}): boolean {
  if (field.hidden === true) {
    return true
  }
  if (field.hideIfNull === true && (value === '&#8212;' || typeof value === 'undefined')) {
    return true
  }
  if (typeof field.availableIf !== 'undefined' && Object.entries(source).length > 0) {
    const isConditionMet: boolean = getConditionResult(field.availableIf, source)
    return !isConditionMet
  }
  return false
}

export default shouldRenderNull
