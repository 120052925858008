import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { ISelectOption } from '../../../../../interfaces/common'
import { forms } from '../../../constants'

// eslint-disable-next-line no-magic-numbers
const maxFileSize = 1000 * 1000

const generateBatchImportFormCards = (t: TFunction, partners: ISelectOption[], disabled: boolean): IFormConfig => {
  const batchImportFormConfig: IFormConfig = {
    form: forms.batchImport,
    url: 'v2/policies/import',
    initialValues: {
      partnerId: '',
      policyFiles: []
    },
    successMessage: `${t('policies.batchImport.successMessage')}`,
    formSections: [
      {
        title: t('policies.batchForm.sectionTitle'),
        icon: 'upload',
        fields: [
          {
            id: 'partnerId',
            editType: InputType.select,
            required: true,
            size: 'full',
            placeholder: t('policies.batchImport.partnerPlaceholder'),
            label: t('policies.batchImport.partnerLabel'),
            options: partners,
            disabled
          },
          {
            id: 'policyFiles',
            dataTest: 'batch-policies-import',
            editType: InputType.file,
            required: true,
            allowedFormats: ['csv'],
            disabled,
            maxFileSize,
            maxFileCount: 1
          }
        ]
      }
    ]
  }

  return batchImportFormConfig
}

export default generateBatchImportFormCards
