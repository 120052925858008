import React from 'react'
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom'

import { Parcels } from '@alteos/dave-ui-lib'
import { Action } from '@alteos/dictionaries/dist/Action'

import { AuthGuard, PermissionGuard } from './hocs/PrivateRoute'
import { LOGIN_ROUTE } from './modules/Auth/constants'
import { LoginPage } from './modules/Auth/screens'
import { INVOICES_ROUTE, InvoiceSideView } from './modules/Invoices'
import { Layout, MicrofrontendLoader } from './modules/Layout'
import { ReferralPartnerSideView } from './modules/Partner'
import { PARTNER_ROUTE, PARTNERS_ROUTE } from './modules/Partner/constants'
import { PARTNERS_MANAGEMENT_ROUTE, Search as PartnerSearch } from './modules/PartnersManagement'
import { PAYMENTS_ROUTE, UpcomingPaymentsSideView } from './modules/Payments'
import { POLICIES_ROUTE, PoliciesSideView, Search as PoliciesSearch } from './modules/Policies'
import { PolicySideView } from './modules/Policy'
import { ERROR_ROUTE, POLICY_NOT_FOUND_ROUTE } from './modules/Static/constants'
import ErrorPage from './modules/Static/screens/Error'
import NotFoundPage from './modules/Static/screens/NotFound'
import { PolicyNotFound } from './modules/Static/screens/PolicyNotFound'
import { RouterErrorElement } from './modules/Static/screens/RouterErrorElement/RouterErrorElement'

const ReferralPartnerPage = React.lazy(() => import('./modules/Partner/screens/ReferralPartner'))
const PartnerLegacyPage = React.lazy(() => import('./modules/Partner/screens/PartnerLegacy'))
const PartnersManagementPage = React.lazy(() => import('./modules/PartnersManagement/screens/Management'))
const PoliciesPage = React.lazy(() => import('./modules/Policies/screens/Policies/index'))
const PaymentsPage = React.lazy(() => import('./modules/Payments/screens/Payments/index'))
const InvoicesPage = React.lazy(() => import('./modules/Invoices/screens/InovicesPage/index'))
const PolicyPage = React.lazy(() => import('./modules/Policy/screens/Policy/index'))

export const router = createBrowserRouter([
  {
    element: <Outlet />,
    errorElement: <RouterErrorElement />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <Navigate to={POLICIES_ROUTE} />
          </AuthGuard>
        )
      },
      {
        // TODO: questionable route
        // we probably want to avoid a redirection dance
        // and render the error where it happened. For example
        // if the user is a /policies/123 and some unforeseen error
        // occured instead of redirecting him to /error we should keep him
        // at the page so that he can save the url where the issue occured.
        path: ERROR_ROUTE,
        element: <ErrorPage />
      },
      {
        path: LOGIN_ROUTE,
        element: <LoginPage />
      },
      {
        path: `${PARTNER_ROUTE}/:id`,
        element: (
          <AuthGuard>
            <Layout searchContent={null} sideViewContent={<ReferralPartnerSideView />}>
              <ReferralPartnerPage />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: `${PARTNERS_ROUTE}/:id`,
        element: (
          <AuthGuard>
            <Layout searchContent={null} sideViewContent={null}>
              <PartnerLegacyPage />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: PARTNERS_MANAGEMENT_ROUTE,
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Layout searchContent={<PartnerSearch />} sideViewContent={<ReferralPartnerSideView />}>
                <PartnersManagementPage />
              </Layout>
            )
          }
        ]
      },
      {
        // TODO: questionable route
        // instead of redirecting the user,
        // it would be more HTTP semantically correct to keep him
        // at the same page
        path: POLICY_NOT_FOUND_ROUTE,
        element: (
          <AuthGuard>
            <PermissionGuard permissions={[Action.PolicyGet]}>
              <PolicyNotFound />
            </PermissionGuard>
          </AuthGuard>
        )
      },
      {
        path: POLICIES_ROUTE,
        element: (
          <AuthGuard>
            <PermissionGuard permissions={[Action.PolicyGet, Action.PolicySearch]}>
              <Outlet />
            </PermissionGuard>
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Layout searchContent={<PoliciesSearch />} sideViewContent={<PoliciesSideView />}>
                <PoliciesPage />
              </Layout>
            )
          },
          {
            path: ':id',
            element: (
              <Layout searchContent={null} sideViewContent={<PolicySideView />}>
                <PolicyPage />
              </Layout>
            )
          }
        ]
      },
      {
        path: '/claims',
        element: (
          <AuthGuard>
            <MicrofrontendLoader parcel={Parcels.Claim} />
          </AuthGuard>
        )
      },
      {
        path: '/v2/policies',
        element: (
          <AuthGuard>
            <MicrofrontendLoader parcel={Parcels.Policy} />
          </AuthGuard>
        )
      },
      {
        path: PAYMENTS_ROUTE,
        element: (
          <AuthGuard>
            <PermissionGuard permissions={[Action.PolicyGet, Action.PolicySearch]}>
              <Outlet />
            </PermissionGuard>
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Layout searchContent={null} sideViewContent={<UpcomingPaymentsSideView />}>
                <PaymentsPage />
              </Layout>
            )
          }
        ]
      },
      {
        path: INVOICES_ROUTE,
        element: (
          <AuthGuard>
            <PermissionGuard permissions={[]}>
              <Outlet />
            </PermissionGuard>
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <Layout searchContent={null} sideViewContent={<InvoiceSideView />}>
                <InvoicesPage />
              </Layout>
            )
          }
        ]
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
])
