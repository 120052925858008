import axios, { AxiosError, AxiosInstance, AxiosResponse, isAxiosError } from 'axios'
import HttpStatus from 'http-status-codes'
import isNil from 'lodash/isNil'
import mem from 'mem'
import { Store } from 'redux'

import { TestingFlags } from '@alteos/ui'

import { API_URL, APP_KEY } from '../../config'
import { authService } from '../../modules/Auth/service/authService/authService'
import { refreshToken as refreshTokenAction } from '../../modules/Auth/store/actionCreators'
import { IRootState } from '../../store/interfaces'
import { GRAPHQL_URL } from './constants'
import { GraphQLException, UnauthorizedException } from './exceptions'

interface IApiOptions {
  client?: AxiosInstance
}

export class Api {
  private readonly testingFlags: TestingFlags
  private readonly axiosInstance: AxiosInstance

  private storeRef: Store<IRootState> | null = null

  constructor(options: IApiOptions = {}) {
    this.axiosInstance =
      options.client ??
      axios.create({
        baseURL: API_URL
      })
    this.testingFlags = TestingFlags.enable()
    this.#injectAccessTokenInterceptor()
    this.#injectRefreshTokenInterceptor()
  }

  // TODO: generally we don't want our http layer to know anything about stores or react.
  bindToStore(store: Store): void {
    this.storeRef = store
  }

  query = async (schema: string, variables: Record<string, unknown> = {}): Promise<any | null> => {
    const response: AxiosResponse = await this.axiosInstance.post(GRAPHQL_URL, { query: schema, variables })
    if (!isNil(response.data) && !isNil(response.data.data)) {
      return response.data.data
    } else {
      const graphQLErrorStack: string[] =
        !isNil(response.data) && !isNil(response.data.errors) ? response.data.errors : []
      throw new GraphQLException(graphQLErrorStack)
    }
  }

  get = async <T = any>(url: string, params?: URLSearchParams): Promise<AxiosResponse<T>> => {
    const response: AxiosResponse = await this.axiosInstance.get(url, { params })
    return response
  }

  post = async (url: string, body?: unknown, params?: unknown): Promise<AxiosResponse<any, any>> => {
    const config = { params }
    return this.axiosInstance.post(url, body, config)
  }

  patch = async (url: string, body?: unknown): Promise<AxiosResponse> => {
    return this.axiosInstance.patch(url, body)
  }

  put = async (url: string, body?: unknown): Promise<AxiosResponse> => {
    return this.axiosInstance.put(url, body)
  }
  #injectAccessTokenInterceptor() {
    this.axiosInstance.interceptors.request.use((request) => {
      const token = this.storeRef!.getState().auth.currentUser?.token
      request.headers['X-App-Key'] = APP_KEY
      if (!isNil(token)) {
        request.headers['Authorization'] = `Bearer ${token}`
      }
      const testingFlagsString: string = this.testingFlags.toString()
      if (testingFlagsString.length !== 0) {
        request.headers['X-Testing-Flags'] = testingFlagsString
      }
      return request
    })
  }

  #injectRefreshTokenInterceptor() {
    type RefreshErrror = AxiosError<any, any> & { config: { _didRetry?: boolean } }

    function isRefreshError(axiosError: unknown): axiosError is RefreshErrror {
      return isAxiosError(axiosError) && axiosError.response?.status === HttpStatus.UNAUTHORIZED
    }

    // NOTE: "mem" used to ensure that we are refreshing the token only 1 time
    const refreshAccessTokenMemo = mem(
      async (): Promise<void> => {
        const refreshToken = this.storeRef?.getState().auth.currentUser?.refreshToken
        if (isNil(refreshToken)) {
          return
        }
        const { refresh_token, access_token } = await authService.refreshAccessToken(refreshToken)
        this.storeRef?.dispatch(refreshTokenAction({ token: access_token, refreshToken: refresh_token ?? null }))
      },
      { maxAge: 10000 }
    )

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!isRefreshError(error)) {
          return Promise.reject(error)
        }
        if (error.config._didRetry === true) {
          return Promise.reject(new UnauthorizedException(error))
        }
        error.config._didRetry = true
        return refreshAccessTokenMemo().then(
          () => {
            return this.axiosInstance(error.config!)
          },
          (_refreshError) => {
            return this.axiosInstance(error.config!)
          }
        )
      }
    )
  }
}

const api: Api = new Api()

export default api
