import i18next from 'i18next'
import { isEmpty, isNil } from 'lodash'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import { FormErrors, InjectedFormProps } from 'redux-form'

import { FormData } from '../../../../../components/Form/ReduxForm'
import { IReferralPartner } from '../../../../Partner/interfaces/referralPartner'
//import hearing aid ID
import {
  EBIKE_2024_PRODUCT_ID,
  EBIKE_LEASING_EXTENSION_PRODUCT_ID,
  HEARING_AIDS_2023_PRODUCT_ID,
  HEARING_AIDS_PRODUCT_ID
} from '../../../constants'

interface IUpdateReferralPartnerFormValues extends FormData {
  products: {
    [key: string]: boolean
  }
  productConfigExtension: IReferralPartner['productConfigExtension']
}

/* eslint-disable no-undefined */
const ibanValidationsForCountry = {
  DE: {
    validate: (iban: string) => {
      const ibanRegex = new RegExp(/^DE\d{20}$/)
      if (!ibanRegex.test(iban)) {
        return i18next.t('common:formValidation.invalidIban')
      }
      return undefined
    },
    minLength: 22,
    maxLength: 22
  },
  AT: {
    validate: (iban: string) => {
      const ibanRegex = new RegExp(/^AT\d{18}$/)
      if (!ibanRegex.test(iban)) {
        return i18next.t('common:formValidation.invalidIban')
      }
      return undefined
    },
    minLength: 20,
    maxLength: 20
  }
}

const phoneNumberValidationsForCountry = {
  DE: {
    validate: (phoneNumber: string) => {
      const phoneNumberRegex = new RegExp(
        /^(((((((00|\+?)49)|0)[1-9][0-9]{1,4}))|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)))[0-9]{1,7}([0-9]{1,5})?)$/
      )
      const regexRes = phoneNumberRegex.test(phoneNumber)
      if (!regexRes) {
        return i18next.t('common:formValidation.invalidPhone')
      }
      return undefined
    },
    minLength: 6,
    maxLength: 50
  },
  AT: {
    validate: (phoneNumber: string) => {
      const phoneNumberRegex = new RegExp(/^(?:\+43|0)\d{1,4}[/\s.-]?\d{1,4}[/\s.-]?\d{3,7}$/)
      const regexRes = phoneNumberRegex.test(phoneNumber)
      if (!regexRes) {
        return i18next.t('common:formValidation.invalidPhone')
      }
      return undefined
    },
    minLength: 6,
    maxLength: 50
  }
}

const plzValidationsForCountry = {
  DE: {
    validate: (plz: string) => {
      // current regex: ^\\d*$
      const plzRegex = new RegExp(/^\d{5}$/)
      if (!plzRegex.test(plz)) {
        return i18next.t('common:formValidation.invalidPlz')
      }
      return undefined
    },
    minLength: 5,
    maxLength: 5
  },
  AT: {
    validate: (plz: string) => {
      const plzRegex = new RegExp(/^[1-9]\d{3}$/)
      if (!plzRegex.test(plz)) {
        return i18next.t('common:formValidation.invalidPlz')
      }
      return undefined
    },
    minLength: 4,
    maxLength: 4
  }
}

const salesTaxIdNumberValidationsForCountry = {
  DE: {
    validate: (salesTaxIdNumber: string) => {
      const salesTaxIdNumberRegex = new RegExp(
        /^((DE)?[0-9]{9})|([0-9]{5}\/[0-9]{5})|([0-9]{2,3}\/[0-9]{3,4}\/[0-9]{3,5})$/
      )
      if (!salesTaxIdNumberRegex.test(salesTaxIdNumber)) {
        return i18next.t('common:formValidation.invalidSalesTaxIdNumber')
      }
      return undefined
    },
    minLength: 4,
    maxLength: 14
  },
  AT: {
    validate: (salesTaxIdNumber: string) => {
      const salesTaxIdNumberRegex = new RegExp(/^(ATU\d{8}|\d{2}-\d{3}\/?\d{4})$/)
      if (!salesTaxIdNumberRegex.test(salesTaxIdNumber)) {
        return i18next.t('common:formValidation.invalidSalesTaxIdNumber')
      }
      return undefined
    },
    minLength: 4,
    maxLength: 11
  }
}

export const validatePhoneNumber = (phoneNumber: string, country: string = 'DE'): string | undefined => {
  const validator = phoneNumberValidationsForCountry[country as keyof typeof phoneNumberValidationsForCountry]
  // if no validator is found, check keys to see if the country is valid else return country not supported
  if (isNil(validator)) return undefined
  if (isNil(phoneNumber) || isEmpty(phoneNumber.trim())) return undefined
  if (phoneNumber.length < validator.minLength) return i18next.t('common:formValidation.tooShort')
  if (phoneNumber.length > validator.maxLength) return i18next.t('common:formValidation.tooLong')
  return validator.validate(phoneNumber)
}

export const validateSalesTaxIdNumber = (salesTaxIdNumber: string, country: string = 'DE'): string | undefined => {
  const validator = salesTaxIdNumberValidationsForCountry[country as keyof typeof salesTaxIdNumberValidationsForCountry]
  if (isNil(validator)) return undefined
  if (isNil(salesTaxIdNumber) || isEmpty(salesTaxIdNumber.trim())) return i18next.t('common:formValidation.required')
  if (salesTaxIdNumber.length < validator.minLength) return i18next.t('common:formValidation.tooShort')
  if (salesTaxIdNumber.length > validator.maxLength) return i18next.t('common:formValidation.tooLong')
  return validator.validate(salesTaxIdNumber)
}

export const validatePlz = (plz: string, country: string = 'DE'): string | undefined => {
  const validator = plzValidationsForCountry[country as keyof typeof plzValidationsForCountry]
  if (isNil(validator)) return undefined
  if (isNil(plz) || isEmpty(plz.trim())) return i18next.t('common:formValidation.required')
  if (plz.length < validator!.minLength) return i18next.t('common:formValidation.tooShort')
  if (plz.length > validator!.maxLength) return i18next.t('common:formValidation.tooLong')
  return validator.validate(plz)
}

export const validateIban = (
  iban: string,
  hasHearingAid?: boolean,
  hearingAidCommission?: string,
  numberOfSelectedProducts?: number,
  country: string = 'DE'
): string | undefined => {
  const validator = ibanValidationsForCountry[country as keyof typeof ibanValidationsForCountry]
  if (isNil(validator)) return undefined
  if (isNil(iban)) return undefined
  if (isEmpty(iban.trim())) {
    // if they have hearingaid and hearingaid commission is 0 and only 1 product is selected, then iban is not required
    if (hasHearingAid! && hearingAidCommission! === '0' && numberOfSelectedProducts === 1) {
      return undefined
    }
    return i18next.t('common:formValidation.required')
  }
  if (iban.length < validator!.minLength) return i18next.t('common:formValidation.tooShort')
  if (iban.length > validator!.maxLength) return i18next.t('common:formValidation.tooLong')

  return validator.validate(iban)
}

export const validateUpdateReferralPartnerForm = (
  values: IUpdateReferralPartnerFormValues,
  props: InjectedFormProps<FormData, unknown, string>
): FormErrors<FormData, string> => {
  const errors: FormErrors<FormData, string> = {}

  const selectedProductkeys = keys(pickBy(values.products))
  const filteredSelectedProductKeys = selectedProductkeys.filter((element) => {
    return element !== ''
  })

  const unselectedProductsKeys = keys(
    pickBy(values.products, (value) => {
      return value === false
    })
  )

  if (unselectedProductsKeys.length !== 0) {
    if (filteredSelectedProductKeys.length === 0) {
      unselectedProductsKeys.forEach((product) => {
        ;(errors as any).products = { [product]: i18next.t('common:formValidation.notEmptyProductCheckbox') }
      })
    }

    values.products = pickBy(values.products)
  }

  const shouldHaveSameGPSIncluded = [
    {
      id: EBIKE_2024_PRODUCT_ID,
      error: 'partner:referralPartner.edit.formValidation.gpsOptionShouldBeEqualEbikeLeasingExtension'
    },
    {
      id: EBIKE_LEASING_EXTENSION_PRODUCT_ID,
      error: 'partner:referralPartner.edit.formValidation.gpsOptionShouldBeEqualEbike'
    }
  ]
  const getGpsIncluded = (id: string) => values.productConfigExtension?.[id]?.values?.gpsIncluded
  const productGpsOptionSelected = (id: string) => !isNil(getGpsIncluded(id)) && values.products[id]
  const gpsIncludedValidationNeeded = shouldHaveSameGPSIncluded.some(({ id }, _, list) => {
    if (productGpsOptionSelected(list[0].id) && productGpsOptionSelected(id)) {
      return getGpsIncluded(list[0].id) !== getGpsIncluded(id)
    }
    return false
  })

  if (gpsIncludedValidationNeeded) {
    props.touch(...shouldHaveSameGPSIncluded.map(({ id }) => `productConfigExtension[${id}].values.gpsIncluded`))
    errors.productConfigExtension = shouldHaveSameGPSIncluded.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: {
          values: {
            gpsIncluded: i18next.t(curr.error)
          }
        }
      }),
      {}
    ) as unknown as string
  }

  const {
    products = {},
    productConfigExtension = {},
    IBAN,
    country,
    companyPhone,
    companyAddressPlz,
    salesTaxIdNumber
  } = values
  errors.companyAddressPlz = validatePlz(companyAddressPlz as string, country as string)
  errors.companyPhone = validatePhoneNumber(companyPhone as string, country as string)
  errors.salesTaxIdNumber = validateSalesTaxIdNumber(salesTaxIdNumber as string, country as string)

  const hasHearingAid = products?.[HEARING_AIDS_PRODUCT_ID] || products?.[HEARING_AIDS_2023_PRODUCT_ID]

  const numOfSelectedProducts = Object.keys(products).length
  const hearingAid2022Commission = productConfigExtension?.[HEARING_AIDS_PRODUCT_ID]?.values?.commissionRate
  const hearingAid2023Commission = productConfigExtension?.[HEARING_AIDS_2023_PRODUCT_ID]?.values?.commissionRate

  const hearingAidCommission = hearingAid2022Commission ?? hearingAid2023Commission

  errors.IBAN = validateIban(
    IBAN as string,
    hasHearingAid,
    hearingAidCommission as unknown as string,
    numOfSelectedProducts,
    country as string
  )
  return errors
}
