import { FC, ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '../../../../components/Loader'
import { IStore } from '../../../../interfaces/store'
import { MODULE_NAME } from '../../constants'
import { ModeType } from '../../dictionaries'
import { loadClaimDetails } from '../../store/actionCreators'
import EditClaimForm from './EditClaimForm'
import ClaimView from './View'

const ClaimDetails: FC = (): ReactElement | null => {
  const { t } = useTranslation('policy')
  const dispatch = useDispatch()
  const claimsState = useSelector((state: IStore) => state[MODULE_NAME])
  const { modalParams, focusedClaim: claim, policy, config, mode, isSubmitting } = claimsState
  const claimId = modalParams?.id ?? ''

  useEffect(() => {
    const fetchData: () => Promise<void> = async () => {
      dispatch(loadClaimDetails({ id: claimId, syncClaimsDocuments: true }))
    }

    void fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimId])

  if (claim === null || config === null || policy === null) {
    return <Loader name="claim-details" />
  }

  if (mode === ModeType.Edit) {
    return <EditClaimForm config={config} policy={policy} claim={claim} t={t} isSubmitting={isSubmitting} />
  }

  return <ClaimView claim={claim} config={config} t={t} />
}

export default ClaimDetails
