import { isNil } from 'lodash'

import { IMandate, IProcessingData } from '../interfaces'

interface IMappedPaymentData {
  firstName?: string
  lastName?: string
  iban?: string
  signedOn?: string
}

export const mapPaymentData = (
  mandate: IMandate | null,
  purchaseProcessingData: IProcessingData | undefined
): IMappedPaymentData => {
  const paymentInformation = (
    !isNil(mandate)
      ? { ...mandate.payerInformation, signedOn: mandate.signedOn }
      : { ...purchaseProcessingData, signedOn: purchaseProcessingData?.consentedAt }
  ) as IMandate

  return paymentInformation
}
