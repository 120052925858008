import { IRequestPagination } from '@alteos/ui'

import defaultApiInstance, { Api } from '../../../services/api/api'
import { IInovicesApi, IInvoicePreviewResponse, IInvoicesResponse, InvoiceTypeEnum } from '../interfaces'

class InvoicesApi implements IInovicesApi {
  constructor(private api: Api) {}

  loadInvoices = async (
    { limit, offset }: IRequestPagination,
    invoiceTypes?: InvoiceTypeEnum[]
  ): Promise<IInvoicesResponse> => {
    const invoicesUrl = 'v1/invoice'
    const params: URLSearchParams = new URLSearchParams([
      ['offset', offset.toString()],
      ['limit', limit.toString()]
    ])

    invoiceTypes?.forEach((type) => {
      params.append('types', type)
    })

    const response = await this.api.get(invoicesUrl, params)
    return response.data
  }

  loadInvoicePreview = async (invoiceId: string): Promise<IInvoicePreviewResponse> => {
    const invoicePreviewUrl = `v1/invoice/${invoiceId}/preview`
    const response = await this.api.get(invoicePreviewUrl)
    return response.data
  }

  sendEmail = async (invoiceId: string, pay?: boolean): Promise<void> => {
    const invoicesUrl = `v1/invoice/${invoiceId}/send-email?pay=${pay}`
    await this.api.post(invoicesUrl)
  }
}

export const invoicesApi: IInovicesApi = new InvoicesApi(defaultApiInstance)
