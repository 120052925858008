import { FC } from 'react'

import { Icon } from '@alteos/ui'

interface IProps {
  name: string
  className?: string
  dataTest?: string
}

export const Loader: FC<IProps> = ({ name, className, dataTest = `app-loader-icon-${name}` }: IProps) => {
  return (
    <span aria-label="Loader Icon">
      <Icon name="loader" dataTest={dataTest} className={className} />
    </span>
  )
}
