import { IAction } from '../../../../interfaces/store'
import { IPartnerResponse, IPartnerState } from '../../interfaces'
import { LOAD_PARTNER_FAILURE, LOAD_PARTNER_REQUEST, LOAD_PARTNER_SUCCESS } from '../constants'

export const initialState: IPartnerState = {
  isFetchingPartner: false,
  partner: { id: '', name: '' },
  errorMessage: null
}

function reducer(state: IPartnerState = initialState, action: IAction<any>): IPartnerState {
  switch (action.type) {
    case LOAD_PARTNER_REQUEST: {
      return {
        ...state,
        isFetchingPartner: true
      }
    }

    case LOAD_PARTNER_SUCCESS: {
      const { payload }: IAction<IPartnerResponse> = action

      return {
        ...state,
        partner: payload
      }
    }

    case LOAD_PARTNER_FAILURE: {
      return {
        ...state,
        isFetchingPartner: false
      }
    }

    default:
      return state
  }
}

export default reducer
