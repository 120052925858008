import { AxiosResponse } from 'axios'

import { IQuoteResponsePayload } from '@alteos/quotation-client/dist/interfaces/IQuoteResponsePayload'

import defaultApiInstance, { Api } from '../../../../services/api/api'
import {
  IActivityLog,
  IGetPolicyFlags,
  IQuotePolicyRequestPayload,
  IMappedPolicyResponsePayload,
  IPayPolicyRequestPayload,
  IGetPolicyResponsePayload,
  IPolicyRenewRequestPayload,
  IPolicyRenewResponsePayload,
  IPolicyUpdateRequest,
  IPolicyUpdateResponse,
  IPurchase,
  IUpdatePaymentDetailsRequestPayload,
  IPolicyApi,
  IGetTerminationRulesRequestPayload,
  IGetTerminationRulesResponsePayload,
  IExecuteTerminationRuleRequestPayload,
  IExecuteTerminationRuleResponsePayload,
  IGetRefundAmountRequestPayload,
  IGetRefundAmountResponsePayload,
  IExecuteTerminationRequestPayload,
  IScheduleEndorsementResponsePayload,
  IScheduleEndorsementRequestPayload,
  IHealthCheck,
  ITransaction,
  IUpdateMandateRequestPayload,
  IMandate
} from '../../interfaces'
import { GET_POLICY_BY_ID_QUERY } from '../../queries/getPolicyByIdQuery'
import { mapPolicyResponse } from '../policyApi/mapPolicyResponse'

export class PolicyApi implements IPolicyApi {
  constructor(private api: Api) {}

  getPolicy = async (policyId: string, flags: IGetPolicyFlags): Promise<IMappedPolicyResponsePayload> => {
    const response: IGetPolicyResponsePayload = await this.api.query(GET_POLICY_BY_ID_QUERY, {
      policyId,
      withClaims: flags?.withClaims ?? false
    })
    return {
      policyById: response.policyById === null ? null : mapPolicyResponse(response.policyById).policyById
    }
  }

  getLogs = async (policyId: string): Promise<IActivityLog[]> => {
    const response: AxiosResponse<IActivityLog[]> = await this.api.get(`v2/policies/${policyId}/activity-logs`)
    return response.data
  }

  updatePolicy = async (
    policyId: string,
    updatePolicyRequest: IPolicyUpdateRequest
  ): Promise<IPolicyUpdateResponse> => {
    const response: AxiosResponse<IPolicyUpdateResponse> = await this.api.post(
      `v1/policies/${policyId}/update`,
      updatePolicyRequest
    )
    return response.data
  }

  updatePaymentDetails = async (
    policyId: string,
    updatePaymentDetails: IUpdatePaymentDetailsRequestPayload
  ): Promise<void> => {
    const response: AxiosResponse<void> = await this.api.post(
      `v1/policies/${policyId}/updatePaymentDetails`,
      updatePaymentDetails
    )
    return response.data
  }

  getPolicyQuotation = async (
    quoteRequest: IQuotePolicyRequestPayload,
    saveQuote: boolean = false
  ): Promise<IQuoteResponsePayload> => {
    const url = 'v2/policies/quote'
    const params = { saveQuote }
    const resp: AxiosResponse<IQuoteResponsePayload> = await this.api.post(url, quoteRequest, params)
    return resp.data
  }

  payForPolicy = async (policyId: string, paymentDetails: IPayPolicyRequestPayload): Promise<void> => {
    await this.api.post(`v1/policies/${policyId}/pay`, paymentDetails)
  }

  renewPolicy = async (renewPayload: IPolicyRenewRequestPayload): Promise<IPolicyRenewResponsePayload> => {
    const resp: AxiosResponse<IPolicyRenewResponsePayload> = await this.api.post('v2/policies/renew', renewPayload)
    return resp.data
  }

  getPurchaseForPolicyId = async (policyId: string): Promise<IPurchase> => {
    const response = await this.api.post('v1/purchases/find', { policyId })
    return response.data
  }

  syncPolicyDocuments = async (policyId: string): Promise<void> => {
    await this.api.post(`v1/policies/${policyId}/synchronize`)
  }

  // NOTE: Terminate policy
  getTerminationRules = async (
    policyId: string,
    payload: IGetTerminationRulesRequestPayload
  ): Promise<IGetTerminationRulesResponsePayload> => {
    const response: AxiosResponse<IGetTerminationRulesResponsePayload> = await this.api.post(
      `v2/policies/${policyId}/termination/rules`,
      payload
    )
    return response.data
  }

  executeTerminationRule = async (
    policyId: string,
    ruleCode: string,
    payload: IExecuteTerminationRuleRequestPayload
  ): Promise<IExecuteTerminationRuleResponsePayload> => {
    const response: AxiosResponse<IExecuteTerminationRuleResponsePayload> = await this.api.post(
      `v2/policies/${policyId}/termination/rules/${ruleCode}`,
      payload
    )
    return response.data
  }

  getRefundAmount = async (
    policyId: string,
    payload: IGetRefundAmountRequestPayload
  ): Promise<IGetRefundAmountResponsePayload> => {
    const response: AxiosResponse<IGetRefundAmountResponsePayload> = await this.api.post(
      `v2/policies/${policyId}/termination/get-refund`,
      payload
    )

    return response.data
  }

  executeTermination = async (policyId: string, payload: IExecuteTerminationRequestPayload): Promise<void> => {
    await this.api.post(`v2/policies/${policyId}/termination`, payload)
  }

  scheduleEndorsement = async (
    policyId: string,
    scheduleEndorsementPayload: IScheduleEndorsementRequestPayload
  ): Promise<IScheduleEndorsementResponsePayload> => {
    const resp: AxiosResponse<IPolicyRenewResponsePayload> = await this.api.post(
      `v2/policies/${policyId}/schedule-endorsement`,
      scheduleEndorsementPayload
    )
    return resp.data
  }

  getHealthCheck = async (policyId: string): Promise<IHealthCheck[]> => {
    const response: AxiosResponse<IHealthCheck[]> = await this.api.get(`v2/policies/${policyId}/health-checks`)
    return response.data
  }

  getTransactions = async (policyId: string): Promise<ITransaction[]> => {
    const response: AxiosResponse<ITransaction[]> = await this.api.get(`v2/policies/${policyId}/transactions`)
    return response.data
  }

  getMandate = async (policyPrettyId: string): Promise<IMandate> => {
    const response: AxiosResponse<IMandate> = await this.api.post('v2/proxy/mandate', {
      method: 'get',
      path: `v1/payment/mandates?reference=${policyPrettyId}`
    })
    return response.data
  }

  updateMandate = async (updateMandatePayload: IUpdateMandateRequestPayload): Promise<void> => {
    await this.api.post('v2/proxy/mandate', {
      method: 'post',
      path: 'v1/payment/mandates',
      payload: updateMandatePayload
    })
  }
}
export const policyApi: IPolicyApi = new PolicyApi(defaultApiInstance)
