import { formatFactory } from '@alteos/ui'

import { formatBoolean } from './formatBoolean'
import { formatTimePeriodPrice } from './formatTimePeriodPrice'
import { formatTimePeriodString } from './formatTimePeriodString'
import { ILocalFormatFactory } from './interfaces'

export const localFormatFactory: ILocalFormatFactory = {
  ...formatFactory,
  formatBoolean,
  formatTimePeriodPrice,
  formatTimePeriodString
}
