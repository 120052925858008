import { AxiosResponse, isAxiosError } from 'axios'
import HttpStatus from 'http-status-codes'
import i18next from 'i18next'
import isNil from 'lodash/isNil'
import { call, put, select } from 'typed-redux-saga'

import { Action } from '@alteos/dictionaries/dist/Action'
import { IFormValues, isNilOrEmptyString } from '@alteos/ui'

import { ICardSection } from '../../../../components/Cards'
import { IFormConfig } from '../../../../components/Form/interfaces'
import ProcessingType from '../../../../dictionaries/ProcessingType'
import { SectionIds } from '../../../../dictionaries/SectionIds'
import ToastType from '../../../../dictionaries/ToastType'
import { IFieldConfig } from '../../../../interfaces/common'
import { IAction, IStore } from '../../../../interfaces/store'
import api from '../../../../services/api/api'
import { getRouter } from '../../../../store/utils/getRouter'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils/requireNotNull'
import { getUserPermissions, hasPermission, Permissions } from '../../../Auth'
import { actions as layoutActions } from '../../../Layout'
import { showModal } from '../../../Modal/store/actionCreators'
import { POLICY_NOT_FOUND_ROUTE } from '../../../Static'
import { policyApi, productConfigApi, scheduledActionsApi } from '../../api'
import { IConfigResponse } from '../../api/productConfigApi/productConfigDto'
import { EndorsementReason } from '../../dictionaries'
import {
  IMandate,
  IMappedPolicyResponsePayload,
  IPolicy,
  IPolicyRenewRequestPayload,
  IPolicyRenewResponsePayload,
  IPolicyUpdateResponse,
  IPolicyUpdateRequest,
  IPayPolicyRequestPayload
} from '../../interfaces'
import {
  SEPA_ENDORSEMENT_MODAL_ID,
  NON_SEPA_ENDORSEMENT_MODAL_ID
} from '../../SideViewContent/PolicyObjectForm/EndorsementModal/constants'
import { waitForEndorsementModalClose } from '../../SideViewContent/PolicyObjectForm/EndorsementModal/sagas'
import { extendUIConfig, getPayloadFromPlainObject, getQuotationPayload } from '../../utils'
import {
  createNoteStart,
  loadNotesStart,
  saveErrorMessage,
  savePolicyAndConfig,
  setMandate,
  setPolicy,
  setPurchase,
  submitFormFailure,
  submitFormSuccess,
  updateIbanFailure,
  updateIbanSuccess
} from '../actionCreators'
import { getPaymentProcessingType } from '../selectors'
import { getCreateScheduledTerminationPayload } from './utils/getCreateScheduledTerminationPayload'
import { getRenewPayload } from './utils/getRenewPayload'
import { isPremiumRelatedFieldChanged } from './utils/isPremiumRelatedFieldChanged'

interface IOnPolicyUpdateCompleteArgs {
  policyId: string
  formName: string
  successMessage: string
}

const policySelector = (state: IStore) => requireNotNull(state['policy'].policy)

export function* loadPolicyAndConfig({ payload: policyId }: IAction<string>): Generator {
  const router = yield* getRouter()
  const permissions: Permissions = (yield* select(getUserPermissions)) as unknown as Permissions
  const withClaims: boolean = hasPermission(permissions, [Action.ClaimGet, Action.ClaimList])

  let policyResponse: IMappedPolicyResponsePayload | undefined
  let mandateResponse: IMandate | undefined
  try {
    policyResponse = yield* call(policyApi.getPolicy, policyId, { withClaims })
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to query policy by Id', error))
    throw error
  }

  try {
    mandateResponse = yield* call(policyApi.getMandate, policyResponse.policyById!.prettyId)
    yield* put(setMandate(mandateResponse))
  } catch (error: unknown) {
    handlePolicyWithoutMandate(error)
  }

  try {
    yield* call(policyApi.syncPolicyDocuments, policyId)
  } catch (error: unknown) {
    yield* triggerErrorPopup(`Failed to synchronize policy documents: ${error}`)
  }

  const policy: IPolicy | null = policyResponse.policyById

  if (isNil(policy)) {
    yield* put(saveErrorMessage('Policy not found', null))
    yield* call(router.navigate, POLICY_NOT_FOUND_ROUTE)
    return
  }

  try {
    const transactionsResponse = yield* call(policyApi.getTransactions, policyResponse.policyById!.id)
    policy.transactions = transactionsResponse
  } catch (error: unknown) {
    yield* triggerErrorPopup(`Failed to get policy transactions: ${error}`)
  }

  if (isNil(policy.metadata.originalPolicyId)) {
    try {
      yield* call(policyApi.syncPolicyDocuments, policyId)
    } catch (error: unknown) {
      yield* triggerErrorPopup(`Failed to synchronize policy documents: ${error}`)
    }
  }

  const { product }: IPolicy = policy
  let configResponse: IConfigResponse | undefined
  try {
    configResponse = yield* call(productConfigApi.getProductConfiguration, product.id)
  } catch (error: any) {
    yield* put(saveErrorMessage('Failed to get product configuration', error))
    throw error
  }

  extendUIConfig(configResponse.configuration)

  yield* put(savePolicyAndConfig({ policy, config: configResponse.configuration }))

  yield* put(loadNotesStart(0))
}

export function* submitEditForm(payload: IFormConfig): Generator {
  const { formSections, initialValues, successMessage, form: formName }: IFormConfig = payload
  let { url }: IFormConfig = payload
  const policy: IPolicy = yield* select(policySelector)
  const formValues: IFormValues = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as IFormValues
  const allFields: IFieldConfig[] = formSections.reduce(
    (result: IFieldConfig[], form: ICardSection) => [...result, ...form.fields],
    []
  )
  const data: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)
  let phone: undefined
  // NOTE: temporary solution until BE is updated to accept null AND undefined (currently only accepts undefined)
  if (typeof data.customer !== 'undefined' && isNilOrEmptyString(data.customer?.phone)) {
    data.customer.phone = phone
  }
  let response: AxiosResponse<IPolicyUpdateResponse> | undefined

  const note: string | undefined = (formValues as { helpers?: Record<string, any> }).helpers?.note
  if (typeof note === 'string' && !isNilOrEmptyString(note)) {
    yield* put(createNoteStart(note, false))
  }

  try {
    response = yield* call(api.post, url, data)
  } catch (error) {
    yield* put(submitFormFailure())
    yield* triggerErrorPopup(error)
    return
  }

  if (typeof response === 'undefined') {
    yield* put(submitFormFailure())
    throw new Error('undefined response at submitEditForm for policies')
  }

  yield* call(onPolicyUpdateComplete, {
    policyId: policy.id,
    formName: payload.form,
    successMessage
  })
}

export function* updateIban({ payload }: IAction<IFormConfig>): Generator {
  const { formSections, initialValues, successMessage, form: formName }: IFormConfig = payload
  const formValues: IFormValues = (yield select(
    (state: IStore) => state.form[formName].values
  ) as unknown) as IFormValues
  const allFields: IFieldConfig[] = formSections.reduce(
    (result: IFieldConfig[], form: ICardSection) => [...result, ...form.fields],
    []
  )
  const data: Record<string, any> = getPayloadFromPlainObject(initialValues, formValues, allFields)
  const policy: IPolicy = requireNotNull(yield* select((state: IStore) => state['policy'].policy), 'policyFromStore')
  const mandate = yield* select((state: IStore) => state['policy'].mandate)

  try {
    if (!isNil(mandate)) {
      yield* call(policyApi.updateMandate, {
        firstName: data.firstName,
        lastName: data.lastName,
        iban: data.iban,
        consentedAt: new Date(mandate.signedOn),
        reference: policy.prettyId
      })
    }
  } catch (error) {
    yield* put(updateIbanFailure())
    yield* triggerErrorPopup(error)
    return
  }

  try {
    yield* call(policyApi.updatePaymentDetails, policy.id, {
      firstName: data.firstName,
      lastName: data.lastName,
      iban: data.iban
    })
  } catch (error) {
    yield* put(updateIbanFailure())
    yield* triggerErrorPopup(
      error,
      // NOTE: temporary extra information for the user in case when update payment request fails because of not-anymore-supported accounting flow
      '. Try reload the page. If payment processing data are not updated, something went wrong'
    )
    return
  }

  const updatedPolicy: IPolicy | null = yield* getPolicy(policy.id)
  if (updatedPolicy !== null) {
    yield* put(setPolicy(updatedPolicy))
  }

  try {
    const updatedMandate = yield* call(policyApi.getMandate, policy.prettyId)
    yield* put(setMandate(updatedMandate))
  } catch (error: unknown) {
    handlePolicyWithoutMandate(error)
  }

  yield* put(updateIbanSuccess({ formName, withReset: true }))
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
}

export function* submitEditObjectsForm(payload: IFormConfig): Generator {
  const policy: IPolicy = yield* select(policySelector)
  const mandate = yield* select((state: IStore) => state['policy'].mandate)

  const formValues: IFormValues = (yield select(
    (state: IStore) => state.form[payload.form].values
  ) as unknown) as IFormValues

  const quotationSectionFields: IFieldConfig[] = payload.formSections
    .filter((it: ICardSection) => it.id === SectionIds.ObjectsFormPremiumRelated)
    .flatMap((it: ICardSection) => it.fields)
  try {
    const quotationFieldsChanged = isPremiumRelatedFieldChanged(
      payload.initialValues,
      formValues,
      quotationSectionFields
    )

    const isEndorsementReason =
      formValues.updateReason === EndorsementReason.Endorsement ||
      formValues.updateReason === EndorsementReason.PeriodicEndorsement

    if (!quotationFieldsChanged && isEndorsementReason) {
      yield* triggerErrorPopup(i18next.t('policy:policyObjectForm.premiumRelatedFieldChangeRequired'))
      yield* put(submitFormFailure())
      return null
    }

    if (quotationFieldsChanged) {
      const permissions = yield* select(getUserPermissions)
      const withPurchaseFind = hasPermission(permissions, [Action.PurchaseFind])
      if (withPurchaseFind) {
        const purchaseResponse = yield* call(policyApi.getPurchaseForPolicyId, policy.id)
        yield* put(setPurchase(purchaseResponse))
      }
      const processingType = yield* select(getPaymentProcessingType)
      if (processingType === null) {
        yield* triggerErrorPopup(i18next.t('policy:endorsement.noProcessingTypeError'))
        yield* put(submitFormFailure())
        return
      }
      if (processingType === ProcessingType.sepa) {
        yield* put(showModal(SEPA_ENDORSEMENT_MODAL_ID))
      } else {
        yield* put(showModal(NON_SEPA_ENDORSEMENT_MODAL_ID))
      }

      const { paymentDetailsUpdate } = yield* waitForEndorsementModalClose()

      if (paymentDetailsUpdate === null) {
        yield* put(submitFormSuccess({ formName: payload.form, withReset: false }))
        return
      }

      const { initialValues, formSections }: IFormConfig = payload
      // NOTE: renew policy
      if (formValues.updateReason === EndorsementReason.Endorsement) {
        const helpers = requireNotNull(formValues.helpers, 'formValues.helpers')
        const endorsementReason = requireNotNull(
          (helpers as Record<string, any>).endorsementReason,
          'helpers.endorsementReason'
        )

        const renewPayload = getRenewPayload(
          formValues,
          initialValues,
          formSections.flatMap((it: ICardSection) => it.fields),
          policy,
          endorsementReason
        )
        const renewPolicyResponse: IPolicyRenewResponsePayload = yield* call(policyApi.renewPolicy, renewPayload)
        yield* call(policyApi.payForPolicy, renewPolicyResponse.policyId, paymentDetailsUpdate)

        if (!isNil(mandate)) {
          yield* call(policyApi.updateMandate, {
            firstName: (paymentDetailsUpdate as IPayPolicyRequestPayload).payment.firstName,
            lastName: (paymentDetailsUpdate as IPayPolicyRequestPayload).payment.lastName,
            iban: (paymentDetailsUpdate as IPayPolicyRequestPayload).payment.iban,
            consentedAt: new Date(mandate.signedOn),
            reference: policy.prettyId
          })
        }
      }

      // NOTE: schedule endorsement
      if (formValues.updateReason === EndorsementReason.PeriodicEndorsement) {
        const quotationPayload = getQuotationPayload(formValues, policy)
        const quotationResponse = yield* call(policyApi.getPolicyQuotation, quotationPayload, true)

        const quoteId = requireNotNull(quotationResponse.quoteId, 'quotationResponse.quoteId')

        const renewPolicyPayload: IPolicyRenewRequestPayload = getRenewPayload(
          formValues,
          initialValues,
          formSections.flatMap((it: ICardSection) => it.fields),
          policy,
          'Periodic endorsement'
        )

        const scheduleEndorsementRequestPayload = {
          quoteId,
          renewPolicyPayload,
          payPolicyPayload: paymentDetailsUpdate
        }

        yield* call(policyApi.scheduleEndorsement, policy.id, scheduleEndorsementRequestPayload)
      }
    } else {
      // NOTE: normal policy update
      const { package: selectedPackage, ...restValues }: Record<string, unknown> = formValues

      const { package: initialPackage, ...initialValues }: Record<string, unknown> = payload.initialValues

      const policyUpdateRequest: IPolicyUpdateRequest = getPayloadFromPlainObject(
        initialValues,
        restValues,
        payload.formSections.flatMap((it: ICardSection) => it.fields)
      ) as IPolicyUpdateRequest

      yield* call(policyApi.updatePolicy, policy.id, policyUpdateRequest)
    }
  } catch (error: unknown) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }

  yield* call(onPolicyUpdateComplete, {
    policyId: policy.id,
    formName: payload.form,
    successMessage: payload.successMessage
  })
}

export function* submitCreateScheduledTerminationForm(payload: IFormConfig): Generator {
  const policy: IPolicy = yield* select(policySelector)

  const formValues: IFormValues = (yield select(
    (state: IStore) => state.form[payload.form].values
  ) as unknown) as IFormValues

  const requestPayload = getCreateScheduledTerminationPayload(formValues)
  try {
    yield* call(scheduledActionsApi.createScheduledAction, policy.id, requestPayload)
  } catch (error: unknown) {
    yield* put(submitFormFailure())
    yield triggerErrorPopup(error)
    return
  }

  yield* call(onPolicyUpdateComplete, {
    policyId: policy.id,
    formName: payload.form,
    successMessage: payload.successMessage
  })

  yield* getPolicy(policy.id)
}

export function* onPolicyUpdateComplete({
  policyId,
  formName,
  successMessage
}: IOnPolicyUpdateCompleteArgs): Generator {
  const updatedPolicy: IPolicy | null = yield* getPolicy(policyId)
  if (updatedPolicy === null) {
    return
  }
  yield* put(setPolicy(updatedPolicy))
  yield* put(submitFormSuccess({ formName, withReset: true }))
  yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
}

function* getPolicy(policyId: string): Generator<unknown, IPolicy | null> {
  const router = yield* getRouter()
  let policyResponse: IMappedPolicyResponsePayload

  try {
    policyResponse = yield* call(policyApi.getPolicy, policyId)
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to get Policy By Id', error))
    throw error
  }

  if (isNil(policyResponse.policyById)) {
    yield* put(saveErrorMessage('Policy not found', null))
    yield* call(router.navigate, POLICY_NOT_FOUND_ROUTE)
    return null
  }

  return policyResponse.policyById
}

function* handlePolicyWithoutMandate(error: unknown): Generator {
  const mandateNotFound = isAxiosError(error) && error.response?.status === HttpStatus.NOT_FOUND
  if (!mandateNotFound) {
    yield* put(saveErrorMessage('Failed to get mandates for policy', error))
    throw error
  }
  yield* put(setMandate(null))
}
