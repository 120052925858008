import { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { showToast } from '@alteos/ui'

import { IStore } from '../../../../interfaces/store'
import { IToastProps } from '../../interfaces/internal'
import { closeToast } from '../../store/actionCreators'

interface IStoreProps {
  toastProps: IToastProps | null
}

interface IDispatchProps {
  clearToast: () => void
}

interface IProps extends IStoreProps, IDispatchProps {}

const ToastMessage: FC<IProps> = ({ toastProps, clearToast }: IProps) => {
  useEffect(() => {
    if (toastProps !== null) {
      void showToast({
        type: toastProps.type,
        id: `toast-${toastProps.type}-form-submission`,
        content: toastProps.message,
        hideAfter: 5
      })
      clearToast()
    }
  }, [toastProps, clearToast])
  return null
}

const mapStateToProps: (state: IStore) => IStoreProps = (state: IStore) => {
  return {
    toastProps: state.layout.toastProps
  }
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    clearToast: () => dispatch(closeToast())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage)
