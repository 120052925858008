import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import unset from 'lodash/unset'

import { InputType, MaskType, IFormValues, getDateForPayload } from '@alteos/ui'

import { IFieldConfig } from '../../../interfaces/common'
import { isFormField } from '../../../interfaces/guards'
import { getConditionResult } from '../../../utils/getConditionResult'

const fieldsToDelete: string[] = ['objects[0].coverageStartsAt', 'objects[0].coverageEndsAt']

function getPayloadFromPlainObject(
  initialValues: Record<string, any>,
  formValues: Record<string, unknown>,
  allFields: IFieldConfig[]
): Record<string, any> {
  const result: Record<string, any> = cloneDeep(initialValues)

  allFields.forEach((field: IFieldConfig) => {
    if (typeof field.id === 'undefined') {
      return
    }

    if (field.id.startsWith('helpers')) {
      return
    }

    if (!isNil(field.availableIf)) {
      const isAvailable: boolean = getConditionResult(field.availableIf, formValues as IFormValues)

      if (!isAvailable) {
        unset(result, field.id)
        return
      }
    }

    if (!isNil(field.disabledIf)) {
      const isDisabled: boolean = getConditionResult(field.disabledIf, formValues as IFormValues)

      if (isDisabled) {
        unset(result, field.id)
        return
      }
    }

    const value: any = get(formValues, field.id)

    if (!has(initialValues, field.id) && isNil(value)) {
      return
    }

    // TODO: this workaround is made to fix issue with claims UI misconfiguration. Number fields should not be validated
    // as strings. Need to validate all configs after migrating claim configs in database in Claims domain.
    if (value === '' && isFormField(field) && field.editType === InputType.number) {
      unset(result, field.id)
      return
    }

    if (isFormField(field) && field.editType === InputType.date) {
      set(result, field.id, getDateForPayload(value))
      return
    }

    if (isFormField(field) && field.editType === InputType.number) {
      set(result, field.id, Number(value))
      return
    }

    if (isFormField(field) && field.editType === InputType.masked && field.mask === MaskType.iban) {
      set(result, field.id, value.replaceAll(' ', ''))
      return
    }

    if (
      isFormField(field) &&
      (field.editType === InputType.string ||
        field.editType === InputType.email ||
        field.editType === InputType.address ||
        field.editType === InputType.text) &&
      typeof formValues[field.id] === 'string'
    ) {
      set(result, field.id, value.trim())
      return
    }

    set(result, field.id, value)
  })

  unset(result, 'helpers')
  unset(result, 'addons')

  fieldsToDelete.forEach((field: string) => unset(result, field))

  if (typeof result.customer !== 'undefined' && result.customer.phone === null) {
    delete result.customer.phone
  }

  return result
}

export default getPayloadFromPlainObject
