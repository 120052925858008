enum PolicyTab {
  Details = 'details',
  Documents = 'documents',
  Claims = 'claims',
  Transactions = 'transactions',
  Activity = 'activity',
  Notes = 'notes',
  Scheduled = 'scheduled'
}

export default PolicyTab
