import cx from 'classnames'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Action as PermissionAction } from '@alteos/dictionaries/dist/Action'
import { DropdownMenu, DropdownOptionType, Icon, IMenuButtonProps, IMenuDividerOption, IMenuOption } from '@alteos/ui'

import { PermissionConstraint } from '../../../../Auth'
import { PermissionGuard } from '../../../../Auth/components/PermissionGuard/PermissionGuard'
import { logout } from '../../../../Auth/store/actionCreators'
import daveLogo from './assets/dave-logo.svg'
import NavLink from './NavLink'

import styles from './sidebar.module.scss'

interface INavLink {
  url: string
  icon: string
  label: string
  permission: PermissionConstraint
}

const Sidebar: FC = (): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation(['common', 'policies', 'claims', 'partnersManagement', 'payments'])

  const accountMenuOptions: (IMenuOption | IMenuDividerOption)[] = [
    {
      type: DropdownOptionType.button,
      label: t('account.logOut'),
      icon: 'exit',
      closeOnClick: true,
      dataTest: 'sidebar-logout-button',
      handleClick: () => {
        dispatch(logout())
      }
    }
  ]

  const links: INavLink[] = [
    {
      url: '/policies',
      icon: 'policies',
      label: t('policies:policies.title'),
      permission: [PermissionAction.PolicyElasticsearch]
    },
    {
      url: '/claims',
      icon: 'claims',
      label: t('common:layout.nav.claims'),
      permission: [PermissionAction.ClaimsGet]
    },
    {
      url: '/partners-management',
      icon: 'users',
      label: t('partnersManagement:title'),
      permission: [PermissionAction.PolicyElasticsearch]
    },
    {
      url: '/payments',
      icon: 'card',
      label: t('payments:title'),
      permission: [PermissionAction.PolicyElasticsearch]
    },
    {
      url: '/invoices',
      icon: 'docs',
      label: t('invoices:title'),
      permission: [PermissionAction.PolicyElasticsearch]
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <img src={daveLogo} alt="dave-logo" className={styles.logo} />
        <div className={styles.links}>
          {links.map((link: INavLink) => {
            return (
              <PermissionGuard permission={link.permission} key={link.url}>
                <NavLink icon={link.icon} url={link.url} label={link.label} />
              </PermissionGuard>
            )
          })}
        </div>
      </div>

      <DropdownMenu options={accountMenuOptions} containerDataTest="account-selector-options-container">
        {({ setModalState, isModalOpen = false, buttonRef }: IMenuButtonProps) => {
          return (
            <button
              ref={buttonRef}
              className={styles.accountButton}
              data-test="sidebar-account-button"
              type="button"
              onClick={() => setModalState(!isModalOpen)}
            >
              <div className={cx(styles.credentials, 'small-caption')}>
                <Icon name="user-filled" size="16px" />
              </div>
            </button>
          )
        }}
      </DropdownMenu>
    </div>
  )
}

export default Sidebar
