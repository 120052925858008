import { TFunction } from 'i18next'

export const displayPolicyTerminationSuccessMessage = (effectiveAt: Date | undefined, t: TFunction): string => {
  const now = new Date()
  const isPolicyTerminationInFuture = new Date(effectiveAt!) > now

  return isPolicyTerminationInFuture
    ? t('policyTerminationForm.successMessageForSchedulingTermination')
    : t('policyTerminationForm.successMessageForTermination')
}
