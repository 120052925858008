import { AxiosResponse } from 'axios'

import { IFormValue } from '@alteos/ui'

import { IFilters } from '../../../components/Filters/interfaces'
import defaultApiInstance, { Api } from '../../../services/api/api'
import { IPoliciesResponse, IPoliciesApi, IProductsResponse, IPartnersResponse } from '../interfaces'
import { GET_ALL_POLICIES_QUERY } from '../queries/getPoliciesQuery'

class PoliciesApi implements IPoliciesApi {
  constructor(private api: Api) {}

  loadPolicies = async (payload: any, filter: IFilters): Promise<IPoliciesResponse> => {
    let offset = 0
    let limit = 20

    if (typeof payload.payload !== 'undefined') {
      offset = payload.payload.offset
      limit = payload.payload.limit
    }

    const response: IPoliciesResponse = await this.api.query(GET_ALL_POLICIES_QUERY, {
      limit,
      offset,
      ...filter
    })

    return response
  }

  getPartnersList = async (): Promise<AxiosResponse<IPartnersResponse>> => {
    const PARTNERS_URL = 'v1/partners'

    const response = await this.api.get(PARTNERS_URL)
    return response
  }

  getProductsList = async (): Promise<AxiosResponse<IProductsResponse>> => {
    const PRODUCTS_URL = 'v1/products/list'

    const response = await this.api.get(PRODUCTS_URL)
    return response
  }

  importPolicies = async (partnerId: IFormValue, data: FormData): Promise<void> => {
    await this.api.post(`v2/policies/import/${partnerId}`, data)
  }

  terminatePolicies = async (data: FormData): Promise<void> => {
    await this.api.post('v1/policies/cancel', data)
  }
}

export const policiesApi: IPoliciesApi = new PoliciesApi(defaultApiInstance)
