import { IValues } from '../../../interfaces/common'
import { IPolicyDocument } from '../interfaces'

export interface IClaimLog {
  id: string
  action: string
  status: string | null
  note: string | null
  createdBy: string
  createdAt: string
}

export interface IClaim {
  id: string
  prettyId: string
  status: string
  subStatus: string | null
  claimType: string
  values: IValues
  logs: IClaimLog[]
  documents: IPolicyDocument[] | null
  currentReserveAmount: number
  transactions: any
}

export interface IClaimResponse {
  claimById: IClaim
}

export interface IClaimNote {
  id: string
  claimId: string
  createdAt: string
  updatedAt: string
  text: string
  createdBy: string
}

export interface IClaimCreateNoteResponse {
  id: string
}

export interface IClaimObligationAccountingData {
  value: number
}

export interface ISyncDocsResponse {
  message: string
}

export interface IClaimTransaction {
  type: string
  description: string
  updatedAt: string
  amount: IClaimObligationAccountingData
}

export interface IClaimTransactionTableRow {
  type: string
  date: string
  description: string
}

export interface IClaimHistoryTableRow {
  eventDate: string
  status: string
  note: string
  type: string
  updatedBy: string
}

export interface IClaimDocumentTableRow {
  createdAt: string
  name: string
  url: string
  version: number
}

export interface ILoadClaimActionPayload {
  id: string
  syncClaimsDocuments: boolean
}

export enum ClaimReserveType {
  Increase = 'increase',
  Decrease = 'decrease'
}
