import { FC } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import StatusContent from '../../components/StatusContent'

const ErrorPage: FC = () => {
  const [t]: UseTranslationResponse<'static'> = useTranslation('static')

  return (
    <StatusContent
      title={t('error.title')}
      content={t('error.content')}
      subContent={t('error.subContent')}
      button={{ to: '/policies', label: t('notFound.button'), icon: 'arrow-left' }}
      dataTest="error"
    />
  )
}

export default ErrorPage
