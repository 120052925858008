import { IFilter, IFilterOption } from '../../../components/Filters/interfaces'
import { ISSUED_AT, PARTNER_ID, PRODUCT_ID, STATUS, QUERY } from '../constants'
import { getDefaultPoliciesFilter } from './getDefaultPoliciesFilter'

const makePoliciesFiltersOptions: (
  products: IFilterOption[],
  partners: IFilterOption[],
  activeFilters: IFilter[]
) => IFilter[] = (products: IFilterOption[], partners: IFilterOption[], activeFilters: IFilter[]): IFilter[] => {
  const [issuedAtDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === ISSUED_AT)
  const [productDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === PRODUCT_ID)
  const [statusDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === STATUS)
  const [partnerDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === PARTNER_ID)
  const [queryDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === QUERY)

  return [
    {
      active: typeof issuedAtDefaultValue !== 'undefined' ? issuedAtDefaultValue.active : false,
      key: ISSUED_AT,
      value: 'Issued At',
      componentType: 'datePickerRange',
      selectedFilter: typeof issuedAtDefaultValue !== 'undefined' ? issuedAtDefaultValue.selectedFilter : '',
      withSearch: false,
      options: null,
      isCountable: true
    },
    {
      active: typeof productDefaultValue !== 'undefined' ? productDefaultValue.active : false,
      key: PRODUCT_ID,
      value: 'Product',
      options: products,
      componentType: null,
      withSearch: true,
      selectedFilter: typeof productDefaultValue !== 'undefined' ? productDefaultValue.selectedFilter : '',
      isCountable: true
    },
    {
      ...getDefaultPoliciesFilter(),
      active: typeof statusDefaultValue !== 'undefined' ? statusDefaultValue.active : false,
      selectedFilter: typeof statusDefaultValue !== 'undefined' ? statusDefaultValue.selectedFilter : ''
    },
    {
      active: typeof partnerDefaultValue !== 'undefined' ? partnerDefaultValue.active : false,
      key: PARTNER_ID,
      value: 'Partner',
      options: partners,
      componentType: null,
      selectedFilter: typeof partnerDefaultValue !== 'undefined' ? partnerDefaultValue.selectedFilter : '',
      withSearch: true,
      isCountable: true
    },
    {
      active: false,
      key: QUERY,
      value: null,
      options: null,
      componentType: null,
      selectedFilter: typeof queryDefaultValue !== 'undefined' ? queryDefaultValue.selectedFilter : '',
      withSearch: false,
      isCountable: false
    }
  ]
}

export default makePoliciesFiltersOptions
