import { reset } from 'redux-form'
import { call, put, select, delay } from 'typed-redux-saga'

import { ToastType } from '@alteos/ui'

import { IFileWrapper } from '../../../../components/FileUploader/interfaces'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { IAction, IStore } from '../../../../interfaces/store'
import { triggerErrorPopup } from '../../../../store/utils/triggerErrorPopup'
import { requireNotNull } from '../../../../utils'
import { actions as layoutActions } from '../../../Layout'
import { partnerPortalApi } from '../../api/partnerPortalApi'
import { GENERIC_PARTNER_ID } from '../../constants'
import { IPartnerPortalPartnerUser, IPartnerPortalRole } from '../../interfaces'
import {
  createPartnerPortalUserFailure,
  createPartnerPortalUserSuccess,
  getPartnerPortalRolesFailure,
  getPartnerPortalRolesSuccess,
  getPartnerPortalUsersFailure,
  getPartnerPortalUsersRequest,
  getPartnerPortalUsersSuccess,
  importPartnerPortalUsersFailure,
  importPartnerPortalUsersSuccess
} from '../actions/partnerPortal'

export function* getPartnerPortalRoles(): Generator {
  try {
    const response: IPartnerPortalRole[] = yield* call(partnerPortalApi.getRoles)

    yield* put(getPartnerPortalRolesSuccess(response))
  } catch (error: unknown) {
    yield* put(getPartnerPortalRolesFailure('Failed to fetch partner portal roles', error))
  }
}

export function* getPartnerPortalUsers({ payload: partnerId }: { payload: string }): Generator {
  try {
    const response: IPartnerPortalPartnerUser[] = yield* call(partnerPortalApi.getPartnerUsers, partnerId)

    yield* put(getPartnerPortalUsersSuccess({ [partnerId]: response }))
  } catch (error: unknown) {
    yield* put(getPartnerPortalUsersFailure('Failed to fetch partner portal users', error))
    yield* triggerErrorPopup(error)
  }
}

export function* createPartnerPortalUser({ payload }: IAction<IFormConfig>): Generator {
  const { successMessage } = payload
  const formName = payload.form
  const formValues = requireNotNull(yield* select((state: IStore) => state.form[formName]))
  const partner = requireNotNull(yield* select((state: IStore) => state.partner.referralPartnerState.referralPartner))
  const { values }: { [fieldName: string]: any } | undefined = formValues
  const body = {
    ...values,
    roles: [values.roles],
    referralPartnerId: partner.id,
    partnerId: GENERIC_PARTNER_ID
  }
  try {
    yield* put(layoutActions.setSideViewIsProcessingData(true))
    yield* call(partnerPortalApi.createUser, body)
    yield* put(createPartnerPortalUserSuccess())
    yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* put(reset(formName))
    // eslint-disable-next-line no-magic-numbers
    yield* delay(1000)
    yield* put(getPartnerPortalUsersRequest(partner.id))
  } catch (error) {
    yield* put(createPartnerPortalUserFailure('Failed to create partner portal user', error))
    yield* put(layoutActions.setSideViewIsProcessingData(false))
    yield* triggerErrorPopup(error)
  }
}

export function* importPartnerPortalUsers({ payload }: IAction<IFormConfig>): Generator {
  const { form: formName, successMessage }: IFormConfig = payload

  const formValues = (yield select((state: IStore) => state.form[formName].values) as unknown) as {
    csv: IFileWrapper[]
  }

  if (Array.isArray(formValues.csv)) {
    const data: FormData = new FormData()
    const { file }: IFileWrapper = formValues.csv[0]
    data.append('file', file)

    try {
      yield* put(layoutActions.setSideViewIsProcessingData(true))
      yield* call(partnerPortalApi.uploadUsers, data)
      yield* put(importPartnerPortalUsersSuccess())
      yield* put(reset(formName))
      yield* put(layoutActions.displayToast({ message: successMessage, type: ToastType.Success }))
      yield* put(layoutActions.setSideViewIsProcessingData(false))
    } catch (error) {
      yield* put(importPartnerPortalUsersFailure())
      yield* put(layoutActions.setSideViewIsProcessingData(false))
      yield* triggerErrorPopup(error)
    }
  }
}
