import cx from 'classnames'
import { FC, ReactElement } from 'react'

import { ITranslatable } from '@alteos/product-configuration/dist/translation/ITranslatable'

import { useDynamicTranslation } from '../../../../../modules/i18n/hooks'

import styles from '../../card.module.scss'

interface IProps {
  children: ReactElement
  label: ITranslatable | undefined
  className?: string
  isInline?: boolean
}

const StatusItem: FC<IProps> = ({ children, label, isInline = false, className = '' }: IProps) => {
  const translatedLabel: string = useDynamicTranslation(label)
  return (
    <div className={cx(styles.item, className, { [styles.grid]: !isInline })}>
      <span className={styles.label}>{translatedLabel}:</span>
      <div className={cx(styles.itemContent, styles.status)}>{children}</div>
    </div>
  )
}

export default StatusItem
