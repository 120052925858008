import { AxiosResponse } from 'axios'

import { ICardSection } from '../../../components/Cards'
import { IProduct } from '../../../interfaces/common'
import { IBasicStore } from '../../../interfaces/store'
import PartnerTab from '../dictionaries/PartnerTab'
import ReferralPartnerSideViewType from '../dictionaries/ReferralPartnerSideViewType'

export interface IReferralPartnerResponse extends AxiosResponse {
  data: IReferralPartner
}

export interface IProductsResponse extends AxiosResponse {
  data: IProductWrapper
}

export interface IProductWrapper {
  products: IProduct[]
}

export interface IReferralPartnerProducts {
  [productId: string]: boolean
}

export enum PartnerType {
  REFERRAL_PARTNER = 'referral_partner',
  ANNEX_PARTNER = 'annex_partner',
  TIED_AGENT = 'tied_agent',
  MULTI_TIED_AGENT = 'multi_tied_agent',
  GROUP_HEAD = 'group_head',
  BROKER_OR_BROKER_POOL = 'broker_or_broker_pool',
  INTEGRATION_PARTNER = 'integration_partner'
}

export enum DistributionChannelEnum {
  API = 'api',
  SHOP = 'shop',
  POLICY_IMPORT = 'policy_import'
}

export interface IReferralPartner {
  distributionChannel: DistributionChannelEnum[] | null
  id: string
  companyName: string
  legalRepFirstName: string
  legalRepLastName: string
  legalEntity: string
  salesTaxIdNumber: string
  issuingAuthority: string
  companyDisplayName: string
  companyRegistrationNumber: string
  companyAddressCity: string
  companyAddressHouseNumber: string
  companyAddressPlz: string
  companyAddressStreet: string
  companyPhone: string
  companyEmail: string
  productSelectionIds: string
  subdomain: string
  source: string
  type: PartnerType
  IBAN: string
  shopUrl: string
  billingEmail: string
  status: IReferralPartnerStatus
  products: IReferralPartnerProducts[]
  values?: { referralSource: string }
  productConfigExtension?: IProductConfigExtension
  country: string | null
  createdAt?: Date | string
  updatedAt?: Date | string
  createdBy?: string
  updatedBy?: string
  isPartnerPortalAllowed?: boolean
  shopCode?: string
}

export enum IReferralPartnerStatus {
  NEW = 'new',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  TERMINATED = 'terminated'
}

export interface IReferralPartnerTabs {
  activeTab: PartnerTab
}

export interface IReferralPartnerState extends IBasicStore {
  isFetchingPartner: boolean
  isActivatingReferralPartner: boolean
  isUpdatingReferralPartner: boolean
  isRejectingReferralPartner: boolean
  isTerminatingReferralPartner: boolean
  referralPartner: IReferralPartner
  activeSideViewType: ReferralPartnerSideViewType | null
  products: IProduct[]
  referralPartnerLog: IReferralPartnerLog[]
}

export interface IReferralPartnerTabState {
  tabs: IReferralPartnerTabs
  referralPartnerState: IReferralPartnerState
}

export interface ISummaryCards {
  referralPartnerCards: ICardSection
  companyData: ICardSection
  additionalData: ICardSection
}

export interface ISetSideViewPayload {
  type: ReferralPartnerSideViewType
  params?: {
    partnerRequestId: string
  }
}
export interface IProductConfigExtension {
  [productId: string]: {
    values: {
      commissionRate?: number
      salesCategory?: string
      gpsIncluded?: boolean
      salesFlow?: string
    }
  }
}

export interface IReferralPartnerUpdateRequest {
  productIds: string[]
  productConfigExtension: IProductConfigExtension | null
  companyName?: string
  companyEmail?: string
  billingEmail?: string
  iban?: string
  subdomain?: string
  salesTaxIdNumber?: string
  companyPhone?: string
  companyRegistrationNumber?: string
  notes?: string
}

export interface IReferralPartnerChangeStatusRequest {
  notes?: string
}

export enum ReferralPartnerLogType {
  UPDATE = 'UPDATE',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  TERMINATED = 'TERMINATED'
}

export interface IFieldChange {
  fieldName: string
  oldValue?: string
  newValue?: string
}

export interface IReferralPartnerLog {
  type: ReferralPartnerLogType
  id: string
  createdBy: string
  fieldsChanged: IFieldChange[] | null
  createdAt: string
  notes: string | null
}
