import { AsyncValidateCallback } from 'redux-form'

export const shouldAsyncValidateReferralPartnerForm = (params: AsyncValidateCallback<FormData, string>): boolean => {
  if (!params.syncValidationPasses) {
    return true
  }
  switch (params.trigger) {
    case 'blur':
      return true
    case 'submit':
      // submitting, so only async validate if form is dirty or was never initialized
      // conversely, DON'T async validate if the form is pristine just as it was initialized
      return !params.pristine || !params.initialized
    default:
      return true
  }
}
