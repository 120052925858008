import { TFunction } from 'i18next'

import { InputType } from '@alteos/ui'

import { ClaimStatus } from '../../../../../dictionaries'
import { IFieldConfig } from '../../../../../interfaces/common'

export const claimStatusFormFields = (
  t: TFunction,
  claimSubStatus: string[],
  isRequired: boolean = false
): IFieldConfig[] => [
  {
    id: 'status',
    label: t('policy:claims.cards.status'),
    editType: InputType.select,
    required: isRequired,
    placeholder: t('policy:claims.cards.selectPlaceholder'),
    options: [
      {
        key: ClaimStatus.Created,
        value: t('policy:claims.claimStatus.created')
      },
      {
        key: ClaimStatus.InProgress,
        value: t('policy:claims.claimStatus.inProgress')
      },
      {
        key: ClaimStatus.PartiallyApproved,
        value: t('policy:claims.claimStatus.partiallyApproved')
      },
      {
        key: ClaimStatus.Approved,
        value: t('policy:claims.claimStatus.approved')
      },
      {
        key: ClaimStatus.Rejected,
        value: t('policy:claims.claimStatus.rejected')
      },
      {
        key: ClaimStatus.Closed,
        value: t('policy:claims.claimStatus.closed')
      }
    ],
    handleFieldChangeOptions: {
      fieldsToUpdate: [
        {
          name: 'subStatus',
          newValue: null
        }
      ]
    }
  },
  {
    id: 'subStatus',
    editType: InputType.select,
    required: true,
    isClearable: true,
    placeholder: t('policy:claims.cards.selectPlaceholder'),
    label: t('policy:claims.cards.subStatus'),
    options: (claimSubStatus ?? []).map((subStatus) => {
      return {
        value: t(`policy:claims.claimSubStatus.${subStatus}`),
        key: subStatus
      }
    })
  },
  {
    editType: InputType.text,
    id: 'helpers.note',
    label: t('policy:forms.updateInformationSection.note'),
    placeholder: t('policy:forms.updateInformationSection.notePlaceholder'),
    maximum: 500
  }
]
