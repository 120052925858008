export const REFERRAL_PARTNER_ID = '{referralPartnerId}'
export const GENERIC_PARTNER_ID = '{genericPartnerId}'
export const PARTNER_PORTAL_USER_ID = '{userId}'

export const REFERRAL_PARTNERS = 'v1/referral-partners'
export const REFERRAL_PARTNER_APPROVE = `${REFERRAL_PARTNERS}/${REFERRAL_PARTNER_ID}/approve`
export const REFERRAL_PARTNER_REJECT = `${REFERRAL_PARTNERS}/${REFERRAL_PARTNER_ID}/reject`
export const REFERRAL_PARTNER_TERMINATE = `${REFERRAL_PARTNERS}/${REFERRAL_PARTNER_ID}/terminate`
export const REFERRAL_PARTNER_UPDATE = `${REFERRAL_PARTNERS}/${REFERRAL_PARTNER_ID}/update`
export const REFERRAL_PARTNER_CHECK_SUBDOMAIN_AVAILABILITY = `${REFERRAL_PARTNERS}/check-availability`

export const GENERIC_PARTNERS = 'v1/partners'
export const GENERIC_PARTNER_GET_PRODUCTS = `${GENERIC_PARTNERS}/${GENERIC_PARTNER_ID}/products`

export const PARTNER_PORTAL = 'v1/portal'
export const PARTNER_PORTAL_ROLES = `${PARTNER_PORTAL}/roles`
export const PARTNER_PORTAL_USERS = `${PARTNER_PORTAL}/users`
export const PARTNER_PORTAL_USERS_UPLOAD = `${PARTNER_PORTAL}/users/upload`
export const PARTNER_PORTAL_USER_BLOCK = `${PARTNER_PORTAL}/users/${PARTNER_PORTAL_USER_ID}/block`
export const PARTNER_PORTAL_USER_UNBLOCK = `${PARTNER_PORTAL}/users/${PARTNER_PORTAL_USER_ID}/unblock`
