import { isNil } from 'lodash'
import debounce from 'lodash/debounce'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { noop } from '@alteos/ui'

import SearchTags from '../../../../components/SearchTags'
import { IStore } from '../../../../interfaces/store'
import { DEFAULT_PAGINATION } from '../../constants'
import PartnersManagementTab from '../../dictionaries/PartnersManagementTab'
import { IPartnersState } from '../../interfaces'
import { IReferralPartnersState } from '../../interfaces/referralPartners'
import { loadPartnersStart } from '../../store/actions/partners'
import { loadReferralPartnersStart } from '../../store/actions/referralPartners'

const DEBOUNCE_TIME = 500

const Search = (): ReactElement => {
  const referralPartnersState: IReferralPartnersState = useSelector(
    (state: IStore) => state.partnersManagement.referralPartners
  )
  const partnersState: IPartnersState = useSelector((state: IStore) => state.partnersManagement.partners)
  const [t]: UseTranslationResponse<'common'> = useTranslation('common')
  const dispatch: Dispatch = useDispatch()
  const activeTab: PartnersManagementTab = useSelector((state: IStore) => state.partnersManagement.tabs.activeTab)

  const handleChange = useCallback(
    (value: string) => {
      dispatch(
        loadReferralPartnersStart({
          ...DEFAULT_PAGINATION,
          query: {
            query: value
          }
        })
      )
    },
    [dispatch]
  )

  const handleReset = useCallback(() => {
    dispatch(
      loadReferralPartnersStart({
        ...DEFAULT_PAGINATION,
        query: {
          query: ''
        }
      })
    )
  }, [dispatch])

  const handleChangeLegacy = useCallback(
    (value: string) => {
      dispatch(
        loadPartnersStart({
          ...DEFAULT_PAGINATION,
          query: value
        })
      )
    },
    [dispatch]
  )

  const handleResetLegacy = useCallback(() => {
    dispatch(
      loadPartnersStart({
        ...DEFAULT_PAGINATION,
        query: ''
      })
    )
  }, [dispatch])

  const resolveFlow = useMemo(() => {
    if (activeTab === PartnersManagementTab.ReferralPartner) {
      return {
        onChange: handleChange,
        onReset: handleReset,
        searchValue: referralPartnersState?.query?.query,
        isProcessing: referralPartnersState.isFetchingReferralPartners,
        dataTest: 'referral-partners-search'
      }
    }

    return {
      onChange: handleChangeLegacy,
      onReset: handleResetLegacy,
      searchValue: partnersState.query,
      isProcessing: partnersState.isFetchingPartners,
      dataTest: 'partners-search'
    }
  }, [
    activeTab,
    handleChange,
    handleChangeLegacy,
    handleReset,
    handleResetLegacy,
    partnersState.isFetchingPartners,
    partnersState.query,
    referralPartnersState.isFetchingReferralPartners,
    referralPartnersState?.query?.query
  ])

  const errors: { length: string; characters: string } = {
    length: t('search.error.length'),
    characters: t('search.error.partnerSearchChars')
  }

  const appliedTags = useMemo(() => {
    if (isNil(resolveFlow.searchValue) || resolveFlow.searchValue === '') {
      return []
    }
    return [resolveFlow.searchValue]
  }, [resolveFlow.searchValue])

  return (
    <SearchTags
      key={activeTab}
      placeholder={t('search.placeholder')}
      buttonLabel={t('search.buttonLabel')}
      appliedTags={appliedTags}
      isProcessing={referralPartnersState.isFetchingReferralPartners}
      dataTest={resolveFlow.dataTest}
      handleReset={resolveFlow.onReset}
      handleTagsChange={debounce(resolveFlow.onChange, DEBOUNCE_TIME, { trailing: true, leading: false })}
      handleTagDelete={noop}
      defaultValue={resolveFlow.searchValue ?? ''}
      errors={errors}
      validationRegExp={/[=|<>!{}[\]^"~*?\\]/}
    />
  )
}

export default Search
