/* eslint-disable @typescript-eslint/typedef */
import { isEqual } from 'lodash'
import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

interface IUseAsyncLoader<T> {
  data: T | null
  isFetching: boolean
  reload(): void
}

export function useAsyncLoader<T>(fetcher: () => Promise<T>, deps: DependencyList = []): IUseAsyncLoader<T> {
  const [data, setData] = useState<T | null>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState<null | Error>(null)
  const [reloadCounter, setReloadCounter] = useState(0)
  const reload = useCallback(() => {
    setReloadCounter((current) => current + 1)
  }, [setReloadCounter])

  useEffect(() => {
    // NOTE: effect to fetch the data
    let mounted = true
    setIsFetching(true)
    fetcher()
      .then((fetched: T) => {
        if (mounted) {
          setIsFetching(false)
          setData(fetched)
        }
      })
      .catch((fetchErr: Error) => {
        if (mounted) {
          setIsFetching(false)
          setData(null)
          setError(fetchErr)
        }
      })
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDeepCompare(deps), reloadCounter])

  useEffect(() => {
    // NOTE: bubble up the error
    if (error !== null) {
      throw error
    }
  }, [error])

  return { data, isFetching, reload }
}

// from : https://stackoverflow.com/a/54096391
function useDeepCompare<T>(value: T): T {
  const ref = useRef<T>(value)
  if (!isEqual(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}
