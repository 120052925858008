import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import { WithFlagContext } from '@alteos/feature-flag-react'

import { Loader } from './components/Loader'
import { currentUser } from './modules/Auth/store/selectors'
import ErrorBoundary from './modules/Static/screens/ErrorBoundary'
import { router } from './routing'

const App: FC = () => {
  const loginUser = useSelector(currentUser)
  return (
    <ErrorBoundary>
      <WithFlagContext>
        {(setUserContext) => {
          void setUserContext(loginUser?.name)
          return null
        }}
      </WithFlagContext>
      <RouterProvider router={router} fallbackElement={<Loader name="app" />} />
    </ErrorBoundary>
  )
}

export default App
