import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../components/Form/interfaces'
import { forms } from '../../../constants'

const generateApproveFormCards = (t: TFunction, source: Record<string, any>, isProcessing: boolean): IFormConfig => {
  const formConfig: IFormConfig = {
    form: forms.approveReferralPartner,
    url: '',
    initialValues: { ...source, notes: '' },
    successMessage: `${t('referralPartner.edit.successMessageApprove')}`,
    formSections: [
      {
        title: t('referralPartner.edit.statusTitle'),
        icon: 'upload',
        fields: [
          {
            id: 'notes',
            editType: InputType.text,
            label: t('referralPartner.edit.notes'),
            placeholder: t('referralPartner.edit.notesPlaceholder'),
            maximum: 500
          },
          {
            editType: InputType.button,
            label: t('referralPartner.edit.activate'),
            dataTest: 'activate-changes-SP-button',
            noMinWidth: true
          }
        ]
      }
    ]
  }

  return formConfig
}

export default generateApproveFormCards
