import { isNil } from 'lodash'

import { IPagination, IRequestPagination } from '@alteos/ui'

import { IAction } from '../../../../interfaces/store'
import { LOGOUT } from '../../../Auth/constants'
import { IPartnersResponse, IPartnersState } from '../../interfaces'
import { LOAD_PARTNERS_FAILURE, LOAD_PARTNERS_REQUEST, LOAD_PARTNERS_SUCCESS } from '../constants'

const DEFAULT_PAGINATION: IPagination = { limit: 20, offset: 0, total: 0 }

export const initialState: IPartnersState = {
  isFetchingPartners: false,
  partners: [],
  query: '',
  pagination: DEFAULT_PAGINATION,
  errorMessage: null
}

function reducer(state: IPartnersState = initialState, action: IAction<any>): IPartnersState {
  switch (action.type) {
    case LOAD_PARTNERS_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }
      return {
        ...state,
        isFetchingPartners: true,
        pagination: {
          ...state.pagination,
          limit: payload.limit,
          offset: payload.offset
        },
        ...(isNil(payload.query)
          ? {}
          : {
              query: payload.query
            })
      }
    }

    case LOAD_PARTNERS_SUCCESS: {
      const { payload }: IAction<IPartnersResponse> = action

      return {
        ...state,
        isFetchingPartners: false,
        partners: payload.partners,
        pagination: {
          ...state.pagination,
          total: payload.totalCount
        }
      }
    }

    case LOAD_PARTNERS_FAILURE: {
      return {
        ...state,
        isFetchingPartners: false,
        pagination: DEFAULT_PAGINATION
      }
    }

    case LOGOUT: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
