import { ScheduledActionType } from '../../../dictionaries'
import { ICreateScheduledTerminationRequestPayload } from '../../../interfaces'

export const getCreateScheduledTerminationPayload = (
  formState: Record<string, any>
): ICreateScheduledTerminationRequestPayload => {
  const { ruleCode, initiator, requestedAt, extraInput, effectiveAt } = formState

  return {
    scheduledAt: effectiveAt,
    type: ScheduledActionType.Termination,
    data: {
      version: 'v2',
      payload: {
        ruleCode,
        initiator,
        requestedAt,
        extraInput
      }
    }
  }
}
