import isNil from 'lodash/isNil'

const isLongFrom = (form: HTMLFormElement | null | undefined): boolean => {
  if (isNil(form)) {
    return false
  }
  return form?.clientHeight > window.innerHeight
}

export default isLongFrom
