import { FC, ReactElement } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators, Dispatch as reduxDispatch } from 'redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container } from '../../../../components/sideViewComponents'
import { IBasicDispatchProps, IStore } from '../../../../interfaces/store'
import { getDateFromIsoString } from '../../../../utils/getDateFromIsoString'
import { MODULE_NAME } from '../../constants'
import { default as actionsToBind } from '../../store/actionCreators'
import { generateReduxFormConfig } from '../../utils'
import { generateScheduledTerminationFormCardsV2 } from './utils/generateScheduledTerminationFormCardsV2'

type IStoreProps = Pick<IStore[typeof MODULE_NAME], 'policy' | 'isSubmitting'>

type Props = WithTranslation & IBasicDispatchProps & IStoreProps

const ScheduledTerminationForm: FC<Props> = ({ actions, t, policy, isSubmitting }: Props): ReactElement | null => {
  const dispatch = useDispatch()
  if (policy === null) {
    return null
  }

  const currentDate = getDateFromIsoString(new Date().toISOString(), 'currentDate')
  const config: IFormConfig = generateScheduledTerminationFormCardsV2(policy, currentDate, t)

  const props: IFormProps = generateReduxFormConfig(config, dispatch, { isSubmitting, formType: FormType.EDIT })

  return (
    <>
      <Container title={t('scheduledTerminationForm.title')} icon="pencil" />
      <Form {...props} />
    </>
  )
}

function mapStateToProps(state: IStore): IStoreProps {
  const policy: IStore['policy']['policy'] | null = state[MODULE_NAME].policy
  const isSubmitting: boolean = state[MODULE_NAME].isSubmitting

  return {
    policy,
    isSubmitting
  }
}

function mapDispatchToProps(dispatch: reduxDispatch): IBasicDispatchProps {
  return {
    actions: bindActionCreators(actionsToBind, dispatch)
  }
}

export default withTranslation(['policy'])(connect(mapStateToProps, mapDispatchToProps)(ScheduledTerminationForm))
