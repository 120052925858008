import { FC, ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Form, IFormProps } from '../../../../components/Form/Form'
import { FormType } from '../../../../components/Form/FormType'
import { IFormConfig } from '../../../../components/Form/interfaces'
import { Container, EditModeLabel } from '../../../../components/sideViewComponents'
import { IStore } from '../../../../interfaces/store'
import { forms, MODULE_NAME } from '../../constants'
import { default as actionsToBind } from '../../store/actionCreators'
import { getTerminationData } from '../../store/selectors'
import { generateReduxFormConfig } from '../../utils'
import { generatePolicyTerminationFormCards } from './utils/generatePolicyTerminationFormCards'

import styles from './styles.module.scss'

export const PolicyTerminationForm: FC = (): ReactElement | null => {
  const dispatch = useDispatch()
  const [t] = useTranslation(['policy', 'countryCodes'])
  const actions = useMemo(() => bindActionCreators(actionsToBind, dispatch), [dispatch])
  const {
    getTerminationRules,
    executeTerminationRuleWithExtraInputFields,
    executeTerminationRuleWithoutExtraInputFields,
    getRefundAmount
  } = actions

  // NOTE: Select from state
  const policy = useSelector((state: IStore) => state[MODULE_NAME].policy)
  const isFetching = useSelector((state: IStore) => state[MODULE_NAME].isFetching)
  const isExecutingTerminationRuleWithExtraInputFields = useSelector(
    (state: IStore) => state[MODULE_NAME].isExecutingTerminationRuleWithExtraInputFields
  )
  const isExecutingTerminationRuleWithoutExtraInputFields = useSelector(
    (state: IStore) => state[MODULE_NAME].isExecutingTerminationRuleWithoutExtraInputFields
  )
  const isSubmitting = useSelector((state: IStore) => state[MODULE_NAME].isSubmitting)

  const terminationData = useSelector(getTerminationData)
  const { rules, executedRule, refundAmount } = terminationData

  const formValues = useSelector((state: IStore) => state.form[forms.terminatePolicyForm]?.values)

  // NOTE: Get field values
  const initiator = formValues?.initiator
  const requestedAt = formValues?.requestedAt
  const terminationReason = formValues?.terminationReason
  const effectiveAt = formValues?.effectiveAt
  const extraInput = formValues?.extraInput

  // NOTE: Dispatch getTerminationRules action
  useEffect(() => {
    getTerminationRules()
  }, [getTerminationRules, initiator, requestedAt])

  // NOTE: Dispatch executeTerminationRuleWithoutExtraInputFields action
  useEffect(() => {
    executeTerminationRuleWithoutExtraInputFields()
  }, [executeTerminationRuleWithoutExtraInputFields, terminationReason])

  // NOTE: Dispatch executeTerminationRuleWithExtraInputFields action
  useEffect(() => {
    executeTerminationRuleWithExtraInputFields()
  }, [executeTerminationRuleWithExtraInputFields, extraInput])

  // NOTE: Dispatch getRefundAmount action
  useEffect(() => {
    getRefundAmount()
  }, [getRefundAmount, effectiveAt])

  if (policy === null) {
    return null
  }

  const config: IFormConfig = generatePolicyTerminationFormCards({
    rules,
    executedRule,
    refundAmount,
    isFetching,
    isExecutingTerminationRuleWithExtraInputFields,
    isExecutingTerminationRuleWithoutExtraInputFields,
    t,
    terminationReason
  })
  const props: IFormProps = generateReduxFormConfig(config, dispatch, { isSubmitting, formType: FormType.EDIT })

  return (
    <>
      <Container
        className={styles.title}
        title={t('policyTerminationForm.title')}
        icon="doc-termination"
        label={<EditModeLabel />}
      />
      <Form {...props} />
    </>
  )
}
