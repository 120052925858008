import cx from 'classnames'
import { FC, ReactElement } from 'react'

import { noop } from '@alteos/ui'

import { IBasicProps } from '../../interfaces/common'
import { ReactComponent as Logo } from './assets/Logo.svg'

import styles from './styles.module.scss'

interface IProps extends IBasicProps {
  handleClick?: () => void
}

const LogoButton: FC<IProps> = ({ handleClick = noop, className = '' }: IProps): ReactElement => {
  return (
    <button type="button" onClick={handleClick} className={cx(styles.button, className)}>
      <Logo />
    </button>
  )
}

export default LogoButton
