import { DateTime } from 'luxon'

import { InputType } from '@alteos/ui'

import { IDatePickerValues, IFilter } from '../../../../components/Filters/interfaces'

export const getFiltersParams = (filters: IFilter[] | undefined): any => {
  let filter: any = {}

  const activeFilters = filters!.filter((item: IFilter) => item.selectedFilter !== '')

  if (activeFilters.length > 0) {
    activeFilters.map((activeFilter: IFilter) => {
      if (activeFilter.selectedFilter === '') {
        return null
      }
      if (activeFilter.componentType === InputType.datePickerRange) {
        const { fromDate, toDate }: IDatePickerValues = activeFilter.selectedFilter as IDatePickerValues

        const filterPayload = {
          created_from: DateTime.fromISO(fromDate).startOf('day'),
          created_to: DateTime.fromISO(toDate).endOf('day')
        }

        filter = { ...filter, ...filterPayload }
      } else {
        const { key, selectedFilter }: IFilter = activeFilter
        const filterPayload: { [x: string]: string | IDatePickerValues } = { [key]: selectedFilter }

        filter = { ...filter, ...filterPayload }
      }
      return null
    })
  }
  return filter
}
