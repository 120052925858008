import { z } from 'zod'

import {
  ISupportedLanguage,
  SUPPORTED_LANGUAGES
} from '@alteos/product-configuration/dist/translation/ISupportedLanguage'

import { localStorageService } from '../../utils/localStorageService'

const LOCAL_STORAGE_KEY = 'language_DAVE'

class LanguageLocalStorage {
  getLanguageOrDefault(): ISupportedLanguage {
    return this.getLanguage() ?? 'de'
  }
  getLanguage(): ISupportedLanguage | null {
    return localStorageService.getItem(LOCAL_STORAGE_KEY, z.enum(SUPPORTED_LANGUAGES))
  }
  setLanguage(language: ISupportedLanguage): void {
    localStorageService.setItem(LOCAL_STORAGE_KEY, language)
  }
}

export const languageLocalStorage: LanguageLocalStorage = new LanguageLocalStorage()
