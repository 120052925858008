import { useSelector } from 'react-redux'

import { Permissions, PermissionConstraint } from '../../interfaces'
import { getUserPermissions } from '../../store/selectors'
import { hasPermission } from './hasPermission'

interface IProps {
  permission: PermissionConstraint
  children: React.ReactNode
}

export function PermissionGuard({ children, permission }: React.PropsWithChildren<IProps>): JSX.Element {
  const currentPermissions: Permissions = useSelector(getUserPermissions)
  return <>{hasPermission(currentPermissions, permission) && children}</>
}
