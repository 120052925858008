import cx from 'classnames'
import { FC } from 'react'

import { IHeadingViewFieldConfig } from '../../../../../interfaces/common'
import { useDynamicTranslation } from '../../../../../modules/i18n/hooks'
import { FieldValue } from '../../../interfaces'

import styles from '../../card.module.scss'

interface IProps {
  isInline: boolean
  field: IHeadingViewFieldConfig
  value: FieldValue
}

const HeadingItem: FC<IProps> = ({ field, isInline, value }: IProps) => {
  const label: string = useDynamicTranslation(field.label)
  const isFullWidth: boolean = field.size === 'full'
  return (
    <div
      className={cx(styles.item, {
        [styles.grid]: !isInline,
        [styles.fullWidth]: isFullWidth
      })}
    >
      <span className={styles.label}>{label}:</span>
      <h2 className={cx(styles.itemContent, styles.fieldHeading)} data-test={field.id}>
        {typeof value === 'string' ? value : null}
      </h2>
    </div>
  )
}

export default HeadingItem
