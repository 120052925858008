const DEFAULT_ICON_SIZE = 40

export interface IIconProps {
  size?: number
  className?: string
}

export function WarningIcon({ size = DEFAULT_ICON_SIZE, className }: IIconProps): React.ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.197 9.60278C18.4521 7.55591 19.0797 6.53247 20.0001 6.53247C20.9205 6.53247 21.5481 7.55591 22.8032 9.60278L33.2832 26.6934C34.6425 28.9102 35.3222 30.0186 34.8514 30.8595C34.3806 31.7005 33.0804 31.7005 30.4801 31.7005H24.6843C24.1628 31.7005 23.9021 31.7005 23.6758 31.8101C23.4495 31.9197 23.2878 32.1242 22.9644 32.5334L21.7199 34.108C20.9272 35.1111 20.5309 35.6126 20.0001 35.6126C19.4693 35.6126 19.0729 35.1111 18.2802 34.1081L17.0357 32.5334C16.7124 32.1242 16.5507 31.9197 16.3244 31.8101C16.0981 31.7005 15.8374 31.7005 15.3159 31.7005H9.52012C6.91978 31.7005 5.61961 31.7005 5.14881 30.8595C4.67801 30.0186 5.35767 28.9102 6.71698 26.6934L17.197 9.60278Z"
        fill="#FF8C4C"
      />
      <path
        d="M21.7959 26.9479C21.7954 27.9266 21.0053 28.7199 20.0313 28.7199C19.0572 28.7199 18.2681 27.9266 18.2686 26.9479C18.2692 25.9692 19.0593 25.1759 20.0333 25.1759C21.0074 25.1759 21.7965 25.9692 21.7959 26.9479Z"
        fill="white"
      />
      <path d="M18.002 15.6709L22 15.6709L22 23.0959H18.002L18.002 15.6709Z" fill="white" />
    </svg>
  )
}

export function SuccessIcon({ size = DEFAULT_ICON_SIZE, className }: IIconProps): React.ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.3107 8.5992C26.6007 7.88917 26.2457 7.53415 25.8098 7.31637C25.6833 7.25315 25.5525 7.19896 25.4183 7.1542C24.9561 7 24.454 7 23.4499 7H11.46C9.15275 7 7.99912 7 7.19874 7.60095C6.97226 7.77099 6.77099 7.97226 6.60095 8.19874C6 8.99912 6 10.1528 6 12.46V29.54C6 31.8473 6 33.0009 6.60095 33.8013C6.77099 34.0277 6.97226 34.229 7.19874 34.3991C7.99912 35 9.15275 35 11.46 35H28.54C30.8473 35 32.0009 35 32.8013 34.3991C33.0277 34.229 33.229 34.0277 33.3991 33.8013C34 33.0009 34 31.8472 34 29.54V17.5501C34 16.546 34 16.0439 33.8458 15.5817C33.801 15.4475 33.7469 15.3167 33.6836 15.1902C33.4659 14.7543 33.1108 14.3993 32.4008 13.6893L27.3107 8.5992Z"
        fill="#48CF9E"
      />
      <path
        d="M8.91602 7H23.4993V11.6967C23.4993 13.7748 23.4993 14.8139 23.0068 15.5637C22.7818 15.9063 22.489 16.1991 22.1464 16.4241C21.3965 16.9167 20.3575 16.9167 18.2793 16.9167H14.136C12.0579 16.9167 11.0188 16.9167 10.2689 16.4241C9.92641 16.1991 9.6336 15.9063 9.40859 15.5637C8.91602 14.8139 8.91602 13.7748 8.91602 11.6967V7Z"
        fill="white"
      />
      <path
        d="M8.91602 23.9568C8.91602 22.5142 8.91602 21.7929 9.27073 21.2796C9.40805 21.0809 9.58021 20.9088 9.77889 20.7715C10.2921 20.4167 11.0134 20.4167 12.456 20.4167H27.5427C28.9853 20.4167 29.7066 20.4167 30.2198 20.7715C30.4185 20.9088 30.5906 21.0809 30.728 21.2796C31.0827 21.7929 31.0827 22.5142 31.0827 23.9567V28.5434C31.0827 29.986 31.0827 30.7073 30.728 31.2205C30.5906 31.4192 30.4185 31.5914 30.2198 31.7287C29.7066 32.0834 28.9853 32.0834 27.5427 32.0834H12.456C11.0134 32.0834 10.2921 32.0834 9.77889 31.7287C9.58021 31.5914 9.40805 31.4192 9.27073 31.2205C8.91602 30.7073 8.91602 29.986 8.91602 28.5434V23.9568Z"
        fill="white"
      />
      <rect x="17.666" y="8.75" width="3.5" height="5.83333" rx="1" fill="#588675" />
      <path d="M13 26.5H26.8333" stroke="#9E9E9E" strokeWidth="2" />
    </svg>
  )
}

export function InfoIcon({ size = DEFAULT_ICON_SIZE, className }: IIconProps): React.ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.1927 35.5714L15.3887 32.2065H24.6105L21.8065 35.5714C21.1355 36.3766 20.8 36.7792 20.387 36.8981C20.1339 36.971 19.8653 36.971 19.6123 36.8981C19.1992 36.7792 18.8637 36.3766 18.1927 35.5714Z"
        fill="#006FFF"
      />
      <path
        d="M6.16797 19.9106C6.16796 10.5447 12.0501 7 20.0008 7C27.9514 7 33.8336 9.92991 33.8336 19.9106C33.8336 29.8913 27.9514 33.4238 20.0008 33.436C12.0501 33.4482 6.16797 29.2765 6.16797 19.9106Z"
        fill="#006FFF"
      />
      <path
        d="M18.0666 14.4411C18.0672 13.3876 18.9176 12.5337 19.9661 12.5337C21.0145 12.5337 21.8639 13.3876 21.8633 14.4411C21.8627 15.4945 21.0123 16.3485 19.9638 16.3485C18.9154 16.3485 18.066 15.4945 18.0666 14.4411Z"
        fill="white"
      />
      <path d="M22.1504 25.9648L17.8469 25.9648L17.8469 18.5874L22.1504 18.5874L22.1504 25.9648Z" fill="white" />
    </svg>
  )
}
