import cx from 'classnames'
import isNil from 'lodash/isNil'
import take from 'lodash/take'
import { FC, KeyboardEvent, useCallback } from 'react'
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

import { noop } from '@alteos/ui'

import { formatBytesHumanReadable } from '../../utils/formatBytesHumanReadable'
import { FileUploaderItem } from './FileUploaderItem'
import { FileUploaderFolderIcon } from './icons/FileUploaderFolderIcon'
import { IFileWrapper } from './interfaces'

import styles from './style.module.scss'

interface IProps {
  id: string
  files: IFileWrapper[]
  allowedFormats?: Array<string>
  maxFileSize?: number
  maxFileCount?: number
  disabled?: boolean
  setFiles(files: IFileWrapper[]): void
  dataTest?: string
}

const FileUploader: FC<IProps> = ({
  id,
  files,
  setFiles,
  allowedFormats,
  disabled = false,
  dataTest = 'file-uploader-input',
  maxFileSize,
  maxFileCount = Number.MAX_SAFE_INTEGER
}: IProps) => {
  const [t]: UseTranslationResponse<'common'> = useTranslation('common')
  const onDrop: DropzoneOptions['onDrop'] = useCallback(
    (acceptedFiles: File[]) => {
      if (disabled) {
        return
      }

      const mappedFiles: IFileWrapper[] = acceptedFiles.map((file: File) => {
        if (!isAllowedFormat(file, allowedFormats)) {
          return { file, errorCode: 'invalidFileType' }
        }
        if (!isFileSizeOk(file, maxFileSize)) {
          return { file, errorCode: 'tooLarge' }
        }
        return { file }
      })

      // TODO: handle the case where [maxFileCount] is reached more gracefully
      const filesToAdd: IFileWrapper[] = take(mappedFiles, maxFileCount - files.length)

      setFiles([...files, ...filesToAdd])
    },
    [files, setFiles, allowedFormats, maxFileSize, maxFileCount, disabled]
  )

  const { getRootProps, getInputProps, isDragActive, isFocused, open }: DropzoneState = useDropzone({
    onDrop,
    noClick: true
  })

  const allowAddFiles: boolean = files.length < maxFileCount && !disabled

  return (
    <div
      {...getRootProps()}
      className={cx(styles.fileUploaderRoot, {
        [styles.fileUploaderRootFocused]: isFocused,
        [styles.fileUploaderRootDragActive]: isDragActive
      })}
    >
      <div className={styles.fileUploaderStaticSection}>
        <FileUploaderFolderIcon />
        <input {...getInputProps()} data-test={dataTest} />
        <p className={cx({ [styles.disabledText]: !allowAddFiles })}>
          {t('fileUploader.phrases.dragnDrop')} <strong>{t('fileUploader.phrases.files')}</strong>{' '}
          {t('fileUploader.phrases.or')}{' '}
          <strong
            className={cx({ [styles.browse]: allowAddFiles })}
            onClick={allowAddFiles ? open : noop}
            tabIndex={0}
            data-test={`${id}-file-uploader`}
            onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
              if ([' ', 'Enter'].includes(event.key) && allowAddFiles) {
                event.preventDefault()
                open()
              }
            }}
          >
            <u>{t('fileUploader.phrases.browse')}</u>
          </strong>
        </p>
        <p className={styles.subDescription}>
          {!isNil(allowedFormats) && (
            <>
              {t('fileUploader.phrases.uploadHint')}{' '}
              <strong>{allowedFormats.map((format: string) => format.toUpperCase()).join(', ')} </strong>
              {t('fileUploader.phrases.files')} <br />
            </>
          )}

          {!isNil(maxFileSize) && (
            <>
              {t('fileUploader.phrases.sizeHint')} <strong>{formatBytesHumanReadable(maxFileSize)}</strong>
            </>
          )}
        </p>
      </div>
      <div className={styles.fileUploaderFileItems}>
        {files.map((file: IFileWrapper, index: number) => {
          return (
            <FileUploaderItem
              key={index}
              file={file}
              disabled={disabled}
              dataTest="file-uploader-item"
              onRemoveFileRequest={(fileToRemove: IFileWrapper) => {
                setFiles(files.filter((currentFile: IFileWrapper) => currentFile !== fileToRemove))
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FileUploader

function isAllowedFormat(file: File, allowedFormats: Array<string> = []) {
  return allowedFormats.length === 0
    ? true
    : allowedFormats.some((format: string) => file.name.toLowerCase().endsWith(format))
}

function isFileSizeOk(file: File, maxFileSize: number = Number.MAX_SAFE_INTEGER) {
  return file.size <= maxFileSize
}
