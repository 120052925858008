import { FC, useState } from 'react'
import { TFunction } from 'react-i18next'

import {
  createTableDataPagination,
  formatFactory,
  Icon,
  ITableColumn,
  ITableInitialState,
  ITableRowProps,
  ITableSortProps,
  sortDates,
  sortStrings,
  Table
} from '@alteos/ui'

import { IClaim, IPolicyDocument, IClaimDocumentTableRow } from '../../../../interfaces'

interface IProps {
  claim: IClaim
  t: TFunction
}

const TOTAL_ROWS = 6

interface IDocumentLink {
  row: {
    original: IClaimDocumentTableRow
  }
}

const DocumentLink: FC<IDocumentLink> = ({ row }: IDocumentLink) => {
  return (
    <a href={row.original.url} target="_blank" rel="noreferrer" data-test={`document-link-${row.original.name}`}>
      <Icon name="watch-document" />
    </a>
  )
}

const ClaimDocumentsTable: FC<IProps> = ({ claim, t }: IProps) => {
  const [offset, setOffset] = useState(0)
  const [pageSize, setPageSize] = useState(TOTAL_ROWS)

  if (claim.documents === null) {
    return null
  }

  const total = claim.documents.length
  const { formatDate } = formatFactory

  const tableColumns: ITableColumn[] = [
    {
      Header: t('common:phrases.actions'),
      accessor: (_originalRow: any, index: number) => index.toString(),
      Cell: DocumentLink
    },
    {
      Header: t('createClaimForm.documentsTable.name'),
      accessor: 'name',
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortStrings(a.values.name, b.values.name)
      }
    },
    {
      Header: t('common:phrases.createdAt'),
      accessor: 'createdAt',
      Cell: (cell: any) => formatDate(cell.value),
      sortType: (a: ITableRowProps<ITableSortProps>, b: ITableRowProps<ITableSortProps>) => {
        return sortDates(a.original.createdAt, b.original.createdAt)
      }
    },
    {
      Header: t('document.version'),
      accessor: 'version'
    }
  ]

  const initialTableState: ITableInitialState = {
    pageSize,
    total,
    offset,
    sortBy: [
      {
        id: 'createdAt',
        desc: true
      }
    ]
  }

  const documentsToDisplay = [...claim.documents].splice(offset, pageSize)

  const tableRows = documentsToDisplay.map((document: IPolicyDocument) => ({
    createdAt: document.createdAt,
    name: document.name,
    url: document.url,
    version: document.version
  }))

  const dataPagination = createTableDataPagination({
    data: tableRows,
    total,
    limit: pageSize,
    offset,
    displayPagination: true,
    setLimit: (value: number) => setPageSize(value),
    setOffset: (value: number) => setOffset(value)
  })

  return (
    <Table
      columns={tableColumns}
      initialState={initialTableState}
      dataTest="claims-documents"
      dataPagination={dataPagination}
      disableSortBy={false}
      emptyTableTitle={t('common:titles.emptyTable')}
    />
  )
}

export default ClaimDocumentsTable
