import { TFunction } from 'react-i18next'
import { Dispatch } from 'redux'

import { InputType } from '@alteos/ui'

import { IFormConfig } from '../../../../../../components/Form/interfaces'
import { closeSideView } from '../../../../../Layout/store/actionCreators'
import { forms } from '../../../../constants'
import { setSideView } from '../../../../store/actions/upcoming'

import styles from '../editPaymentMethod.module.scss'

export const generateUpdatePaymentMethodForm = (t: TFunction, dispatch: Dispatch): IFormConfig => {
  return {
    form: forms.UpdatePaymentMethod,
    url: 'v1/partners/generic-upcomingPayments/subdomain/update',
    initialValues: {},
    successMessage: `${t('upcomingPayments.edit.paymentMethodUpdateSuccessMessage')}`,
    formSections: [
      {
        title: t('upcomingPayments.edit.sepaTitle'),
        icon: 'details',
        fields: [
          {
            id: 'iban',
            editType: InputType.string,
            required: true,
            size: 'full',
            label: t('upcomingPayments.edit.iban')
          },
          {
            id: 'firstName',
            editType: InputType.string,
            required: true,
            minimum: 2,
            size: 'half',
            label: t('upcomingPayments.edit.firstName')
          },
          {
            id: 'lastName',
            editType: InputType.string,
            required: true,
            minimum: 2,
            size: 'half',
            label: t('upcomingPayments.edit.lastName')
          },
          {
            editType: InputType.button,
            label: t('upcomingPayments.edit.cancel'),
            dataTest: 'cancel-button',
            style: styles.cancelButton,
            color: 'tertiary',
            size: 'small',
            buttonType: 'reset',
            handleClick: () => {
              dispatch(setSideView(null))
              dispatch(closeSideView())
            }
          },
          {
            editType: InputType.button,
            label: t('upcomingPayments.edit.save'),
            dataTest: 'save-button',
            size: 'small'
          }
        ]
      }
    ]
  }
}

export default generateUpdatePaymentMethodForm
