/* eslint-disable no-console */
import type { ZodSchema } from 'zod'

import { safeJsonStringify } from './safeJsonStringify'

export class LocalStorageService {
  constructor(private readonly storage: Storage = window.localStorage) {}

  getItem<T, Def extends Zod.ZodTypeDef, Input>(itemKey: string, schema: ZodSchema<T, Def, Input>): T | null {
    const itemRaw = this.storage.getItem(itemKey)
    if (itemRaw === null) {
      return null
    }
    try {
      const asObject = JSON.parse(itemRaw)
      return schema.parse(asObject)
    } catch (e) {
      console.warn(`Failed to retrieve item: '${itemKey}' from localStorage`, e)
      this.storage.removeItem(itemKey)
      return null
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setItem(itemKey: string, itemToBeSaved: any): void {
    if (typeof itemToBeSaved === 'object' || typeof itemToBeSaved === 'string') {
      const serialized = safeJsonStringify(itemToBeSaved)
      if (serialized === null) {
        console.warn('Failed to serialize', itemToBeSaved)
        return
      }
      this.storage.setItem(itemKey, serialized)
    } else if (this.#isSupportedPrimitive(itemToBeSaved)) {
      this.storage.setItem(itemKey, itemToBeSaved.toString())
      console.log(this.storage)
    } else {
      console.warn(
        `Tried to save an unsupported item to localStorage of key: '${itemKey}' type: '${typeof itemToBeSaved}'`,
        itemToBeSaved
      )
    }
  }

  clearItem(itemKey: string): void {
    this.storage.removeItem(itemKey)
  }

  #isSupportedPrimitive(obj: any): obj is number | string {
    return typeof obj === 'number' || typeof obj === 'boolean'
  }
}

export const localStorageService = new LocalStorageService()
