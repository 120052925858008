import { IAction } from '../../../interfaces/store'
import { IUiConfig } from '../api/productConfigApi/productConfigDto'
import {
  CREATE_NOTE_ERROR,
  CREATE_NOTE_START,
  CREATE_NOTE_STOP,
  CREATE_NOTE_SUCCESS,
  UPDATE_NOTE_START,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERROR,
  EDIT_NOTE_FINISH,
  GET_POLICY_AND_CONFIG_FAILURE,
  GET_POLICY_AND_CONFIG_REQUEST,
  GET_POLICY_AND_CONFIG_SUCCESS,
  SET_PURCHASE,
  LOAD_NOTES_ERROR,
  LOAD_NOTES_START,
  LOAD_NOTES_SUCCESS,
  RESET_STATE,
  SET_ACTIVE_SIDE_VIEW_TYPE,
  SET_POLICY,
  SUBMIT_FORM_ERROR,
  SUBMIT_FORM_START,
  SUBMIT_FORM_SUCCESS,
  UPDATE_IBAN_START,
  UPDATE_IBAN_SUCCESS,
  UPDATE_IBAN_ERROR,
  SET_EDITING_NOTE_ID,
  LOAD_CLAIM_DETAILS_SUCCESS,
  LOAD_CLAIM_DETAILS_START,
  LOAD_CLAIM_DETAILS_FAILURE,
  GET_TERMINATION_RULES_SUCCESS,
  GET_TERMINATION_RULES_FAILURE,
  EXECUTE_TERMINATION_RULE_SUCCESS,
  EXECUTE_TERMINATION_RULE_FAILURE,
  GET_TERMINATION_RULES_START,
  EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START,
  EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START,
  GET_REFUND_AMOUNT_START,
  GET_REFUND_AMOUNT_SUCCESS,
  GET_REFUND_AMOUNT_FAILURE,
  EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_FAILURE,
  EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_FAILURE,
  SET_MANDATE
} from '../constants'
import { PolicyTab, ModeType } from '../dictionaries'
import { IModalPayload, INote, IPolicy, IPolicyModuleState } from '../interfaces'

export const initialState: IPolicyModuleState = {
  activeTab: PolicyTab.Details,
  isFetching: false,
  isExecutingTerminationRuleWithExtraInputFields: false,
  isExecutingTerminationRuleWithoutExtraInputFields: false,
  errorMessage: null,
  policy: null,
  purchaseData: null,
  config: null,
  activeSideViewType: null,
  mode: ModeType.View,
  isSubmitting: false,
  isNoteCreating: false,
  isNoteUpdating: false,
  editingNoteId: null,
  notes: [],
  areNotesLoading: false,
  focusedClaim: null,
  terminationData: {
    rules: null,
    executedRule: null,
    refundAmount: null
  },
  mandate: null
}

function reducer(state: IPolicyModuleState = initialState, action: IAction<any>): IPolicyModuleState {
  switch (action.type) {
    case SET_PURCHASE:
      return {
        ...state,
        purchaseData: action.payload
      }
    case SET_MANDATE:
      return {
        ...state,
        mandate: action.payload
      }
    case GET_POLICY_AND_CONFIG_REQUEST:
      return {
        ...state,
        policy: null,
        notes: [],
        isFetching: true
      }
    case GET_POLICY_AND_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        policy: action.payload.policy as IPolicy,
        config: action.payload.config as IUiConfig
      }
    case GET_POLICY_AND_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        policy: null,
        errorMessage: action.payload as string
      }
    case SET_POLICY: {
      return {
        ...state,
        policy: action.payload as IPolicy
      }
    }
    case SET_ACTIVE_SIDE_VIEW_TYPE: {
      if (action.payload === null) {
        return {
          ...state,
          activeSideViewType: null
        }
      }

      const { type, mode, params }: IModalPayload = action.payload

      return {
        ...state,
        activeSideViewType: type,
        mode: mode as ModeType,
        modalParams: params
      }
    }
    case SUBMIT_FORM_START: {
      return {
        ...state,
        isSubmitting: true
      }
    }
    case SUBMIT_FORM_SUCCESS: {
      return {
        ...state,
        isSubmitting: false
      }
    }
    case SUBMIT_FORM_ERROR: {
      return {
        ...state,
        isSubmitting: false
      }
    }

    case UPDATE_IBAN_START: {
      return {
        ...state,
        isSubmitting: true
      }
    }
    case UPDATE_IBAN_SUCCESS: {
      return {
        ...state,
        isSubmitting: false
      }
    }
    case UPDATE_IBAN_ERROR: {
      return {
        ...state,
        isSubmitting: false
      }
    }

    case CREATE_NOTE_START: {
      return {
        ...state,
        isNoteCreating: true
      }
    }
    case CREATE_NOTE_STOP: {
      return {
        ...state,
        isNoteCreating: false
      }
    }
    case CREATE_NOTE_SUCCESS: {
      return {
        ...state,
        isNoteCreating: false
      }
    }
    case CREATE_NOTE_ERROR: {
      return {
        ...state,
        isNoteCreating: false
      }
    }
    case UPDATE_NOTE_START: {
      return {
        ...state,
        isNoteUpdating: true
      }
    }
    case UPDATE_NOTE_SUCCESS: {
      return {
        ...state,
        isNoteUpdating: false,
        editingNoteId: null
      }
    }
    case UPDATE_NOTE_ERROR: {
      return {
        ...state,
        isNoteUpdating: false
      }
    }
    case SET_EDITING_NOTE_ID: {
      return {
        ...state,
        editingNoteId: action.payload
      }
    }
    case EDIT_NOTE_FINISH: {
      return {
        ...state,
        editingNoteId: null
      }
    }
    case LOAD_NOTES_START: {
      return {
        ...state,
        areNotesLoading: true
      }
    }
    case LOAD_NOTES_SUCCESS: {
      const newNotes: INote[] = action.payload

      if (newNotes.length === 0) {
        return {
          ...state,
          areNotesLoading: false
        }
      }

      if (newNotes[0].pagination.offset > 0) {
        return {
          ...state,
          areNotesLoading: false,
          notes: [...state.notes, ...newNotes]
        }
      }

      return {
        ...state,
        areNotesLoading: false,
        notes: newNotes
      }
    }
    case LOAD_NOTES_ERROR: {
      return {
        ...state,
        areNotesLoading: false,
        notes: []
      }
    }
    case LOAD_CLAIM_DETAILS_START: {
      return {
        ...state,
        focusedClaim: null
      }
    }
    case LOAD_CLAIM_DETAILS_SUCCESS: {
      return {
        ...state,
        focusedClaim: action.payload
      }
    }
    case LOAD_CLAIM_DETAILS_FAILURE: {
      return {
        ...state,
        focusedClaim: null
      }
    }
    case GET_TERMINATION_RULES_START: {
      return {
        ...state,
        isFetching: true,
        terminationData: {
          rules: null,
          executedRule: null,
          refundAmount: null
        }
      }
    }
    case GET_TERMINATION_RULES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        terminationData: {
          rules: action.payload.rules,
          executedRule: null,
          refundAmount: null
        }
      }
    }
    case GET_TERMINATION_RULES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        terminationData: {
          rules: null,
          executedRule: null,
          refundAmount: null
        }
      }
    }
    case EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_START: {
      return {
        ...state,
        isExecutingTerminationRuleWithExtraInputFields: true,
        terminationData: { ...state.terminationData, executedRule: null }
      }
    }
    case EXECUTE_TERMINATION_RULE_WITH_EXTRA_INPUT_FIELDS_FAILURE: {
      return {
        ...state,
        isExecutingTerminationRuleWithExtraInputFields: false,
        terminationData: { ...state.terminationData, executedRule: null }
      }
    }
    case EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_START: {
      return {
        ...state,
        isExecutingTerminationRuleWithoutExtraInputFields: true,
        terminationData: { ...state.terminationData, executedRule: null }
      }
    }
    case EXECUTE_TERMINATION_RULE_WITHOUT_EXTRA_INPUT_FIELDS_FAILURE: {
      return {
        ...state,
        isExecutingTerminationRuleWithoutExtraInputFields: false,
        terminationData: { ...state.terminationData, executedRule: null }
      }
    }
    case EXECUTE_TERMINATION_RULE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isExecutingTerminationRuleWithExtraInputFields: false,
        isExecutingTerminationRuleWithoutExtraInputFields: false,
        terminationData: { ...state.terminationData, executedRule: action.payload }
      }
    }
    case EXECUTE_TERMINATION_RULE_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isExecutingTerminationRuleWithExtraInputFields: false,
        isExecutingTerminationRuleWithoutExtraInputFields: false,
        terminationData: { ...state.terminationData, executedRule: null }
      }
    }
    case GET_REFUND_AMOUNT_START: {
      return {
        ...state,
        isFetching: true,
        terminationData: { ...state.terminationData, refundAmount: null }
      }
    }
    case GET_REFUND_AMOUNT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        terminationData: { ...state.terminationData, refundAmount: action.payload }
      }
    }
    case GET_REFUND_AMOUNT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        terminationData: { ...state.terminationData, refundAmount: null }
      }
    }
    case RESET_STATE: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
