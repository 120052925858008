/* eslint-disable require-yield */
import { isNil } from 'lodash'
import { call, debounce, put, takeLatest } from 'typed-redux-saga'

import { DaveUiEventType, Parcels } from '@alteos/dave-ui-lib'

import { events } from '../../../platform/events'
import { LOGIN_START, LOGIN_SUCCESS, LOGOUT, REFRESH_TOKEN } from '../constants'
import { ILoginStartAction, ILoginSuccessAction, ILogoutAction, IRefreshTokenAction } from '../interfaces'
import { authCache } from '../service/authCache'
import { authService } from '../service/authService/authService'
import { tokenCache } from '../service/tokenCache'
import { loginFail, loginSuccess } from './actionCreators'

function* onStartLogin({ payload }: ILoginStartAction): Generator {
  const { username, password } = payload
  try {
    const loginResponse = yield* call(authService.login, username, password)
    yield* put(loginSuccess({ loginResponse: loginResponse }))
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.log('Error while trying to authenticate!', error)
    yield* put(loginFail({ errorMessage: 'IncorrectEmailPassword' }))
  }
}

function* onLoginSuccess({ payload: { loginResponse } }: ILoginSuccessAction): Generator {
  authCache.save(loginResponse)
  tokenCache.setAccessToken(loginResponse.token)
  if (!isNil(loginResponse.refreshToken)) {
    tokenCache.setRefreshToken(loginResponse.refreshToken)
  } else {
    tokenCache.clearRefreshToken()
  }
  events.emit({
    type: DaveUiEventType.UserSignedIn,
    src: Parcels.Dave,
    userId: loginResponse.userId,
    permissions: loginResponse.permissions,
    token: loginResponse.token,
    refreshToken: loginResponse.refreshToken
  })
}

function* onLogout(_action: ILogoutAction): Generator {
  authCache.clear()
  tokenCache.clearTokens()
  events.emit({
    type: DaveUiEventType.UserSignedOut,
    src: Parcels.Dave
  })
}

function* onTokenRefresh({ payload }: IRefreshTokenAction): Generator {
  tokenCache.setAccessToken(payload.token)
  if (!isNil(payload.refreshToken)) {
    tokenCache.setRefreshToken(payload.refreshToken)
  } else {
    tokenCache.clearRefreshToken()
  }
  events.emit({
    type: DaveUiEventType.UserTokenRefreshed,
    src: Parcels.Dave,
    refreshToken: payload.refreshToken,
    token: payload.token
  })
}

function* watchLoginStart(): Generator {
  yield* takeLatest(LOGIN_START, onStartLogin)
}

function* watchLoginSuccess(): Generator {
  yield* takeLatest(LOGIN_SUCCESS, onLoginSuccess)
}

function* watchLogout(): Generator {
  // NOTE: sometimes multiple failed HTTP requests might
  // trigger the logout() action, by using
  // [debounce] we'll group them together
  const LOGOUT_DEBOUNCE = 100
  yield* debounce(LOGOUT_DEBOUNCE, LOGOUT, onLogout)
}

function* watchTokenRefresh(): Generator {
  yield* takeLatest(REFRESH_TOKEN, onTokenRefresh)
}

export default (): Generator[] => [watchLoginStart(), watchLoginSuccess(), watchLogout(), watchTokenRefresh()]
