import { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { MarkdownContentContainer, LandingButton as Button } from '@alteos/ui'

import Logo from '../../../../components/LogoButton'
import { POLICIES_ROUTE } from '../../../Policies'
import { ReactComponent as NotFoundImage } from '../../assets/NotFoundImage.svg'

import styles from './styles.module.scss'

interface IProps {
  title: string
  content: string
  subContent: string
  button: {
    to?: string
    handleClick?: () => void
    label: string
    icon: string
  }
  dataTest: string
  image?: ReactElement
  handleLogoClick?: () => void
}

const StatusContent: FC<IProps> = ({
  title,
  content,
  subContent,
  button,
  image,
  handleLogoClick,
  dataTest
}: IProps) => {
  const navigate = useNavigate()
  return (
    <div className={styles.container} data-test={`${dataTest}-container`}>
      <Logo
        handleClick={typeof handleLogoClick !== 'undefined' ? handleLogoClick : () => navigate(POLICIES_ROUTE)}
        className={styles.logo}
      />
      <div className={styles.mainContentWrapper}>
        <div className={styles.mainContentContainer}>
          <div className={styles.image} data-test={`${dataTest}-image`}>
            {typeof image !== 'undefined' ? image : <NotFoundImage />}
          </div>
          <div className={styles.contentContainer}>
            <MarkdownContentContainer dataTest={`${dataTest}-title`} content={`<h1>${title}</h1>`} />
            <p className={styles.content}>{content}</p>
            <p className={styles.subContent}>{subContent}</p>
            <Button
              className={styles.button}
              to={button.to}
              handleClick={button.handleClick}
              icon={button.icon}
              dataTest={`${dataTest}-button`}
            >
              {button.label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatusContent
