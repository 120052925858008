import { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'

import { ModalContext } from './ModalContext'

interface IProps {
  children: ReactNode
  modalId: string
  isOpen: boolean
  variant?: 'default' | 'info' | 'success' | 'warning'
}

export const ModalRoot: FC<IProps> = ({ isOpen, children, modalId, variant = 'default' }: IProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      id={modalId}
      style={{
        overlay: {
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 100
        },
        content: {
          position: 'unset',
          maxWidth: 480,
          width: '100%',
          padding: 0,
          borderRadius: 8,
          border: '1px solid rgba(0, 0, 0, .12)'
        }
      }}
    >
      <ModalContext.Provider value={{ variant }}>{children}</ModalContext.Provider>
    </ReactModal>
  )
}
