import { IReferralPartnerStatus, PartnerType } from '../../interfaces'

export const defaultReferralPartner = {
  id: '',
  companyName: '',
  legalRepFirstName: '',
  legalRepLastName: '',
  legalEntity: '',
  salesTaxIdNumber: '',
  issuingAuthority: '',
  companyDisplayName: '',
  companyRegistrationNumber: '',
  companyAddressCity: '',
  companyAddressHouseNumber: '',
  companyAddressPlz: '',
  companyAddressStreet: '',
  companyPhone: '',
  companyEmail: '',
  type: '' as PartnerType,
  productSelectionIds: '',
  subdomain: '',
  source: '',
  IBAN: '',
  shopUrl: '',
  billingEmail: '',
  country: '',
  distributionChannel: null,
  products: [],
  status: 'new' as IReferralPartnerStatus
}
