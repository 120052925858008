import { isNil, omit, omitBy } from 'lodash'
import { call, put, select } from 'typed-redux-saga'

import { IAction, IStore } from '../../../../interfaces/store'
import { referralPartnersApi } from '../../api/referralPartnersApi'
import { MODULE_NAME } from '../../constants'
import { IPartnerQueryRequest, IReferralPartnersResponse } from '../../interfaces/referralPartners'
import { loadReferralPartnersFailure, loadReferralPartnersSuccess, saveErrorMessage } from '../actions/referralPartners'
import { getFiltersParams } from '../utils/filtersUtils'
import mapReferralPartnersList from '../utils/mapReferralPartnersList'

export function* loadReferralPartners(action: IAction<IPartnerQueryRequest>): Generator {
  const referralPartnersState = yield* select((state: IStore) => state[MODULE_NAME].referralPartners)
  const filters = referralPartnersState.filters
  const activeFilters = getFiltersParams(filters)
  const { payload = {} } = action

  const queryObject = {
    ...referralPartnersState.pagination,
    ...omit(payload, 'query'),
    query: omitBy(
      {
        ...referralPartnersState.query,
        ...activeFilters,
        ...(isNil(payload.query) ? {} : payload.query)
      },
      (value?: unknown) => isNil(value) || value === ''
    )
  }

  try {
    const response: IReferralPartnersResponse = yield* call(referralPartnersApi.getReferralPartners, queryObject)

    yield* put(loadReferralPartnersSuccess(mapReferralPartnersList(response)))
  } catch (error: unknown) {
    yield* put(saveErrorMessage('Failed to fetch referralPartner requests', error))
    yield* put(loadReferralPartnersFailure())
  }
}

export function* watchFilterChangesReferralPartner(): Generator {
  yield* loadReferralPartners({} as any)
}
