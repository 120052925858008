import { FC, ReactElement } from 'react'
import { TFunction } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Card, ICardSection, ExpandableCard } from '../../../../../components/Cards'
import FieldType from '../../../../../dictionaries/FieldType'
import { closeSideView } from '../../../../Layout/store/actionCreators'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { ClaimStatusItem } from '../../../components/status/ClaimStatusItem'
import { ClaimSubStatusItem } from '../../../components/SubStatus/ClaimSubStatusItem'
import { SIDE_VIEW_ANIMATION_DURATION } from '../../../constants'
import { ModeType, PolicySideViewType } from '../../../dictionaries'
import { IClaim } from '../../../interfaces'
import { setModal } from '../../../store/actionCreators'
import ClaimDocumentsTable from './ClaimDocumentsTable'
import ClaimHistoryTable from './ClaimHistoryTable'

import styles from './styles.module.scss'

interface IProps {
  claim: IClaim
  config: IUiConfig
  t: TFunction
}

const ClaimView: FC<IProps> = ({ claim, config, t }: IProps): ReactElement | null => {
  const dispatch = useDispatch()
  const { claimsCards } = config
  const { id } = claim
  const claimTitle = `${t('editClaimForm.headerCard.title')}: ${claim.prettyId}`

  const headerCard: ICardSection = {
    fields: [
      {
        type: FieldType.Element,
        value: (source: IClaim) => {
          return <ClaimStatusItem value={source.status} label={t('editClaimForm.headerCard.status')} />
        }
      },
      {
        type: FieldType.Element,
        value: (source: IClaim) => {
          return <ClaimSubStatusItem value={source.subStatus} label={t('claims.cards.subStatus')} />
        }
      },
      {
        label: t('editClaimForm.headerCard.lastUpdatedAt'),
        id: 'updatedAt',
        formatFunction: 'formatDate'
      },
      {
        type: FieldType.Button,
        children: t('editClaimForm.headerCard.editClaim'),
        size: 'full',
        color: 'outline',
        icon: 'pencil',
        handleClick: async () => {
          dispatch(setModal(null))
          dispatch(closeSideView())
          setTimeout(() => {
            dispatch(
              setModal({
                type: PolicySideViewType.ClaimDetails,
                mode: ModeType.Edit,
                params: {
                  id
                }
              })
            )
          }, SIDE_VIEW_ANIMATION_DURATION)
        }
      }
    ]
  }

  return (
    <div className={styles.container}>
      <ExpandableCard
        className={styles.card}
        title={claimTitle}
        withLargeHeading={true}
        isExpandable={false}
        icon="claims"
        expandedContent={<Card className={styles.subCard} content={{ fields: headerCard.fields }} source={claim} />}
        handleClick={() => dispatch(setModal(null))}
      />
      <ExpandableCard
        className={styles.card}
        title={t('createClaimForm.historyTable.title')}
        expandedContent={<ClaimHistoryTable claim={claim} t={t} />}
      />
      {claimsCards.claimDetailsCards.map((card: ICardSection, index: number) => (
        <ExpandableCard
          className={styles.card}
          title={card.title as string}
          key={index}
          expandedContent={<Card className={styles.subCard} content={{ fields: card.fields }} source={claim} inline />}
        />
      ))}
      <ExpandableCard
        className={styles.card}
        title={t('claimsSideView.documents')}
        expandedContent={<ClaimDocumentsTable claim={claim} t={t} />}
      />
    </div>
  )
}

export default ClaimView
