import cx from 'classnames'
import { FC, ReactElement } from 'react'

import { Card, IButton } from '..'
import { IBasicProps } from '../../../interfaces/common'
import { ICardSection } from '../interfaces'

import styles from './enhancedCard.module.scss'

interface IProps extends IBasicProps {
  card: ICardSection
  cardHeader?: ReactElement | null
  sidebarIcon?: ReactElement | null
  cardButtons?: IButton[]
  withBackground?: boolean
  source?: Record<string, any>
  helpers?: Record<string, any>
  url?: string | null
  isInline?: boolean
  handleClick?: () => void
}

const EnhancedCard: FC<IProps> = ({
  card,
  cardHeader = null,
  sidebarIcon = null,
  cardButtons,
  withBackground,
  className = '',
  source,
  helpers,
  dataTest,
  isInline = false,
  url = null,
  handleClick
}: IProps): ReactElement => {
  const sidebar: ReactElement | null = sidebarIcon !== null ? <div className={styles.sidebar}>{sidebarIcon}</div> : null
  const content: ReactElement = (
    <>
      {sidebar}
      <div className={styles.wrapper}>
        {cardHeader}
        <Card
          helpers={helpers}
          isNode
          className={className}
          inline={isInline}
          content={card}
          withBackground={withBackground}
          source={source}
          buttons={cardButtons}
        />
      </div>
    </>
  )

  if (url !== null) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        data-test={dataTest}
        className={cx(styles.container, styles.link, className, { [styles.withBackground]: withBackground })}
      >
        {content}
      </a>
    )
  }

  // NOTE: for when entire card is clickable e.g. claims card
  if (typeof handleClick !== 'undefined') {
    return (
      <button
        className={cx(styles.container, styles.button, className, { [styles.withBackground]: withBackground })}
        onClick={handleClick}
        data-test={dataTest}
      >
        {content}
      </button>
    )
  }

  return (
    <div className={cx(styles.container, className, { [styles.withBackground]: withBackground })} data-test={dataTest}>
      {content}
    </div>
  )
}

export default EnhancedCard
