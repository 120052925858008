import { Dispatch } from 'redux'

import { IFormProps } from '../../../components/Form/Form'
import { FormType } from '../../../components/Form/FormType'
import { IFormConfig } from '../../../components/Form/interfaces'
import { setModal, submitFormStart } from '../store/actionCreators'

const generateReduxFormConfig = (config: IFormConfig, dispatch: Dispatch, options: Partial<IFormProps>): IFormProps => {
  const finalConfig: IFormProps = {
    ...config,
    initialValues: config.initialValues,
    hasFormButtons: false,
    isSubmitting: false,
    formType: FormType.CREATE,
    dataTest: config.form,
    onClose: () => dispatch(setModal(null)),
    onSubmit: () => dispatch(submitFormStart(finalConfig)),
    ...options
  }
  return finalConfig
}

export default generateReduxFormConfig
