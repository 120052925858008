import cx from 'classnames'
import { FC } from 'react'

import { Button } from '@alteos/ui'

import { IButtonViewFieldConfig } from '../../../../../interfaces/common'

import styles from '../../card.module.scss'

interface IProps {
  isInline: boolean
  field: IButtonViewFieldConfig
}

const ButtonItem: FC<IProps> = ({ field, isInline }: IProps) => {
  const isFullWidth: boolean = field.size === 'full'
  return (
    <div
      className={cx(styles.item, styles.text, {
        [styles.fullWidth]: isFullWidth,
        [styles.grid]: !isInline
      })}
    >
      <span className={styles.itemContent}>
        <Button {...field} type="button" className={styles.button} dataTest={`${field.children}-button`} small />
      </span>
    </div>
  )
}

export default ButtonItem
