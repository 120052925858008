import React, { useRef } from 'react'

import { IParcel, Parcels } from '@alteos/dave-ui-lib'

import { useAsyncLoader } from '../../../../hooks/useAsyncLoader'
import { useOnMountEffect } from '../../../../hooks/useOnMountEffect'
import assertUnreachable from '../../../../utils/assertUnreachable'
import { requireNotNull } from '../../../../utils/requireNotNull'
import Layout from '../Layout'

interface IMicrofrontEndModule {
  default: {
    parcel: IParcel
  }
}

export interface IMicrofrontendLoaderProps {
  parcel: Parcels
}

function getImportStatement(parcel: Parcels): Promise<IMicrofrontEndModule> {
  switch (parcel) {
    case Parcels.Dave:
      throw new Error('Parcels.Dave not supported')
    case Parcels.Claim:
      return import('claim-ui/parcel')
    case Parcels.Policy:
      return import('policy-ui/parcel')
    default:
      return assertUnreachable(parcel)
  }
}

export function MicrofrontendLoader({ parcel }: IMicrofrontendLoaderProps): React.ReactElement {
  const { data: module, isFetching } = useAsyncLoader<IMicrofrontEndModule | null>(
    () =>
      getImportStatement(parcel).catch((reason) => {
        // eslint-disable-next-line no-console
        console.error('Failed to load module', reason)
        return null
      }),
    []
  )
  return (
    <Layout searchContent={null} sideViewContent={null} noPadding={true}>
      <>
        {isFetching && 'Loading..'}
        {!isFetching && module === null && 'Failed to load sub-application, check back later'}
        {!isFetching && module !== null && <RouteWrapper module={module} />}
      </>
    </Layout>
  )
}

interface IRouteWrapperProps {
  module: IMicrofrontEndModule
}

function RouteWrapper({
  module: {
    default: { parcel }
  }
}: IRouteWrapperProps) {
  const mountAt = useRef<HTMLDivElement | null>(null)

  useOnMountEffect(() => {
    const mountAtNode = requireNotNull(mountAt.current)
    parcel.mountToNode(mountAtNode)
    return () => {
      parcel.unmount(mountAtNode)
    }
  })

  return <div ref={mountAt} />
}
