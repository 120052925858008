import { FC } from 'react'
import { TFunction } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Form, IFormProps } from '../../../../../components/Form/Form'
import { FormType } from '../../../../../components/Form/FormType'
import { IFormConfig } from '../../../../../components/Form/interfaces'
import { Container, EditModeLabel } from '../../../../../components/sideViewComponents'
import { IStore } from '../../../../../interfaces/store'
import { getCurrentLanguage } from '../../../../i18n/selectors'
import { ConfirmationModal } from '../../../../Modal/Modals/ConfirmationModal'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { forms, SIDE_VIEW_ANIMATION_DURATION } from '../../../constants'
import { ModeType, PolicySideViewType } from '../../../dictionaries'
import { IClaim, IPolicy } from '../../../interfaces'
import { setModal, submitFormStart } from '../../../store/actionCreators'
import { generateReduxFormConfig } from '../../../utils'
import generateEditClaimFormCards from '../utils/generateEditClaimFormCards'
import {
  EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID,
  EDIT_CLAIM_WITH_STATUS_CHANGE_CONFIRMATION_MODAL_ID
} from './consts'

import styles from './styles.module.scss'

interface IProps {
  config: IUiConfig
  policy: IPolicy
  claim: IClaim
  isSubmitting: boolean
  t: TFunction
}

const EditClaimForm: FC<IProps> = ({ config, policy, claim, t, isSubmitting }: IProps) => {
  const dispatch = useDispatch()
  const language = useSelector(getCurrentLanguage)

  const formValues = useSelector((state: IStore) => state.form[forms.editClaimForm]?.values)

  const claimStatus = formValues?.status

  if (config === null || policy === null || claim === null) {
    return null
  }

  const formConfig: IFormConfig = generateEditClaimFormCards(config, claim, policy, t, language, claimStatus)
  const claimEditTitle = `${t('editClaimForm.title')}: ${claim.prettyId}`
  const closeClaimWarningModalContent = t('editClaimForm.closeClaimWarningModalContent')
  const closeClaimStatusChangeWarningModalContent = t('editClaimForm.closeClaimStatusChangeWarningModalContent')

  const formProps: IFormProps = generateReduxFormConfig(formConfig, dispatch, {
    source: policy,
    isSubmitting,
    formType: FormType.EDIT,
    onSubmit: () => dispatch(submitFormStart(formProps)),
    onClose: () => {
      dispatch(setModal(null))
      setTimeout(() => {
        dispatch(
          setModal({
            type: PolicySideViewType.ClaimDetails,
            mode: ModeType.View,
            params: {
              id: claim.id
            }
          })
        )
      }, SIDE_VIEW_ANIMATION_DURATION)
    }
  })

  return (
    <>
      <Container title={claimEditTitle} icon="pencil" className={styles.title} label={<EditModeLabel />} />
      <ConfirmationModal modalId={EDIT_CLAIM_WITH_CLOSE_CONFIRMATION_MODAL_ID}>
        <p>{closeClaimWarningModalContent}</p>
      </ConfirmationModal>
      <ConfirmationModal modalId={EDIT_CLAIM_WITH_STATUS_CHANGE_CONFIRMATION_MODAL_ID}>
        <p>{closeClaimStatusChangeWarningModalContent}</p>
      </ConfirmationModal>
      <Form {...formProps} />
    </>
  )
}

export default EditClaimForm
