import { PaymentTab } from '../../interfaces'

export const getInitialPaymentsPageTab = (): PaymentTab => {
  if (typeof window !== 'undefined') {
    try {
      const queryParams = new URLSearchParams(window.location.search)
      const activeTab = queryParams.get('tab')
      if (Object.values(PaymentTab).includes(activeTab as PaymentTab)) {
        return activeTab as PaymentTab
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while getting tab from query params', e)
    }
  }
  return PaymentTab.Upcoming
}
